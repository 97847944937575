export default Object.freeze({
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_NATIONALITY: "SET_NATIONALITY",
  SET_JOB_FUNCTION: "SET_JOB_FUNCTION",
  SET_PROJECT: "SET_PROJECT",
  SET_COUNTRY: "SET_COUNTRY",
  SET_EMPLOYER: "SET_EMPLOYER",
  SET_NOTIFICATION_TOKEN: "SET_NOTIFICATION_TOKEN",
  SET_NOTIFICATION_BINDING: "SET_NOTIFICATION_BINDING",
  SET_SETUP_STATUS: "SET_SETUP_STATUS",
  SET_LOCAL_TOKEN: "SET_LOCAL_TOKEN",
  SET_REPORT_ISSUE_VIEW: "SET_REPORT_ISSUE_VIEW",
  APP_RESET: "APP_RESET",
  SEE_WELCOME_MESSAGE: "SEE_WELCOME_MESSAGE",
  MOOD_RESPONSE: "MOOD_RESPONSE",
  SET_FORCE_RTL: "SET_FORCE_RTL"
});
