import { Card, CardItem, Content } from "native-base";
import React, { Component } from "react";
import { Image, StyleSheet } from "react-native";
import { connect } from "react-redux";
import HeaderImage from "../../assets/img/resources/resources_header_image.png";
import { Screen } from "../../utils";
import items from "./items";

export default Screen(
  connect(state => ({
    settings: state.settings,
    projectProfile: state.projectProfile.projectProf
  }))(
    class ResourcesTab extends Component {
      render() {
        return (
          <Content>
            <Card headerImage>
              <CardItem>
                <Image source={HeaderImage} style={style.headerImage} />
              </CardItem>
            </Card>
            {items.map(ItemComponent => ItemComponent(this.props))}
          </Content>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  headerImage: {
    height: 200,
    width: null,
    flex: 1
  }
});
