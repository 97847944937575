import React from "react";
import { TouchableOpacity } from "react-native";
import { Body, Card, CardItem, Left, Text, Thumbnail } from "native-base";
import AboutImage from "../../../assets/img/resources/Resources_about.png";
import { setChildHeader } from "../../mainTabNavigator/actions";
import I18n from "../../../i18n";

export default function ProjectProfile(props) {
  return (
    <TouchableOpacity
      testID="projectProfile"
      key="projectProfile"
      onPress={() => {
        props.navigation.navigate("ProjectDetails");
        setChildHeader("appStrings.CATEGORY_YOUR_PROJECT");
      }}
    >
      <Card resourcecard>
        <CardItem>
          <Left>
            <Thumbnail source={AboutImage} />
            <Body>
              <Text title>{I18n.t("appStrings.CATEGORY_YOUR_PROJECT")}</Text>
              <Text description>
                {I18n.t("appStrings.CATEGORY_DESC_YOUR_PROJECT")}
              </Text>
            </Body>
          </Left>
        </CardItem>
      </Card>
    </TouchableOpacity>
  );
}
