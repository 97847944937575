import React from "react";
import { throttle } from "throttle-debounce";

import { View, Text } from "native-base";
import { TouchableOpacity, StyleSheet, Platform } from "react-native";
import Autocomplete from "react-native-autocomplete-input";
import wcColors from "../../native-base-theme/variables/wcColors";

const AutoComplete = ({
  data,
  label,
  placeholder,
  onChangeText,
  onSelect,
  value
}) => {
  const handleChangeText = throttle(200, onChangeText);
  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.container}>
        <Autocomplete
          style={styles.textInput}
          listContainerStyle={styles.listContainer}
          listStyle={styles.list}
          data={data}
          value={value || ""}
          onEndEditing={e => console.log(e)}
          onChangeText={text => handleChangeText(text)}
          placeholder={placeholder}
          placeholderTextColor={wcColors.demoGrey6}
          keyExtractor={(item, index) => `${index}__${item.value}`}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() => {
                onSelect(item);
              }}
            >
              <Text autosuggest>{item.label}</Text>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    color: wcColors.demoGrey3,
    fontSize: 16,
    marginTop: 10
  },
  textInput: {
    backgroundColor: "white",
    height: 40,
    paddingLeft: 8
  },
  container: {
    flex: 1,
    marginVertical: 5
  },
  listContainer: {
    flex: 1,
    width: "100%",
    padding: 0
  },
  list: {
    position: "relative",
    flex: 1,
    marginTop: 0,
    margin: 0,
    padding: 10,
    borderWidth: 1,
    borderTopWidth: 1,
    borderColor: wcColors.fullList.border
  }
});

export default AutoComplete;
