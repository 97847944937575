import React from "react";
import { TouchableOpacity } from "react-native";
import { Body, Card, CardItem, Left, Text, Thumbnail } from "native-base";
import Promises from "./Promises";
import PromiseDetails from "./PromiseDetails";
import { setChildHeader } from "../../screens/mainTabNavigator/actions";
import PromiseImage from "../../assets/img/resources/promises/image0.png";
import actionTypes from "./actionTypes";
import reducer from "./reducer";
import I18n from "../../i18n";

export default {
  resources: {
    mainSelector: props => {
      return (
        <TouchableOpacity
          testID="promises"
          onPress={() => {
            props.navigation.navigate("Promises");
            setChildHeader(I18n.t("appStrings.PROMISES_GUIDE_TITLE"));
          }}
        >
          <Card resourcecard>
            <CardItem>
              <Left>
                <Thumbnail source={PromiseImage} />
                <Body>
                  <Text title>{I18n.t("appStrings.PROMISES_GUIDE_TITLE")}</Text>
                  <Text description>
                    {I18n.t("appStrings.PROMISES_GUIDE_INFO")}
                  </Text>
                </Body>
              </Left>
            </CardItem>
          </Card>
        </TouchableOpacity>
      );
    },
    routes: {
      Promises: {
        screen: Promises,
        path: "resources/promises",
        navigationOptions: {
          title: "appStrings.PROMISES_GUIDE_TITLE",
          route: "ResourcesTab",
          hasBack: true
        }
      },
      PromiseDetails: {
        screen: PromiseDetails,
        path: "resources/promises/:promise",
        navigationOptions: {
          title: "appStrings.PROMISES_GUIDE_TITLE",
          route: "ResourcesTab",
          hasBack: true
        }
      }
    }
  },
  actionTypes,
  reducer
};
