export default [
  {
    category: "fundamental",
    description:
      "Conform Constituției României, ești liber să-ți exprimi părerea, gândurile și credințele.",
    translations: {
      ro:
        "Conform Constituției României, ești liber să-ți exprimi părerea, gândurile și credințele.",
      en:
        "According to the Romanian Constitution, you are free to express opinions, thoughts and beliefs."
    },
    citations: [
      {
        source: "Art. 30, alin.1) din Constituție",
        text:
          "Libertatea de exprimare a gândurilor, a opiniilor sau a credințelor și libertatea creațiilor de orice fel, prin viu grai, prin scris, prin imagini, prin sunete sau prin alte mijloace de comunicare în public, sunt inviolabile."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1db6ca465001673e4fb" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" }
    ]
  },
  {
    category: "fundamental",
    description:
      "Ai dreptul de a fi consultat de către angajator și dreptul de a fi informat de către autorități. Inclusiv Colegiul Medicilor trebuie să-ți răspundă la informațiile solicitate.",
    translations: {
      ro:
        "Ai dreptul de a fi consultat de către angajator și dreptul de a fi informat de către autorități. Inclusiv Colegiul Medicilor trebuie să-ți răspundă la informațiile solicitate.",
      en:
        "You have the right to be consulted by your employer and the right to be kept informed by authorities. Even The Medical College must answer requests for information."
    },
    citations: [
      {
        source: "Art. 31 din Constituție",
        text:
          "Art. 31, Constituția României:\n(1) Dreptul persoanei de a avea acces la orice informație de interes public nu poate fi îngrădit.\n(2) Autoritățile publice, potrivit competențelor ce le revin, sunt obligate să asigure informarea corectă a cetățenilor asupra treburilor publice și asupra problemelor de interes personal."
      },
      {
        source: "Art 39 din Codul Muncii",
        text:
          "Art. 39 (1), Codul Muncii\nh) dreptul la informare și consultare."
      },
      {
        source: "Art. 38 din Legea 306/2004 lit. b)",
        text:
          "Membrii Colegiului Medicilor din România au următoarele drepturi:\n[...]\nsă se adreseze organelor abilitate ale Colegiului Medicilor din România şi să primească informaţiile solicitate;\n[...]"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4ec" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" },
      { issuetype_id: "5f7ff1dc6ca465001673e505" }
    ]
  },
  {
    category: "fundamental",
    description:
      "Suntem cu toții egali în drepturi și trebuie să fim tratați egal. Sunt interzise privilegiile și discriminarea (directă sau indirectă, pe orice criteriu - sex, vârstă, rasă, etc).",
    translations: {
      ro:
        "Suntem cu toții egali în drepturi și trebuie să fim tratați egal. Sunt interzise privilegiile și discriminarea (directă sau indirectă, pe orice criteriu - sex, vârstă, rasă, etc).",
      en:
        "We are all equal in rights and must be treated the same. Privileges and discrimination (either directly or indirectly, for any reason - gender, age, race, etc) are forbidden."
    },
    citations: [
      {
        source: "Art. 16 din Constituție",
        text:
          "Art 16, Constituția României:\n(1) Cetățenii sunt egali în fața legii și a autorităților publice, fără privilegii și fără discriminări."
      },
      {
        source: "Art. 5 din Codul Muncii",
        text:
          "Art. 5, Codul Muncii:\n1) În cadrul relațiilor de muncă funcționează principiul egalității de tratament față de toți salariații și angajatorii.\n(2) Orice discriminare directă sau indirectă față de un salariat, bazată pe criterii de sex, orientare sexuală, caracteristici genetice, vârstă, apartenență națională, rasă, culoare, etnie, religie, opțiune politică, origine socială, handicap, situație sau responsabilitate familială, apartenență ori activitate sindicală, este interzisă."
      },
      {
        source: "Art. 39 (1) din Codul Muncii",
        text:
          "Art. 39 (1), Codul Muncii:\nd) dreptul la egalitate de șanse și de tratament;"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1da6ca465001673e4f4" },
      { issuetype_id: "5f7ff1da6ca465001673e4f7" },
      { issuetype_id: "5f7ff1db6ca465001673e4f8" },
      { issuetype_id: "5f7ff1db6ca465001673e4f9" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" }
    ]
  },
  {
    category: "fundamental",
    description: "Ai dreptul să faci petiții către autoritățile publice.",
    translations: {
      ro: "Ai dreptul să faci petiții către autoritățile publice.",
      en: "You have the right to initiate petitions towards public authorities."
    },
    citations: [
      {
        source: "Art. 51 din Constituție",
        text:
          "(1) Cetățenii au dreptul să se adreseze autorităților publice prin petiții formulate numai în numele semnatarilor."
      }
    ]
  },
  {
    category: "fundamental",
    description:
      "Ca lucrător în U.E., ești protejat în caz de concediere nejustificată.",
    translations: {
      ro:
        "Ca lucrător în U.E., ești protejat în caz de concediere nejustificată.",
      en: "As an E.U. worker, you are protected against unlawful dismissal. "
    },
    citations: [
      {
        source: "Art. 30 din Carta Europeană a Drepturilor Fundamentale",
        text:
          "Orice lucrător are dreptul la protecție împotriva oricărei concedieri nejustificate, în conformitate cu dreptul Uniunii și cu legislațiile și practicile naționale."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1db6ca465001673e4f9" },
      { issuetype_id: "5f7ff1db6ca465001673e4fa" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" }
    ]
  },
  {
    category: "fundamental",
    description:
      "Autoritățile trebuie să te trateze imparțial, echitabil, într-un termen rezonabil. ",
    translations: {
      ro:
        "Autoritățile trebuie să te trateze imparțial, echitabil, într-un termen rezonabil. ",
      en:
        "Authorities must treat you objectively, fairly and within reasonable time."
    },
    citations: [
      {
        source: "Art. 41 din Carta Europeană a Drepturilor Fundamentale",
        text:
          "(1) Orice persoană are dreptul de a beneficia, în ce privește problemele sale, de un tratament imparțial, echitabil și într-un termen rezonabil din partea instituțiilor, organelor, oficiilor și agențiilor Uniunii."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d86ca465001673e4eb" },
      { issuetype_id: "5f7ff1d86ca465001673e4ec" },
      { issuetype_id: "5f7ff1d96ca465001673e4f1" },
      { issuetype_id: "5f7ff1d96ca465001673e4f3" },
      { issuetype_id: "5f7ff1da6ca465001673e4f5" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1dd6ca465001673e509" }
    ]
  },
  {
    category: "employee",
    description:
      "E dreptul tău să lucrezi într-un mediu sigur și sănătos, în care să ți se respecte demnitatea și conștiința. ",
    translations: {
      ro:
        "E dreptul tău să lucrezi într-un mediu sigur și sănătos, în care să ți se respecte demnitatea și conștiința. ",
      en:
        "It is your right to work in a secure and healthy environment where your dignity and conscience are respected."
    },
    citations: [
      {
        source: "Art 6 din Codul Muncii",
        text:
          "(1) Orice salariat care prestează o muncă beneficiază de condiții de muncă adecvate activității desfășurate, de protecție socială, de securitate și sănătate în muncă, precum și de respectarea demnității și a conștiinței sale, fără nicio discriminare."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4e7" },
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d96ca465001673e4f0" },
      { issuetype_id: "5f7ff1d96ca465001673e4f3" },
      { issuetype_id: "5f7ff1da6ca465001673e4f4" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" },
      { issuetype_id: "5f7ff1dd6ca465001673e506" },
      { issuetype_id: "5f7ff1dd6ca465001673e507" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1dd6ca465001673e509" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" }
    ]
  },
  {
    category: "employee",
    description:
      "Ai dreptul la negocieri colective și la protecția datelor cu caracter personal. Nu poți fi concediat nelegal.",
    translations: {
      ro:
        "Ai dreptul la negocieri colective și la protecția datelor cu caracter personal. Nu poți fi concediat nelegal.",
      en:
        "You have the right to collective negotiations and protection of private data. You cannot be wrongfully dismissed."
    },
    citations: [
      {
        source: "Art 6 din Codul Muncii",
        text:
          "Art. 6 din Codul Muncii\n(2) Tuturor salariaților care prestează o muncă le sunt recunoscute dreptul la negocieri colective, dreptul la protecția datelor cu caracter personal, precum și dreptul la protecție împotriva concedierilor nelegale."
      },
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Art. 39, din Codul Muncii (1)\n[...]\nj) dreptul la protecție în caz de concediere;\nk) dreptul la negociere colectivă și individuală;\n[...]"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" }
    ]
  },
  {
    category: "employee",
    description:
      "Ai dreptul de a te asocia (în sindicate sau asociații profesionale) pentru a apăra drepturile și a promova interesele profesiei tale. ",
    translations: {
      ro:
        "Ai dreptul de a te asocia (în sindicate sau asociații profesionale) pentru a apăra drepturile și a promova interesele profesiei tale. ",
      en:
        "You have the right to join together (in unions of professional associations) to defend work rights and promote the interests of your profession."
    },
    citations: [
      {
        source: "Art 7 din Codul Muncii",
        text:
          "Salariații și angajatorii se pot asocia liber pentru apărarea drepturilor și promovarea intereselor lor profesionale, economice și sociale."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" }
    ]
  },
  {
    category: "employee",
    description: "Ai dreptul la salariu.",
    translations: {
      ro: "Ai dreptul la salariu.",
      en: "You have the right to salary."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\na) dreptul la salarizare pentru munca depusă;\n[...]"
      }
    ],
    issuetypes: [{ issuetype_id: "5f7ff1da6ca465001673e4f4" }]
  },
  {
    category: "employee",
    description:
      "Ai dreptul la repaus zilnic și săptămânal, dar și la concediu anual (minim 20 de zile lucrătoare).",
    translations: {
      ro:
        "Ai dreptul la repaus zilnic și săptămânal, dar și la concediu anual (minim 20 de zile lucrătoare).",
      en:
        "You have the right to daily and weekly rest and also annual leave (for a minimum of 20 working days)."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Art. 39, din Codul Muncii (1)\nSalariatul are, în principal:\n[...]\nb) dreptul la repaus zilnic și săptămânal;\nc) dreptul la concediu de odihnă anual;\n[...]"
      },
      {
        source: "Art. 145 din Codul Muncii ",
        text:
          "Art. 145 din Codul Muncii \n(1) Durata minimă a concediului de odihnă anual este de 20 de zile lucrătoare."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d96ca465001673e4f3" },
      { issuetype_id: "5f7ff1dd6ca465001673e509" }
    ]
  },
  {
    category: "employee",
    description: "Ai dreptul la demnitate în muncă.",
    translations: {
      ro: "Ai dreptul la demnitate în muncă.",
      en: "You have the right to dignity in your line of work."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\n[...]\ne) dreptul la demnitate în muncă;\n[...]"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d96ca465001673e4f3" },
      { issuetype_id: "5f7ff1db6ca465001673e4fb" }
    ]
  },
  {
    category: "employee",
    description:
      "Angajatorul trebuie să-ți asigure un mediu de lucru sănătos și sigur. ",
    translations: {
      ro:
        "Angajatorul trebuie să-ți asigure un mediu de lucru sănătos și sigur. ",
      en: "Your employer must provide a healthy and secure working environment."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\n[...]\nf) dreptul la securitate și sănătate în muncă;\n[...]"
      },
      {
        source: "Art. 175 din Codul Muncii",
        text:
          "Art. 175 din Codul Muncii\n(1) Angajatorul are obligația să asigure securitatea și sănătatea salariaților în toate aspectele legate de muncă."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4e9" },
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d86ca465001673e4eb" },
      { issuetype_id: "5f7ff1d96ca465001673e4f0" },
      { issuetype_id: "5f7ff1dd6ca465001673e506" },
      { issuetype_id: "5f7ff1dd6ca465001673e507" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1dd6ca465001673e509" },
      { issuetype_id: "5f7ff1de6ca465001673e50c" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" }
    ]
  },
  {
    category: "employee",
    description: "Ai dreptul să te formezi profesional (training-uri, etc.)",
    translations: {
      ro: "Ai dreptul să te formezi profesional (training-uri, etc.)",
      en: "You have the right to professional development (e.g. training)."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\n[...]\ng) dreptul la acces la formarea profesională;\n[...]"
      }
    ]
  },
  {
    category: "employee",
    description:
      "Ai dreptul, ca salariat, să fii informat și consultat de angajator.",
    translations: {
      ro: "Ai dreptul, ca salariat, să fii informat și consultat de angajator.",
      en:
        "As an employee, you have the right to be informed and consulted by your employer."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\n[...]\ndreptul la informare și consultare;\n[...]"
      }
    ],
    issuetypes: [{ issuetype_id: "5f7ff1d86ca465001673e4ec" }]
  },
  {
    category: "employee",
    description:
      "Ai dreptul de a „face să fie bine\u201d, adică de a te implica pentru a crea un mediu de lucru mai bun și condiții de muncă mai bune. ",
    translations: {
      ro:
        "Ai dreptul de a „face să fie bine\u201d, adică de a te implica pentru a crea un mediu de lucru mai bun și condiții de muncă mai bune. ",
      en:
        'You have the right to "do good," meaning to get involved towards a better working environment and working conditions.'
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Salariatul are, în principal:\n[...]\ni) Dreptul de a lua parte la determinarea și ameliorarea condițiilor de muncă și a mediului de muncă\n[...]"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d96ca465001673e4ed" },
      { issuetype_id: "5f7ff1d96ca465001673e4f1" },
      { issuetype_id: "5f7ff1dc6ca465001673e505" },
      { issuetype_id: "5f7ff1dd6ca465001673e506" },
      { issuetype_id: "5f7ff1de6ca465001673e50b" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" },
      { issuetype_id: "5f7ff1da6ca465001673e4f5" },
      { issuetype_id: "5f7ff1da6ca465001673e4f6" },
      { issuetype_id: "5f7ff1da6ca465001673e4f7" },
      { issuetype_id: "5f7ff1db6ca465001673e4f8" },
      { issuetype_id: "5f7ff1db6ca465001673e4fc" },
      { issuetype_id: "5f7ff1db6ca465001673e4fd" },
      { issuetype_id: "5f7ff1db6ca465001673e4fe" },
      { issuetype_id: "5f7ff1dc6ca465001673e4ff" },
      { issuetype_id: "5f7ff1dc6ca465001673e500" }
    ]
  },
  {
    category: "employee",
    description:
      "Ai dreptul de a participa la acțiuni colective (inclusiv grevă).",
    translations: {
      ro: "Ai dreptul de a participa la acțiuni colective (inclusiv grevă).",
      en: "You have the right to joint endeavours (including strikes)."
    },
    citations: [
      {
        source: "Art. 39, din Codul Muncii (1)",
        text:
          "Art. 39, din Codul Muncii (1)\n\n\nCitation text:\nSalariatul are, în principal:\n[...]\nl) dreptul de a participa la acțiuni colective;\n\n[...]"
      }
    ]
  },
  {
    category: "employee",
    description: "Ai dreptul de a lucra într-o altă țară.",
    translations: {
      ro: "Ai dreptul de a lucra într-o altă țară.",
      en: "You have the right to work abroad."
    },
    citations: [
      {
        source: "Art. 9 din Codul Muncii",
        text:
          "Cetățenii români sunt liberi să se încadreze în muncă în statele membre ale Uniunii Europene, precum și în oricare alt stat, cu respectarea normelor dreptului internațional al muncii și a tratatelor bilaterale la care România este parte."
      }
    ]
  },
  {
    category: "employee",
    description:
      "Ai dreptul la o durată adecvată de muncă. Munca de noapte nu poate depăși 48 de ore pe săptămână (inclusiv orele suplimentare).",
    translations: {
      ro:
        "Ai dreptul la o durată adecvată de muncă. Munca de noapte nu poate depăși 48 de ore pe săptămână (inclusiv orele suplimentare).",
      en:
        "You have the right to proper work hours. Night-time work cannot exceed 48 hours per week (including overtime)."
    },
    citations: [
      {
        source: "Art. 114 din Codul Muncii",
        text:
          "Art. 114\n(1) Durata maximă legală a timpului de muncă nu poate depăși 48 de ore pe săptămână, inclusiv orele suplimentare.\n\nArt. 128\n(1) Tinerii care nu au împlinit vârsta de 18 ani nu pot presta muncă de noapte.\n(2) Femeile gravide, lăuzele și cele care alăptează nu pot fi obligate să presteze muncă de noapte."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d96ca465001673e4f3" },
      { issuetype_id: "5f7ff1da6ca465001673e4f4" }
    ]
  },
  {
    category: "whistleblower",
    description:
      "Ai dreptul să semnalezi, în siguranță, încălcări ale legii (adică de a fi „avertizor de integritate\u201d), inclusiv fapte de corupție sau tratamente discriminatorii.",
    translations: {
      ro:
        "Ai dreptul să semnalezi, în siguranță, încălcări ale legii (adică de a fi „avertizor de integritate\u201d), inclusiv fapte de corupție sau tratamente discriminatorii.",
      en:
        "You have the right to safely report legal misconducts (whistleblowing) including acts of corruption or discriminatory behaviour."
    },
    citations: [
      {
        source: "Art. 5 din. Legea 571/2004",
        text:
          "Semnalarea unor fapte de încălcare a legii de către persoanele menționate la art. 1 și 2, prevăzute de lege ca fiind abateri disciplinare, contravenții sau infracțiuni, constituie avertizare în interes public și privește:\na) infracțiuni de corupție, infracțiuni asimilate infracțiunilor de corupție, infracțiuni în legătură directă cu infracțiunile de corupție, infracțiunile de fals și infracțiunile de serviciu sau în legătură cu serviciul;\nb) infracțiuni împotriva intereselor financiare ale Comunităților Europene;\nc) practici sau tratamente preferențiale ori discriminatorii în exercitarea atribuțiilor unităților prevăzute la art. 2;\nd) încălcarea prevederilor privind incompatibilitățile și conflictele de interese;\ne) folosirea abuzivă a resurselor materiale sau umane;\nf) partizanatul politic în exercitarea prerogativelor postului, cu excepția persoanelor alese sau numite politic;\ng) încălcări ale legii în privința accesului la informații și a transparenței decizionale;\nh) încălcarea prevederilor legale privind achizițiile publice și finanțările nerambursabile;\ni) incompetența sau neglijența în serviciu;\nj) evaluări neobiective ale personalului în procesul de recrutare, selectare, promovare, retrogradare și eliberare din funcție;\nk) încălcări ale procedurilor administrative sau stabilirea unor proceduri interne cu nerespectarea legii;\nl) emiterea de acte administrative sau de altă natură care servesc interese de grup sau clientelare;\nm) administrarea defectuoasă sau frauduloasă a patrimoniului public și privat al autorităților publice, instituțiilor publice și al celorlalte unități prevăzute la art. 2;\nn) încălcarea altor dispoziții legale care impun respectarea principiului bunei administrări și cel al ocrotirii interesului public."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1dc6ca465001673e502" },
      { issuetype_id: "5f7ff1dc6ca465001673e503" }
    ]
  },
  {
    category: "whistleblower",
    description:
      "Ai dreptul să ți se prezume buna-credinţă atunci când semnalezi încălcări ale legii.",
    translations: {
      ro:
        "Ai dreptul să ți se prezume buna-credinţă atunci când semnalezi încălcări ale legii.",
      en: "You have the right of good faith when reporting legal misconduct."
    },
    citations: [
      {
        source: "Art. 14 al. 2 din Codul Civil;",
        text:
          "Art. 14 al. 2 din Codul Civil\nBuna-credinţă se prezumă până la proba contrară."
      },
      {
        source: "Art. 7 al. 1 lit. a din Legea 571/2004",
        text:
          "Art. 7 al. 1 lit. a din Legea 571/2004\nAvertizorii în interes public beneficiază de prezumția de bună-credință, în condițiile art. 4 lit. h), până la proba contrară;"
      }
    ]
  },
  {
    category: "whistleblower",
    description:
      "Ai dreptul să ți se protejeze identitatea atunci când semnalezi încălcări ale legii, dacă cel reclamat îți este șef (direct sau indirect).",
    translations: {
      ro:
        "Ai dreptul să ți se protejeze identitatea atunci când semnalezi încălcări ale legii, dacă cel reclamat îți este șef (direct sau indirect).",
      en:
        "You have the right to protection of identity when reporting legal misconduct if the defendant is your (direct or indirect) superior."
    },
    citations: [
      {
        source: "Art. 7 alin. 2 și art. 8 din Legea 571/2004",
        text:
          "În situația în care cel reclamat prin avertizarea în interes public este șef ierarhic, direct sau indirect, ori are atribuții de control, inspecție și evaluare a avertizorului, comisia de disciplină sau alt organism similar va asigura protecția avertizorului, ascunzându-i identitatea.\n\nÎn cazul avertizărilor în interes public, prevăzute la art. 5 lit. a) și b), se vor aplica din oficiu prevederile art. 12 alin. (2) lit. a) din Legea nr. 682/2002 privind protecția martorilor."
      }
    ]
  },
  {
    category: "whistleblower",
    description:
      "Dacă ești cercetat disciplinar după ce ai făcut o sesizare de încălcare a legii, ai dreptul de a fi cercetat disciplinară conform legii, adică în prezența presei și a unui reprezentant al sindicatului.",
    translations: {
      ro:
        "Dacă ești cercetat disciplinar după ce ai făcut o sesizare de încălcare a legii, ai dreptul de a fi cercetat disciplinară conform legii, adică în prezența presei și a unui reprezentant al sindicatului.",
      en:
        "If under disciplinary investigation following the report of legal misconduct, you have the right to be investigated in accordance with the law, under the eyes of the media and a union representative."
    },
    citations: [
      {
        source: "Art. 7 al. 1 lit. b din Legea 571/2004",
        text:
          "La cererea avertizorului cercetat disciplinar ca urmare a unui act de avertizare, comisiile de disciplină sau alte organisme similare din cadrul autorităților publice, instituțiilor publice sau al altor unități prevăzute la art. 2 au obligația de a invita presa și un reprezentant al sindicatului sau al asociației profesionale"
      }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul de a nu fi sancționat (direct ori indirect) dacă sesizezi încălcări ale legii.",
    translations: {
      ro:
        "Ai dreptul de a nu fi sancționat (direct ori indirect) dacă sesizezi încălcări ale legii.",
      en:
        "You have the right not to be sanctioned (directly or indirectly) if reporting legal misconduct."
    },
    citations: [
      {
        source: "Art. 4 lit. d teza I din Legea 571/2004 ",
        text:
          "d) principiul nesancționării abuzive, conform căruia nu pot fi sancționate persoanele care reclamă ori sesizează încălcări ale legii, direct sau indirect, prin aplicarea unei sancțiuni inechitabile și mai severe pentru alte abateri disciplinare. În cazul avertizării în interes public, nu sunt aplicabile normele deontologice sau profesionale de natură să împiedice avertizarea în interes public;"
      },
      {
        source: "Art. 41 din Carta Europeană a Drepturilor Fundamentale",
        text:
          "Art. 41 din Carta Europeană a Drepturilor Fundamentale\nOrice persoană are dreptul de a beneficia, în ce privește problemele sale, de un tratament imparțial, echitabil și într-un termen rezonabil din partea instituțiilor, organelor, oficiilor și agențiilor Uniunii."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1db6ca465001673e4fb" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ești independent, ai dreptul de a decide în ceea ce ține de actul medical. Nu ți se pot impune îngrădiri privind prescripţia şi recomandările cu caracter medical, deci ai dreptul de a prescrie şi recomanda tot ceea ce e necesar din punct de vedere medical pacientului.",
    translations: {
      ro:
        "Ești independent, ai dreptul de a decide în ceea ce ține de actul medical. Nu ți se pot impune îngrădiri privind prescripţia şi recomandările cu caracter medical, deci ai dreptul de a prescrie şi recomanda tot ceea ce e necesar din punct de vedere medical pacientului.",
      en:
        "You are independent, you have the right to decide when it comes to medical concerns. No restrictions may be set concerning your medical prescriptions and recommendations so you have the right to medically prescribe and recommend whatever you think is necessary for patients."
    },
    citations: [
      {
        source: "Art 6, alin.1 din Legea 306/2004",
        text:
          "Art 6, alin.1 din Legea 306/2004\nÎn scopul asigurării în orice împrejurare a intereselor pacientului, profesia de medic are la baza exercitării sale independența şi libertatea profesională a medicului, precum şi dreptul de decizie asupra hotărârilor cu caracter medical."
      },
      {
        source: "Art 6, alin. 3 din legea 306/2004",
        text:
          "Art 6, alin. 3 din legea 306/2004\nÎn legătură cu exercitarea profesiei şi în limita competențelor profesionale, medicului nu-i pot fi impuse îngrădiri privind prescripţia şi recomandările cu caracter medical, având în vedere caracterul umanitar al profesiei de medic, obligaţia medicului de deosebit respect faţă de ființa umană şi de loialitate faţă de pacientul sau, precum şi dreptul medicului de a prescrie şi recomandă tot ceea ce este necesar din punct de vedere medical pacientului."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d86ca465001673e4ea" },
      { issuetype_id: "5f7ff1d86ca465001673e4eb" },
      { issuetype_id: "5f7ff1d86ca465001673e4ec" },
      { issuetype_id: "5f7ff1d96ca465001673e4f1" },
      { issuetype_id: "5f7ff1da6ca465001673e4f5" },
      { issuetype_id: "5f7ff1dd6ca465001673e506" },
      { issuetype_id: "5f7ff1dd6ca465001673e507" },
      { issuetype_id: "5f7ff1dd6ca465001673e508" },
      { issuetype_id: "5f7ff1dd6ca465001673e509" },
      { issuetype_id: "5f7ff1de6ca465001673e50c" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul de a alege și de a fi ales(ă) în Colegiul Medicilor.",
    translations: {
      ro: "Ai dreptul de a alege și de a fi ales(ă) în Colegiul Medicilor.",
      en: "You have the right to vote and be voted among The Medical College."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. a)",
        text:
          "să aleagă şi să fie aleşi în organele de conducere de la nivelul structurilor teritoriale sau naţionale ale Colegiului Medicilor din România;"
      }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul să fii informat în timp util despre și să participi la orice acțiune a Colegiului Medicilor.",
    translations: {
      ro:
        "Ai dreptul să fii informat în timp util despre și să participi la orice acțiune a Colegiului Medicilor.",
      en:
        "You have the right to know beforehand and take part in any ventures of The Medical College."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. c)",
        text:
          "să participe la orice acţiune a Colegiului Medicilor din România şi să fie informați în timp util despre aceasta;"
      }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul de a folosi (împreună cu familia ta) dotările Colegiului Medicilor.",
    translations: {
      ro:
        "Ai dreptul de a folosi (împreună cu familia ta) dotările Colegiului Medicilor.",
      en:
        "You (and your family) have the right to use The Medical College facilities."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. d)",
        text:
          "Să folosească, împreună cu membrii lor de familie, toate dotările sociale, profesionale, culturale şi sportive ale Colegiului Medicilor din România şi ale colegiilor teritoriale;"
      }
    ]
  },
  {
    category: "admin_professional",
    description: "Ai dreptul să depui contestații când primești sancțiuni.",
    translations: {
      ro: "Ai dreptul să depui contestații când primești sancțiuni.",
      en: "You have the right to appeal sanctions."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. f)",
        text: "să conteste sancţiunile primite;"
      }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul să ceri ajutoare materiale pentru situații deosebite.",
    translations: {
      ro: "Ai dreptul să ceri ajutoare materiale pentru situații deosebite.",
      en:
        "You have the right to ask for material aid in exceptional circumstances."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. g)",
        text:
          "să solicite ajutoare materiale pentru situaţii deosebite, atât personal, cât şi prin membrii lor de familie;"
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d76ca465001673e4e6" },
      { issuetype_id: "5f7ff1d86ca465001673e4e7" },
      { issuetype_id: "5f7ff1d86ca465001673e4e9" },
      { issuetype_id: "5f7ff1d96ca465001673e4f0" },
      { issuetype_id: "5f7ff1da6ca465001673e4f6" },
      { issuetype_id: "5f7ff1de6ca465001673e50c" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Dacă ești asigurat, ai dreptul, alături de soț/soție și copiii, la medicamente, proteze și asistență medicală gratuită",
    translations: {
      ro:
        "Dacă ești asigurat, ai dreptul, alături de soț/soție și copiii, la medicamente, proteze și asistență medicală gratuită",
      en:
        "If insured, you, your partner and children have the right to medication, prosthetics and free medical assistance."
    },
    citations: [
      {
        source: "Art. 36 din Legea 306/2004 lit. h)",
        text:
          "h) medicii în activitate sau pensionari, precum şi soţul sau soția şi copiii aflaţi în întreţinerea acestora beneficiază în mod gratuit, începând cu data de 1 ianuarie 2007, de asistenţa medicală, medicamente şi proteze, în condiţiile respectării dispoziţiilor legale privind plata contribuţiei la asigurările sociale de sănătate."
      }
    ],
    issuetypes: [
      { issuetype_id: "5f7ff1d76ca465001673e4e6" },
      { issuetype_id: "5f7ff1d86ca465001673e4e7" },
      { issuetype_id: "5f7ff1de6ca465001673e50c" },
      { issuetype_id: "5f7ff1de6ca465001673e50d" }
    ]
  },
  {
    category: "admin_professional",
    description:
      "Ai dreptul să te judeci, în termen de 15 zile, cu Comisia superioară de disciplină, dacă aceasta te sancționează și crezi că e nedrept. ",
    translations: {
      ro:
        "Ai dreptul să te judeci, în termen de 15 zile, cu Comisia superioară de disciplină, dacă aceasta te sancționează și crezi că e nedrept. ",
      en:
        "You have the right, within 15 days, to take the upper discipline Commision to court if they sanction you and you think it’s wrongful."
    },
    citations: [
      {
        source: "Art. 78 Legea 306/2004",
        text:
          "Împotriva deciziei de sancţionare a Comisiei superioare de disciplina, în termen de 15 zile de la comunicare, medicul sancţionat poate formula o acţiune în anulare la secţia de contencios administrativ a tribunalului în raza căruia îşi desfăşoară activitatea."
      }
    ]
  }
];
