export default {
  title: "NHRC Worker Rights Booklet",
  description:
    "The National Human Rights Committee of Qatar created this booklet to help workers understand thir rights and responsibilities during their work and life in Qatar.",
  button_label: "View the booklet in English",
  pdf: "nhrcbook_en.pdf",
  other_langs: [
    {
      language_name: "العربية",
      pdf: "nhrcbook_ar.pdf"
    },
    {
      language_name: "नेपाली",
      pdf: "nhrcbook_ne.pdf"
    },
    {
      language_name: "اردو",
      pdf: "nhrcbook_ur.pdf"
    }
  ]
};
