import React, { Component } from "react";
import { View, Text, Icon, Body } from "native-base";
import { connect } from "react-redux";
import I18n from "../i18n";
import { sendMoodResponse } from "../actions/settings";
import { MOOD_TIMEOUT } from "../const";

export default connect(state => ({
  offline: state.offline,
  lastMoodResponse: state.settings.lastMoodResponse,
  project: state.settings.projectId,
  currentLocal: state.settings.language
}))(
  class MoodResponse extends Component {
    state = { sent: false };
    _working = false;
    click = mood => async () => {
      this.setState({ sent: true });
      if (!this._working) {
        this._working = true;
        sendMoodResponse(mood);
      }
    };

    render() {
      const now = new Date();
      if (
        !this.props.offline &&
        !this.state.sent &&
        this.props.project &&
        (!this.props.lastMoodResponse ||
          now - new Date(this.props.lastMoodResponse) >= MOOD_TIMEOUT)
      ) {
        return (
          <View moodResponse>
            <Body>
              <Text>{I18n.t("appStrings.MOOD_RESPONSE_QUESTION")}</Text>
              <Icon
                type="FontAwesome"
                name="smile-o"
                onPress={this.click("smile")}
              />
              <Icon
                type="FontAwesome"
                name="meh-o"
                onPress={this.click("meh")}
              />
              <Icon
                type="FontAwesome"
                name="frown-o"
                onPress={this.click("frown")}
              />
            </Body>
          </View>
        );
      }

      return null;
    }
  }
);
