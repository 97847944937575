import color from "color";
import { Platform } from "react-native";

const colors = {
  positive: "#2483ff",
  teal: "#28b7cd",
  blue: "#2483ff",
  get lightblue() {
    return color(this.blue).lighten(0.1).hex();
  },
  blue2: "#336699",
  blue3: "#DFF0FF",
  blue4: "#CFE0F9",
  blue5: "#D0E0F9",
  paleBlue: "#F5FCFF",
  cream: "#FFFDED",
  cream2: "#FDF2D8",
  palePink: "#F7F0FC",
  cream: "#fffded",

  white: "#FFF",
  black: "#000000",
  white2: "#FAFAFA",
  white3: "#F0EFF5",
  black: "#000",
  black2: "#222",
  yellow: "#FFCD00",
  yellow2: "#FFE26C",
  lightYellow: "#F0B323",
  darkYellow: "#CC8A00",
  orange: "#CC702F",
  sand: "#C5B9AC",
  brown: "#4E3524",
  brown2: "#4D331F",
  red: "#C8102E",
  lightRed: "#E4002B",
  darkRed: "#AF272F",
  purple: "#AE2573",
  lightPurple: "#C6007E",
  darkPurple: "#9E007E",
  gradient0: "#F0B323",
  gradient35: "#FFCD00",
  gradient100: "#CC8A00",
  grey1: "#DDDDDB",
  grey2: "#727272",
  grey3: "#CCC",
  grey4: "#808080",
  grey5: "#333",
  grey6: "#BCBCBC",
  grey7: "#3B3B3B",
  grey8: "#606060",
  grey9: "#444",
  grey10: "#F0F0F0",
  grey11: "#555",
  grey12: "#CBCBCB",
  grey13: "#384850",
  grey14: "#C9C8CD",
  grey15: "#8F8E95",
  grey16: "#C8C7CC",
  grey17: "#F9F9F9",
  grey18: "#666",
  grey19: "#777",
  grey20: "#A7A7A7",
  transparentLight: "rgba(0, 0, 0, 0.2)",
  transparentDark: "rgba(0, 0, 0, 0.8)",

  demoBlue1: "#0B4F71",
  demoBlue2: "#166086",
  demoBlue3: "#1D6F98",
  demoBlue4: "#2D83AE",
  demoBlue5: "#3994C1",
  demoBlue6: "#55AAD4",
  demoBlue7: "#7CC1E4",
  demoBlue8: "#A7D8F0",
  demoBlue9: "#D1EEFC",
  demoBlue10: "#EBF8FF",

  demoGreen4: "#2F8132",
  demoGreen6: "#57AE5B",
  demoGreen7: "#7BC47F",
  demoGreen8: "#A3D9A5",
  demoGreen9: "#C1EAC5",

  demoGrey1: "#1F2933",
  demoGrey2: "#323F4B",
  demoGrey3: "#3E4C59",
  demoGrey4: "#52606D",
  demoGrey5: "#616E7C",
  demoGrey6: "#7B8794",
  demoGrey7: "#9AA5B1",
  demoGrey8: "#CBD2D9",
  demoGrey9: "#E4E7EB",
  demoGrey10: "#F5F7FA",

  demoRed1: "#610404",
  demoRed3: "#911111",

  demoPurple1: "#240754",
  demoPurple2: "#34126F",
  demoPurple3: "#421987",
  demoPurple4: "#51279B",
  demoPurple5: "#653CAD",
  demoPurple6: "#724BB7",
  demoPurple7: "#8662C7",
  demoPurple8: "#A081D9",

  demoYellow6: "#F7D070",
  demoYellow7: "#F9DA8B",
  demoYellow8: "#F8E3A3",
  demoYellow9: "#FCEFC7",

  funkyPaletteRed: "#FF533D",
  funkyPaletteBlue: "#6374FF",
  funkyPaletteYellow: "#FFD240",
  funkyBlack: "#010101",
  funkyWhite: "#FFFFFF",
  funkyRed1: "hsl(7, 100%, 22%)",
  funkyRed2: "hsl(7, 100%, 32%)",
  funkyRed3: "hsl(7, 100%, 42%)",
  funkyRed4: "hsl(7, 100%, 52%)",
  funkyRed5: "hsl(7, 100%, 62%)",
  funkyRed6: "hsl(7, 100%, 70%)",
  funkyRed7: "hsl(7, 100%, 77%)",
  funkyRed8: "hsl(7, 100%, 85%)",
  funkyRed9: "hsl(7, 100%, 96%)",
  funkyBlue1: "hsl(233, 100%, 12%)",
  funkyBlue2: "hsl(233, 100%, 26%)",
  funkyBlue3: "hsl(233, 100%, 40%)",
  funkyBlue4: "hsl(233, 100%, 55%)",
  funkyBlue5: "hsl(233, 100%, 69%)",
  funkyBlue6: "hsl(233, 100%, 75%)",
  funkyBlue7: "hsl(233, 100%, 80%)",
  funkyBlue8: "hsl(233, 100%, 86%)",
  funkyBlue9: "hsl(233, 100%, 92%)",
  funkyYellow1: "hsl(46, 100%, 23%)",
  funkyYellow2: "hsl(46, 100%, 33%)",
  funkyYellow3: "hsl(46, 100%, 43%)",
  funkyYellow4: "hsl(46, 100%, 53%)",
  funkyYellow5: "hsl(46, 100%, 63%)",
  funkyYellow6: "hsl(46, 100%, 70%)",
  funkyYellow7: "hsl(46, 100%, 77%)",
  funkyYellow8: "hsl(46, 100%, 84%)",
  funkyYellow9: "hsl(46, 100%, 98%)"
};

export default {
  ...colors,
  toolbarBackground: colors.funkyRed5,
  containerBackground: colors.funkyYellow9,
  dialog: {
    titleText: colors.black,
    button: {
      background: colors.demoBlue5,
      text: colors.white
    },
    cancelButton: {
      background: colors.teal,
      text: colors.white
    }
  },
  fullList: {
    border: colors.sand,
    background: colors.white
  },
  hotlineDialog: {
    titleText: colors.black,
    cancel: {
      background: colors.teal,
      text: colors.white
    },
    inset: {
      background: colors.cream2
    },
    insetLink: {
      text: colors.purple
    }
  },
  hotlineModal: {
    background: colors.grey6,
    title: colors.grey7,
    button: colors.purple,
    back: colors.grey8
  },
  tabBar: {
    indicator: {
      border: colors.funkyBlue5,
      background: colors.brown
    },
    tab: {
      border: colors.grey1
    },
    label: {
      active: colors.funkyBlue5,
      inactive: colors.demoGrey5
    },
    background: colors.white,
    borderBottom: colors.grey3
  },
  rights: {
    expoStandards: {
      listItem: {
        background: colors.purple
      }
    },
    categoryList: {
      listItem: {
        background: colors.funkyWhite
      }
    },
    header: {
      header: colors.demoBlue1,
      title: colors.demoBlue1
    }
  },
  whatsNew: {
    link: colors.teal
  },
  postDetails: {
    link: colors.teal,
    background: colors.paleBlue
  },
  setEmployer: {
    emptyResult: {
      background: colors.white
    },
    list: {
      border: colors.sand,
      background: colors.white
    },
    searchHelp: {
      icon: colors.brown
    },
    searchInput: {
      placeholder: colors.grey4,
      background: colors.white,
      icon: "green",
      iconBackground: colors.white
    },
    icon: {
      background: colors.white,
      text: colors.brown
    },
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
  },
  setProject: {
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
    button: {
      background: colors.brown,
      text: colors.white
    },
    formInputButton: {
      background: colors.white2,
      text: colors.brown
    }
  },
  moodResponse: {
    background: colors.funkyBlue9,
    text: colors.funkyBlue2,
    icon: colors.funkyBlue2
  },
  promise: {
    mainText: colors.brown,
    rawText: colors.brown
  },
  // Button component
  button: {
    citation: {
      text: colors.funkyBlue5
    },
    skipProject: {
      background: colors.demoGreen6,
      text: colors.white,
      icon: colors.white
    },
    qrCode: {
      background: colors.demoGreen4,
      text: colors.white,
      icon: {
        text: colors.black,
        background: colors.white
      }
    },
    connectProject: {
      text: colors.demoBlue3,
      icon: colors.black
    },
    about: {
      text: colors.demoBlue3,
      icon: colors.black
    },
    welcome: {
      background: colors.funkyYellow4,
      text: colors.funkyBlue1,
      icon: colors.funkyBlue1
    },
    dialog: {
      background: colors.teal
    },
    pollDecline: {
      text: colors.grey9,
      icon: colors.grey9
    },
    reportIssue: {
      background: colors.funkyBlue5,
      text: colors.white
    },
    employerHelp: {
      background: colors.demoGreen4,
      text: colors.white,
      fontSize: 18,
      icon: {
        text: colors.black,
        background: colors.white
      }
    },
    setup: {
      border: colors.white,
      text: colors.white
    },
    languages: {
      border: colors.white,
      text: colors.white
    }
  },
  // Card component
  card: {
    communityProfileDetails: {
      contact: {
        icon: colors.brown,
        text: colors.brown
      },
      title: {
        header: colors.funkyBlue2,
        subtitle: colors.demoGrey2
      },
      description: colors.funkyBlue1,      
      link: colors.funkyBlue2
    },
    resource: {
      borderLeft: colors.funkyBlue7,
      title: colors.funkyBlue2,
      description: colors.demoGrey4
    },
    communityProfileSummary: {
      header: colors.funkyBlue2,
      subtitle: colors.demoGrey3,
      description: colors.funkyBlue1,
      icon: colors.funkyBlue2
    },
    rightsDetails: {
      header: {
        background: colors.funkyBlue5,
        icon: colors.white,
        text: colors.white
      },
      text: colors.funkyBlue1,
      icon: colors.funkyBlue1
    },
    rights: {
      header: {
        background: colors.purple,
        icon: colors.white,
        text: colors.white
      },
      icon: colors.purple
    },
    hotline: {
      header: {
        background: colors.blue,
        icon: colors.white,
        text: colors.white
      },
      icon: colors.blue
    },
    resources: {
      header: {
        background: colors.red,
        icon: colors.white,
        text: colors.white
      },
      icon: colors.red
    },
    project: {
      header: {
        background: colors.teal,
        icon: colors.white,
        text: colors.white
      },
      icon: colors.teal
    },
    projectDetails: {
      header: {
        title: colors.black,
        subtitle: colors.teal
      },
      image: {
        border: colors.teal
      }
    },
    question: {
      checkbox: {
        border: colors.grey3
      },
      text: colors.grey9
    },
    disconnected: {
      background: colors.demoGrey4,
      text: colors.white
    },
    noproject: {
      icon: colors.sand,
      text: colors.brown
    },
    noposts: {
      icon: colors.funkyYellow5,
      text: colors.funkyBlue1
    },
    greeting: {
      background: colors.white
    },
    warning: {
      background: colors.white,
      icon: colors.demoRed3,
      text: colors.funkyRed1
    },
    notice: {
      background: colors.white,
      icon: colors.demoBlue6,
      text: colors.funkyBlue5
    },
    poll: {
      background: colors.funkyYellow5,
      cardBackground: colors.funkyYellow5,
      icon: colors.funkyBlue2,
      text: colors.funkyBlue1,
      header: colors.funkyBlue2,
      answerPrompt: colors.funkyBlue2
    },
    post: {
      header: {
        title: colors.funkyBlue1,
        timestamp: colors.funkyBlue5
      },
      body: colors.funkyBlue1,
      readmore: {
        text: colors.funkyBlue5,
        icon: colors.funkyBlue5
      },
      link: {
        header: colors.grey9,
        text: colors.grey9
      }
    },
    whatsnew: {
      border: colors.blue5
    },
    settings: {
      icon: colors.grey3
    },
    radio: {
      disabledBackground: colors.grey10,
      text: colors.grey9,
      icon: colors.grey9
    },
    reportIssue: {
      header: colors.funkyBlue5
    },
    resourcealert: {
      border: "#d0e0f9"
    },
    healthalert: {
      background: colors.white,
      icon: colors.red,
      text: colors.red
    },
    shadow: colors.black
  },
  // H1, H2, H3, Header components
  header: {
    h1: {
      poll: colors.black,
      rights: colors.funkyBlue1,
      connectProject: colors.brown,
      employer: colors.brown,
      projectName: colors.demoBlue2,
      welcome: colors.white,
      nationality: colors.white,
      selectProject: colors.white,
      setup: colors.brown,
      employerSearchHelp: colors.brown
    },
    h2: {
      welcome: colors.yellow2,
      setup: colors.demoGrey2,
      rights: colors.funkyBlue2,
      rightsTitle: colors.funkyBlue1
    },
    h3: {
      pollQuestion: colors.demoGrey1,
      communityName: colors.funkyBlue1,
      connectProject: colors.black,
      setup: colors.brown,
      employerSearch: colors.brown,
      employerSearchProject: colors.brown
    },
    button: {
      transparentIcon: colors.white
    },
    poll: {
      background: colors.funkyBlue5
    },
    about: {
      background: colors.demoGreen4
    },
    reportIssue: {
      background: colors.funkyBlue5
    },
    welcome: {
      icon: colors.white,
      text: colors.white
    },
    nationality: {
      icon: colors.white,
      text: colors.white
    },
    selectProject: {
      icon: colors.white,
      text: colors.white
    },
    setup: {
      icon: colors.brown,
      text: colors.brown
    },
    shadow: colors.black
  },
  // Content component
  content: {
    background: "transparent",
    segmentBackground: "transparent",
    setup: {
      header: colors.yellow2,
      text: {
        welcome: colors.white,
        connectProject: colors.white,
        skipProject: colors.white
      },
      button: {
        connectProject: {
          text: colors.white,
          icon: colors.white
        },
        skipProject: {
          text: colors.white,
          icon: colors.white
        }
      },
      form: {
        inputButton: {
          background: colors.white2,
          text: colors.brown
        }
      }
    },
    setProject: {
      formInputButton: {
        background: colors.white2,
        text: colors.brown
      }
    }
  },
  // CardItem component
  cardItem: Platform.OS == "ios" ? colors.grey11 : colors.black2,
  // Form component
  form: {
    input: {
      background: colors.grey10,
      text: colors.black,
      button: {
        background: colors.blue,
        text: colors.white
      }
    },
    employer: {
      border: colors.grey3,
      background: colors.white,
      button: {
        background: colors.white2,
        text: colors.grey9
      }
    },
    settings: {
      border: colors.grey3,
      button: {
        background: colors.white,
        text: colors.brown
      }
    }
  },
  // Item component
  item: {
    reportIssue: {
      background: colors.white
    }
  },
  // ListItem component
  listItem: {
    searchBar: {
      background: colors.white
    },
    right: {
      icon: colors.grey14
    },
    text: {
      countryList: colors.brown,
      employerList: colors.brown
    },
    icon: {
      right: {
        text: colors.grey15,
        icon: colors.grey16
      }
    },
    rights: {
      itemHeader: {
        background: colors.funkyWhite,
        text: colors.funkyBlack
      },
      separatorBackground: colors.funkyBlue5,
      text: colors.funkyBlue2,
      icon: colors.funkyBlue2
    },
    reportIssue: {
      itemHeader: {
        background: colors.funkyWhite,
        text: colors.funkyBlue2
      },
      text: colors.funkyBlue1,
      icon: colors.funkyBlue5
    },
    promises: {
      text: colors.brown,
      icon: colors.lightYellow
    }
  },
  qrModal: {
    closeIcon: {
      text: colors.white
    },
    background: colors.black
  },
  chooseLang: {
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
  },
  nationality: {
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
  },
  nationalityFullList: {
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
    button: {
      text: {
        settings: colors.demoGrey2,
        setup: colors.white
      }
    }
  },
  welcome: {
    backgroundGradient: [colors.funkyPaletteBlue, colors.funkyPaletteRed],
    gradientStart: { x: 1, y: 0 },
    gradientEnd: { x: 0, y: 1 },
  },
  // Separator component
  separator: {
    text: colors.grey19,
    background: colors.white3
  },
  // Text component
  text: {
    note: colors.grey20,
    divider: colors.demoGrey3,
    settingsLabel: colors.demoGrey1,
    welcomeMessage: colors.demoGrey4,
    dialog: colors.grey9,
    pollTitle: colors.demoGrey4,
    pollDescription: colors.black,
    pollResponseText: colors.demoBlue1,
    pollDecline: colors.demoGrey3,
    communityDescription: colors.funkyBlue1,
    center: {
      reportIssue: colors.demoGrey1
    },
    attribution: colors.grey18,
    title: colors.black,
    copyright: colors.grey18,
    reportIssue: {
      text: colors.demoGrey1,
      label: colors.demoGrey1
    },
    aboutAppName: colors.demoGrey1,
    aboutPrivacy: colors.demoBlue1,
    version: colors.demoGrey1,
    skipProject: colors.black,
    connectProject: colors.demoGrey1,
    yourProject: colors.demoGrey3,
    welcome: colors.white,
    nationality: colors.white,
    selectProject: colors.white,
    setup: colors.brown,
    employerSetup: colors.purple,
    about: colors.demoGrey3,
    rightsDescription: colors.demoGrey1,
    employer: colors.brown
  },
  disabled: {
    icon: colors.grey13
  },
  projectName: colors.demoBlue2,
  pollQuestion: colors.demoGrey1,
  toolbarDefaultBg: colors.demoBlue1,
  offlineIndicator: "red",
  progressBackground: colors.transparentLight,
  communityProfileDetailsAddress: colors.black,
  videoModalBackground: colors.black,
  footerBorder: colors.grey12,
  icon: colors.black,
  listBackground: colors.white,
  pickerAndroid: colors.grey15,
  swipeRowItem: colors.white,
  tabBackground: colors.white,
  shadow: colors.black,
  toastBackground: colors.transparentDark,
  styles: {
    instructions: colors.grey5,
    text: colors.white,
    green: colors.brown2,
    blue: colors.blue2
  }
};
