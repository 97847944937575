import { StyleSheet, I18nManager } from "react-native";
import wcColors from "../native-base-theme/variables/wcColors";
import variables from "../native-base-theme/variables/platform";

export default StyleSheet.create({
  container: {
    flex: 1
  },
  center: {
    justifyContent: "center",
    alignItems: "center"
  },
  vertical: {
    flexDirection: "column",
    flex: 1
  },
  body: {
    padding: 15
  },
  left: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  right: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  backgroundImage: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    margin: 10
  },
  instructions: {
    textAlign: "center",
    color: wcColors.styles.instructions,
    marginBottom: 5
  },
  iconWithTextRow: {
    flexDirection: "row"
  },
  chevronStyle: {
    marginLeft: I18nManager.isRTL ? 0 : 15,
    marginRight: I18nManager.isRTL ? 15 : 0,
    transform: [{ rotateZ: I18nManager.isRTL ? "180deg" : "0deg" }]
  },
  text: {
    color: wcColors.styles.text
  },
  green: {
    color: wcColors.styles.green
  },
  blue: {
    color: wcColors.styles.blue
  },
  textStyle: {
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr"
  },
  inputStyle: {
    textAlign: I18nManager.isRTL ? "right" : "left"
  },
  gradient: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  imageIcon: {
    width: variables.iconFontSize,
    height: variables.iconFontSize,
    marginTop: 2,
    marginHorizontal: 8
  }
});
