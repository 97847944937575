import App from "../app/root";
import React from "react";
import { AppRegistry, Linking, StyleSheet } from "react-native";
import { Label, Item } from "native-base";
import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";
import { patchFlatListProps } from "./react-native-web-refresh-control";
import "./styles.css";

// Check that service workers are supported
if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js");
  });
}

// patch flat list refresh control
patchFlatListProps();

// fix Linking open URL to open in new tab
Linking.openURL = function openURL(url) {
  try {
    if (canUseDOM) {
      const urlToOpen = new URL(url, window.location).toString();
      window.open(urlToOpen, "_blank", "noopener");
    }
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

// fix native-base Item Label rendering, when input is not focused, but has value
Item.WrappedComponent.prototype.renderLabel = function (label, labelProps) {
  const newLabel = [];
  const labelStyle = StyleSheet.flatten([
    { fontSize: 15, lineHeight: 30 },
    labelProps.style
  ]);
  if (this.props.floatingLabel) {
    if (this.state.isFocused || (this.inputProps && this.inputProps.value)) {
      newLabel.push(
        React.createElement(Label, {
          ...labelProps,
          key: "newFLabel",
          float: true,
          style: labelStyle
        })
      );
      this.floatUp(-16);
    } else {
      newLabel.push(label);
      this.floatBack(labelProps.floatBack);
    }
  } else {
    newLabel.push(
      React.createElement(Label, {
        ...labelProps,
        key: "newLabel"
      })
    );
  }
  return newLabel;
};

const fonts = [
  "MaterialIcons",
  "MaterialCommunityIcons",
  "Ionicons",
  "FontAwesome"
];
fonts.forEach(font => {
  // Generate required css
  const iconFont = require(/* webpackMode: "eager" */ `react-native-vector-icons/Fonts/${font}.ttf`);
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: ${font};
  }`;

  // Create stylesheet
  const style = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: () => location.reload()
// });

// register the app
AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  initialProps: {},
  rootTag: document.getElementById("root")
});
