import types from "../actions/types";

const initialState = false;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROJECT_STATUS:
      return action.status;
    default:
      return state;
  }
};
