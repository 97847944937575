import variable from "./../variables/platform";
import colors from "./../variables/wcColors";

export default (variables = variable) => {
  const iconTheme = {
    fontSize: variables.iconFontSize,
    color: colors.icon
  };

  return iconTheme;
};
