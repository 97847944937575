import variable from "./../variables/platform";
import colors from "./../variables/wcColors";

export default (variables = variable) => {
  const tabTheme = {
    flex: 1,
    backgroundColor: colors.tabBackground
  };

  return tabTheme;
};
