import React, { Component, Fragment } from "react";
import {
  StatusBar,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Linking,
  Keyboard,
  Image,
  Alert
} from "react-native";
import { View, Card, Toast, Button, Text, Icon } from "native-base";
import FormInput from "./FormInput";
import { setProject, activation } from "../actions/settings";
import I18n from "../i18n";
import { dialogConfig } from "../const";
import store from "../store";
import { isOffline } from "../offline";
import { getCommunityProfiles } from "../actions/communityProfiles";

let instance = null;
let _working = false;

export default class ConnectToProject extends Component {
  state = {
    projectCode: this.props.projectCode,
    showQR: false
  };

  static fireSubmit = ({ project, employer }) => {
    if (instance) {
      typeof instance.props.onSubmit == "function" &&
        instance.props.onSubmit.call(instance, project, employer);
    }
  };

  projectSetupError = async () => {
    const err = store.getState().error;
    if (err === I18n.t("appStrings.NETWORK_ERROR") || (await isOffline())) {
      return Toast.show({
        text: I18n.t("appStrings.NETWORK_ERROR"),
        position: "top",
        duration: 3000,
        type: "danger"
      });
    } else {
      return Toast.show({
        text: I18n.t("appStrings.PROJECT_SETUP_ERROR_TEXT"),
        position: "top",
        duration: 3000,
        type: "danger"
      });
    }
  };

  getProjectAndNavigate = async value => {
    if (_working || !value || (value && !value.trim())) return;
    _working = true;
    Keyboard.dismiss();
    const result = await activation(value);

    if (!result) {
      _working = false;
      return this.projectSetupError();
    }

    const { project, employer } = result;

    if (!project) {
      _working = false;
      return this.projectSetupError();
    }

    const nationality = store.getState().settings.nationality;

    await getCommunityProfiles(nationality, false);

    typeof this.props.onSubmit == "function" &&
      this.props.onSubmit(project, employer);

    _working = false;
  };

  render() {
    instance = this;
    const { topContent, bottomContent } = this.props;

    return (
      <Fragment>
        <Card connectToProject>
          {topContent}

          <Text connectProject>{I18n.t("appStrings.LBL_PROJECT_CODE")}</Text>

          <FormInput
            connectProject
            value={this.state.projectCode}
            onSubmit={this.getProjectAndNavigate}
            buttonText={I18n.t("appStrings.LBL_OK")}
          />
        </Card>

        {bottomContent}
      </Fragment>
    );
  }
}
