import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { Screen } from "../../utils";
import { Content, Card, CardItem, Text } from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";

export default Screen(
  connect(state => ({
    settings: state.settings
  }))(
    class RightDetails extends Component {
      state = {
        citation: this.props.navigation.getParam("citation")
      };

      render() {
        const lang = I18n.currentLocale();

        const source = this.state.citation[lang]
          ? this.state.citation[lang].source
          : this.state.citation.source;
        const text = this.state.citation[lang]
          ? this.state.citation[lang].text
          : this.state.citation.text;
        return (
          <Content padder>
            <Card rightsDetails>
              <CardItem header>
                <Text>{source}</Text>
              </CardItem>
              <CardItem>
                <Text>{text}</Text>
              </CardItem>
            </Card>
          </Content>
        );
      }
    }
  )
);
