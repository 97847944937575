export default [
  {
    categoryType: "equipment_consumables",
    // type: "FontAwesome",
    // icon: "stethoscope",
    icon: require("../assets/img/issue_categories/equipment_consumables.png"),
    title: "appStrings.CATEGORY_EQUIPMENT_CONSUMABLES"
  },
  {
    categoryType: "cleaning_products",
    // type: "FontAwesome",
    // icon: "shower",
    icon: require("../assets/img/issue_categories/cleaning_products.png"),
    title: "appStrings.CATEGORY_CLEANING_PRODUCTS"
  },
  {
    categoryType: "hospital_work",
    // type: "FontAwesome",
    // icon: "hospital-o",
    icon: require("../assets/img/issue_categories/hospital_work.png"),
    title: "appStrings.CATEGORY_HOSPITAL_WORK"
  },
  {
    categoryType: "corruption",
    // type: "FontAwesome",
    // icon: "user-secret",
    icon: require("../assets/img/issue_categories/corruption.png"),
    title: "appStrings.CATEGORY_CORRUPTION"
  },
  {
    categoryType: "covid",
    // type: "FontAwesome",
    // icon: "flask",
    icon: require("../assets/img/issue_categories/covid.png"),
    title: "appStrings.CATEGORY_COVID"
  }

  /*
  ,


  {
    categoryType: "food",
    type: "FontAwesome",
    icon: "cutlery",
    title: "appStrings.CATEGORY_FOOD"
  },
  {
    categoryType: "dignity_respect",
    icon: "md-heart",
    title: "appStrings.CATEGORY_DIGNITY_RESPECT"
  },
  {
    categoryType: "recruit_docs",
    type: "FontAwesome",
    icon: "file-text-o",
    title: "appStrings.CATEGORY_RECRUIT_DOCS"
  },
  {
    categoryType: "wages",
    icon: "ios-cash",
    title: "appStrings.CATEGORY_WAGES"
  },
  {
    categoryType: "health_safety",
    type: "FontAwesome",
    icon: "medkit",
    title: "appStrings.CATEGORY_HEALTH_SAFETY"
  },
  {
    categoryType: "worksite",
    icon: "md-hammer",
    title: "appStrings.CATEGORY_WORKSITE"
  },
  {
    categoryType: "transport",
    type: "FontAwesome",
    icon: "bus",
    title: "appStrings.CATEGORY_TRANSPORT"
  },
  {
    categoryType: "accommodations",
    type: "FontAwesome",
    icon: "home",
    title: "appStrings.CATEGORY_ACCOMMODATIONS"
  },
  {
    categoryType: "returnhome",
    type: "FontAwesome",
    icon: "plane",
    title: "appStrings.CATEGORY_RETURNHOME"
  }
  */
];
