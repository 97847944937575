import { StyleSheet, I18nManager } from "react-native";
import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const platform = variables.platform;
  const cardTheme = {
    ".transparent": {
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null
    },
    ".connectToProject": {
      borderRadius: 15,
      padding: 15,
      marginBottom: 30,
      ...(colors.setProject.border
        ? {
            borderWidth: 1,
            borderColor: colors.setProject.border
          }
        : {}),
      "NativeBase.Form": {
        ".formInput": {
          "NativeBase.Button": {
            backgroundColor: colors.setProject.formInputButton.background,
            ...(colors.setProject.formInputButton.border
              ? {
                  borderWidth: variables.borderWidth * 2,
                  borderColor: colors.setProject.formInputButton.border,
                  borderRadius: 5,
                  backgroundColor: "transparent"
                }
              : {}),
            shadowColor: null,
            shadowOffset: null,
            shadowOpacity: null,
            shadowRadius: null,
            elevation: null,
            "NativeBase.Text": {
              color: colors.setProject.formInputButton.text,
              fontWeight:
                colors.setProject.formInputButton.fontWeight || "normal"
            }
          }
        }
      }
    },
    ".autosuggest": {
      padding: variables.listItemPadding + 5
    },
    ".communityDetails": {
      "NativeBase.CardItem": {
        flex: -1,
        flexDirection: "column",
        ".contact": {
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          "NativeBase.Icon": {
            alignSelf: "center",
            marginHorizontal: 10,
            textAlign: "left",
            color: colors.card.communityProfileDetails.contact.icon
          },
          "NativeBase.Body": {
            "NativeBase.Text": {
              ".value": {
                color: colors.card.communityProfileDetails.contact.text
              }
            }
          }
        },
        ".title": {
          flexDirection: "column",
          "NativeBase.H3": {
            alignSelf: "flex-start",
            color: colors.card.communityProfileDetails.title.header,
            fontWeight: "normal"
          },
          "NativeBase.Text": {
            ".subtitle": {
              color: colors.card.communityProfileDetails.title.subtitle,
              fontWeight: "normal"
            }
          }
        },
        ".description": {
          "NativeBase.Text": {
            color: colors.card.communityProfileDetails.description
          }
        },
        ".link": {
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Icon": {
            alignSelf: "flex-start",
            marginHorizontal: 10,
            textAlign: "left",
            color: colors.card.communityProfileDetails.link
          },
          "NativeBase.Text": {
            color: colors.card.communityProfileDetails.link
          }
        }
      },
      "NativeBase.Icon": {
        flex: -1,
        alignSelf: "center",
        marginHorizontal: 10
      }
    },
    ".headerImage": {
      margin: 0,
      padding: 0,
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      "NativeBase.CardItem": {
        margin: 0,
        padding: 0
      }
    },
    ".resourcecard": {
      marginTop: 10,
      marginHorizontal: 10,
      borderColor: null,
      borderLeftColor: colors.card.resource.borderLeft,
      borderRightColor: variables.cardBorderColor,
      borderTopColor: variables.cardBorderColor,
      borderBottomColor: variables.cardBorderColor,
      borderLeftWidth: 5,
      paddingBottom: 5,
      "NativeBase.CardItem": {
        "NativeBase.Left": {
          "NativeBase.Body": {
            "NativeBase.Text": {
              ".title": {
                color: colors.card.resource.title,
                fontWeight: "bold",
                marginBottom: 5
              },
              ".description": {
                color: colors.card.resource.description
              }
            }
          }
        }
      }
    },
    ".community": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "NativeBase.CardItem": {
        flexDirection: "column",
        flex: -1,
        "NativeBase.H3": {
          alignSelf: "flex-start",
          color: colors.card.communityProfileSummary.header,
          fontWeight: "normal"
        },
        "NativeBase.Text": {
          flex: 1,
          ".subtitle": {
            fontSize: variables.DefaultFontSize * 0.8,
            fontWeight: "normal",
            color: colors.card.communityProfileSummary.subtitle,
            marginBottom: 15
          },
          ".description": {
            color: colors.card.communityProfileSummary.description
          }
        }
      },
      "NativeBase.Icon": {
        flex: -1,
        color: colors.card.communityProfileSummary.icon,
        alignSelf: "center",
        marginHorizontal: 10
      }
    },
    ".rightsDetails": {
      marginTop: 30,
      "NativeBase.CardItem": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        ".header": {
          flex: 1,
          height: null,
          padding: 5,
          paddingHorizontal: variables.listItemPadding + 5,
          borderBottomWidth: StyleSheet.hairlineWidth * 2,
          borderColor: variables.cardBorderColor,
          flexDirection: "row",
          backgroundColor: colors.card.rightsDetails.header.background,
          justifyContent: "flex-start",
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.rightsDetails.header.icon,
            textAlign: "left",
            margin: 0
          },
          "NativeBase.Text": {
            color: colors.card.rightsDetails.header.text,
            fontSize: variables.DefaultFontSize * 1.1,
            fontWeight: "normal",
            alignSelf: "center",
            margin: 0
          }
        },
        "NativeBase.Text": {
          flex: -1,
          color: colors.card.rightsDetails.text
        },
        "NativeBase.Icon": {
          flex: -1,
          color: colors.card.rightsDetails.icon,
          alignSelf: "center",
          marginHorizontal: 10,
          textAlign: "right"
        }
      },
      paddingBottom: 0
    },
    ".rights": {
      "NativeBase.CardItem": {
        flexDirection: "row",
        justifyContent: "space-between",
        ".header": {
          flex: 1,
          backgroundColor: colors.card.rights.header.background,
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.rights.header.icon,
            textAlign: "left"
          },
          "NativeBase.Text": {
            color: colors.card.rights.header.text,
            alignSelf: "center"
          }
        },
        "NativeBase.Text": {
          flex: 1
        },
        "NativeBase.Icon": {
          color: colors.card.rights.icon,
          flex: -1,
          textAlign: "right"
        }
      }
    },
    ".hotline": {
      "NativeBase.CardItem": {
        flexDirection: "row",
        justifyContent: "space-between",
        ".header": {
          flex: 1,
          backgroundColor: colors.card.hotline.header.background,
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.hotline.header.icon,
            textAlign: "left"
          },
          "NativeBase.Text": {
            color: colors.card.hotline.header.text,
            alignSelf: "center"
          }
        },
        "NativeBase.Text": {
          flex: 1
        },
        "NativeBase.Icon": {
          color: colors.card.hotline.text,
          flex: -1,
          textAlign: "right"
        }
      }
    },
    ".resources": {
      "NativeBase.CardItem": {
        flexDirection: "row",
        justifyContent: "space-between",
        ".header": {
          flex: 1,
          backgroundColor: colors.card.resources.header.background,
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.resources.header.icon,
            textAlign: "left"
          },
          "NativeBase.Text": {
            color: colors.card.resources.header.text,
            alignSelf: "center"
          }
        },
        "NativeBase.Text": {
          flex: 1
        },
        "NativeBase.Icon": {
          color: colors.card.resources.icon,
          flex: -1,
          textAlign: "right"
        }
      }
    },
    ".project": {
      "NativeBase.CardItem": {
        flexDirection: "row",
        justifyContent: "space-between",
        ".header": {
          flex: 1,
          backgroundColor: colors.card.project.header.background,
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.project.header.icon,
            textAlign: "left"
          },
          "NativeBase.Text": {
            color: colors.card.project.header.text,
            alignSelf: "center"
          }
        },
        "NativeBase.Text": {
          flex: 1
        },
        "NativeBase.Icon": {
          color: colors.card.project.icon,
          flex: -1,
          textAlign: "right"
        }
      }
    },
    ".projectDetails": {
      "NativeBase.CardItem": {
        ".header": {
          flexDirection: "column",
          "NativeBase.Text": {
            ".title": {
              color: colors.card.projectDetails.header.title
            },
            ".subtitle": {
              fontWeight: null,
              color: colors.card.projectDetails.header.subtitle
            }
          }
        },
        ".image": {
          padding: 0,
          borderBottomWidth: 5,
          borderColor: colors.card.projectDetails.image.border
        }
      }
    },
    ".aboutExpo": {
      "NativeBase.CardItem": {
        "NativeBase.Body": {
          "NativeBase.Text": {
            ".description": {
              color: colors.brown,
              marginBottom: 15
            }
          }
        }
      }
    },
    ".question": {
      borderRadius: 10,
      marginVertical: 15,
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null,
      "NativeBase.CardItem": {
        borderRadius: 10,
        "NativeBase.CheckBox": {
          left: 0,
          margin: 0,
          padding: 0,
          borderColor: colors.card.question.checkbox.border,
          borderWidth: 1
        },
        "NativeBase.Text": {
          color: colors.card.question.text,
          paddingHorizontal: 15,
          flex: -1
        }
      }
    },
    marginVertical: 5,
    marginHorizontal: 2,
    flex: 1,
    borderWidth: variables.borderWidth,
    borderRadius: 2,
    borderColor: variables.cardBorderColor,
    flexWrap: "nowrap",
    backgroundColor: variables.cardDefaultBg,
    shadowColor: colors.card.shadow,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 1.5,
    elevation: 3,
    ".disconnected": {
      marginHorizontal: 0,
      marginVertical: 10,
      "NativeBase.CardItem": {
        paddingVertical: 10,
        paddingRight: 0,
        paddingLeft: 25,
        borderRadius: 0,
        backgroundColor: colors.card.disconnected.background,
        "NativeBase.Body": {
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Text": {
            paddingLeft: 15,
            paddingRight: 0,
            alignSelf: "center",
            flex: 1,
            fontSize: 14,
            fontWeight: "bold"
          }
        }
      }
    },
    ".noproject": {
      marginTop: 15,
      "NativeBase.CardItem": {
        "NativeBase.Body": {
          "NativeBase.Icon": {
            color: colors.card.noproject.icon,
            fontSize: 46,
            alignSelf: "center"
          },
          "NativeBase.Text": {
            color: colors.card.noproject.text,
            alignSelf: "center",
            textAlign: "center"
          },
          "NativeBase.Button": {
            marginVertical: 15,
            alignSelf: "center"
          }
        }
      }
    },
    ".noposts": {
      marginTop: 15,
      "NativeBase.CardItem": {
        "NativeBase.Body": {
          "NativeBase.Icon": {
            color: colors.card.noposts.icon,
            fontSize: 46,
            alignSelf: "center"
          },
          "NativeBase.Text": {
            color: colors.card.noposts.text,
            alignSelf: "center",
            textAlign: "center",
            marginTop: 30
          },
          "NativeBase.Button": {
            marginVertical: 15,
            alignSelf: "center"
          }
        }
      }
    },
    ".greeting": {
      marginHorizontal: 0,
      marginVertical: 0,
      "NativeBase.CardItem": {
        padding: 0,
        borderRadius: 0,
        backgroundColor: colors.card.greeting.background
      }
    },
    ".warning": {
      marginHorizontal: 0,
      marginVertical: 10,
      //borderWidth: 1,
      // borderColor: colors.card.warning.border, // TODO: uncomment?
      "NativeBase.CardItem": {
        paddingVertical: 10,
        paddingRight: 5,
        paddingLeft: 25,
        borderRadius: 0,
        backgroundColor: colors.card.warning.background,
        "NativeBase.Body": {
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.warning.icon,
            fontSize: variables.iconFontSize * 0.8,
            ".forward": {
              flex: -1,
              //fontSize: variables.iconFontSize * 0.8,
              textAlign: "right",
              paddingHorizontal: 10
            }
          },
          "NativeBase.Text": {
            color: colors.card.warning.text,
            paddingLeft: 15,
            paddingRight: 0,
            alignSelf: "center",
            flex: 1,
            fontSize: 14,
            fontWeight: "bold"
          }
        }
      }
    },

    ".notice": {
      marginHorizontal: 0,
      marginVertical: 10,
      //borderWidth: 1,
      // borderColor: colors.card.notice.border, // TODO: uncomment?
      "NativeBase.CardItem": {
        paddingVertical: 10,
        paddingRight: 5,
        paddingLeft: 25,
        borderRadius: 0,
        backgroundColor: colors.card.notice.background,
        "NativeBase.Body": {
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.notice.icon,
            fontSize: variables.iconFontSize * 0.8,
            ".forward": {
              flex: -1,
              //fontSize: variables.iconFontSize * 0.8,
              textAlign: "right",
              paddingHorizontal: 10
            }
          },
          "NativeBase.Text": {
            color: colors.card.notice.text,
            paddingLeft: 15,
            paddingRight: 0,
            alignSelf: "center",
            flex: 1,
            fontSize: 14,
            fontWeight: "bold"
          }
        }
      }
    },
    ".poll": {
      marginHorizontal: 0,
      borderRadius: 5,
      // paddingVertical: 10,
      // borderWidth: 1,
      // borderColor: colors.card.poll.border,
      backgroundColor: colors.card.poll.background,
      "NativeBase.CardItem": {
        paddingVertical: 5,
        paddingRight: 5,
        paddingLeft: 15,
        borderRadius: 5,
        backgroundColor: colors.card.poll.cardBackground,
        "NativeBase.Body": {
          flexDirection: "row",
          alignItems: "center",
          ".question": {
            marginTop: 10
          },
          "NativeBase.Icon": {
            color: colors.card.poll.icon,
            fontSize: variables.iconFontSize * 0.8,
            ".forward": {
              flex: -1,
              //fontSize: variables.iconFontSize * 0.8,
              textAlign: "right",
              paddingHorizontal: 10
            }
          },
          "NativeBase.Text": {
            color: colors.card.poll.text,
            paddingLeft: 5,
            paddingRight: 0,
            alignSelf: "center",
            flex: 1,
            fontSize: 20,
            fontWeight: "bold",
            ".pollHeader": {
              color: colors.card.poll.header,
              fontSize: 12
            }
          }
        },
        ".answerPrompt": {
          "NativeBase.Body": {
            marginBottom: 10,
            flexDirection: "row",
            "NativeBase.Text": {
              color: colors.card.poll.answerPrompt,
              textAlign: "right",
              fontSize: 16
            }
          }
        }
      }
    },
    ".post": {
      "NativeBase.CardItem": {
        paddingHorizontal: 10,
        ".header": {
          paddingVertical: 5,
          paddingHorizontal: 15,
          flex: 1,
          flexWrap: "wrap",
          justifyContent: "flex-start",
          "NativeBase.Body": {
            "NativeBase.Text": {
              margin: 0,
              padding: 0,
              //fontFamily: "Nunito",
              ".title": {
                flex: -1,
                paddingHorizontal: 10,
                color: colors.card.post.header.title,
                fontWeight: "bold"
              },
              ".timestamp": {
                flex: -1,
                paddingHorizontal: 10,
                fontSize: 11,
                fontWeight: null,
                color: colors.card.post.header.timestamp,
                fontWeight: "bold",
                marginVertical: 4
              }
            }
          }
        },
        ".body": {
          "NativeBase.Body": {
            "NativeBase.Text": {
              color: colors.card.post.body,
              paddingHorizontal: 10,
              fontSize: 14,
              marginTop: 10
            }
          }
        },
        ".readmore": {
          alignSelf: "flex-end",
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Text": {
            fontSize: variables.DefaultFontSize * 0.9,
            color: colors.card.post.readmore.text,
            marginHorizontal: 5
          },
          "NativeBase.Icon": {
            width: variables.iconFontSize * 0.7,
            fontSize: variables.iconFontSize * 0.7,
            color: colors.card.post.readmore.icon
          }
        },
        ".link": {
          borderColor: variables.cardBorderColor,
          borderWidth: 1,
          marginHorizontal: 10,
          "NativeBase.Icon": {
            alignSelf: "flex-start"
          },
          "NativeBase.Body": {
            "NativeBase.H2": {
              color: colors.card.post.link.header
            },
            "NativeBase.Text": {
              color: colors.card.post.link.text
            }
          }
        }
      }
    },
    ".whatsnew": {
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null,
      marginHorizontal: 0,
      borderColor: colors.card.whatsnew.border,
      borderRadius: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      minHeight: platform === "web" ? "auto" : null
    },
    ".settings": {
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null,
      margin: 1,
      marginVertical: 5,
      borderWidth: StyleSheet.hairlineWidth,
      "NativeBase.CardItem": {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "NativeBase.Text": {
          alignSelf: "center",
          flex: -1
        },
        "NativeBase.Icon": {
          flex: -1,
          color: colors.card.settings.icon,
          textAlign: "right"
        }
      }
    },
    ".radio": {
      borderBottomWidth: 0,
      "NativeBase.CardItem": {
        ".bordered": {
          borderBottomWidth: StyleSheet.hairlineWidth
        },
        ".disabled": {
          backgroundColor: colors.card.radio.disabledBackground
        },
        "NativeBase.Text": {
          color: colors.card.radio.text
        },
        "NativeBase.Icon": {
          color: colors.card.radio.icon
        }
      }
    },
    ".employer": {
      marginVertical: 15
    },
    ".reportissue": {
      padding: variables.listItemPadding + 5,
      marginVertical: 15,
      "NativeBase.H1": {
        color: colors.card.reportIssue.header
      }
    },
    ".resourcealert": {
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null,
      marginHorizontal: 0,
      borderColor: colors.card.resourcealert.border,
      borderRadius: 0
    },
    ".healthalert": {
      marginHorizontal: 0,
      marginVertical: 10,
      "NativeBase.CardItem": {
        paddingVertical: 10,
        paddingRight: 5,
        paddingLeft: 25,
        borderRadius: 0,
        backgroundColor: colors.card.healthalert.background,
        "NativeBase.Body": {
          flexDirection: "row",
          alignItems: "center",
          "NativeBase.Icon": {
            color: colors.card.healthalert.icon,
            fontSize: variables.iconFontSize * 0.8,
            ".forward": {
              flex: -1,
              textAlign: "right",
              paddingHorizontal: 10
            }
          },
          "NativeBase.Text": {
            color: colors.card.healthalert.text,
            paddingLeft: 15,
            paddingRight: 0,
            alignSelf: "center",
            flex: 1,
            fontSize: 14,
            fontWeight: "bold"
          }
        }
      }
    }
  };

  return cardTheme;
};
