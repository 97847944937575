import { I18nManager } from "react-native";
import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const h3Theme = {
    ".pollQuestion": {
      paddingVertical: 15,
      color: colors.header.h3.pollQuestion,
      fontWeight: "bold"
    },
    ".communityName": {
      paddingTop: 10,
      fontWeight: "normal",
      color: colors.header.h3.communityName
    },
    ".connectProject": {
      color: colors.header.h3.connectProject,
      fontWeight: "bold",
      fontSize: variables.DefaultFontSize,
      marginVertical: 10
    },
    ".setup": {
      textAlign: "center",
      color: colors.header.h3.setup
    },
    ".employerSearch": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h3.employerSearch,
      marginVertical: 10
    },
    ".employerSearchProject": {
      textAlign: "center",
      color: colors.header.h3.employerSearchProject,
      fontSize: 16
    },
    ".kyrCategoryName": {
      color: colors.funkyBlue2,
    },
    color: variables.textColor,
    fontSize: variables.fontSizeH3,
    lineHeight: variables.lineHeightH3,
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr"
  };

  return h3Theme;
};
