import React, { Component, Fragment } from "react";
import { Dimensions } from "react-native";
import { connect } from "react-redux";
// import { NavigationActions, StackActions } from "react-navigation";
import moment from "moment";
import config from "react-native-config";
import FastImage from "react-native-fast-image";

import {
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
  FlatList,
  SafeAreaView,
  Linking,
  Platform
} from "react-native";
import Hyperlink from "react-native-hyperlink";
import { Card, CardItem, Body, Text, Icon, View, Content } from "native-base";
import SplashScreen from "react-native-splash-screen";

import { Screen } from "../../utils";
import Progress from "../../components/Progress";
import showDialog from "../../components/Dialog";
import MoodResponse from "../../components/MoodResponse";
// import NoProjectDialog from "../../components/NoProjectDialog";

import AlertImageIcon from "../../assets/img/global_warning.png";
import NoticeImageIcon from "../../assets/img/global_notice.png";
import GreetingImage from "../../assets/img/greeting.png";
import CheckboxImage from "../../assets/img/checkbox.png";
import wcColors from "../../../native-base-theme/variables/wcColors";

import I18n from "../../i18n";
import { getFeed, getFeedPosts, getGreeting } from "./actions";
import { filterPolls } from "./actions/index";
import { welcomeMessageVisible } from "../../actions/settings";
import { setChildHeader } from "../mainTabNavigator/actions";
import { getNotificationUrl } from "../../notifications";
import { uriPrefix, EMPLOYERS_TIMEOUT } from "../../const";
import { cachedGET } from "../../axios";
import { isOffline } from "../../offline";

export default Screen(
  connect(state => ({
    settings: state.settings,
    warnings: state.whatsnews.alerts.warnings,
    notices: state.whatsnews.alerts.notices,
    posts: state.whatsnews.posts,
    polls: filterPolls(
      state.whatsnews.polls.availablePolls || [],
      state.whatsnews.polls.hiddenPolls || []
    ),
    isOffline: state.offline,
    isActiveProject: state.projectStatus,
    rootNavigator: state.tabsReducer.rootNavigator
  }))(
    class WhatsNewsTab extends Component {
      state = {
        canLoadMore: true,
        isRefreshing: false,
        isLoadMore: false,
        loadedOnce: false,
        seeWelcomeMessage: this.props.settings.seeWelcomeMessage
      };

      async componentDidMount() {
        const offline = await isOffline();
        const url = getNotificationUrl();
        if (!offline && !url) {
          const result = await getFeed();
          if (result.length < config.FEED_SIZE) {
            this.setState({ canLoadMore: false });
          }
        }

        welcomeMessageVisible();
        this._handleIntentURL({
          url: url || (await Linking.getInitialURL())
        });

        Linking.addEventListener("url", this._handleIntentURL);

        SplashScreen.hide();
      }
      componentWillUnmount() {
        Linking.removeEventListener("url", this._handleIntentURL);
      }

      _handleIntentURL = async ({ url }) => {
        if (url) {
          await getFeed();

          url = url.replace(`${uriPrefix}app/whatsnew/`, "");
          await getFeed();
          switch (true) {
            case url.startsWith("warning"):
              this.props.navigation.navigate("WhatsNewTab");
              const warning = this.props.warnings.find(
                it => it.id == url.split("/").pop()
              );
              if (warning) {
                try {
                  this._list.scrollToOffset({ animated: true, offset: 0 });
                } catch (err) {}
                warning.content.description &&
                  warning.content.description.length &&
                  showDialog(
                    warning.content.title,
                    warning.content.description
                  );
              }
              break;
            case url.startsWith("info"):
              this.props.navigation.navigate("WhatsNewTab");
              const notice = this.props.notices.find(
                it => it.id == url.split("/").pop()
              );
              if (notice) {
                try {
                  this._list.scrollToOffset({ animated: true, offset: 0 });
                } catch (err) {}
                notice.content.description &&
                  notice.content.description.length &&
                  showDialog(notice.content.title, notice.content.description);
              }
              break;
            case url == "post":
              this.props.navigation.navigate("WhatsNewTab");
              try {
                this._list.scrollToIndex({ animated: true, index: 0 });
              } catch (err) {}
              break;
          }
        }
      };

      /* isConnectedToProject() {
        const {
          projectId,
          projectName,
          employerId,
          employerName
        } = this.props.settings;
        if (
          projectId &&
          projectName &&
          ((employerId && employerName) || employerName)
        ) {
          return true;
        } else {
          return false;
        }
      } */

      /* _navigateToSettings() {
        this.props.rootNavigator.dispatch(
          NavigationActions.navigate({
            routeName: "App",
            action: StackActions.reset({
              index: 1,
              key: null,
              actions: [
                NavigationActions.navigate({ routeName: "SettingsTab" }),
                NavigationActions.navigate({ routeName: "SetProject" })
              ]
            })
          })
        );
      } */

      formatPostDate(date) {
        if (date) {
          return moment(date).fromNow();
        } else {
          return "";
        }
      }

      refreshFeed = async () => {
        const offline = await isOffline();
        if (offline) {
          showDialog(I18n.t("appStrings.MSG_DISCONNECTED"));
          return;
        }

        this.setState({ isRefreshing: true });
        const result = await getFeed();
        this.setState({ isRefreshing: false });
        if (result.length < config.FEED_SIZE) {
          this.setState({ canLoadMore: false });
        } else {
          this.setState({ canLoadMore: true });
        }
      };

      generateWarnings = () => {
        const orderedWarnings =
          this.props.warnings && this.props.warnings.length
            ? this.props.warnings.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              })
            : [];

        return orderedWarnings.map((warning, key) =>
          warning.content.description && warning.content.description.length ? (
            <TouchableWithoutFeedback
              onPress={() => {
                showDialog(warning.content.title, warning.content.description);
              }}
              key={warning.id}
              testID={"warning"}
            >
              <Card whatsnew warning>
                <CardItem>
                  <Body>
                    <Image style={style.alertImage} source={AlertImageIcon} />
                    <Text>{warning.content.title}</Text>
                    <Icon
                      forward
                      name={
                        I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"
                      }
                    />
                  </Body>
                </CardItem>
              </Card>
            </TouchableWithoutFeedback>
          ) : (
            <Card whatsnew warning key={warning.id} testID={"warning"}>
              <CardItem>
                <Body>
                  <Image style={style.alertImage} source={AlertImageIcon} />
                  <Text>{warning.content.title}</Text>
                </Body>
              </CardItem>
            </Card>
          )
        );
      };

      generateNotices = () => {
        const orderedNotices =
          this.props.notices && this.props.notices.length
            ? this.props.notices.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              })
            : [];

        return orderedNotices.map((notice, key) =>
          notice.content.description && notice.content.description.length ? (
            <TouchableWithoutFeedback
              testID={"notice"}
              onPress={() =>
                showDialog(notice.content.title, notice.content.description)
              }
              key={notice.id}
            >
              <Card whatsnew notice>
                <CardItem>
                  <Body>
                    <Image style={style.alertImage} source={NoticeImageIcon} />
                    <Text>{notice.content.title}</Text>
                    <Icon
                      forward
                      name={
                        I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"
                      }
                    />
                  </Body>
                </CardItem>
              </Card>
            </TouchableWithoutFeedback>
          ) : (
            <Card whatsnew notice key={notice.id}>
              <CardItem>
                <Body>
                  <Image style={style.alertImage} source={NoticeImageIcon} />
                  <Text>{notice.content.title}</Text>
                </Body>
              </CardItem>
            </Card>
          )
        );
      };

      generatePolls = () => {
        const orderedPolls =
          this.props.polls && this.props.polls.length
            ? this.props.polls.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              })
            : [];

        return orderedPolls.map((poll, key) => (
          <View poll testID={"poll"} key={poll.id}>
            <TouchableWithoutFeedback
              onPress={async () => {
                const result = await cachedGET(
                  `/context/projects/${this.props.settings.projectId}`,
                  EMPLOYERS_TIMEOUT
                );
                this.props.navigation.navigate("PollModal", {
                  poll,
                  pollId: poll.id,
                  employers:
                    (result && result.project && result.project.employers) || []
                });
              }}
            >
              <Card whatsnew poll>
                <CardItem>
                  <Body question>
                    <Image source={CheckboxImage} style={style.pollImage} />
                    <Text uppercase={true} pollHeader>
                      {I18n.t("appStrings.MSG_OPINION_MATTERS")}
                    </Text>
                  </Body>
                </CardItem>
                <CardItem>
                  <Body>
                    <Text>{poll.content.question}</Text>
                  </Body>
                </CardItem>
                <CardItem answerPrompt>
                  <Body>
                    <Text>{I18n.t("appStrings.POLL_ACTION")}</Text>
                    <Icon
                      forward
                      type="FontAwesome"
                      name={I18n.isRTL ? "caret-left" : "caret-right"}
                    />
                  </Body>
                </CardItem>
              </Card>
            </TouchableWithoutFeedback>
          </View>
        ));
      };

      navigateToPostDetails = post => {
        this.props.navigation.navigate("PostDetails", {
          post,
          postId: post.id
        });
        // setActiveNavigator(this.props.navigation);
        setChildHeader("appStrings.TITLE_WHATSNEW");
      };

      generatePosts = ({ item }) => {
        const post = item;
        const content = item.data.content;
        const properties = item.data.properties;
        let card = (
          <Card whatsnew post key={post.id}>
            <CardItem header>
              {content.author_image ? (
                <FastImage
                  style={style.image}
                  source={{
                    uri: content.author_image,
                    priority: FastImage.priority.normal
                  }}
                  resizeMode={FastImage.resizeMode.cover}
                />
              ) : (
                <Image
                  style={style.image}
                  source={require("../../assets/img/no_author_image.png")}
                />
              )}
              <Body>
                <Text title>{content.title}</Text>
                <Text timestamp>
                  {I18n.t("appStrings.POST_AUTHOR_TIMESTAMP", {
                    DATE_AND_TIME: this.formatPostDate(post.created_at),
                    AUTHOR_NAME: content.author ? content.author : ""
                  })}
                </Text>
              </Body>
            </CardItem>
            {(content.post_image || properties?.post_image) && (
              <FastImage
                style={style.postImage}
                source={{
                  uri: content.post_image || properties?.post_image,
                  priority: FastImage.priority.normal
                }}
                resizeMode={FastImage.resizeMode.cover}
              />
            )}
            <CardItem body>
              <Body>
                <Hyperlink
                  linkDefault={true}
                  linkStyle={{ color: wcColors.whatsNew.link }}
                >
                  <Text>
                    {post.type === "info-detailed"
                      ? content.post_summary
                      : content.subtitle}
                  </Text>
                </Hyperlink>
              </Body>
            </CardItem>
            {post.type === "info-detailed" && (
              <CardItem readmore>
                <Text readmore>{I18n.t("appStrings.PROMPT_READMORE")}</Text>
                <Icon
                  readmore
                  name={I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
                />
              </CardItem>
            )}
          </Card>
        );
        if (post.type === "info-detailed") {
          card = (
            <TouchableWithoutFeedback
              testID={"post"}
              onPress={() => this.navigateToPostDetails(post)}
            >
              <View post>{card}</View>
            </TouchableWithoutFeedback>
          );
        } else {
          card = <View post>{card}</View>;
        }
        return card;
      };

      _loadInProgress = false;
      _canLoadMore = true;
      _isLoadMore = false;

      loadMorePosts = async x => {
        const dist =
          Platform.OS === "ios"
            ? x.distanceFromEnd > 0
            : x.distanceFromEnd === 0;

        if (this._loadInProgress || !this._canLoadMore || dist) return;
        this._isLoadMore = true;
        this._loadInProgress = true;

        const postSize = this.props.posts.length;
        const lastItem = this.props.posts[postSize - 1];
        this.setState({ isLoadMore: true });
        const result = await getFeedPosts(
          config.FEED_SIZE,
          lastItem.created_at,
          lastItem.id,
          true
        );

        if (
          result &&
          (result.length === 0 || result.length < config.FEED_SIZE)
        ) {
          this.setState({ canLoadMore: false });
          this._canLoadMore = false;
        }

        this.setState({ isLoadMore: false });

        setTimeout(() => {
          this._isLoadMore = false;
        }, 400);
        this._loadInProgress = false;
      };

      render() {
        const orderedPosts =
          this.props.posts && this.props.posts.length
            ? [...this.props.posts]
            : [];
        orderedPosts.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        return (
          <SafeAreaView
            style={{
              height: "100%"
            }}
            forceInset={{ horizontal: "always", top: "always" }}
          >
            <FlatList
              ref={ref => (this._list = ref)}
              data={orderedPosts}
              contentContainerStyle={[
                {
                  paddingHorizontal: 0,
                  maxWidth: 1200
                }
              ]}
              renderItem={this.generatePosts}
              initialNumToRender={config.FEED_SIZE}
              keyExtractor={(item, key) => `${item.id}_${key}`}
              onRefresh={this.refreshFeed}
              refreshing={this.state.isRefreshing}
              removeClippedSubviews
              disableVirtualization
              onEndReachedThreshold={Platform.OS === "ios" ? 0.01 : 0.8}
              onEndReached={x => {
                if (
                  this.props.posts &&
                  this.props.posts.length > 0 &&
                  !this._isLoadMore &&
                  this.state.canLoadMore
                ) {
                  this.loadMorePosts(x);
                }
              }}
              ListEmptyComponent={
                <Card whatsnew noposts>
                  <CardItem
                    style={{
                      marginVertical: 30
                    }}
                  >
                    <Body>
                      <Icon name="lightbulb-on" type="MaterialCommunityIcons" />
                      <Text>
                        {I18n.t("appStrings.MSG_UPDATES_PLACEHOLDER")}
                      </Text>
                    </Body>
                  </CardItem>
                </Card>
              }
              ListHeaderComponent={
                <View
                  style={{
                    flex: 1,
                    marginTop: 0,
                    marginBottom: 15
                  }}
                >
                  <Card whatsnew greeting>
                    <CardItem>
                      <Image
                        source={GreetingImage}
                        style={style.greetingImage}
                      />
                    </CardItem>
                    <CardItem>
                      <Body>
                        <Text welcomeMessage>
                          {!this.state.seeWelcomeMessage
                            ? I18n.t("appStrings.WELCOME_TITLE")
                            : I18n.t(getGreeting())}
                        </Text>
                      </Body>
                    </CardItem>
                  </Card>

                  <MoodResponse />

                  {this.props.isOffline && (
                    <Card whatsnew disconnected>
                      <CardItem>
                        <Body>
                          <View style={{ width: "100%" }}>
                            <Text disconnected>
                              {I18n.t("appStrings.MSG_DISCONNECTED")}
                            </Text>
                          </View>
                        </Body>
                      </CardItem>
                    </Card>
                  )}

                  {/* {!this.props.isActiveProject && (
                    <NoProjectDialog
                      headerText={I18n.t("appStrings.MSG_INACTIVE_PROJECT")}
                      buttonOnPress={() => this._navigateToSettings()}
                      buttonText={I18n.t(
                        "appStrings.MSG_CONNECT_TO_DIFFERENT_PROJECT"
                      )}
                    />
                  )} */}

                  {/* {!this.isConnectedToProject() && (
                    <NoProjectDialog
                      headerText={I18n.t(
                        "appStrings.MSG_NOT_CONNECTED_TO_PROJECT"
                      )}
                      buttonOnPress={() => this._navigateToSettings()}
                      buttonText={I18n.t("appStrings.MSG_CONNECT_TO_PROJECT")}
                    />
                  )} */}

                  {this.props.warnings && this.props.warnings.length > 0 ? (
                    <Fragment>{this.generateWarnings()}</Fragment>
                  ) : null}
                  {this.props.notices && this.props.notices.length > 0 ? (
                    <Fragment>{this.generateNotices()}</Fragment>
                  ) : null}
                  {this.props.polls && this.props.polls.length > 0 ? (
                    <Fragment>{this.generatePolls()}</Fragment>
                  ) : null}
                </View>
              }
              ListFooterComponent={
                (this._canLoadMore ||
                  !this.props.posts ||
                  (this.props.posts && this.props.posts.length == 0)) &&
                this._isLoadMore && <Progress />
              }
            />
          </SafeAreaView>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  image: {
    width: 40,
    height: 40
  },
  postImage: {
    width: "100%",
    aspectRatio: 16 / 9,
    ...(Platform.OS == "web"
      ? {}
      : {
          marginLeft: -1,
          marginRight: -1
        })
  },
  alertImage: {
    width: 24,
    height: 24
  },
  greetingImage: {
    height: 200,
    width: null,
    flex: 1
  },
  pollImage: {
    width: 24,
    height: 24
  }
});
