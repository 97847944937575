import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const platform = variables.platform;

  const theme = {
    "NativeBase.Item": {
      ".fixedLabel": {
        "NativeBase.Label": {
          paddingLeft: null
        },
        marginLeft: 15
      },
      ".inlineLabel": {
        "NativeBase.Label": {
          paddingLeft: null
        },
        marginLeft: 15
      },
      ".placeholderLabel": {
        "NativeBase.Input": {}
      },
      ".stackedLabel": {
        "NativeBase.Label": {
          top: 5,
          paddingLeft: null
        },
        "NativeBase.Input": {
          paddingLeft: null,
          marginLeft: null
        },
        "NativeBase.Icon": {
          marginTop: 36
        },
        marginLeft: 15
      },
      ".floatingLabel": {
        "NativeBase.Input": {
          paddingLeft: null,
          top: 10,
          marginLeft: null
        },
        "NativeBase.Label": {
          left: 0,
          top: 6
        },
        "NativeBase.Icon": {
          top: 6
        },
        marginTop: 15,
        marginLeft: 15
      },
      ".regular": {
        "NativeBase.Label": {
          left: 0
        },
        marginLeft: 0
      },
      ".rounded": {
        "NativeBase.Label": {
          left: 0
        },
        marginLeft: 0
      },
      ".underline": {
        "NativeBase.Label": {
          left: 0,
          top: 0,
          position: "relative"
        },
        "NativeBase.Input": {
          left: -15
        },
        marginLeft: 15
      },
      ".last": {
        marginLeft: 0,
        paddingLeft: 15
      },
      "NativeBase.Label": {
        paddingRight: 5
      },
      marginLeft: 15
    },
    ".formInput": {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      padding: 0,
      marginVertical: 15,
      "NativeBase.Item": {
        flex: 1,
        marginHorizontal: 5,
        paddingVertical: 2,
        borderWidth: 0,
        backgroundColor: colors.form.input.background,
        borderRadius: 5,
        "NativeBase.Input": {
          flex: 1,
          color: colors.form.input.text
        }
      },
      "NativeBase.Button": {
        flex: -1,
        alignSelf: "center",
        marginHorizontal: 5,
        borderRadius: 5,
        backgroundColor: colors.form.input.button.background,
        "NativeBase.Text": {
          color: colors.form.input.button.text
        }
      }
    },
    ".employer": {
      borderWidth: 0,
      borderBottomWidth: 0,
      borderColor: colors.form.employer.border,
      backgroundColor: colors.form.employer.background,
      "NativeBase.Button": {
        backgroundColor: colors.form.employer.button.background,
        flex: -1,
        shadowColor: null,
        shadowOffset: null,
        shadowOpacity: null,
        shadowRadius: null,
        elevation: null,
        "NativeBase.Text": {
          color: colors.form.employer.button.text
        }
      }
    },
    ".settingsFormInput": {
      borderWidth: 0,
      borderBottomWidth: 0,
      borderColor: colors.form.settings.border,
      "NativeBase.Button": {
        backgroundColor: colors.form.settings.button.background,
        flex: -1,
        shadowColor: null,
        shadowOffset: null,
        shadowOpacity: null,
        shadowRadius: null,
        elevation: null,
        "NativeBase.Text": {
          color: colors.form.settings.button.text
        }
      }
    },
    ".settingsJobFunctionInput": {
      borderWidth: 0,
      borderBottomWidth: 0,
      borderColor: colors.form.settings.border,
      "NativeBase.Button": {
        backgroundColor: colors.white,
        borderWidth:1,
        borderColor: colors.demoGrey5,
        flex: -1,
        shadowColor: null,
        shadowOffset: null,
        shadowOpacity: null,
        shadowRadius: null,
        elevation: null,
        "NativeBase.Text": {
          color: colors.demoGrey3
        }
      }
    }
  };

  return theme;
};
