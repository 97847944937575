import React, { Component } from "react";
import { Screen } from "../../utils";
import { Image, TouchableOpacity } from "react-native";
import {
  Content,
  Card,
  CardItem,
  List,
  ListItem,
  Body,
  Icon,
  Text,
  View, 
  Left,
  Right,
  Thumbnail,
  H1,
  H3
} from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";
import styles from "../../styles";

export default Screen(
  connect(state => ({
    settings: state.settings,
    rights: state.rights,
    expoStandards: state.expoStandards
  }))(
    class KnowYourRights extends Component {
      renderCategory = (category, header, icon) => {
        return this.props.rights[category] ||
          (this.props.settings.projectId &&
            this.props.settings.projectName &&
            ((this.props.settings.employerId &&
              this.props.settings.employerName) ||
              this.props.settings.employerName) &&
            this.props.expoStandards[category]) ? (
          <TouchableOpacity
            testID={category}
            onPress={() => {
              this.props.navigation.navigate("RightsCategoryList", {
                category,
                header
              });
            }}
          >
            <Card>
              <CardItem>
                <Left>
                  <Thumbnail source={icon} />
                  <Body>
                    <H3 kyrCategoryName>{I18n.t(header)}</H3>
                  </Body>
                </Left>
              </CardItem>
            </Card>
          </TouchableOpacity>
        ) : null;
      };

      render() {
        return (
          <Content padder>
              <H1 kyrHeader>{I18n.t("appStrings.RIGHTS_GUIDE_TITLE")}</H1>
              {this.renderCategory(
                "fundamental",
                "appStrings.RIGHTS_GUIDE_CATEGORY_FUNDAMENTAL",
                require("../../assets/img/rights/rights_category_fundamental.png")
              )}
              {this.renderCategory(
                "employee",
                "appStrings.RIGHTS_GUIDE_CATEGORY_EMPLOYEE",
                require("../../assets/img/rights/rights_category_employee.png")
              )}
              {this.renderCategory(
                "whistleblower",
                "appStrings.RIGHTS_GUIDE_CATEGORY_WHISTLEBLOWER",
                require("../../assets/img/rights/rights_category_whistleblower.png")
              )}
              {this.renderCategory(
                "admin_professional",
                "appStrings.RIGHTS_GUIDE_CATEGORY_ADMIN_PROFESSIONAL",
                require("../../assets/img/rights/rights_category_admin_professional.png")
              )}
            
          </Content>
        );
      }
    }
  )
);
