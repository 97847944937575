import { Content } from "native-base";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { showToast } from "../app/utils";
import I18n from "../app/i18n";

const PdfViewer = ({ source, navigation }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = () => {
    showToast(I18n.t("appStrings.PDF_LOADING_ERROR"), "danger");
    navigation.goBack();
  };

  return (
    <Content>
      <Document
        renderMode="svg"
        file={source}
        loading=""
        error=""
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} loading="" />
        ))}
      </Document>
    </Content>
  );
};

export default PdfViewer;
