import React from "react";
import { TouchableOpacity } from "react-native";
import { Body, Card, CardItem, Left, Text, Thumbnail } from "native-base";
import HelpfulResourcesImage from "../../../assets/img/resources/Resources_helpful.png";
import { setChildHeader } from "../../mainTabNavigator/actions";
import I18n from "../../../i18n";

export default function ProfileSummary(props) {
  return (
    <TouchableOpacity
      testID="profileSummary"
      key="profileSummary"
      onPress={() => {
        props.navigation.navigate("CommunityProfSummary");
        setChildHeader("appStrings.CATEGORY_YOUR_COMMUNITY");
      }}
    >
      <Card resourcecard>
        <CardItem>
          <Left>
            <Thumbnail source={HelpfulResourcesImage} />
            <Body>
              <Text title>{I18n.t("appStrings.CATEGORY_YOUR_COMMUNITY")}</Text>
              <Text description>
                {I18n.t("appStrings.CATEGORY_DESC_YOUR_COMMUNITY")}
              </Text>
            </Body>
          </Left>
        </CardItem>
      </Card>
    </TouchableOpacity>
  );
}
