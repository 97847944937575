import store from "../../../store";
import types from "./types";
import { StackActions, NavigationActions } from "react-navigation";
import { setForceRtl } from "../../../actions/settings";

export function setActiveTab(tab) {
  //const childNavigator = store.getState().tabsReducer.activeChildNavigator;

  store.dispatch({
    type: types.SET_ACTIVE_TAB,
    tab
  });
}

export function setActiveNavigator(navigator) {
  // alert(JSON.stringify(navigator, null, 2));
  store.dispatch({
    type: types.SET_ACTIVE_NAVIGATOR,
    activeChildNavigator: navigator
  });
}

export function setActiveNavigatorIndex(index) {
  store.dispatch({
    type: types.SET_ACTIVE_NAVIGATOR_INDEX,
    childNavigatorIndex: index
  });
}

export function setChildHeader(header) {
  store.dispatch({
    type: types.SET_CHILD_HEADER,
    childHeaderTitle: header
  });
}

export async function setRootNavigator(rootNavigator) {
  const { hasBeenSetup, forceRtl } = store.getState().settings;
  if (forceRtl && !hasBeenSetup) {
    setForceRtl(false);
    this.props.navigation.navigate("SetupScreens");
    // rootNavigator.dispatch(
    //   NavigationActions.navigate({
    //     routeName: "SetupScreens",
    //     action: StackActions.reset({
    //       index: 1,
    //       key: null,
    //       actions: [
    //         NavigationActions.navigate({ routeName: "Language" }),
    //         NavigationActions.navigate({ routeName: "Welcome1" })
    //       ]
    //     })
    //   })
    // );
  }

  store.dispatch({
    type: types.SET_ROOT_NAVIGATOR,
    rootNavigator
  });
}

export function setRootModalStatus(status) {
  store.dispatch({
    type: types.SET_ROOT_MODAL_STATUS,
    status
  });
}
