import React, { Component } from "react";
import { Screen } from "../../utils";
import { Content, List, ListItem, Icon, Text, View, H2 } from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";
import wcColors from "../../../native-base-theme/variables/wcColors";

const headerString = {
  fundamental: "appStrings.RIGHTS_GUIDE_CATEGORY_FUNDAMENTAL",
  employee: "appStrings.RIGHTS_GUIDE_CATEGORY_EMPLOYEE",
  whistleblower: "appStrings.RIGHTS_GUIDE_CATEGORY_WHISTLEBLOWER",
  admin_professional: "appStrings.RIGHTS_GUIDE_CATEGORY_ADMIN_PROFESSIONAL"
};

export default Screen(
  connect(state => ({
    settings: state.settings,
    rights: state.rights,
    expoStandards: state.expoStandards
  }))(
    class KnowYourRights extends Component {
      state = {
        headerString:
          headerString[this.props.navigation.getParam("category")] ||
          this.props.navigation.getParam("header"),
        category: this.props.rights[this.props.navigation.getParam("category")],
        expoStandardsCategory: this.props.expoStandards[
          this.props.navigation.getParam("category")
        ]
      };

      generateList = () => {
        const lang = I18n.currentLocale();
        return this.state.category ? (
          <List>
            <ListItem
              rights
              itemHeader
              style={{
                backgroundColor:
                  wcColors.rights.categoryList.listItem.background
              }}
            >
              <Text>
                {I18n.t("appStrings.RIGHTS_GUIDE_ROMANIA_LAW_HEADER")}
              </Text>
            </ListItem>
            {this.state.category.map((value, key) => {
              const description = value.translations[lang]
                ? value.translations[lang]
                : value.description;

              return (
                <ListItem
                  rights
                  key={key}
                  onPress={() => {
                    this.props.navigation.navigate("RightDetails", {
                      right: value,
                      category: value.category,
                      issueType: "issueType"
                    });
                  }}
                >
                  <Text>{description}</Text>
                  <Icon
                    name={
                      I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : null;
      };

      generateExpoStandardsList = () => {
        const lang = I18n.currentLocale();
        return this.state.expoStandardsCategory &&
          this.props.settings.projectId &&
          this.props.settings.projectName &&
          ((this.props.settings.employerId &&
            this.props.settings.employerName) ||
            this.props.settings.employerName) ? (
          <List>
            <ListItem
              rights
              itemHeader
              style={{
                backgroundColor:
                  wcColors.rights.expoStandards.listItem.background
              }}
            >
              <Text>
                {I18n.t("appStrings.RIGHTS_GUIDE_EXPO_STANDARDS_HEADER")}
              </Text>
            </ListItem>
            {this.state.expoStandardsCategory.map((value, key) => {
              const description = value.translations[lang]
                ? value.translations[lang]
                : value.description;

              return (
                <ListItem
                  rights
                  key={key}
                  onPress={() => {
                    this.props.navigation.navigate("RightDetails", {
                      right: value
                    });
                  }}
                >
                  <Text>{description}</Text>
                  <Icon
                    name={
                      I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : null;
      };

      render() {
        return (
          <Content>
            <View padder>
              <H2 rightsHeader>{I18n.t(this.state.headerString)}</H2>
            </View>
            {this.generateList()}
            {this.generateExpoStandardsList()}
          </Content>
        );
      }
    }
  )
);
