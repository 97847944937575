import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
export * from "react-native-web";
import { NativeModules, Image } from "react-native-web";
export { default as ViewPropTypes } from "./ViewPropTypes";
export { default as ColorPropType } from "./ColorPropType";
import RNWModal from "modal-enhanced-react-native-web";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import PropTypes from "prop-types";

// patch RNI18n
const languages = [navigator.language];
NativeModules.RNI18n = {
  languages,
  getLanguages: () => Promise.resolve(languages),
  currentLocale: () => "en"
};
console.log("native modules", NativeModules.RNI18n, Image);
Image.propTypes = {
  resizeMode: PropTypes.string
};

// const { default: I18n } = require("../../app/i18n");

export function Modal(props) {
  return <RNWModal style={{ margin: 0 }} {...props} />;
}

// let openDatePicker = null;
// let closeDateTimePicker = null;
// let dismissDateTimePicker = null;
// export class DatePickerAndroid {
//   static dismissedAction = "DismissedAction";
//   static open({ date, minDate, maxDate }) {
//     return new Promise((resolve, reject) => {
//       closeDateTimePicker = date => {
//         date = moment(date);
//         resolve({
//           action: "SuccessAction",
//           year: date.year(),
//           month: date.month(),
//           day: date.date()
//         });
//         closeDateTimePicker = null;
//       };
//       dismissDateTimePicker = () => {
//         resolve({
//           action: DatePickerAndroid.dismissedAction
//         });
//         dismissDateTimePicker = null;
//       };
//       openDatePicker({ date, minDate, maxDate });
//     });
//   }
// }

// let openTimePicker = null;
// export class TimePickerAndroid {
//   static open({ hour, minute, is24Hour }) {
//     return new Promise((resolve, reject) => {
//       closeDateTimePicker = date => {
//         date = moment(date);
//         resolve({
//           action: "SuccessAction",
//           hour: date.hour(),
//           minute: date.minute()
//         });
//         closeDateTimePicker = null;
//       };
//       dismissDateTimePicker = () => {
//         resolve({
//           action: DatePickerAndroid.dismissedAction
//         });
//         dismissDateTimePicker = null;
//       };
//       openTimePicker({ hour, minute });
//     });
//   }
// }

// function ModalDateTimePicker() {
//   const [selectedDate, handleDateChange] = useState(new Date());
//   const [datePickerOpen, setDatePickerOpen] = useState(false);
//   const [timePickerOpen, setTimePickerOpen] = useState(false);
//   const [minDate, setMinDate] = useState(new Date("1900-01-01"));
//   const [maxDate, setMaxDate] = useState(new Date("2100-01-01"));
//   openDatePicker = ({ date, minDate, maxDate }) => {
//     setDatePickerOpen(true);
//     setMinDate(minDate || new Date("1900-01-01"));
//     setMaxDate(maxDate || new Date("2100-01-01"));
//     handleDateChange(date);
//   };
//   openTimePicker = ({ hour, minute }) => {
//     setTimePickerOpen(true);
//     handleDateChange(moment().set("hours", hour).set("minutes", minute));
//   };

//   function onAccept(date) {
//     setDatePickerOpen(false);
//     setTimePickerOpen(false);
//     if (typeof closeDateTimePicker == "function") closeDateTimePicker(date);
//   }

//   function onClose() {
//     setDatePickerOpen(false);
//     setTimePickerOpen(false);
//     if (typeof dismissDateTimePicker == "function") dismissDateTimePicker();
//   }

//   return (
//     <MuiPickersUtilsProvider utils={MomentUtils} locale={I18n.currentLocale()}>
//       <DatePicker
//         open={datePickerOpen}
//         minDate={minDate}
//         maxDate={maxDate}
//         value={selectedDate}
//         onChange={handleDateChange}
//         onAccept={onAccept}
//         onClose={onClose}
//         cancelLabel={I18n.t("global.cancel")}
//         okLabel={I18n.t("global.ok")}
//       />
//       <TimePicker
//         open={timePickerOpen}
//         value={selectedDate}
//         onChange={handleDateChange}
//         onAccept={onAccept}
//         onClose={onClose}
//         cancelLabel={I18n.t("global.cancel")}
//         okLabel={I18n.t("global.ok")}
//         ampm={I18n.currentLocale().indexOf("en") == 0}
//       />
//     </MuiPickersUtilsProvider>
//   );
// }

// ReactDOM.render(
//   <ModalDateTimePicker />,
//   document.getElementById("datetimepicker")
// );

let showAlert = null;
export class Alert {
  static alert(...args) {
    showAlert(...args);
  }
}

function ModalAlert() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [message, setMessage] = useState("");
  const [buttons, setButtons] = useState([{ text: "OK" }]);
  const [options, setOptions] = useState({});

  showAlert = (
    title = "Alert",
    message = "",
    buttons = [{ text: "OK" }],
    options = {}
  ) => {
    setOpen(true);
    setTitle(title);
    setMessage(message);
    setButtons(buttons);
    setOptions(options);
  };

  const handleClose = onPress => () => {
    setOpen(false);
    if (typeof onPress === "function") {
      onPress();
    } else if (typeof options.onDismiss == "function") {
      options.onDismiss();
    }
  };

  const defaultClose = handleClose();
  useEffect(() => {
    const handleBrowserHistory = e => {
      if (options.cancelable) {
        defaultClose();
      }
    };
    window.addEventListener("popstate", handleBrowserHistory);
    return () => {
      window.removeEventListener("popstate", handleBrowserHistory);
    };
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose()}
      {...(options.cancelable === false
        ? { disableBackdropClick: true, disableEscapeKeyDown: true }
        : {})}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map(button => (
          <Button
            key={button.text}
            onClick={handleClose(button.onPress)}
            color="primary"
          >
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

ReactDOM.render(<ModalAlert />, document.getElementById("alert"));
