import issueTypes from "../variables/issueTypes";
import types from "../actions/types";

const initialState = filterIssueTypes(issueTypes);

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ISSUE_TYPES:
      return {
        ...filterIssueTypes(action.issueTypes)
      };
    default:
      return state;
  }
};

export function filterIssueTypes(issueTypes) {
  return issueTypes.reduce((prev, next) => {
    if (prev[next.category]) {
      return {
        ...prev,
        [next.category]: [...prev[next.category], next]
      };
    } else {
      return {
        ...prev,
        [next.category]: [next]
      };
    }
  }, {});
}
