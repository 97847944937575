import ProjectDetails from "./ProjectDetails";
import MainSelector from "./MainSelector";
import CitationDetails from "./citationDetails";
import CommunityProfSummary from "./communityProfileSummary";
import CommunityProfDetails from "./communityProfileDetails";
import features from "../../features";

const MyStack = {
  ResourcesTab: {
    screen: MainSelector,
    path: "resources/",
    navigationOptions: {
      title: "appStrings.TITLE_INFORMATION",
      route: "ResourcesTab"
    }
  },
  ProjectDetails: {
    screen: ProjectDetails,
    path: "resources/projectdetails",
    navigationOptions: {
      title: "appStrings.CATEGORY_YOUR_PROJECT",
      route: "ResourcesTab",
      hasBack: true
    }
  },
  CitationDetails: {
    screen: CitationDetails,
    navigationOptions: {
      title: "appStrings.RIGHTS_GUIDE_TITLE",
      route: "ResourcesTab",
      hasBack: true
    }
  },
  CommunityProfSummary: {
    screen: CommunityProfSummary,
    path: "resources/communityprofile",
    navigationOptions: {
      title: "appStrings.CATEGORY_YOUR_COMMUNITY",
      route: "ResourcesTab",
      hasBack: true
    }
  },
  CommunityProfDetails: {
    screen: CommunityProfDetails,
    path: "resources/communityprofile/:type/:name",
    navigationOptions: {
      title: "appStrings.CATEGORY_YOUR_COMMUNITY",
      route: "ResourcesTab",
      hasBack: true
    }
  },
  ...features.pdfViewer.resources.routes({
    routingKey: "Whistleblowing",
    pathKey: "resources/whistleblowing",
    title: "appStrings.CATEGORY_WHISTLEBLOWING",
    route: "ResourcesTab"
  }),
  ...Object.keys(features)
    .filter(f => typeof features[f].resources == "object")
    .reduce(
      (p, c) => ({
        ...p,
        ...(typeof features[c].resources.routes == "object"
          ? features[c].resources.routes
          : {})
      }),
      {}
    )
};

export default MyStack;
