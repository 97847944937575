import React, { Component } from "react";
import {
  TouchableOpacity,
  BackHandler,
  StyleSheet,
  Linking
} from "react-native";
import { NavigationActions, StackActions } from "react-navigation";
import { Screen } from "../../utils";
import {
  Content,
  Header,
  Body,
  Text,
  Right,
  Title,
  View,
  ActionSheet
} from "native-base";
import I18n from "../../i18n";
import DeviceInfo from "react-native-device-info";
import { setRootModalStatus } from "../mainTabNavigator/actions";
import store from "../../store";

export default Screen(
  class AboutProject extends Component {
    componentDidMount() {
      setRootModalStatus(true);
      BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);
    }

    componentWillUnmount() {
      setRootModalStatus(false);
      BackHandler.removeEventListener(
        "hardwareBackPress",
        this.handleBackPress
      );
    }

    handleBackPress = () => {
      this.props.navigation.goBack();
    };

    handleOpenUrl = () => {};

    render() {
      return (
        <Content>
          <View about>
            <Text aboutAppName>{DeviceInfo.getApplicationName()}</Text>
            <Text
              selectable={false}
              version
              delayLongPress={10000}
              onLongPress={() =>
                ActionSheet.show(
                  {
                    options: ["Erase all data and reset app", "Cancel"],
                    cancelButtonIndex: 1,
                    destructiveButtonIndex: 0,
                    title: "Advanced options"
                  },
                  buttonIndex => {
                    if (buttonIndex == 0) {
                      store.dispatch({ type: "APP_RESET" });
                      this.props.navigation.navigate("SetupScreens");
                      // store
                      //   .getState()
                      //   .tabsReducer.rootNavigator.navigate("SetupScreens");
                      // store.getState().tabsReducer.rootNavigator.dispatch(
                      //   StackActions.reset({
                      //     index: 0,
                      //     key: null,
                      //     actions: [
                      //       NavigationActions.navigate({
                      //         routeName: "SetupScreens"
                      //       })
                      //     ]
                      //   })
                      // );
                    }
                  }
                )
              }
            >
              v{DeviceInfo.getReadableVersion()}
            </Text>
            <Text center about>
              {I18n.t("appStrings.ABOUT_CONTENT")}
            </Text>

            <Text
              center
              aboutPrivacy
              onPress={() =>
                Linking.openURL("https://www.workerapp.org/privacy")
              }
            >
              {I18n.t("appStrings.ABOUT_PRIVACY_LINK")}
            </Text>
            <Text aboutKYR>
              {I18n.t("appStrings.ABOUT_CONTENT_KYR_ATTRIBUTION")}
            </Text>
            <Text aboutKYR>
              {I18n.t("appStrings.ABOUT_CONTENT_KYR_MR_TITLE")}
            </Text>
            <Text aboutKYR>
              {I18n.t("appStrings.ABOUT_CONTENT_KYR_MR_COPYRIGHT")}
            </Text>
          </View>
        </Content>
      );
    }
  },
  props => {
    return (
      <Header about {...props}>
        <Body>
          <Title>{`${I18n.t("appStrings.LBL_ABOUT_TITLE")}`}</Title>
        </Body>
        <Right>
          <TouchableOpacity onPress={() => props.navigation.goBack()}>
            <Text>{I18n.t("appStrings.LBL_CLOSE")}</Text>
          </TouchableOpacity>
        </Right>
      </Header>
    );
  },
  null,
  {
    navigationOptions: {
      header: null,
      modal: true
    }
  }
);

const style = StyleSheet.create({
  containerStyle: {
    padding: 10
  }
});
