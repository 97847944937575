import WhatsNew from "./whatsnewsTab";
import PostDetails from "./postDetails";
import { withSetupGuard } from "../../utils";

const MyStack = {
  WhatsNewTab: {
    screen: withSetupGuard(WhatsNew),
    path: "whatsnew/",
    navigationOptions: {
      title: "appStrings.TITLE_WHATSNEW",
      route: "WhatsNewTab"
    }
  },
  PostDetails: {
    screen: PostDetails,
    path: "whatsnew/post/:postId",
    navigationOptions: {
      title: "appStrings.TITLE_WHATSNEW",
      route: "WhatsNewTab",
      hasBack: true
    }
  }
};

export default MyStack;
