import variable from "./../variables/platform";
import colors from "./../variables/wcColors";

export default (variables = variable) => {
  const platform = variables.platform;
  const viewTheme = {
    ".padder": {
      padding: variables.contentPadding
    },
    ".poll": {
      margin: 10
    },
    ".post": {
      marginTop: 5,
      marginBottom: 5
    },

    ".welcome": {
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    ".about": {
      marginTop: 15,
      padding: variables.contentPadding
    },
    ".moodResponse": {
      marginVertical: 10,
      marginHorizontal: 0,
      flex: platform === "web" ? "none" : 1,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Body": {
        width: "100%",
        flex: platform === "web" ? "none" : 0,
        paddingVertical: 10,
        backgroundColor: colors.moodResponse.background,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        "NativeBase.Text": {
          width: "100%",
          marginBottom: 10,
          textAlign: "center",
          color: colors.moodResponse.text,
          fontSize: 16,
          fontWeight: "normal"
        },
        "NativeBase.Icon": {
          paddingHorizontal: 15,
          color: colors.moodResponse.icon,
          fontSize: 30
        }
      }
    },
    ".center": {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    }
  };

  return viewTheme;
};
