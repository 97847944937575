import React, { Component } from "react";
import { connect } from "react-redux";
import { NavigationActions } from "react-navigation";

import { Screen } from "../../utils";
import { TouchableOpacity, Platform } from "react-native";
import {
  Card,
  CardItem,
  Body,
  Text,
  Content,
  H3,
  Header,
  Right,
  Title,
  CheckBox,
  Icon,
  Button
} from "native-base";
import HospitalSuggest from "./../../components/HospitalSuggest";

import {
  setPollAnswer,
  declinePoll,
  getPollById,
  filterPolls,
  getFeed
} from "./actions";
import { cachedGET } from "../../axios";
import { EMPLOYERS_TIMEOUT } from "../../const";
import showDialog from "../../components/Dialog";
import I18n from "../../i18n";

export default Screen(
  connect(state => ({
    isOffline: state.offline,
    polls: filterPolls(
      state.whatsnews.polls.availablePolls || [],
      state.whatsnews.polls.hiddenPolls || []
    ),
    settings: state.settings,
    isActiveProject: state.projectStatus
  }))(
    class PollModal extends Component {
      _working = false;

      constructor(props) {
        super(props);
        let poll = this.props.navigation.getParam("poll");
        const pollId = this.props.navigation.getParam("pollId");
        if (!poll && pollId) {
          getFeed();
          poll = this.props.polls.find(it => it.id == pollId);
        }
        if (poll) {
          this.state = this._stateFromPoll(poll);
        } else {
          this.state = {};
        }
      }

      async componentDidMount() {
        if (!this.props.isOffline) {
          const pollId = this.props.navigation.getParam("pollId");
          const newState = {};
          if (!this.state.poll && pollId) {
            const poll = await getPollById(pollId);
            const { questions } = this._stateFromPoll(poll);
            newState.poll = poll;
            newState.questions = questions;
          }
          if (!this.state.employers) {
            const result = await cachedGET(
              `/context/projects/${this.props.settings.projectId}`,
              EMPLOYERS_TIMEOUT
            );
            newState.employers =
              (result && result.project && result.project.employers) || [];
          }
          return this.setState(newState);
        }
      }

      _stateFromPoll(poll) {
        if (!poll) return null;
        return {
          poll,
          value: null,
          employerId: null,
          employerName: "",
          employers: this.props.navigation.state.params.employers,
          questions: poll.content.responses.reduce((prev, next) => {
            return [
              ...prev,
              {
                ...next,
                checked: false
              }
            ];
          }, [])
        };
      }

      handleChecking = async value => {
        if (this.props.isOffline) {
          showDialog(I18n.t("appStrings.MSG_DISCONNECTED"));
        } else {
          let newValue = value;
          const newQuestion = this.state.questions.reduce((prev, next) => {
            if (next.value === value) {
              if (next.checked) newValue = null;
              return [...prev, { ...next, checked: !next.checked }];
            } else {
              return [...prev, { ...next, checked: false }];
            }
          }, []);

          this.setState({ questions: newQuestion, value: newValue });
        }
      };

      submitPollResponse = async () => {
        if (this._working) return;
        this._working = true;
        await setPollAnswer(
          this.state.poll,
          this.state.value,
          this.state.employerId,
          this.state.employerName
        );
        showDialog(
          I18n.t("appStrings.POLL_RESPONSE_SUBMITTED_TITLE"),
          I18n.t("appStrings.POLL_RESPONSE_SUBMITTED_TEXT")
        );
        this.props.navigation.goBack();
        this._working = false;
      };

      handleEmployerSelect = (employerName, employerId) => {
        this.setState({
          employerId,
          employerName
        });
      };

      declineActualPoll = () => {
        declinePoll(this.state.poll);
        this.props.navigation.goBack();
      };

      generateAnswers = () => {
        return (
          <Card question>
            {this.state.questions.map((question, key) => (
              <TouchableOpacity
                key={key}
                testID="answer"
                onPress={() => {
                  this.handleChecking(question.value);
                }}
              >
                <CardItem bordered={Platform.OS === "web" ? undefined : true}>
                  <CheckBox
                    checked={question.checked}
                    onPress={() => {
                      this.handleChecking(question.value);
                    }}
                  />
                  <Text pollResponseText>{question.text}</Text>
                </CardItem>
              </TouchableOpacity>
            ))}
          </Card>
        );
      };

      render() {
        if (!this.state.poll) return null;
        return (
          <Content padder>
            <Text pollTitle uppercase={true}>
              {this.state.poll.content.title}
            </Text>
            {this.state.poll.content.description && (
              <Text pollDescription>{this.state.poll.content.description}</Text>
            )}
            <H3 pollQuestion>{this.state.poll.content.question}</H3>
            <div>{this.generateAnswers()}</div>

            <HospitalSuggest
              placeholder={I18n.t("appStrings.ENTER_HOSPITAL_NAME_OPTIONAL")}
              employers={this.state.employers}
              onSelect={this.handleEmployerSelect}
              value={this.state.employerName}
            />

            <Button
              testID="submitPollResponse"
              reportissue
              block
              onPress={this.submitPollResponse}
              disabled={!this.state.value || this._working}
              style={{ marginVertical: 20 }}
            >
              <Text numberOfLines={1} uppercase={false}>
                {I18n.t("appStrings.LBL_SUBMIT_POLL_RESPONSE")}
              </Text>
            </Button>

            <Button
              block
              transparent
              declinePoll
              onPress={this.declineActualPoll}
              testID="cancel"
            >
              <Icon name="md-close-circle" />
              <Text pollDecline>{I18n.t("appStrings.POLL_DECLINE")}</Text>
            </Button>
          </Content>
        );
      }
    }
  ),
  props => (
    <Header poll {...props}>
      <Body>
        <Title>{I18n.t("appStrings.POLL_TITLE")}</Title>
      </Body>
      <Right>
        <TouchableOpacity
          onPress={() => props.navigation.dispatch(NavigationActions.back())}
        >
          <Text>{I18n.t("appStrings.LBL_CANCEL")}</Text>
        </TouchableOpacity>
      </Right>
    </Header>
  ),
  null,
  {
    navigationOptions: {
      header: null,
      modal: true
    }
  }
);
