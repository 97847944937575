export default [
  // FUNKY CITIZENS ISSUE TYPES:
  {
    id: "5f7ff1d76ca465001673e4e6",
    category: "equipment_consumables",
    description: "I don't have enough protective gloves.",
    translations: {
      en: "I don't have enough protective gloves.",
      ro: "Nu am destule mănuși chirurgicale"
    }
  },
  {
    id: "5f7ff1d86ca465001673e4e7",
    category: "equipment_consumables",
    description: "I don't have enough protective masks.",
    translations: {
      en: "I don't have enough protective masks.",
      ro: "Nu am destule măști de protecție"
    }
  },
  {
    id: "5f7ff1d86ca465001673e4e9",
    category: "equipment_consumables",
    description: "I don't have (enough) disinfectant or it's diluted.",
    translations: {
      en: "I don't have (enough) disinfectant or it's diluted.",
      ro: "Nu am (destul) dezinfectant sau acesta e diluat"
    }
  },
  {
    id: "5f7ff1d86ca465001673e4ea",
    category: "equipment_consumables",
    description: "I have poorly made safety equipment.",
    translations: {
      en: "I have poorly made safety equipment.",
      ro: "Am materialele de protecție de calitate foarte proastă"
    }
  },
  {
    id: "5f7ff1d86ca465001673e4eb",
    category: "equipment_consumables",
    description: "My safety equipment doesn't protect me enough.",
    translations: {
      en: "My safety equipment doesn't protect me enough.",
      ro: "Echipamentul meu de protecție nu mă protejează destul"
    }
  },
  {
    id: "5f7ff1d86ca465001673e4ec",
    category: "equipment_consumables",
    description:
      "I know there's safety equipment available but it's under lock and key.",
    translations: {
      en:
        "I know there's safety equipment available but it's under lock and key.",
      ro:
        "Știu că există materialele de protecție există, dar sunt ținute sub cheie."
    }
  },
  {
    id: "5f7ff1d96ca465001673e4ed",
    category: "equipment_consumables",
    description:
      "I have to get my own consumables (like syringes) because they're unavailable.",
    translations: {
      en:
        "I have to get my own consumables (like syringes) because they're unavailable.",
      ro: "Trebuie să cumpăr eu consumabile (precum seringi), pentru că n-avem."
    }
  },
  {
    id: "5f7ff1d96ca465001673e4ee",
    category: "equipment_consumables",
    description:
      "I don't have safety equipment consumables (e.g. contrast substance)",
    translations: {
      en: "I don't have safety equipment consumables (e.g. contrast substance)",
      ro:
        "Nu am consumabile pentru echipamente medicale (e.g. substanță de contrast)"
    }
  },
  {
    id: "5f7ff1d96ca465001673e4ef",
    category: "equipment_consumables",
    description: "A different issue about equipment and consumables.",
    translations: {
      en: "A different issue about equipment and consumables.",
      ro: "O problemă diferită legată de echipament și materiale consumabile."
    },
    require_comment: true
  },
  {
    id: "5f7ff1d96ca465001673e4f0",
    category: "cleaning_products",
    description: "I don't have enough cleaning products.",
    translations: {
      en: "I don't have enough cleaning products.",
      ro: "Nu avem destule produse de curățenie."
    }
  },
  {
    id: "5f7ff1d96ca465001673e4f1",
    category: "cleaning_products",
    description:
      "Patients lack basic items like soap, toilets or clean bed sheets.",
    translations: {
      en: "Patients lack basic items like soap, toilets or clean bed sheets.",
      ro:
        "Pacienților le lipsesc lucruri de bază precum săpun, toalete sau așternuturi curate"
    }
  },
  {
    id: "5f7ff1d96ca465001673e4f2",
    category: "cleaning_products",
    description: "A different issue about cleaning products.",
    translations: {
      en: "A different issue about cleaning products.",
      ro: "O problemă diferită legată de produsele de curățenie."
    },
    require_comment: true
  },
  {
    id: "5f7ff1d96ca465001673e4f3",
    category: "hospital_work",
    description: "I work very long hours.",
    translations: {
      en: "I work very long hours.",
      ro: "Lucrez ture foarte lungi."
    }
  },
  {
    id: "5f7ff1da6ca465001673e4f4",
    category: "hospital_work",
    description: "I work unpaid long hours.",
    translations: {
      en: "I work unpaid long hours.",
      ro: "Lucrez ture lungi fără să fiu plătit pentru ele."
    }
  },
  {
    id: "5f7ff1da6ca465001673e4f5",
    category: "hospital_work",
    description:
      "Patients must buy medication and other items we should have available in the hospital.",
    translations: {
      en:
        "Patients must buy medication and other items we should have available in the hospital.",
      ro:
        "Pacienții trebuie să cumpere medicamente și alte lucruri pe care ar trebui să le avem în spital."
    }
  },
  {
    id: "5f7ff1da6ca465001673e4f6",
    category: "hospital_work",
    description: "Patients don't get hearty and adequate meals.",
    translations: {
      en: "Patients don't get hearty and adequate meals.",
      ro: "Pacienții nu primesc o mâncare adecvată, hrănitoare."
    }
  },
  {
    id: "5f7ff1da6ca465001673e4f7",
    category: "hospital_work",
    description: "Patients are crowded in quarters and wards.",
    translations: {
      en: "Patients are crowded in quarters and wards.",
      ro: "Pacienții sunt înghesuiți în saloane și secții."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4f8",
    category: "hospital_work",
    description: "Patients wait in long queues until being checked.",
    translations: {
      en: "Patients wait in long queues until being checked.",
      ro: "Pacienții stau la cozi mari până să fie văzuți."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4f9",
    category: "hospital_work",
    description: "The hospital director is politically appointed.",
    translations: {
      en: "The hospital director is politically appointed.",
      ro: "Directorul spitalului meu e numit politic."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4fa",
    category: "hospital_work",
    description:
      "The hospital director is using his position for political gains.",
    translations: {
      en: "The hospital director is using his position for political gains.",
      ro: "Directorul spitalului face jocuri politice din poziția lui."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4fb",
    category: "hospital_work",
    description: "I'm harassed or abused by a ward chief or director.",
    translations: {
      en: "I'm harassed or abused by a ward chief or director.",
      ro: "Sunt hărțuit sau abuzat de un șef de secție sau director."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4fc",
    category: "hospital_work",
    description: "My hospital lacks sufficient personnel due to emmigration.",
    translations: {
      en: "My hospital lacks sufficient personnel due to emmigration.",
      ro: "Spitalul nu are suficient personal pentru că mulți au emigrat."
    }
  },
  {
    id: "5f7ff1db6ca465001673e4fd",
    category: "hospital_work",
    description: "My hospital lacks sufficient doctors.",
    translations: {
      en: "My hospital lacks sufficient doctors.",
      ro: "Nu sunt suficienți doctori la spitalul meu"
    }
  },
  {
    id: "5f7ff1db6ca465001673e4fe",
    category: "hospital_work",
    description: "My hospital lacks sufficient nurses.",
    translations: {
      en: "My hospital lacks sufficient nurses.",
      ro: "Nu sunt destule asistente la spitalul meu"
    }
  },
  {
    id: "5f7ff1dc6ca465001673e4ff",
    category: "hospital_work",
    description: "My hospital lacks sufficient administrative personnel.",
    translations: {
      en: "My hospital lacks sufficient administrative personnel.",
      ro: "Nu e destul personal administrativ la spitalul meu"
    }
  },
  {
    id: "5f7ff1dc6ca465001673e500",
    category: "hospital_work",
    description: "My hospital lacks sufficient specialist doctors.",
    translations: {
      en: "My hospital lacks sufficient specialist doctors.",
      ro: "Nu sunt destui medici specialiști la spitalul meu"
    }
  },
  {
    id: "5f7ff1dc6ca465001673e501",
    category: "hospital_work",
    description: "A different issue about hospital work.",
    translations: {
      en: "A different issue about hospital work.",
      ro: "O problemă diferită legată de munca la spital."
    },
    require_comment: true
  },
  {
    id: "5f7ff1dc6ca465001673e502",
    category: "corruption",
    description: "Doctors, nurses or anesthetists are bribed to do their job.",
    translations: {
      en: "Doctors, nurses or anesthetists are bribed to do their job.",
      ro:
        "Medicii, asistentele sau anesteziștii iau șpăgi pentru a-și face treaba"
    }
  },
  {
    id: "5f7ff1dc6ca465001673e503",
    category: "corruption",
    description:
      "Management receives bribes for awarding public acquisition contracts.",
    translations: {
      en:
        "Management receives bribes for awarding public acquisition contracts.",
      ro: "Conducerea ia șpagă pentru a da contracte de achiziții publice"
    }
  },
  {
    id: "5f7ff1dc6ca465001673e504",
    category: "corruption",
    description: "A different issue about corruption",
    translations: {
      en: "A different issue about corruption",
      ro: "O problemă diferită legată de corupție."
    },
    require_comment: true
  },
  {
    id: "5f7ff1dc6ca465001673e505",
    category: "covid",
    description: "I'm aware of doctors who undermine the severity of COVID-19.",
    translations: {
      en: "I'm aware of doctors who undermine the severity of COVID-19.",
      ro: "Sunt conștient de doctori care minimizează severitatea COVID-19"
    }
  },
  {
    id: "5f7ff1dd6ca465001673e506",
    category: "covid",
    description: "I'm not regularly tested for COVID-19.",
    translations: {
      en: "I'm not regularly tested for COVID-19.",
      ro: "Nu sunt testat în mod regulat pentru COVID-19"
    }
  },
  {
    id: "5f7ff1dd6ca465001673e507",
    category: "covid",
    description: "I've not been tested for COVID-19.",
    translations: {
      en: "I've not been tested for COVID-19.",
      ro: "Nu am fost testat pentru COVID-19"
    }
  },
  {
    id: "5f7ff1dd6ca465001673e508",
    category: "covid",
    description:
      "My superior forbade me from testing for COVID-19 because of the risk of me going into quarantine.",
    translations: {
      en:
        "My superior forbade me from testing for COVID-19 because of the risk of me going into quarantine.",
      ro:
        "Directorul/șeful nu m-a lăsat să mă testez de COVID-19 pentru că nu a vrut să riște ca eu să intru în carantină."
    }
  },
  {
    id: "5f7ff1dd6ca465001673e509",
    category: "covid",
    description: "I had to work despite testing positive for COVID-19.",
    translations: {
      en: "I had to work despite testing positive for COVID-19.",
      ro: "A trebuit să lucrez chiar dacă am ieșit pozitiv la testul COVID-19"
    }
  },
  {
    id: "5f7ff1dd6ca465001673e50a",
    category: "covid",
    description: "I tested positive for COVID-19.",
    translations: {
      en: "I tested positive for COVID-19.",
      ro: "Am ieșit pozitiv la testul COVID-19"
    }
  },
  {
    id: "5f7ff1de6ca465001673e50b",
    category: "covid",
    description: "Patients are not usually tested for COVID-19.",
    translations: {
      en: "Patients are not usually tested for COVID-19.",
      ro: "Pacienții nu sunt testați de regulă pentru COVID-19"
    }
  },
  {
    id: "5f7ff1de6ca465001673e50c",
    category: "covid",
    description: "We lack sufficient COVID-19 tests.",
    translations: {
      en: "We lack sufficient COVID-19 tests.",
      ro: "Nu avem destule teste COVID-19"
    }
  },
  {
    id: "5f7ff1de6ca465001673e50d",
    category: "covid",
    description: "Our COVID-19 tests are inadequate.",
    translations: {
      en: "Our COVID-19 tests are inadequate.",
      ro: "Testele noastre de COVID-19 nu sunt bune"
    }
  },
  {
    id: "5f7ff1de6ca465001673e50e",
    category: "covid",
    description: "A different issue about COVID-19.",
    translations: {
      en: "A different issue about COVID-19.",
      ro: "O problemă diferită legată de COVID-19."
    },
    require_comment: true
  }
];
