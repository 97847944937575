import React, { Component } from "react";
import { View, ActivityIndicator } from "react-native";
import { connect } from "react-redux";

import wcColors from "../../native-base-theme/variables/wcColors";
import styles from "../styles";

export const ProgressComponent = props => {
  return (
    <View
      style={
        props.modalProgress
          ? [
              styles.backgroundImage,
              { backgroundColor: wcColors.progressBackground }
            ]
          : { height: 50 }
      }
    >
      <ActivityIndicator
        animating={true}
        size="large"
        style={styles.backgroundImage}
      />
    </View>
  );
};

export default connect(state => ({
  progress: state.progress.active,
  modalProgress: state.progress.modal
}))(
  class Progress extends Component {
    render() {
      if (this.props.progress) {
        return <ProgressComponent {...this.props} />;
      }

      return null;
    }
  }
);
