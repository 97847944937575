import { createStackNavigator } from "react-navigation-stack";
import ChooseLanguage from "./chooselanguage";
import Welcome1 from "./welcome1";
import Welcome2 from "./welcome2";
import Welcome3 from "./welcome3";
import Welcome4 from "./welcome4";
import JobFunction from "./jobFunction";
// import SelectProject from "./selectProject";
import SelectEmployer from "./selectEmployer";
import { transitionConfig } from "../../utils";

export default createStackNavigator(
  {
    Language: {
      screen: ChooseLanguage,
      path: "language"
    },
    Welcome1: {
      screen: Welcome1,
      path: "welcome1"
    },
    Welcome2: {
      screen: Welcome2,
      path: "welcome2"
    },
    Welcome3: {
      screen: Welcome3,
      path: "welcome3"
    },
    Welcome4: {
      screen: Welcome4,
      path: "welcome4"
    },
    JobFunction: {
      screen: JobFunction,
      path: "jobFunction"
    },
    /* SelectProject: {
      screen: SelectProject,
      path: "project"
    }, */
    SelectEmployer: {
      screen: SelectEmployer,
      path: "employer"
    }
  },
  {
    transitionConfig
  }
);
