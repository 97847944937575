import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import VideoPlayer from "react-native-video-controls";
import Orientation from "react-native-orientation-locker";
import { AndroidBackHandler } from "react-navigation-backhandler";

import wcColors from "../../../native-base-theme/variables/wcColors";

export default class VideoModal extends Component {
  static navigationOptions = {
    header: null,
    modal: true
  };

  onBackButtonPressAndroid = () => {
    Orientation.lockToPortrait();
  };

  render() {
    return (
      <AndroidBackHandler onBackPress={this.onBackButtonPressAndroid}>
        <View style={style.container}>
          <VideoPlayer
            source={{ uri: this.props.navigation.getParam("url") }}
            onBack={() => {
              Orientation.lockToPortrait();
              this.props.navigation.goBack();
            }}
            onEnterFullscreen={() => Orientation.lockToLandscape()}
            onExitFullscreen={() => Orientation.lockToPortrait()}
            style={style.backgroundVideo}
          />
        </View>
      </AndroidBackHandler>
    );
  }
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: wcColors.videoModalBackground
  },
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
});
