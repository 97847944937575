import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import {
  Button,
  Content,
  Text,
  H1,
  H3,
  Header,
  Icon,
  View,
  ListItem
} from "native-base";
import {
  TouchableWithoutFeedback,
  FlatList,
  StyleSheet,
  Dimensions
} from "react-native";
import SearchInput, { createFilter } from "react-native-search-filter";
import LinearGradient from "react-native-linear-gradient";
import FormInput from "../../components/FormInput";
import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";

import I18n from "../../i18n";
import { setEmployer, setSetupStatus } from "../../actions/settings";

const SelectEmployer = connect(state => ({
  projectId: state.settings.projectId,
  projectName: state.settings.projectName
}))(
  class SelectEmployer extends Component {
    state = {
      employers: [],
      searchTerm: "",
      searchInputBlurred: true,
      employerListThreshold: 10,
      displaySearchHelp: false,
      customEmployerSearchInputBlurred: true
    };

    async componentDidMount() {
      if (!this.state.employers) {
        setChildHeader("appStrings.LBL_YOUR_EMPLOYER");
        const result = await GET(`/context/projects/${this.props.projectId}`);
        this.setState({
          employers:
            (result && result.project && result.project.employers) || []
        });
      }
    }

    setEmployerAndNavigate = async employer => {
      //set employer
      setEmployer(employer);
      //Set setup status
      await setSetupStatus(true);
      this.props.navigation.navigate("App");
      // const resetAction = StackActions.reset({
      //   index: 0,
      //   key: null,
      //   actions: [NavigationActions.navigate({ routeName: "App" })]
      // });
      // this.props.navigation.dispatch(resetAction);
    };

    toggleSearchHelp = () => {
      this.setState({
        displaySearchHelp: !this.state.displaySearchHelp
      });
    };

    renderEmptyResults = () => {
      return (
        <View
          style={{
            height: 50,
            flex: 1,
            justifyContent: "center",
            backgroundColor: wcColors.setEmployer.emptyResult.background
          }}
        >
          <Text style={{ alignSelf: "center" }}>
            {I18n.t("appStrings.EMPLOYERSEARCH_EMPTY")}
          </Text>
        </View>
      );
    };

    renderFullList = filteredEmployers => {
      const shortList =
        filteredEmployers || this.props.navigation.getParam("employers", "");
      const orderedList = shortList.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      //Note: FlatList's renderItem prop expects an object called item
      return (
        <FlatList
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
          contentContainerStyle={{
            borderWidth: StyleSheet.hairlineWidth,
            borderBottomWidth: 0,
            borderColor: wcColors.setEmployer.list.border,
            backgroundColor: wcColors.setEmployer.list.background,
            marginTop: 20,
            marginBottom: 20
          }}
          data={orderedList}
          keyExtractor={item => item.company_id}
          ListEmptyComponent={this.renderEmptyResults}
          renderItem={({ item }) => (
            <ListItem
              key={item.company_id}
              onPress={() => {
                this.setEmployerAndNavigate(item);
              }}
            >
              <Text employerList key={item.company_id}>
                {item.name}
              </Text>
            </ListItem>
          )}
        />
      );
    };

    renderSearchHelp = () => {
      return (
        <Content setup>
          <View padder>
            <H1 employerSearchHelpSetup>
              {I18n.t("appStrings.LBL_EMPLOYERSEARCH")}
            </H1>
          </View>
          <View
            style={{ flex: 1, flexDirection: "column", alignItems: "center" }}
          >
            <View
              style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
            >
              <Icon
                name="lightbulb-on"
                type="MaterialCommunityIcons"
                style={{
                  color: wcColors.setEmployer.icon.text,
                  paddingHorizontal: 10
                }}
              />
              <Text
                settingsLabel
                left
                style={{
                  width: 290,
                  paddingLeft: 10,
                  color: wcColors.setEmployer.text
                }}
              >
                {I18n.t("appStrings.PROMPT_YOUR_EMPLOYER_DESCRIPTION")}
              </Text>
            </View>
          </View>
          <View padder>
            <FormInput
              onFocus={this.handleCustomEmployerFocused}
              onBlur={this.handleCustomEmployerBlurred}
              settingsFormInput
              onSubmit={value => {
                if (value && value.trim())
                  this.setEmployerAndNavigate({ name: value.trim() });
              }}
              placeholder={I18n.t("appStrings.LBL_EMPLOYER_NAME")}
              buttonText={I18n.t("appStrings.LBL_OK")}
            />
          </View>
          <Button
            block
            transparent
            cancelEmployer
            onPress={() => {
              this.toggleSearchHelp();
              this.clearSearchTerm();
            }}
          >
            <Text uppercase={false}>{I18n.t("appStrings.LBL_CANCEL")}</Text>
          </Button>
        </Content>
      );
    };

    renderHelpButton = () => {
      return (
        <Button
          block
          transparent
          employerHelp
          onPress={() => {
            this.clearSearchTerm();
            this.toggleSearchHelp();
          }}
        >
          <Text uppercase={false}>
            {I18n.t("appStrings.EMPLOYERSEARCH_BUTTON")}
          </Text>
        </Button>
      );
    };

    updateSearchTerm = searchTerm => {
      this.setState({ searchTerm });
    };

    clearSearchTerm = () => {
      this.setState({ searchTerm: "" });
    };

    handleSearchInputFocused = () => {
      this.setState({ searchInputBlurred: false });
    };

    handleSearchInputBlurred = () => {
      this.setState({ searchInputBlurred: true });
    };

    handleCustomEmployerFocused = () => {
      this.setState({ customEmployerSearchInputBlurred: false });
    };

    handleCustomEmployerBlurred = () => {
      this.setState({ customEmployerSearchInputBlurred: true });
    };

    render() {
      const {
        searchTerm,
        searchInputBlurred,
        employerListThreshold,
        displaySearchHelp
      } = this.state;
      const employerList = this.props.navigation.getParam("employers", []);
      const filteredEmployers = employerList.filter(
        createFilter(searchTerm, ["name"])
      );

      const windowHeight = Dimensions.get("window").height;

      return (
        <Fragment>
          <LinearGradient
            colors={wcColors.setEmployer.backgroundGradient}
            locations={wcColors.setEmployer.gradientLocations}
            start={wcColors.setEmployer.gradientStart}
            end={wcColors.setEmployer.gradientEnd}
            style={styles.gradient}
          />
          {displaySearchHelp && this.renderSearchHelp()}
          {!displaySearchHelp && (
            <Fragment>
              <TouchableWithoutFeedback
                onPress={() => this.props.navigation.goBack()}
              >
                <Header hasTabs nationality>
                  <Icon name={I18n.isRTL ? "arrow-forward" : "arrow-back"} />
                  <Text>{I18n.t("appStrings.NAV_BACK")}</Text>
                </Header>
              </TouchableWithoutFeedback>
              <Content setup>
                <View padder>
                  {/* {searchInputBlurred && (
                    <H3 employerSearchProject>{this.props.projectName}</H3>
                  )} */}
                  <H1 nationality>
                    {I18n.t("appStrings.PROMPT_YOUR_EMPLOYER")}
                  </H1>
                  {searchInputBlurred && (
                    <Fragment>
                      <Text nationality>
                        {I18n.t("appStrings.PROMPT_YOUR_EMPLOYER_DESCRIPTION")}
                      </Text>
                    </Fragment>
                  )}
                  {employerList.length > employerListThreshold && (
                    <Fragment>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          marginBottom: 10
                        }}
                      >
                        <View
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.icon.background,
                            justifyContent: "center",
                            paddingLeft: 20,
                            paddingRight: 0,
                            borderBottomLeftRadius: 150,
                            borderTopLeftRadius: 150
                          }}
                        >
                          <Icon
                            name="search"
                            fontSize={40}
                            style={{ color: wcColors.setEmployer.icon.text }}
                          />
                        </View>
                        <SearchInput
                          clearIcon={true}
                          onChangeText={term => {
                            this.updateSearchTerm(term);
                          }}
                          placeholder={I18n.t(
                            "appStrings.EMPLOYERSEARCH_PLACEHOLDER"
                          )}
                          throttle={1}
                          fuzzy={true}
                          sortResults={true}
                          onFocus={this.handleSearchInputFocused}
                          onBlur={this.handleSearchInputBlurred}
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.searchInput.background,
                            height: 50,
                            width: 250,
                            fontSize: 16,
                            alignSelf: "center"
                          }}
                          placeholderTextColor={
                            wcColors.setEmployer.searchInput.placeholder
                          }
                        />
                        <View
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.searchInput.iconBackground,
                            justifyContent: "center",
                            paddingLeft: 0,
                            paddingRight: 20,
                            borderBottomRightRadius: 150,
                            borderTopRightRadius: 150
                          }}
                        >
                          <Icon
                            name="search"
                            fontSize={40}
                            style={{
                              color: wcColors.setEmployer.searchInput.icon,
                              opacity: 0.0
                            }}
                          />
                        </View>
                      </View>
                    </Fragment>
                  )}
                  {this.renderFullList(filteredEmployers)}
                  {this.renderHelpButton()}
                  {!searchInputBlurred && searchTerm.length !== 0 && (
                    <View style={{ height: windowHeight * 0.45 }} />
                  )}
                </View>
              </Content>
            </Fragment>
          )}
        </Fragment>
      );
    }
  }
);

SelectEmployer.navigationOptions = {
  header: null
};

export default SelectEmployer;
