import { Platform } from "react-native";
import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const contentTheme = {
    ".padder": {
      padding: variables.contentPadding
    },
    ".setup": {
      ".padder": {
        margin: 10,
        padding: 0.01
      },
      "NativeBase.H2": {
        ".employer": {
          color: colors.content.setup.header
        }
      },
      "NativeBase.Text": {
        ".welcome": {
          textAlign: "left",
          color: colors.content.setup.text.welcome
        },
        ".connectProject": {
          textAlign: "left",
          color: colors.content.setup.text.connectProject
        },
        ".skipProject": {
          textAlign: "center",
          width: "100%",
          color: colors.content.setup.text.skipProject,
          fontWeight: "bold"
        }
      },
      "NativeBase.Button": {
        ".connectProject": {
          "NativeBase.Text": {
            color: colors.content.setup.button.connectProject.text
          },
          "NativeBase.Icon": {
            color: colors.content.setup.button.connectProject.icon
          }
        },
        ".skipProject": {
          "NativeBase.Text": {
            color: colors.content.setup.button.skipProject.text
          },
          "NativeBase.Icon": {
            color: colors.content.setup.button.skipProject.icon
          }
        }
      },
      "NativeBase.Form": {
        ".formInput": {
          "NativeBase.Button": {
            backgroundColor: colors.content.setup.form.inputButton.background,
            shadowColor: null,
            shadowOffset: null,
            shadowOpacity: null,
            shadowRadius: null,
            elevation: null,
            "NativeBase.Text": {
              color: colors.content.setup.form.inputButton.text,
              fontWeight: "normal"
            }
          }
        }
      }
    },

    ".setProject": {
      "NativeBase.Form": {
        ".formInput": {
          "NativeBase.Button": {
            backgroundColor:
              colors.content.setProject.formInputButton.background,
            shadowColor: null,
            shadowOffset: null,
            shadowOpacity: null,
            shadowRadius: null,
            elevation: null,
            "NativeBase.Text": {
              color: colors.content.setProject.formInputButton.text,
              fontWeight: "normal"
            }
          }
        }
      }
    },

    flex: 1,
    backgroundColor: colors.content.background,
    "NativeBase.Segment": {
      borderWidth: 0,
      backgroundColor: colors.content.segmentBackground
    },
    width: Platform.OS == "web" ? "100%" : undefined,
    maxWidth: Platform.OS == "web" ? 1200 : undefined,
    alignSelf: Platform.OS == "web" ? "center" : undefined
  };

  return contentTheme;
};
