import promises from "../../variables/promises";
import types from "./actionTypes";

const initialState = filterPromises(promises);

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROMISES:
      return {
        ...filterPromises(action.promises)
      };
    default:
      return state;
  }
};

export function filterPromises(promisesList) {
  return promisesList.reduce((prev, next) => {
    if (prev[next.categoryType]) {
      return {
        ...prev,
        [next.categoryType]: [...prev[next.categoryType], next]
      };
    } else {
      return {
        ...prev,
        [next.categoryType]: [next]
      };
    }
  }, {});
}
