import React from "react";
import DialogManager, { DialogContent } from "react-native-dialog-component";
import { View, Image } from "react-native";
import { Text, Button } from "native-base";
import { dialogConfig } from "../const";
import I18n from "../i18n";
import wcColors from "../../native-base-theme/variables/wcColors";

export function dismissDialog() {
  DialogManager.dismissAll();
}

export default async function showDialog(
  title,
  description,
  image,
  buttonText,
  onPress,
  cancelText,
  onCancel,
  onDismiss
) {
  return new Promise((resolve, reject) => {
    try {
      DialogManager.show({
        title,
        ...dialogConfig,
        onDismissed: onDismiss || undefined,
        children: (
          <DialogContent>
            {image && (
              <Image
                resizeMode="contain"
                source={image}
                style={{ width: "100%", height: 150 }}
              />
            )}
            {description &&
              (typeof description == "string" ? (
                <Text style={{ marginVertical: 15 }}>{description}</Text>
              ) : (
                description
              ))}
            <View
              style={{
                flexDirection: "row",
                alignItems: "stretch",
                justifyContent: "center"
              }}
            >
              <Button
                block
                small
                transparent
                style={{
                  backgroundColor: wcColors.dialog.button.background,
                  marginHorizontal: 10
                }}
                onPress={
                  onPress ||
                  (() => DialogManager.dismissAll(_ => resolve(true)))
                }
              >
                <Text
                  uppercase={false}
                  style={{
                    color: wcColors.dialog.button.text,
                    textAlign: "center"
                  }}
                >
                  {buttonText || I18n.t("appStrings.LBL_OK")}
                </Text>
              </Button>
              {cancelText && (
                <Button
                  block
                  small
                  transparent
                  style={{
                    backgroundColor: wcColors.dialog.cancelButton.background
                  }}
                  onPress={
                    onCancel ||
                    (() => DialogManager.dismissAll(_ => resolve(false)))
                  }
                >
                  <Text
                    uppercase={false}
                    style={{ color: wcColors.dialog.cancelButton.text }}
                  >
                    {typeof cancelText == "string"
                      ? cancelText
                      : I18n.t("appStrings.LBL_CANCEL")}
                  </Text>
                </Button>
              )}
            </View>
          </DialogContent>
        )
      });
    } catch (err) {
      reject(err);
    }
  });
}
