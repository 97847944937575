import { Linking } from "react-native";
import { Content, View } from "native-base";
import React, { Component } from "react";
import Pdf from "react-native-pdf";
import { Screen } from "../../utils";

export default Screen(
  class PDFViewer extends Component {
    render() {
      const source = this.props.navigation.getParam("source");
      return (
        <View
          style={{
            flex: 1,
            backgroundColor: "white"
          }}
        >
          <Pdf
            source={typeof source == "function" ? source() : source}
            spacing={0}
            fitWidth={true}
            fitPolicy={0}
            onPressLink={url => Linking.canOpenURL(url) && Linking.openURL(url)}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "#fff"
            }}
            navigation={this.props.navigation}
          />
        </View>
      );
    }
  }
);
