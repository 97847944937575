import store from "../store";
import types from "./settingsTypes";
import { GET, inlineGET, hiddenPOST } from "../axios";
import { setLocale } from "../i18n";
import { getFeed } from "../screens/whatsnewsTab/actions";
import callBindNotificationRegistration from "./bindNotificationRegistration";
import { setProjectStatus } from "./projectStatus";
import { getLocalToken } from "./localToken";

export async function setLanguage(lang) {
  store.dispatch({ type: types.SET_LANGUAGE, lang });
  await setLocale(lang);
  await bindNotificationRegistration();
}

export async function setJobFunction(job) {
  store.dispatch({ type: types.SET_JOB_FUNCTION, job });
  await bindNotificationRegistration();
}

export async function setNationality(nat) {
  store.dispatch({ type: types.SET_NATIONALITY, nat });
  await bindNotificationRegistration();
}

export async function activation(activationCode, isBackground) {
  setProjectStatus(true);
  const result = await (isBackground ? inlineGET : GET)("/context/projects", {
    params: { activation_code: activationCode.trim() }
  });

  if (!result) {
    return;
  }

  const { project, employer } = result;
  if (project) {
    const { country } = project;
    if (country) {
      store.dispatch({ type: types.SET_COUNTRY, country });
    }
    store.dispatch({ type: types.SET_PROJECT, proj: project });
  }
  if (employer) {
    store.dispatch({ type: types.SET_EMPLOYER, empl: employer });
  }
  const { language, nationality } = store.getState().settings;
  if (project && employer && language && nationality) {
    getFeed();
    await bindNotificationRegistration();
  }

  return result;
}

export async function setProject(projectCode) {
  const params = {
    project_code: projectCode.trim()
  };
  const result = await GET("/context/projects", { params });

  if (!result) {
    return;
  }

  const proj = result.project;
  store.dispatch({ type: types.SET_PROJECT, proj });
  // await getProjectProfile(proj.id);
  setProjectStatus(true);

  await bindNotificationRegistration();

  return proj;
}

export async function disconnectFromProject() {
  store.dispatch({ type: types.SET_PROJECT, proj: null });
  setEmployer(null);
  setProjectStatus(true);
  await getFeed();
}

export async function setEmployer(empl) {
  store.dispatch({ type: types.SET_EMPLOYER, empl });
  const settings = store.getState().settings;
  setProjectStatus(true);
  if (settings.hasBeenSetup) {
    await getFeed();
  }

  await bindNotificationRegistration();
}

export async function setNotificationToken(token) {
  store.dispatch({ type: types.SET_NOTIFICATION_TOKEN, token });
  await bindNotificationRegistration(token);
}

export async function bindNotificationRegistration(token) {
  await callBindNotificationRegistration(token);
}

export async function setSetupStatus(status) {
  store.dispatch({ type: types.SET_SETUP_STATUS, status });
  if (status) {
    await getFeed();
  }
}

export function setReportIssueView(viewed) {
  store.dispatch({ type: types.SET_REPORT_ISSUE_VIEW, viewed });
}

export function welcomeMessageVisible() {
  store.dispatch({ type: types.SEE_WELCOME_MESSAGE });
}

export async function sendMoodResponse(mood) {
  const { projectId, employerId, employerName } = store.getState().settings;
  const result = await hiddenPOST("/mood_responses", {
    mood,
    context: {
      project_id: projectId || undefined,
      employer_id: employerId || undefined,
      employer_name: employerName || undefined
    },
    local_token: getLocalToken()
  });
  store.dispatch({ type: types.MOOD_RESPONSE });
}

export function setForceRtl(forceRtl) {
  store.dispatch({ type: types.SET_FORCE_RTL, forceRtl });
}
