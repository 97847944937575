import { Platform } from "react-native";
import ProjectProfile from "./projectProfile";
import ProfileSummary from "./profileSummary";
import ExpoStandards from "../../../features/expoStandards";
// import Promises from "../../../features/promises";
import PDFViewer from "../../../features/pdfViewer";
// import HotlineReferral from "../../../features/hotlineReferral";

import WhistleblowingImage from "../../../assets/img/resources/Resources_whistleblowing.png";
import I18n from "../../../i18n";

const whistleblowerSource = {
  web: {
    en: require("../../../assets/whistleblower.screens.en.pdf"),
    ro: require("../../../assets/whistleblower.screens.ro.pdf")
  },
  ios: {
    en: require("../../../assets/whistleblower.screens.en.pdf"),
    ro: require("../../../assets/whistleblower.screens.ro.pdf")
  },
  android: {
    en: { uri: "bundle-assets://pdf/whistleblower.screens.en.pdf" },
    ro: { uri: "bundle-assets://pdf/whistleblower.screens.ro.pdf" }
  }
};

export default [
  ProjectProfile,
  ExpoStandards.resources.mainSelector,
  // HotlineReferral.resources.mainSelector,
  ProfileSummary,
  //Promises.resources.mainSelector
  PDFViewer.resources.mainSelector({
    routingKey: "Whistleblowing",
    title: () => I18n.t("appStrings.CATEGORY_WHISTLEBLOWING"),
    description: () => I18n.t("appStrings.CATEGORY_DESC_WHISTLEBLOWING"),
    thumbnail: WhistleblowingImage,
    source: () =>
      whistleblowerSource[Platform.OS][I18n.currentLocale()] ||
      whistleblowerSource[Platform.OS].en
  })
];
