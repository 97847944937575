import React, { Component } from "react";
import { Screen } from "../../utils";
import { StyleSheet } from "react-native";
import { Text, Content, Card, CardItem, Icon, Right } from "native-base";
import languages from "../../locale/languages";
import I18n from "../../i18n";
import { setLanguage } from "../../actions/settings";
import { getFeed } from "../whatsnewsTab/actions";

export default Screen(
  class SetLanguage extends Component {
    renderCards = currentLang => {
      return languages.map((lang, key) => {
        const isCurrent = currentLang === lang.languageCode;
        return (
          <CardItem
            key={key}
            button
            disabled={isCurrent}
            bordered
            onPress={() => this.changeLanguage(lang.languageCode)}
          >
            <Text>{lang.languageName}</Text>
            {isCurrent ? <Icon type="Ionicons" name="md-checkmark" /> : null}
          </CardItem>
        );
      });
    };

    changeLanguage = async langCode => {
      setLanguage(langCode);
      this.props.navigation.goBack();
      await getFeed();
    };

    render() {
      const currentLang = I18n.currentLocale();
      return (
        <Content padder>
          <Text divider>{I18n.t("appStrings.PROMPT_CHOOSE_LANGUAGE")}</Text>
          <Card settings radio>
            {this.renderCards(currentLang)}
          </Card>
        </Content>
      );
    }
  }
);
