import { StyleSheet } from "react-native";
import variable from "./../variables/platform";
import colors from "./../variables/wcColors";

export default (variables = variable) => {
  return {
    backgroundColor: colors.listBackground,
    paddingBottom: StyleSheet.hairlineWidth
  };
};
