import { Alert } from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { showToast } from "./utils";
import store from "./store";
import I18n from "./i18n";
import types from "./actions/types";

let fetching = false;
let fetchResolves = [];
export function isOffline() {
  const promise = new Promise(resolve => {
    fetchResolves.push(resolve);
  });
  if (fetching) return promise;
  fetching = true;
  NetInfo.fetch().then(({ isConnected }) => {
    fetching = false;
    fetchResolves.forEach(resolve => resolve(!isConnected));
    fetchResolves = [];
  });
  return promise;
}

export function attempInit({ isConnected }) {
  store.dispatch({ type: types.OFFLINE, offline: !isConnected });
}

export async function offlineGuard(message, fn) {
  if (!fn && message) {
    fn = message;
    message = null;
  }
  if (await isOffline()) {
    if (typeof message == "function") {
      message = await message();
    }
    if (typeof message == "string") {
      Alert.alert(I18n.t("offline.title"), message);
      return null;
    }
    if (!message) showToast(I18n.t("offline.title"), "danger");
    return message;
  } else return await fn();
}
