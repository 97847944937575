import Dashboard from "./dashboard";
import SetLanguage from "./setLanguage";
import SetEmployer from "./setEmployer";
import SetJobFunction from "./setJobFunction";

const MyStack = {
  SettingsTab: {
    screen: Dashboard,
    path: "settings/",
    navigationOptions: {
      title: "appStrings.TITLE_SETTINGS",
      route: "SettingsTab"
    }
  },
  SetLanguage: {
    screen: SetLanguage,
    navigationOptions: {
      title: "appStrings.LBL_LANGUAGE",
      route: "SettingsTab",
      hasBack: true
    }
  },
  SetJobFunction: {
    screen: SetJobFunction,
    navigationOptions: {
      title: "appStrings.LBL_JOB_FUNCTION",
      route: "SettingsTab",
      hasBack: true
    }
  },
  SetEmployer: {
    screen: SetEmployer,
    path: "settings/employers",
    navigationOptions: {
      title: "appStrings.LBL_YOUR_EMPLOYER",
      route: "SettingsTab",
      hasBack: true
    }
  }
};

export default MyStack;
