import { combineReducers } from "redux";
import error from "./error";
import progress from "./progress";
import offline from "./offline";
import misc from "./misc";
import tabsReducer from "../screens/mainTabNavigator/reducers";
import settings from "./settings";
import whatsnews from "../screens/whatsnewsTab/reducers";
import issueTypes from "./issueTypes";
import communityProfiles from "./communityProfiles";
import projectProfile from "../screens/resourcesTab/reducers";
import rights from "./rights";
import projectStatus from "./projectStatus";
import features from "../features";

export default combineReducers({
  error,
  progress,
  offline,
  misc,
  tabsReducer,
  settings,
  whatsnews,
  issueTypes,
  communityProfiles,
  projectProfile,
  rights,
  projectStatus,
  ...Object.keys(features)
    .filter(f => typeof features[f].reducer !== "undefined")
    .reduce((p, c) => ({ ...p, [c]: features[c].reducer }), {})
});
