import { createStore, applyMiddleware, compose } from "redux";
import { Platform, I18nManager } from "react-native";
import RNRestart from "react-native-restart";
import { persistStore, persistReducer, getStoredState } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "@react-native-community/async-storage";
import storageSession from "redux-persist/lib/storage/session";
import reducers from "./reducers";
import types from "./actions/settingsTypes";
import devToolsEnhancer from "remote-redux-devtools";
import { setLocale } from "./i18n";
import { setForceRtl } from "./actions/settings";

let lang;

const customMiddleWare = commingStore => next => async action => {
  next(action);
  if (action.type === types.SET_LANGUAGE) {
    await persistor.flush();
    const major = action.lang;
    const rtl = major == "ar" || major == "ur";
    I18nManager.forceRTL(rtl);
    if (rtl != I18nManager.isRTL) {
      setForceRtl(true);
      await persistor.flush();
      RNRestart.Restart();
    }
  }
};

const store = createStore(
  persistReducer(
    {
      key: "root",
      storage: Platform.OS == "web" ? storageSession : storage,
      stateReconciler: autoMergeLevel2,
      blacklist: ["tabsReducer", "issueTypes", "rights", "expoStandards"]
    },
    reducers
  ),
  applyMiddleware(customMiddleWare)
  // __DEV__ ? devToolsEnhancer() : undefined
);

export const persistor = persistStore(store);

export default store;
