import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const platformStyle = variables.platformStyle;
  const platform = variables.platform;
  const darkCommon = {
    "NativeBase.Text": {
      color: variables.brandDark
    },
    "NativeBase.Icon": {
      color: variables.brandDark
    },
    "NativeBase.IconNB": {
      color: variables.brandDark
    }
  };
  const lightCommon = {
    "NativeBase.Text": {
      color: variables.brandLight
    },
    "NativeBase.Icon": {
      color: variables.brandLight
    },
    "NativeBase.IconNB": {
      color: variables.brandLight
    }
  };
  const primaryCommon = {
    "NativeBase.Text": {
      color: variables.btnPrimaryBg
    },
    "NativeBase.Icon": {
      color: variables.btnPrimaryBg
    },
    "NativeBase.IconNB": {
      color: variables.btnPrimaryBg
    }
  };
  const successCommon = {
    "NativeBase.Text": {
      color: variables.btnSuccessBg
    },
    "NativeBase.Icon": {
      color: variables.btnSuccessBg
    },
    "NativeBase.IconNB": {
      color: variables.btnSuccessBg
    }
  };
  const infoCommon = {
    "NativeBase.Text": {
      color: variables.btnInfoBg
    },
    "NativeBase.Icon": {
      color: variables.btnInfoBg
    },
    "NativeBase.IconNB": {
      color: variables.btnInfoBg
    }
  };
  const warningCommon = {
    "NativeBase.Text": {
      color: variables.btnWarningBg
    },
    "NativeBase.Icon": {
      color: variables.btnWarningBg
    },
    "NativeBase.IconNB": {
      color: variables.btnWarningBg
    }
  };
  const dangerCommon = {
    "NativeBase.Text": {
      color: variables.btnDangerBg
    },
    "NativeBase.Icon": {
      color: variables.btnDangerBg
    },
    "NativeBase.IconNB": {
      color: variables.btnDangerBg
    }
  };
  const buttonTheme = {
    ".disabled": {
      backgroundColor: variables.btnDisabledBg
    },
    ".bordered": {
      ".dark": {
        ...darkCommon,
        backgroundColor: "transparent",
        borderColor: variables.brandDark,
        borderWidth: variables.borderWidth * 2
      },
      ".light": {
        ...lightCommon,
        backgroundColor: "transparent",
        borderColor: variables.brandLight,
        borderWidth: variables.borderWidth * 2
      },
      ".primary": {
        ...primaryCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnPrimaryBg,
        borderWidth: variables.borderWidth * 2
      },
      ".success": {
        ...successCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnSuccessBg,
        borderWidth: variables.borderWidth * 2
      },
      ".info": {
        ...infoCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnInfoBg,
        borderWidth: variables.borderWidth * 2
      },
      ".warning": {
        ...warningCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnWarningBg,
        borderWidth: variables.borderWidth * 2
      },
      ".danger": {
        ...dangerCommon,
        backgroundColor: "transparent",
        borderColor: variables.btnDangerBg,
        borderWidth: variables.borderWidth * 2
      },
      ".disabled": {
        backgroundColor: null,
        borderColor: variables.btnDisabledBg,
        borderWidth: variables.borderWidth * 2,
        "NativeBase.Text": {
          color: variables.btnDisabledBg
        }
      },
      ...primaryCommon,
      borderWidth: variables.borderWidth * 2,
      elevation: null,
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      backgroundColor: "transparent"
    },

    ".dark": {
      ".bordered": {
        ...darkCommon
      },
      backgroundColor: variables.brandDark
    },
    ".light": {
      ".transparent": {
        ...lightCommon,
        backgroundColor: null
      },
      ".bordered": {
        ...lightCommon
      },
      ...darkCommon,
      backgroundColor: variables.brandLight
    },

    ".primary": {
      ".bordered": {
        ...primaryCommon
      },
      backgroundColor: variables.btnPrimaryBg
    },

    ".success": {
      ".bordered": {
        ...successCommon
      },
      backgroundColor: variables.btnSuccessBg
    },

    ".info": {
      ".bordered": {
        ...infoCommon
      },
      backgroundColor: variables.btnInfoBg
    },

    ".warning": {
      ".bordered": {
        ...warningCommon
      },
      backgroundColor: variables.btnWarningBg
    },

    ".danger": {
      ".bordered": {
        ...dangerCommon
      },
      backgroundColor: variables.btnDangerBg
    },

    ".block": {
      justifyContent: "center",
      alignSelf: "stretch"
    },

    ".full": {
      justifyContent: "center",
      alignSelf: "stretch",
      borderRadius: 0
    },

    ".rounded": {
      // paddingHorizontal: variables.buttonPadding + 20,
      borderRadius: variables.borderRadiusLarge
    },

    ".transparent": {
      backgroundColor: "transparent",
      elevation: 0,
      shadowColor: null,
      shadowOffset: null,
      shadowRadius: null,
      shadowOpacity: null,
      ...primaryCommon,
      ".dark": {
        ...darkCommon,
        backgroundColor: null
      },
      ".danger": {
        ...dangerCommon,
        backgroundColor: null
      },
      ".warning": {
        ...warningCommon,
        backgroundColor: null
      },
      ".info": {
        ...infoCommon,
        backgroundColor: null
      },
      ".primary": {
        ...primaryCommon,
        backgroundColor: null
      },
      ".success": {
        ...successCommon,
        backgroundColor: null
      },
      ".light": {
        ...lightCommon,
        backgroundColor: null
      }
    },

    ".small": {
      height: 32,
      "NativeBase.Text": {
        fontSize: 14,
        lineHeight: 20
      }
    },

    ".large": {
      height: 60,
      "NativeBase.Text": {
        fontSize: 22,
        lineHeight: 32
      }
    },

    ".capitalize": {},

    ".vertical": {
      flexDirection: "column",
      height: null
    },

    "NativeBase.Text": {
      fontFamily: variables.btnFontFamily,
      marginLeft: 0,
      marginRight: 0,
      color: variables.inverseTextColor,
      fontSize: variables.btnTextSize,
      lineHeight: variables.btnLineHeight,
      paddingHorizontal: 16,
      backgroundColor: "transparent",
      alignSelf: "center"
      // childPosition: 1
    },

    "NativeBase.Icon": {
      color: variables.inverseTextColor,
      fontSize: 24,
      marginHorizontal: 16,
      paddingTop: platform === "ios" ? 2 : undefined
    },
    "NativeBase.IconNB": {
      color: variables.inverseTextColor,
      fontSize: 24,
      marginHorizontal: 16,
      paddingTop: platform === "ios" ? 2 : undefined
    },

    ".iconLeft": {
      "NativeBase.Text": {
        marginLeft: 0
      },
      "NativeBase.IconNB": {
        marginRight: 0,
        marginLeft: 16
      },
      "NativeBase.Icon": {
        marginRight: 0,
        marginLeft: 16
      }
    },
    ".iconRight": {
      "NativeBase.Text": {
        marginRight: 0
      },
      "NativeBase.IconNB": {
        marginLeft: 0,
        marginRight: 16
      },
      "NativeBase.Icon": {
        marginLeft: 0,
        marginRight: 16
      }
    },
    ".picker": {
      "NativeBase.Text": {
        ".note": {
          fontSize: 16,
          lineHeight: null
        }
      }
    },
    ".dialog": {
      backgroundColor: colors.button.dialog.background,
      shadowColor: null,
      shadowOffset: null,
      shadowOpacity: null,
      shadowRadius: null,
      elevation: null
    },
    ".declinePoll": {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Icon": {
        color: colors.button.pollDecline.icon,
        flex: -1
      },
      "NativeBase.Text": {
        color: colors.button.pollDecline.text,
        flex: -1,
        fontSize: 14,
        paddingHorizontal: 0,
        alignSelf: "center"
      }
    },
    ".connectProject": {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Text": {
        fontSize: variables.btnTextSize * 1.2,
        color: colors.button.connectProject.text
      }
    },
    ".setProject": {
      flex: 1,
      backgroundColor: colors.setProject.button.background,
      marginVertical: 15,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Text": {
        color: colors.setProject.button.text
      }
    },
    ".nationalityOther": {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Text": {
        fontSize: variables.btnTextSize,
        color: colors.nationalityFullList.button.text.settings,
        ".setup": {
          color: colors.nationalityFullList.button.text.setup
        }
      }
    },
    ".citation": {
      alignSelf: "flex-end",
      "NativeBase.Text": {
        color: colors.button.citation.text
      }
    },
    ".reportissue": {
      marginHorizontal: 10,
      backgroundColor: colors.button.reportIssue.background,
      "NativeBase.Text": {
        color: colors.button.reportIssue.text
        //fontSize: variables.btnTextSize * 1.2
      }
    },
    ".brownOutline": {
      borderColor: colors.brown,
      "NativeBase.Text": {
        color: colors.brown
      }
    },

    ".skipProject": {
      flex: 0,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      backgroundColor: colors.button.skipProject.background,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.skipProject.text,
        fontSize: 18
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        paddingHorizontal: 5,
        color: colors.button.skipProject.icon,
        textAlign: "left",
        ...(colors.button.skipProject.icon
          ? {}
          : {
              display: "none"
            })
      },
      ...(colors.button.skipProject.border
        ? {
            borderColor: colors.button.skipProject.border,
            borderWidth: variables.borderWidth * 2,
            elevation: null,
            shadowColor: null,
            shadowOffset: null,
            shadowOpacity: null,
            shadowRadius: null,
            backgroundColor: "transparent"
          }
        : {})
    },
    ".aboutContact": {
      borderColor: colors.funkyBlue1,
      "NativeBase.Text": {
        color: colors.funkyBlue1
      }
    },
    ".employerHelp": {
      height: null,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      backgroundColor: colors.button.employerHelp.background,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.employerHelp.text,
        fontSize: colors.button.employerHelp.fontSize || 13
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        marginHorizontal: 15,
        paddingHorizontal: 5,
        color: colors.button.employerHelp.icon,
        textAlign: "left",
        backgroundColor: colors.button.employerHelp.icon.background
      }
    },
    ".cancelEmployer": {
      height: null,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      marginHorizontal: 5,
      //backgroundColor: colors.button.employerHelp.background,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.employerHelp.text,
        fontSize: colors.button.employerHelp.fontSize || 13
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        marginHorizontal: 15,
        paddingHorizontal: 5,
        color: colors.button.employerHelp.icon,
        textAlign: "left",
        backgroundColor: colors.button.employerHelp.icon.background
      }
    },
    ".cancelEmployerSettings": {
      height: null,
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      marginHorizontal: 5,
      //backgroundColor: colors.button.employerHelp.background,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.funkyBlue2,
        fontSize: 16
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        marginHorizontal: 15,
        paddingHorizontal: 5,
        color: colors.button.employerHelp.icon,
        textAlign: "left",
        backgroundColor: colors.button.employerHelp.icon.background
      }
    },
    ".qrcode": {
      flex: 0,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      backgroundColor: colors.button.qrCode.background,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.qrCode.text,
        fontSize: 18
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        marginHorizontal: 15,
        paddingHorizontal: 5,
        color: colors.button.qrCode.icon.text,
        textAlign: "left",
        backgroundColor: colors.button.qrCode.icon.background
      }
    },
    ".connectProject": {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.connectProject.text,
        fontSize: 13
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        color: colors.button.connectProject.icon,
        textAlign: "left"
      }
    },
    ".about": {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginVertical: 15,
      "NativeBase.Text": {
        flex: -1,
        margin: 0,
        color: colors.button.about.text,
        fontSize: 13
      },
      "NativeBase.Icon": {
        flex: -1,
        margin: 0,
        color: colors.button.about.icon,
        textAlign: "left"
      }
    },
    ".setup": {
      borderColor: colors.button.setup.border,
      //borderRadius: 5,
      marginVertical: 10,
      alignItems: "center",
      alignSelf: "stretch",
      justifyContent: "center",
      "NativeBase.Text": {
        color: colors.button.setup.text,
        fontWeight: "normal",
        fontSize: variables.btnTextSize * 1.2
      }
    },
    ".languages": {
      borderColor: colors.button.languages.border,
      // borderRadius: 5,
      marginVertical: 10,
      alignItems: "center",
      alignSelf: "stretch",
      justifyContent: "center",
      "NativeBase.Text": {
        color: colors.button.languages.text,
        fontWeight: "normal",
        fontSize: variables.btnTextSize
      }
    },
    ".welcome": {
      //shadowColor: null,
      //shadowOffset: null,
      //shadowOpacity: null,
      //shadowRadius: null,
      //elevation: null,
      backgroundColor: colors.button.welcome.background,
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "center",
      //borderRadius: 5,
      //flexDirection: "row",
      //margin: 5,
      "NativeBase.Text": {
        color: colors.button.welcome.text,
        fontWeight: "bold",
        //flex: 1,
        textAlign: "center",
        marginHorizontal: 20
      },
      "NativeBase.Icon": {
        color: colors.button.welcome.icon,
        position: "absolute",
        right: 0,
        fontSize: variables.iconFontSize * 1.5
      }
    },

    //    "NativeBase.Text": {
    //      alignSelf: "center"
    //    },

    paddingVertical: variables.buttonPadding,
    paddingHorizontal: variables.buttonPadding,
    backgroundColor: variables.btnPrimaryBg,
    borderRadius: variables.borderRadiusBase,
    borderColor: variables.btnPrimaryBg,
    borderWidth: null,
    height: 45,
    alignSelf: "flex-start",
    flexDirection: "row",
    elevation: 2,
    shadowColor: platformStyle === "material" ? variables.brandDark : undefined,
    shadowOffset:
      platformStyle === "material" ? { width: 0, height: 2 } : undefined,
    shadowOpacity: platformStyle === "material" ? 0.2 : undefined,
    shadowRadius: platformStyle === "material" ? 1.2 : undefined,
    alignItems: "center",
    justifyContent: "space-between"
  };
  return buttonTheme;
};
