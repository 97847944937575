import React, { Component, Fragment } from "react";
import {
  Linking
} from "react-native";
import { Screen } from "../../utils";
import { Content, Text, Card, CardItem, Icon, Button, H1, View, ActionSheet } from "native-base";
import I18n from "../../i18n";
import { setChildHeader } from "../mainTabNavigator/actions";
import languages from "../../locale/languages";
import { connect } from "react-redux";
import { GET } from "../../axios";
import { manifest } from "../../const";
import DeviceInfo from "react-native-device-info";
import store from "../../store";

export default Screen(
  connect(state => ({
    hasBeenSetup: state.settings.hasBeenSetup,
    language: state.settings.language,
    nationality: state.settings.nationality,
    employer: state.settings.employerName,
    jobFunction: state.settings.jobFunction,
    projectId: state.settings.projectId,
    projectName: state.settings.projectName,
    offline: state.offline
  }))(
    class Dashboard extends Component {
      getLanguageName = () => {
        const lang = languages.find(lang => {
          return lang.languageCode === this.props.language;
        });
        return lang.languageName;
      };

      getJobFunctionName = () => {
        const fullList = I18n.t("jobFunctionList").shortList;
        const jobFunction = fullList.find(job => {
          return job.code === this.props.jobFunction;
        });
        return (jobFunction || { name: this.props.jobFunction }).name;
      };

      getProject = async () => {
        const result = await GET(`/context/projects/${this.props.projectId}`);
        setChildHeader("appStrings.LBL_YOUR_EMPLOYER");
        this.props.navigation.navigate("SetEmployer", {
          employers:
            (result && result.project && result.project.employers) || []
        });
      };

      render() {
        return (
          <Content padder>

          <View about>
            <Text aboutAppName>{DeviceInfo.getApplicationName()}</Text>
            <Text
              selectable={false}
              version
              delayLongPress={10000}
              onLongPress={() =>
                ActionSheet.show(
                  {
                    options: ["Erase all data and reset app", "Cancel"],
                    cancelButtonIndex: 1,
                    destructiveButtonIndex: 0,
                    title: "Advanced options"
                  },
                  buttonIndex => {
                    if (buttonIndex == 0) {
                      store.dispatch({ type: "APP_RESET" });
                      this.props.navigation.navigate("SetupScreens");
                      // store
                      //   .getState()
                      //   .tabsReducer.rootNavigator.navigate("SetupScreens");
                      // store.getState().tabsReducer.rootNavigator.dispatch(
                      //   StackActions.reset({
                      //     index: 0,
                      //     key: null,
                      //     actions: [
                      //       NavigationActions.navigate({
                      //         routeName: "SetupScreens"
                      //       })
                      //     ]
                      //   })
                      // );
                    }
                  }
                )
              }
            >
              v{DeviceInfo.getReadableVersion()}
            </Text>
          </View>



            {/* {this.props.projectName && this.props.projectId ? (
              <Fragment>
                <Text center yourProject>
                  {I18n.t("appStrings.LBL_YOUR_PROJECT")}
                </Text>
                <H1 projectName>{this.props.projectName}</H1>
                <Button
                  testID="setProject"
                  block
                  transparent
                  connectProject
                  onPress={() => {
                    setChildHeader("appStrings.LBL_PROJECT");
                    this.props.navigation.navigate("SetProject");
                  }}
                >
                  <Text uppercase={false}>
                    {I18n.t("appStrings.LBL_CHANGE_PROJECT")}
                  </Text>
                </Button>
              </Fragment>
            ) : (
              <Button
                block
                setProject
                testID="setProject"
                onPress={() => {
                  setChildHeader("appStrings.LBL_PROJECT");
                  this.props.navigation.navigate("SetProject");
                }}
              >
                <Text uppercase={false}>
                  {I18n.t("appStrings.PROMPT_PROJECT_CODE")}
                </Text>
              </Button>
            )} */}
            <Text settingsLabel>{I18n.t("appStrings.LBL_LANGUAGE")}</Text>
            <Card settings>
              <CardItem
                button
                testID="setLanguage"
                onPress={() => {
                  setChildHeader("appStrings.LBL_LANGUAGE");
                  this.props.navigation.navigate("SetLanguage");
                }}
              >
                {this.props.hasBeenSetup && (
                  <Text>{this.getLanguageName()}</Text>
                )}
                <Icon
                  name={I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
                />
              </CardItem>
            </Card>
            <Text settingsLabel>{I18n.t("appStrings.LBL_JOB_FUNCTION")}</Text>
            <Card settings>
              <CardItem
                button
                testID="setJobFunction"
                onPress={() => {
                  setChildHeader("appStrings.LBL_JOB_FUNCTION");
                  this.props.navigation.navigate("SetJobFunction");
                }}
              >
                {this.props.hasBeenSetup && (
                  <Text>{this.getJobFunctionName()}</Text>
                )}
                <Icon
                  name={I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
                />
              </CardItem>
            </Card>
            {/* {this.props.hasBeenSetup && this.props.employer ? (
              <Fragment>
                <Text settingsLabel>
                  {I18n.t("appStrings.LBL_YOUR_EMPLOYER")}
                </Text>
                <Card settings>
                  <CardItem
                    testID="setEmployer"
                    button={!this.props.offline ? true : false}
                    onPress={
                      !this.props.offline
                        ? () => {
                            this.getProject();
                          }
                        : undefined
                    }
                  >
                    <Text>{this.props.employer}</Text>
                    {!this.props.offline && (
                      <Icon
                        name={
                          I18n.isRTL
                            ? "ios-chevron-back"
                            : "ios-chevron-forward"
                        }
                      />
                    )}
                  </CardItem>
                </Card>
              </Fragment>
            ) : null} */}
            {/*
            <Button
              block
              transparent
              about
              testID="AboutProject"
              onPress={() => {
                this.props.navigation.navigate("AboutProject");
              }}
            >
              <Text uppercase={false}>
                {`${I18n.t("appStrings.LBL_ABOUT_TITLE")}`}
              </Text>
            </Button>
           */}

          <View style={{marginVertical: 30}}>
            <Button 
              rounded 
              bordered
              small
              aboutContact 
              style={{alignSelf: 'center'}}
              onPress={()=>Linking.openURL('mailto:degarda@funkycitizens.org?subject=Feedback%20despre%20%E2%80%9EDe%20Gard%C4%83%E2%80%9D')}
            >
              <Text>{I18n.t("appStrings.ABOUT_FEEDBACK")}</Text>
            </Button>
          </View>

          <View about>
            <Text center aboutCopyright>
              {I18n.t("appStrings.ABOUT_POWEREDBY")}
            </Text>
            <Text center aboutCopyright>
              {I18n.t("appStrings.ABOUT_CONTENT")}
            </Text>

            <Text
              center
              aboutPrivacy
              onPress={() =>
                Linking.openURL("https://www.workerapp.org/degarda-privacy")
              }
            >
              {I18n.t("appStrings.ABOUT_PRIVACY_LINK")}
            </Text>
            
          </View>


          







          </Content>
        );
      }
    }
  )
);
