import types from "../actions/types";
import { showToast } from "../utils";
import I18n from "../i18n";

export default (state = null, action) => {
  switch (action.type) {
    case types.ERROR:
      if (action.err) {
        if (__DEV__) {
          // return action.err;
        }
        try {
          const { err } = action;
          if (
            err &&
            err.response &&
            err.response.status >= 400 &&
            err.response.status < 500 &&
            // err.response.status != 404 &&
            (!err.response.data ||
              (err.response.data &&
                !err.response.data.msg &&
                !err.response.data.error))
          ) {
            return err;
          }
          const msg =
            (err &&
              err.response &&
              err.response.data &&
              (err.response.data.msg || err.response.data.error)) ||
            err;
          showToast(
            I18n.t(`appError.${msg.message || msg.toString()}`, {
              defaultValue: msg.message || msg.toString()
            }),
            "danger"
          );
          return err;
        } catch (_) {
          if (__DEV__) {
            console.error(action.err);
          }
          return action.err;
        }
      } else return null;
    default:
      return state;
  }
};
