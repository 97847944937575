import types from "../actions/types";
import settingsTypes from "../../../actions/settingsTypes";

const initialState = {
  alerts: {
    warnings: [],
    notices: []
  },
  polls: {
    availablePolls: [],
    hiddenPolls: null
  },
  posts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_RESET:
      return initialState;
    case settingsTypes.SET_PROJECT:
    case settingsTypes.SET_EMPLOYER:
      return initialState;
    case types.GET_ALERTS:
      return {
        ...state,
        alerts: action.alerts
      };
    case types.GET_POLLS:
      return {
        ...state,
        polls: {
          ...state.polls,
          availablePolls: action.polls,
          hiddenPolls: null
        }
      };
    case types.GET_POSTS:
      return {
        ...state,
        posts: action.posts
      };

    case types.SET_SUBMITED_POLL:
      return {
        ...state,
        polls: {
          ...state.polls,
          hiddenPolls: [...(state.polls.hiddenPolls || []), action.poll]
        }
      };
    case types.SET_HIDDEN_POLLS:
      return {
        ...state,
        polls: {
          ...state.polls,
          hiddenPolls: action.hiddenPolls
        }
      };
    case types.LOAD_MORE_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...action.posts]
      };
    default:
      return state;
  }
};
