import config from "react-native-config";
import { ScaleAnimation } from "react-native-dialog-component";
import { I18nManager } from "react-native";

export const APP_TOKEN = config.APP_TOKEN || null;
export const isDev = config.DEV || __DEV__ || false;
export const api =
  config.REST_API || "https://funky-client-api-dev.caravanstudiosapps.org";
export const namespace = "org.caravanstudios.wcfunky";
export const manifest = {
  version: config.VERSION_NAME || "0.9",
  buildDate: process.env.BUILD_DATE || new Date()
};
export const GCM_SENDER_ID = config.GCM_SENDER_ID || "379746134599";
export const FCM_PROJECT_ID = config.FCM_PROJECT_ID || "379746134599";
export const MOOD_TIMEOUT = 1000 * 60 * 60 * 24;
export const EMPLOYERS_TIMEOUT = 1000 * 60 * 5;
export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*-]{6,}$/;
export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const uriPrefix = "wcfunky://";

export const dialogConfig = {
  titleTextStyle: {
    fontSize: 32,
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr",
    color: "#000",
    fontFamily: "Nunito",
    textAlign: "center",
    lineHeight: "normal"
  },
  titleAlign: "center",
  animationDuration: 0,
  ScaleAnimation: new ScaleAnimation(),
  width: 300
};

export const PROJECT_ID = config.PROJECT_ID;
export const PROJECT_NAME = config.PROJECT_NAME;
export const NATIONALITY = config.NATIONALITY || "RO";
