export const workAtHeight = require("./image1.png");
export const mobilePlant = require("./image2.png");
export const lifting = require("./image3.png");
export const breakingGround = require("./image4.png");
export const driving = require("./image5.png");
export const hotWorks = require("./image6.png");
export const energisedSystems = require("./image7.png");
export const confinedSpaces = require("./image8.png");
export const temporaryWorks = require("./image9.png");
export const workingInTheHeat = require("./image10.png");
