import store from "../../../store";
import types from "./types";
import { POST, inlineGET } from "../../../axios";
import { getLocalToken } from "../../../actions/localToken";
import config from "react-native-config";

export async function getFeed() {
  await getAlerts();
  await getPolls();
  return await getFeedPosts();
}

export async function getFeedPosts(limit, lastCreatedAt, lastId, isLoadmore) {
  const settings = store.getState().settings;

  const params = {
    project: settings.projectId ? settings.projectId : undefined,
    employer: settings.employerId ? settings.employerId : undefined,
    nationality: settings.nationality,
    lang: settings.language,
    limit: limit ? limit : config.FEED_SIZE,
    last_timestamp: lastCreatedAt ? new Date(lastCreatedAt).getTime() : null,
    last_id: lastId ? lastId : null,
    country: settings.country ? settings.country : undefined
  };

  const posts = (await inlineGET("/posts", { params })) || [];
  if (isLoadmore) {
    store.dispatch({ type: types.LOAD_MORE_POSTS, posts });
  } else {
    store.dispatch({ type: types.GET_POSTS, posts });
  }
  return posts;
}

export async function getPostById(id) {
  return await inlineGET(`/posts/${id}`);
}

export async function getAlerts() {
  // get the current context
  // TODO: check if attached to a project

  const settings = store.getState().settings;

  const params = {
    project: settings.projectId ? settings.projectId : undefined,
    employer: settings.employerId ? settings.employerId : undefined,
    nationality: settings.nationality,
    lang: settings.language,
    country: settings.country ? settings.country : undefined
  };

  const result = await inlineGET("/alerts", { params: params });
  const alerts = filterAlerts(result || []);
  store.dispatch({ type: types.GET_ALERTS, alerts });
}

export function getGreeting() {
  const currentDate = Date.now();
  const current = new Date(currentDate);
  const currentHour = current.getHours();

  if (currentHour < 3) {
    return "appStrings.GREETING_EVENING";
  }
  if (currentHour >= 3 && currentHour < 12) {
    return "appStrings.GREETING_MORNING";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "appStrings.GREETING_AFTERNOON";
  } else if (currentHour >= 17 && currentHour <= 24) {
    return "appStrings.GREETING_EVENING";
  } else {
    return "appStrings.GREETING_WELCOME";
  }
}

export function filterAlerts(alerts) {
  const result = alerts.reduce(
    (prev, alert) => {
      if (!alert.hidden) {
        if (alert.type == "warning") {
          return {
            ...prev,
            warnings: [...prev.warnings, alert]
          };
        } else if (alert.type === "info") {
          return {
            ...prev,
            notices: [...prev.notices, alert]
          };
        }
      }

      return prev;
    },
    {
      warnings: [],
      notices: []
    }
  );
  return result;
}

export async function getPolls() {
  // get the current context
  // TODO: check if attached to a project

  const settings = store.getState().settings;

  const params = {
    project: settings.projectId,
    employer: settings.employerId,
    nationality: settings.nationality,
    lang: settings.language,
    country: settings.country ? settings.country : undefined
  };

  const polls = await inlineGET("/polls", { params: params });
  const hiddenPolls = store.getState().whatsnews.polls.hiddenPolls;
  store.dispatch({
    type: types.SET_HIDDEN_POLLS,
    hiddenPolls: checkHiddenPolls(polls, hiddenPolls)
  });
  store.dispatch({ type: types.GET_POLLS, polls });
}

export async function getPollById(id) {
  return await inlineGET(`/polls/${id}`);
}

export async function setPollAnswer(poll, answer, employerId, employerName) {
  const settings = store.getState().settings;

  const pollResponse = {
    poll_id: poll.id,
    context: {
      project_id: settings.projectId || undefined,
      employer_id: employerId || settings.employerId || undefined,
      employer_name: employerName || settings.employerName || undefined
    },
    response: answer,
    local_token: getLocalToken()
  };

  await POST("/poll_responses", pollResponse);
  store.dispatch({ type: types.SET_SUBMITED_POLL, poll });
}

export function declinePoll(poll) {
  store.dispatch({ type: types.SET_SUBMITED_POLL, poll });
}

//This function filter the polls list,
//based on hidden polls list(polls with id presented in hidden polls will not returned)

export function filterPolls(availablePolls, hiddenPolls = []) {
  const idArray =
    hiddenPolls && hiddenPolls.length > 0
      ? hiddenPolls.map(poll => poll.id)
      : [];

  const result = availablePolls.reduce((prev, next) => {
    if (idArray.includes(next.id)) {
      return prev;
    } else {
      return [...prev, next];
    }
  }, []);

  return result;
}

//This function check the hidden polls list, while the polls is fetched,
//it will remove not presented polls from hidden polls list

export function checkHiddenPolls(availablePolls, hiddenPolls = []) {
  return (hiddenPolls || []).reduce((prev, next) => {
    if (availablePolls.some(e => e.id === next.id)) {
      return [...prev, next];
    } else {
      return prev;
    }
  }, []);
}
