import store from "../store";
import types from "./settingsTypes";
import config from "react-native-config";

export function generateRandomToken() {
  let token = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 32; i++)
    token += chars.charAt(Math.floor(Math.random() * chars.length));

  return token;
}

export function updateLocalToken() {
  const localToken = {
    token: generateRandomToken(),
    createdAt: Date.now()
  };

  store.dispatch({ type: types.SET_LOCAL_TOKEN, localToken });
  return localToken.token;
}

export function rotateLocalToken(ttl_minutes) {
  if (typeof ttl_minutes === "undefined" || ttl_minutes == 0) {
    // rotate immediately
    return updateLocalToken();
  } else {
    // see if there's a saved local token
    const localToken = store.getState().settings.localToken;

    if (localToken) {
      const expireTime = new Date(localToken.createdAt + 60000 * ttl_minutes);

      if (Date.now() > expireTime) {
        return updateLocalToken();
      }

      return localToken.token;
    } else return updateLocalToken();
  }
}

export function getLocalToken() {
  return rotateLocalToken(config.LOCAL_TOKEN_TTL);
}
