import types from "../actions/settingsTypes";
import { generateRandomToken } from "../actions/localToken";
import { resetStack } from "../components/MainHeader";
import { PROJECT_ID, PROJECT_NAME, NATIONALITY } from "./../const";
import I18n from "../i18n";
import * as I18nObj from "../i18n";

console.log("settings i18n", I18n, I18nObj);

const initialState = {
  language: I18n.currentLocale(),
  detectedLanguage: I18n.currentLocale(),
  jobFunction: null,
  nationality: NATIONALITY,
  projectId: PROJECT_ID,
  projectName: PROJECT_NAME,
  employerId: null,
  employerName: null,
  notificationClientId: null,
  notificationBinding: null,
  hasBeenSetup: false,
  hasViewedIssueReportIntro: false,
  localToken: null,
  seeWelcomeMessage: false,
  lastMoodResponse: null,
  forceRtl: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_RESET:
      return initialState;
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.lang
      };
    case types.SET_JOB_FUNCTION:
      return {
        ...state,
        jobFunction: action.job
      };
    case types.SET_NATIONALITY:
      return {
        ...state,
        nationality: action.nat
      };
    /* case types.SET_PROJECT:
      if (!action.proj) resetStack("ReportIssueTab");
      return {
        ...state,
        projectId: action.proj ? action.proj.id : null,
        projectName: action.proj ? action.proj.name : null,
        employerId: null,
        employerName: null
      }; */
    case types.SET_COUNTRY:
      return {
        ...state,
        country: action.country
      };
    case types.SET_EMPLOYER:
      if (!action.empl) resetStack("ReportIssueTab");
      return {
        ...state,
        employerId: action.empl ? action.empl.company_id : null,
        employerName: action.empl ? action.empl.name : null
      };
    case types.SET_NOTIFICATION_TOKEN:
      return {
        ...state,
        notificationToken: action.token,
        notificationClientId:
          state.notificationClientId || generateRandomToken()
      };
    case types.SET_NOTIFICATION_BINDING:
      return { ...state, notificationBinding: action.binding };
    case types.SET_SETUP_STATUS:
      return {
        ...state,
        hasBeenSetup: action.status
      };
    case types.SET_LOCAL_TOKEN:
      return {
        ...state,
        localToken: action.localToken
      };
    case types.SET_REPORT_ISSUE_VIEW:
      return {
        ...state,
        hasViewedIssueReportIntro: action.viewed
      };
    case types.SEE_WELCOME_MESSAGE: {
      return {
        ...state,
        seeWelcomeMessage: true
      };
    }
    case types.MOOD_RESPONSE:
      return {
        ...state,
        lastMoodResponse: new Date()
      };
    case types.SET_FORCE_RTL: {
      return { ...state, forceRtl: action.forceRtl };
    }
    default:
      return state;
  }
};
