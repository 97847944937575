import { Platform } from "react-native";
import store from "../store";
import { hiddenPOST } from "../axios";
import types from "./settingsTypes";

export default async function bindNotificationRegistration(token) {
  const state = store.getState();
  const {
    notificationClientId: client_id,
    projectId: project_id,
    // employerId: employer_id,
    nationality,
    jobFunction: job_function,
    language,
    notificationBinding
  } = state.settings;

  const notification_address = token ? token : state.settings.notificationToken;

  if (!notification_address) return false;

  let notification_type = "fcm";
  if (Platform.OS == "ios") {
    notification_type = "apn";
  }

  if (
    !(
      client_id &&
      notification_type &&
      notification_address &&
      nationality &&
      job_function &&
      language
    )
  ) {
    return false;
  }

  const binding = {
    client_id,
    notification_type,
    notification_address,
    project_id,
    // employer_id,
    nationality,
    job_function,
    language
  };

  if (notificationBinding) {
    let eq = true;
    for (const prop in binding) {
      eq = eq && binding[prop] == notificationBinding[prop];
    }
    if (eq) return true;
  }

  store.dispatch({ type: types.SET_NOTIFICATION_BINDING, binding });
  return await hiddenPOST("/notification/registrations", binding);
}
