import React, { Component } from "react";
import { TouchableOpacity, BackHandler } from "react-native";
import { connect } from "react-redux";

import { Screen } from "../../utils";
import {
  Card,
  Body,
  Text,
  Item,
  Input,
  Content,
  H1,
  Header,
  Button,
  Right
} from "native-base";
import HospitalSuggest from "./../../components/HospitalSuggest";
import showDialog, { dismissDialog } from "../../components/Dialog";
import { lock } from "../../assets/img/welcome";

import { setRootModalStatus } from "../mainTabNavigator/actions";
import { reportIssue } from "./actions";
import issueTypes from "../../variables/issueTypes";
import features from "../../features";
import I18n from "../../i18n";
import { cachedGET } from "../../axios";
import { EMPLOYERS_TIMEOUT } from "../../const";
import wcColors from "../../../native-base-theme/variables/wcColors";

export default Screen(
  connect(state => ({
    settings: state.settings,
    isOffline: state.offline,
    isActiveProject: state.projectStatus
  }))(
    class ReportIssue extends Component {
      _working = false;

      state = {
        issue: this.props.navigation.state.params.issue ||
          issueTypes.find(
            issue => issue.id == this.props.navigation.state.params.issueType
          ) || {
            translations: { en: "" }
          },
        comments: "",
        employerId: null,
        employerName: "",
        employers: this.props.navigation.state.params.employers
      };

      get filteredEmployersNames() {
        return this.state.filteredEmployers.map(({ name, company_id }) => ({
          label: name,
          value: company_id
        }));
      }

      async componentDidMount() {
        setRootModalStatus(true);
        BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);

        if (!this.props.isOffline && !this.state.employers) {
          const result = await cachedGET(
            `/context/projects/${this.props.settings.projectId}`,
            EMPLOYERS_TIMEOUT
          );
          this.setState({
            employers:
              (result && result.project && result.project.employers) || []
          });
        }
      }

      componentWillUnmount() {
        setRootModalStatus(false);
        BackHandler.removeEventListener(
          "hardwareBackPress",
          this.handleBackPress
        );
      }

      handleBackPress = () => {
        this.props.navigation.goBack();
      };

      handleEmployerSelect = (employerName, employerId) => {
        this.setState({
          employerId,
          employerName
        });
      };

      issuePosted = async () => {
        return await reportIssue(
          this.state.comments,
          this.state.issue.id,
          this.props.settings.projectId,
          this.state.employerId,
          this.state.employerName,
          // this.props.settings.employerId,
          // this.props.settings.employerName,
          this.props.settings.jobFunction
        );
      };

      submitIssue = async () => {
        if (this._working) return;
        this._working = true;

        if (!(this.state.employerId || this.state.employerName)) {
          showDialog(
            I18n.t("appStrings.LBL_EMPLOYER_REQUIRED_ERROR_TITLE"),
            I18n.t("appStrings.LBL_EMPLOYER_REQUIRED_ERROR")
          );
          this._working = false;
        } else if (
          this.state.issue.require_comment &&
          this.state.comments.length == 0
        ) {
          showDialog(
            I18n.t("appStrings.LBL_COMMENTS_REQUIRED_ERROR_TITLE"),
            I18n.t("appStrings.LBL_COMMENTS_REQUIRED_ERROR")
          );
          this._working = false;
        } else if (this.props.isOffline) {
          showDialog(I18n.t("appStrings.MSG_DISCONNECTED"));
          this._working = false;
        } else if (await this.issuePosted()) {
          this.props.navigation.goBack();
          const afterIssuePosted = Object.keys(features)
            .filter(f => typeof features[f].afterIssuePosted == "function")
            .map(f => features[f].afterIssuePosted);

          if (afterIssuePosted.length > 0) {
            for (let i = 0; i < afterIssuePosted.length; i++) {
              await afterIssuePosted[i](this.state, this.props);
            }
          } else {
            showDialog(
              I18n.t("appStrings.POST_SUBMIT_MESSAGE_TITLE"),
              I18n.t("appStrings.POST_SUBMIT_MESSAGE"),
              lock
            );
          }
        } else {
          showDialog(
            I18n.t("appStrings.REPORT_ISSUE_ERROR_TITLE"),
            I18n.t("appStrings.REPORT_ISSUE_ERROR_TEXT")
          );
          this._working = false;
        }
      };

      handleInputChange = evt => {
        this.setState({ comments: evt.nativeEvent.text });
      };

      render() {
        const translation = I18n.currentLocale();
        return (
          <Content padder>
            {/* <Text reportissue>
              <Text label>{I18n.t("appStrings.LBL_PROJECT")}</Text>
              <Text label>: </Text>
              {this.props.settings.projectName}
            </Text>
            <Text reportissue>
              <Text label>{I18n.t("appStrings.LBL_EMPLOYER")}</Text>
              <Text label>: </Text>
              {this.props.settings.employerName}
            </Text> */}
            {this.state.issue && this.state.issue.translations && (
              <Card reportissue>
                <H1>{this.state.issue.translations[translation]}</H1>
              </Card>
            )}

            <HospitalSuggest
              placeholder={I18n.t("appStrings.ENTER_HOSPITAL_NAME")}
              employers={this.state.employers}
              onSelect={this.handleEmployerSelect}
              value={this.state.employerName}
            />

            <Item reportissue regular>
              <Input
                multiline
                onChange={evt => this.handleInputChange(evt)}
                ref={ref => (this._description = ref)}
                placeholder={
                  this.state.issue && !this.state.issue.require_comment
                    ? I18n.t("appStrings.LBL_COMMENTS_PLACEHOLDER")
                    : I18n.t("appStrings.LBL_COMMENTS_PLACEHOLDER_REQUIRED")
                }
                placeholderTextColor={wcColors.demoGrey7}
                textAlignVertical={"top"}
                numberOfLines={3}
                name="description"
                value={this.state.comments}
              />
            </Item>
            <Button
              testID="submitIssue"
              reportissue
              block
              onPress={this.submitIssue}
              disabled={this._working}
            >
              <Text numberOfLines={1} uppercase={false}>
                {I18n.t("appStrings.LBL_SUBMIT_REPORT")}
              </Text>
            </Button>
            <TouchableOpacity
              onPress={() => {
                if (!this._working) {
                  this._working = true;
                  showDialog(
                    I18n.t("appStrings.LBL_SUBMIT_REPORT_HELP_LINK"),
                    I18n.t("appStrings.SUBMIT_REPORT_HELP_CONTENT"),
                    require("../../assets/img/report/report_help_image.png"),
                    undefined,
                    () => {
                      this._working = false;
                      dismissDialog();
                    },
                    undefined,
                    () => {
                      this._working = false;
                      dismissDialog();
                    },
                    () => {
                      this._working = false;
                    }
                  );
                }
              }}
            >
              <Text center reportissue>
                {I18n.t("appStrings.LBL_SUBMIT_REPORT_HELP_LINK")}
              </Text>
            </TouchableOpacity>
          </Content>
        );
      }
    }
  ),
  props => (
    <Header reportissue {...props}>
      <Body>
        <Text>{I18n.t("appStrings.TITLE_REPORT_ISSUE")}</Text>
      </Body>
      <Right>
        <TouchableOpacity onPress={() => props.navigation.goBack()}>
          <Text uppercase={false}>{I18n.t("appStrings.LBL_CANCEL")}</Text>
        </TouchableOpacity>
      </Right>
    </Header>
  ),
  null,
  {
    navigationOptions: {
      header: null,
      modal: true
    }
  }
);
