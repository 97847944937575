import React, { Component } from "react";
import { View, Image } from "react-native";
import { Screen } from "../../utils";
import styles from "../../styles";
import { List, ListItem, Text, Icon, Content, Button } from "native-base";
import issueCategories from "../../variables/issueCategories";
import { setChildHeader } from "../mainTabNavigator/actions";
import I18n from "../../i18n";
import { setReportIssueView } from "../../actions/settings";
import { connect } from "react-redux";
import { lock } from "../../assets/img/welcome";
import showDialog from "../../components/Dialog";

export default Screen(
  connect(state => ({
    projectId: state.settings.projectId,
    hasViewedIssueReportIntro: state.settings.hasViewedIssueReportIntro,
    activeTab: state.tabsReducer.activeTab
  }))(
    class SelectCategory extends Component {
      componentDidMount() {
        this._checkReportViewed();
      }

      componentDidUpdate() {
        this._checkReportViewed();
      }

      _checkReportViewed = () => {
        if (
          !this.props.hasViewedIssueReportIntro &&
          this.props.projectId &&
          this.props.activeTab &&
          this.props.activeTab.key === "ReportIssueTab"
        ) {
          showDialog(
            I18n.t("appStrings.HELP_ISSUEREPORT_TITLE"),
            I18n.t("appStrings.HELP_ISSUEREPORT_DESC"),
            lock
          );
          setReportIssueView(true);
        }
      };

      generateCategories() {
        return issueCategories.map((category, key) => (
          <ListItem
            reportissue
            key={key}
            onPress={() => {
              this.props.navigation.navigate("SelectIssueType", {
                category: category.categoryType
              });
              setChildHeader(category.title);
            }}
            testID="issuetype"
          >
            {category.icon &&
            typeof category.icon == "string" &&
            !category.icon.includes(".png") ? (
              <Icon type={category.type} name={category.icon} />
            ) : (
              <Image style={styles.imageIcon} source={category.icon} />
            )}
            <Text>{I18n.t(category.title)}</Text>
          </ListItem>
        ));
      }

      render() {
        return (
          <Content>
            <List>
              <ListItem reportissue itemHeader>
                <Text>{I18n.t("appStrings.PROMPT_REPORT_ISSUE_ABOUT")}</Text>
              </ListItem>
              {this.generateCategories()}
            </List>
          </Content>
        );
      }
    }
  )
);
