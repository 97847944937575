import React, { Component, Fragment } from "react";
import {
  StyleSheet,
  RefreshControl,
  TouchableOpacity,
  Image
} from "react-native";
import { Screen } from "../../utils";
import { Content, Card, CardItem, H3, Icon, Text, View } from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";
import { getCommunityProfiles } from "../../actions/communityProfiles";
import styles from "../../styles";
import { isOffline } from "../../offline";

export default Screen(
  connect(state => ({
    settings: state.settings,
    communityProfiles: state.communityProfiles.profiles,
    isOffline: state.offline
  }))(
    class CommunityProfileSummary extends Component {
      state = {
        isRefreshing: false
      };

      async componentDidMount() {
        const offline = await isOffline();
        if (!offline) {
          await getCommunityProfiles(this.props.settings.nationality, false);
        }
      }

      refreshCommunityProfile = async () => {
        const offline = await isOffline();
        if (!offline) {
          this.setState({ isRefreshing: true });
          await getCommunityProfiles(this.props.settings.nationality, true);
          this.setState({ isRefreshing: false });
        }
      };

      getCommunityName = () => {
        const fullList = I18n.t("countryList").fullList;
        const community = fullList.find(
          element => element.code == this.props.settings.nationality
        );
        return community ? community.name : null;
      };

      generateGuides = () => {
        const guides =
          this.props.communityProfiles.guide &&
          this.props.communityProfiles.guide.length
            ? this.props.communityProfiles.guide
            : null;

        const lang = I18n.currentLocale();

        if (guides) {
          return (
            <View>
              <Text divider>{I18n.t("appStrings.LBL_RESOURCES_GUIDES")}</Text>
              {guides.map((guide, key) => {
                const localeGuide =
                  guide.tranlations &&
                  guide.translations[lang] &&
                  Object.keys(guide.tranlations).length
                    ? guide.tranlations[lang]
                    : guide;
                return (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("CommunityProfDetails", {
                        type: guide.type,
                        name: guide.name,
                        communityProfile: guide
                      });
                    }}
                    key={key}
                  >
                    <Card community guide>
                      <CardItem>
                        <H3>{localeGuide.name}</H3>
                        <Text subtitle>{localeGuide.publisher}</Text>
                        <Text description>{localeGuide.summary}</Text>
                      </CardItem>
                      <Icon
                        name={
                          I18n.isRTL
                            ? "ios-chevron-back"
                            : "ios-chevron-forward"
                        }
                      />
                    </Card>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        } else {
          return null;
        }
      };

      generatePersons = () => {
        const persons =
          this.props.communityProfiles.person &&
          this.props.communityProfiles.person.length
            ? this.props.communityProfiles.person
            : null;

        if (persons) {
          return (
            <View>
              <Text divider>{I18n.t("appStrings.LBL_RESOURCES_PEOPLE")}</Text>
              {persons.map((person, key) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("CommunityProfDetails", {
                        communityProfile: person
                      });
                    }}
                    key={key}
                  >
                    <Card community person>
                      <CardItem>
                        {/* {person.image && (
                          <Image
                            style={{ width: 30, height: 30, marginRight: 10 }}
                            source={{
                              uri: person.image
                            }}
                          />
                        )} */}
                        <H3>{person.name}</H3>
                        <Text subtitle>{person.title}</Text>
                        <Text>{person.summary}</Text>
                      </CardItem>
                      <Icon
                        name={
                          I18n.isRTL
                            ? "ios-chevron-back"
                            : "ios-chevron-forward"
                        }
                      />
                    </Card>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        } else {
          return null;
        }
      };

      generateOrganizations = () => {
        const organizations =
          this.props.communityProfiles.organization &&
          this.props.communityProfiles.organization.length
            ? this.props.communityProfiles.organization
            : null;

        if (organizations) {
          return (
            <View>
              <Text divider>
                {I18n.t("appStrings.LBL_RESOURCES_ORGANIZATIONS")}
              </Text>
              {organizations.map((organization, key) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("CommunityProfDetails", {
                        communityProfile: organization
                      });
                    }}
                    key={key}
                  >
                    <Card community organization>
                      <CardItem>
                        <H3>{organization.name}</H3>
                        <Text subtitle>{organization.title}</Text>
                      </CardItem>
                      <Icon
                        name={
                          I18n.isRTL
                            ? "ios-chevron-back"
                            : "ios-chevron-forward"
                        }
                      />
                    </Card>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        } else {
          return null;
        }
      };

      generateEmbassy = () => {
        const embassies =
          this.props.communityProfiles.embassy &&
          this.props.communityProfiles.embassy.length
            ? this.props.communityProfiles.embassy
            : null;
        if (embassies) {
          return (
            <View>
              <Text divider>{I18n.t("appStrings.LBL_RESOURCES_EMBASSY")}</Text>
              {embassies.map((embassy, key) => {
                return (
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("CommunityProfDetails", {
                        communityProfile: embassy
                      });
                    }}
                    key={key}
                  >
                    <Card community embassy>
                      <CardItem>
                        <H3>{embassy.name}</H3>
                        <Text>{embassy.title}</Text>
                      </CardItem>
                      <Icon
                        name={
                          I18n.isRTL
                            ? "ios-chevron-back"
                            : "ios-chevron-forward"
                        }
                      />
                    </Card>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        } else {
          return null;
        }
      };

      render() {
        const countryName = this.getCommunityName();
        return (
          <Content
            padder
            refreshControl={
              <RefreshControl
                enabled={!this.props.isOffline}
                refreshing={this.state.isRefreshing}
                onRefresh={this.refreshCommunityProfile}
              />
            }
          >
            <View padder>
              {Object.keys(this.props.communityProfiles).length > 0 && (
                <Fragment>
                  {/**
                  <Text communityDescription>
                    {I18n.t("appStrings.COMMUNITY_RESOURCES_DESCRIPTION")}
                  </Text>
                   */}
                  <View>{this.generateEmbassy()}</View>
                  <View>{this.generatePersons()}</View>
                  <View>{this.generateOrganizations()}</View>
                  <View>{this.generateGuides()}</View>
                </Fragment>
              )}
              {Object.keys(this.props.communityProfiles).length === 0 &&
                !this.props.isOffline && (
                  <Text communityDescription>
                    {I18n.t("appStrings.COMMUNITY_RESOURCES_NOINFO")}
                  </Text>
                )}
              {Object.keys(this.props.communityProfiles).length === 0 &&
                this.props.isOffline && (
                  <Text communityDescription>
                    {I18n.t("appStrings.COMMUNITY_RESOURCES_NOTDOWNLOADED")}
                  </Text>
                )}
            </View>
          </Content>
        );
      }
    }
  )
);
