import React, { Component } from "react";
import { NavigationActions, StackActions } from "react-navigation";
import { createBrowserHistory, createHashHistory } from "history";
import {
  getPathAndParamsFromLocation,
  matchPathAndParams,
  paramsToString
} from "./utils";

const { NAVIGATE, BACK, SET_PARAMS } = NavigationActions;
const { PUSH, POP } = StackActions;

export default function withBrowserHistory(Navigator, guard) {
  const Wrapper = class extends Component {
    static defaultProps = {
      navigatorRef: null,
      basePath: "/",
      uriPrefix: ""
    };

    constructor(props) {
      super(props);
      this.history = createHashHistory();
    }

    componentDidMount() {
      this.pathAndParams = getPathAndParamsFromLocation(
        this.history.location,
        this.props.basePath,
        this.props.uriPrefix
      );

      const action =
        Navigator.router.getActionForPathAndParams(
          this.pathAndParams.path,
          this.pathAndParams.params
        ) || NavigationActions.init();

      this.unlistener = this.history.listen((location, actionType) => {
        if (this._skipListener) {
          this._skipListener = false;
          return;
        }
        if (actionType == "POP") {
          const pathAndParams = getPathAndParamsFromLocation(
            location,
            this.props.basePath,
            this.props.uriPrefix
          );

          if (typeof guard == "function") {
            if (!guard(pathAndParams)) {
              this.history.replace(
                `${this.props.basePath}${this.pathAndParams.path}`
              );
              return;
            }
          }

          this.pathAndParams = pathAndParams;

          const action = Navigator.router.getActionForPathAndParams(
            pathAndParams.path,
            pathAndParams.params
          );

          if (action) {
            this._navigator.setState({
              nav: Navigator.router.getStateForAction(action)
            });
          }
        }
      });
    }

    componentWillUnmount() {
      this.unlistener();
    }

    handleNavigationStateChange = (...args) => {
      const { basePath, uriPrefix } = this.props;
      const [prevState, nextState, action] = args;
      const pathAndParams = Navigator.router.getPathAndParamsForState(
        nextState
      );
      const locationPathAndParams = getPathAndParamsFromLocation(
        this.history.location,
        this.props.basePath,
        this.props.uriPrefix
      );
      if (matchPathAndParams(locationPathAndParams, pathAndParams)) return;
      this.pathAndParams = pathAndParams;

      if (action.ignoreHistory) return;
      if (action.type == "Navigation/BACK") {
        this._skipListener = true;
        this.history.goBack();
      } else if (
        action.type == "Navigation/NAVIGATE" ||
        action.type == "Navigation/JUMP_TO" ||
        action.type == "Navigation/RESET"
      ) {
        this.history.push(`${basePath}${pathAndParams.path}`);
      }

      this.props.onNavigationStateChange &&
        this.props.onNavigationStateChange(...args);
    };

    render() {
      const {
        navigatorRef,
        onNavigationStateChange,
        ...restProps
      } = this.props;

      return (
        <Navigator
          ref={ref => {
            this._navigator = ref;
            window.nav = ref;
          }}
          onNavigationStateChange={this.handleNavigationStateChange}
          {...restProps}
        />
      );
    }
  };
  Wrapper.router = Navigator.router;
  return Wrapper;
}
