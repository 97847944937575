import SelectIssueType from "./selectIssueType";
import InitRoute from "./initialRoute";
import issueCategories from "../../variables/issueCategories";
import { withSetupGuard } from "../../utils";

const MyStack = {
  ReportIssueTab: {
    screen: withSetupGuard(InitRoute),
    path: "reportissue/",
    navigationOptions: {
      title: "appStrings.TITLE_REPORT_ISSUE",
      route: "ReportIssueTab"
    }
  },
  SelectIssueType: {
    screen: withSetupGuard(SelectIssueType),
    path: "reportissue/:category",
    navigationOptions: {
      getTitle: ({ getParam }) => {
        const categoryType = getParam("category", "");
        const category =
          issueCategories.find(it => it.categoryType == categoryType) || {};
        return category.title || "appStrings.TITLE_REPORT_ISSUE";
      },
      hasBack: true,
      route: "ReportIssueTab"
    }
  }
};

export default MyStack;
