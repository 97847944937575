import store from "../store";
import types from "./types";
import { GET } from "../axios";

export function setProjectStatus(status) {
  store.dispatch({ type: types.SET_PROJECT_STATUS, status });
}

export async function checkProjectStatus() {
  const { projectId, employerId } = store.getState().settings;

  if (projectId) {
    const proj = await GET("/context/projects/" + projectId);
    if (proj === null) return;
    if (
      proj &&
      proj.project &&
      proj.project.employers &&
      proj.project.employers.length
    ) {
      if (employerId) {
        const result = proj.project.employers.find(
          employer => employer.company_id === employerId
        );
        if (result) {
          setProjectStatus(true);
        } else {
          setProjectStatus(false);
        }
      }
    } else {
      setProjectStatus(false);
    }
  }
}
