import { Content, List, Text, Card, CardItem, Body, View } from "native-base";
import { Image, StyleSheet } from "react-native";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Screen } from "../../utils";
import I18n from "../../i18n";
import * as promiseImages from "../../assets/img/resources/promises";
import wcColors from "../../../native-base-theme/variables/wcColors";

export default Screen(
  connect(state => ({
    settings: state.settings,
    promises: state.promises
  }))(
    class Promises extends Component {
      state = {
        promise: this.props.navigation.getParam("promiseType"),
        currentPromise: this.props.promises[
          this.props.navigation.getParam("promiseType")
        ][0]
      };
      renderNumberAndPointsByRTL(locale, number) {
        return locale === "ar" || locale === "ur" ? "." + number : number + ".";
      }
      renderItems(descriptions, locale) {
        var descriptionsTexts =
          descriptions && descriptions[locale]
            ? descriptions[locale]
            : descriptions["en"];
        return descriptionsTexts.map((item, index) => {
          return (
            <View style={style.promiseItemView}>
              <View style={style.promiseItemLeftView}>
                <Text style={style.promiseRawText}>
                  {this.renderNumberAndPointsByRTL(locale, index + 1)}
                </Text>
              </View>
              <View style={style.promiseItemRightView}>
                <Text style={style.promiseRawText}>{item.description}</Text>
              </View>
            </View>
          );
        });
      }
      render() {
        const locale = I18n.currentLocale() ? I18n.currentLocale() : "en";
        return (
          <Content>
            <View style={style.promiseViewImage}>
              <Image
                source={promiseImages[this.state.currentPromise.image]}
                style={style.promiseImage}
              />
            </View>
            <View style={style.promiseViewText}>
              <Text style={style.promiseMainText}>
                {this.state.currentPromise.title[locale]
                  ? this.state.currentPromise.title[locale]
                  : this.state.currentPromise.title["en"]}
              </Text>
              {this.renderItems(this.state.currentPromise.descriptions, locale)}
            </View>
          </Content>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  promiseViewImage: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: -25
  },
  promiseViewText: {
    flex: 2,
    marginTop: 20,
    marginBottom: 20,
    marginHorizontal: 20
  },
  promiseImage: {
    width: 150,
    height: 150
  },
  promiseMainText: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 22,
    fontWeight: "bold",
    marginTop: 10,
    color: wcColors.promise.mainText
  },
  promiseItemView: {
    flexDirection: "row",
    justifyContent: "space-around",
    flex: 1,
    width: "100%",
    marginTop: 10
  },
  promiseItemLeftView: {
    width: "15%",
    marginLeft: 15
  },
  promiseItemRightView: {
    width: "85%",
    marginRight: 15
  },
  promiseRawText: {
    fontSize: 14,
    color: wcColors.promise.rawText
  }
});
