import React, { Component, Fragment } from "react";
import {
  UIManager,
  Alert,
  KeyboardAvoidingView,
  StatusBar,
  AppState,
  Linking,
  Platform,
  Dimensions,
  View
} from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { Root, StyleProvider, Text, ActionSheet, Toast } from "native-base";
import getTheme from "../native-base-theme/components";
import { NavigationActions, StackActions } from "react-navigation";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import AppNavigation, { getChangeTab, routes } from "./navigation";
import { DismissKeyboard } from "./utils";
import Progress from "./components/Progress";
import Immersive from "react-native-immersive";
import styles from "./styles";
import { isOffline, attempInit } from "./offline";
import RNRestart from "react-native-restart";
import { setJSExceptionHandler } from "react-native-exception-handler";
import I18n, { setLocale, initLocale } from "./i18n";
import { rotateLocalToken } from "./actions/localToken";
import config from "react-native-config";
import { uriPrefix } from "./const";
import Orientation from "react-native-orientation-locker";
import { activation } from "./actions/settings";
import ConnectToProject from "./components/ConnectToProject";
import showDialog from "./components/Dialog";
import { resetStack, changeStack } from "./components/MainHeader";
import { setRootNavigator } from "./screens/mainTabNavigator/actions";
import LinearGradient from "react-native-linear-gradient";
import wcColors from "../native-base-theme/variables/wcColors";

let ExtraDimensions = null;

if (Platform.OS === "android") {
  ExtraDimensions = require("react-native-extra-dimensions-android");
}

const height = Dimensions.get("window").height;

const errorHandler = e => {
  if (!__DEV__) {
    Alert.alert(
      I18n.t("appStrings.UNEXPECTED_ERROR"),
      I18n.t("appStrings.NEED_TO_RESTART") + e,
      [
        {
          text: I18n.t("appStrings.RESTART"),
          onPress: () => {
            RNRestart.Restart();
          }
        }
      ],
      {
        cancelable: false
      }
    );
  }
};

setJSExceptionHandler(errorHandler, true);

if (typeof UIManager.setLayoutAnimationEnabledExperimental == "function") {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const AppRoot = connect(state => ({
  modalProgress: state.progress.modal
}))(
  class AppRoot extends Component {
    state = {
      initialRouteName: null,
      paddingSize:
        Platform.OS === "android"
          ? ExtraDimensions.get("SOFT_MENU_BAR_HEIGHT")
          : 0
    };

    async componentDidMount() {
      attempInit({ isConnected: !(await isOffline()) });
      this.connectionChangeUnsubscribe = NetInfo.addEventListener(attempInit);
    }

    constructor(props) {
      super(props);
      if (typeof this.connectionChangeUnsubscribe == "function") {
        this.connectionChangeUnsubscribe();
      }
    }

    render() {
      return (
        <KeyboardAvoidingView
          style={[
            styles.container,
            {
              height: height
            }
          ]}
          behavior={Platform.OS === "android" ? "padding" : undefined}
        >
          <PersistGate loading={<Progress />} persistor={persistor}>
            <StyleProvider style={getTheme()}>
              <AppNavigation
                uriPrefix={uriPrefix}
                onNavigationStateChange={(prevState, newState, action) => {
                  let route = newState.routes[newState.index];
                  if (route.routeName == "App") {
                    route = route.routes[route.index];
                  }
                  const navigationOptions =
                    (routes[route.routeName] || {}).navigationOptions || {};
                  changeStack(navigationOptions.route, route);
                  const changeTab = getChangeTab();
                  if (typeof changeTab == "function") {
                    changeTab(navigationOptions.route);
                  }
                }}
                ref={ref => setRootNavigator(ref)}
              />
            </StyleProvider>
            {this.props.modalProgress && <Progress />}
          </PersistGate>
        </KeyboardAvoidingView>
      );
    }
  }
);

class App extends Component {
  state = {
    isReady: false,
    isImmersive: false,
    immersiveState: null
  };

  setImmersiveOn = () => {
    Immersive.on();
    this.setState({ isImmersive: true });
  };
  setImmersiveOff = () => {
    Immersive.off();
    this.setState({ isImmersive: false });
  };
  restoreImmersive = () => Immersive.on();

  async componentDidMount() {
    Orientation.lockToPortrait();
    rotateLocalToken(config.LOCAL_TOKEN_TTL);
    AppState.addEventListener("change", this._handleAppStateChange);
    Linking.addEventListener("url", this._handleIntentURL);
    this._handleIntentURL({ url: await Linking.getInitialURL() });
  }

  componentWillUnmount() {
    Orientation.lockToPortrait();
    AppState.removeEventListener("change", this._handleAppStateChange);
    Linking.removeEventListener("url", this._handleIntentURL);
  }

  _handleAppStateChange = nextAppState => {
    rotateLocalToken(config.LOCAL_TOKEN_TTL);
  };

  _intentCommands = {
    dev: {
      reset: () => {
        store.dispatch({ type: "APP_RESET" });
        store
          .getState()
          .tabsReducer.rootNavigator.dispatch(
            NavigationActions.navigate({ routeName: "SetupScreens" })
          );
      }
    },
    activation: async activationCode => {
      const state = store.getState();
      let goToEmployers = false;
      const { projectId } = state.settings;
      const onActivate = async () => {
        const result = await activation(activationCode);
        if (result) {
          ConnectToProject.fireSubmit(result);
          resetStack("WhatsNewTab");
        }
        return result;
      };
      if (projectId) {
        const { projectName, employerName } = state.settings;
        const ok = await showDialog(
          I18n.t("appStrings.ACTIVATION_DEEP_LINK_CONFIRM_TITLE"),
          <View style={{ marginVertical: 15 }}>
            <View>
              <Text>
                <Text style={{ fontWeight: "bold" }}>
                  {I18n.t("appStrings.LBL_YOUR_PROJECT")}
                </Text>
                : {projectName}
              </Text>
            </View>
            {employerName && (
              <View>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {I18n.t("appStrings.LBL_YOUR_EMPLOYER")}
                  </Text>
                  : {employerName}
                </Text>
              </View>
            )}
          </View>,
          null,
          null,
          null,
          true
        );
        if (ok) {
          const { project, employer } = await onActivate();
          if (project && !employer) {
            goToEmployers = project.employers || [];
          }
        }
      } else {
        const { project, employer } = await onActivate();
        if (project) {
          await showDialog(
            I18n.t("appStrings.ACTIVATION_DEEP_LINK_SUCCESS"),
            <View style={{ marginVertical: 15 }}>
              <View>
                <Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {I18n.t("appStrings.LBL_YOUR_PROJECT")}
                  </Text>
                  : {project.name}
                </Text>
              </View>
              {employer && (
                <View>
                  <Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {I18n.t("appStrings.LBL_YOUR_EMPLOYER")}
                    </Text>
                    : {employer.name}
                  </Text>
                </View>
              )}
            </View>
          );
          if (!employer) {
            goToEmployers = project.employers || [];
          }
        }
      }
      if (goToEmployers) {
        store.getState().tabsReducer.rootNavigator.dispatch(
          NavigationActions.navigate({
            routeName: "App",
            action: StackActions.reset({
              index: 2,
              key: null,
              actions: [
                NavigationActions.navigate({ routeName: "SettingsTab" }),
                NavigationActions.navigate({ routeName: "SetProject" }),
                NavigationActions.navigate({ routeName: "SetEmployer" })
              ]
            })
          })
        );
      }
    }
  };
  _handleIntentURL = ({ url }) => {
    if (url) {
      url = url.replace(uriPrefix, "");
      intent = url.split("/");
      let command = this._intentCommands;
      for (let i = 0; i < intent.length; i++) {
        const part = intent[i];
        if (typeof command[part] == "function") {
          if (i < intent.length - 1) {
            command[part].apply(this, intent.slice(i + 1));
          } else {
            command[part]();
          }
          break;
        } else if (typeof command[part] != "undefined") {
          command = command[part];
        } else break;
      }
    }
  };

  render() {
    return (
      <Fragment>
        <LinearGradient
          colors={wcColors.chooseLang.backgroundGradient}
          locations={wcColors.chooseLang.gradientLocations}
          start={wcColors.chooseLang.gradientStart}
          end={wcColors.chooseLang.gradientEnd}
          style={styles.gradient}
        />
        <Root>
          <StatusBar hidden={Platform.OS === "android" ? true : false} />
          <Provider store={store}>
            <AppRoot />
          </Provider>
        </Root>
      </Fragment>
    );
  }
}

export default DismissKeyboard(App);
