import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { Content, View, Button, Text, H1, Header, Icon } from "native-base";
import { Platform, TouchableWithoutFeedback } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import FormInput from "../../components/FormInput";
import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";

import I18n from "../../i18n";
import { setJobFunction, setSetupStatus } from "../../actions/settings";
import { GET } from "../../axios";

const JobFunction = connect(state => ({
  projectId: state.settings.projectId,
  projectName: state.settings.projectName,
  currentLocal: state.settings.language
}))(
  class JobFunction extends Component {
    static navigationOptions = {
      header: null
    };

    state = {
      somethingElse: false
    };

    storeJobFunction = async item => {
      setJobFunction(item);
      setSetupStatus(true);
      this.props.navigation.navigate("App");
    };

    renderShortList = () => {
      const shortList = I18n.t("jobFunctionList").shortList;

      return shortList.map((job, key) => (
        <Button
          testID={"jobFunctionList" + key}
          key={key}
          rounded
          bordered
          setup
          onPress={() => {
            return this.storeJobFunction(job.code);
          }}
        >
          <Text>{job.name}</Text>
        </Button>
      ));
    };

    toggleSomethingElse = () => {
      this.setState(state => ({ somethingElse: !state.somethingElse }));
    };

    renderSomethingElse = () => {
      return (
        <Content setup padder>
          <View padder>
            <H1 employerSearchHelpSetup>
              {I18n.t("appStrings.PROMPT_WHAT_JOB_FUNCTION")}
            </H1>
          </View>
          <View
            style={{ flex: 1, flexDirection: "column", alignItems: "center" }}
          ></View>
          <View padder>
            <FormInput
              onFocus={this.handleCustomEmployerFocused}
              onBlur={this.handleCustomEmployerBlurred}
              settingsFormInput
              onSubmit={value => {
                if (value && value.trim()) {
                  this.storeJobFunction(value.trim());
                }
              }}
              placeholder={I18n.t("appStrings.LBL_JOB_FUNCTION_NAME")}
              buttonText={I18n.t("appStrings.LBL_OK")}
            />
          </View>
          <Button
            block
            transparent
            cancelEmployer
            onPress={() => {
              this.toggleSomethingElse();
            }}
          >
            <Text uppercase={false}>{I18n.t("appStrings.LBL_CANCEL")}</Text>
          </Button>
        </Content>
      );
    };

    render() {
      return (
        <Fragment>
          <LinearGradient
            colors={wcColors.nationality.backgroundGradient}
            locations={wcColors.nationality.gradientLocations}
            start={wcColors.nationality.gradientStart}
            end={wcColors.nationality.gradientEnd}
            style={styles.gradient}
          />
          {this.state.somethingElse ? (
            this.renderSomethingElse()
          ) : (
            <>
              {Platform.OS === "web" ? (
                <Button
                  bordered
                  small
                  onPress={() => this.props.navigation.goBack()}
                  style={{ borderColor: "white", margin: 5 }}
                >
                  <Text numberOfLines={1} style={{ color: "white" }}>
                    {I18n.t("appStrings.LBL_CHANGE_LANGUAGE")}
                  </Text>
                </Button>
              ) : (
                <TouchableWithoutFeedback
                  onPress={() => this.props.navigation.goBack()}
                >
                  <Header hasTabs nationality>
                    <Icon name={I18n.isRTL ? "arrow-forward" : "arrow-back"} />
                    <Text>{I18n.t("appStrings.NAV_BACK")}</Text>
                  </Header>
                </TouchableWithoutFeedback>
              )}
              <Content setup padder>
                <H1 nationality>
                  {I18n.t("appStrings.PROMPT_WHAT_JOB_FUNCTION")}
                </H1>

                {this.renderShortList()}

                <Button
                  testID="fullList"
                  block
                  transparent
                  nationalityOther
                  setup
                  onPress={this.toggleSomethingElse}
                >
                  <Text>{I18n.t("appStrings.LBL_SOMETHING_ELSE")}</Text>
                </Button>
              </Content>
            </>
          )}
        </Fragment>
      );
    }
  }
);

export default JobFunction;
