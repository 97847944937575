import types from "./types";
import store from "../store";
import { inlineGET as GET } from "../axios";
import config from "react-native-config";
import { isOffline } from "../offline";

export async function getCommunityProfiles(countryCode, forceDownload) {
  const community = store.getState().communityProfiles;
  const expireTime = new Date(
    community.lastRefresh + 60000 * config.COMMUNITY_PROFILES_CACHE_TTL
  );

  if (community.profiles && Object.keys(community.profiles).length == 0 && !(await isOffline())) {
    forceDownload = true;
  }

  if (forceDownload || Date.now() > expireTime) {
    const communityProfiles = await GET(`/community/${countryCode}/profiles`);
    store.dispatch({ type: types.GET_COMMUNITY_PROFILES, communityProfiles });
  }
}
