import React, { Component } from "react";
import { Screen } from "../../utils";
import { List, ListItem, Text, Icon, Content } from "native-base";
import I18n from "../../i18n";
import {
  setActiveNavigator,
  setChildHeader
} from "../mainTabNavigator/actions";
import { cachedGET } from "../../axios";
import { EMPLOYERS_TIMEOUT } from "../../const";
import { connect } from "react-redux";
import { AndroidBackHandler } from "react-navigation-backhandler";

export default Screen(
  connect(state => ({
    issueTypes: state.issueTypes,
    settings: state.settings,
    rootModalOpen: state.tabsReducer.rootModalOpen,
    activeTab: state.tabsReducer.activeTab
  }))(
    class SelectIssueType extends Component {
      state = {
        category: this.props.navigation.getParam("category"),
        currentIssueType: this.props.issueTypes[
          this.props.navigation.getParam("category")
        ]
      };

      componentDidUpdate(prevProps) {
        if (
          (!this.props.settings.projectId ||
            !this.props.settings.projectName) && //||
          // (!this.props.employerId && !this.props.settings.employerName) ||
          // !this.props.settings.employerName) &&
          this.props.activeTab &&
          this.props.activeTab.key === "ReportIssueTab"
        ) {
          this.props.navigation.pop();
          setActiveNavigator(this.props.navigation);
          setChildHeader(null);
        }
      }

      generateCategories(lang) {
        const rootNavigator =
          this.props.screenProps && this.props.screenProps.rootNavigator
            ? this.props.screenProps.rootNavigator
            : null;

        return this.state.currentIssueType.map((issueType, key) => {
          return (
            <ListItem
              reportissue
              key={key}
              testID="issuereport"
              onPress={async () => {
                const result = await cachedGET(
                  `/context/projects/${this.props.settings.projectId}`,
                  EMPLOYERS_TIMEOUT
                );
                this.props.navigation.navigate("ReportIssue", {
                  issue: issueType,
                  issueType: issueType.id,
                  employers:
                    (result && result.project && result.project.employers) || []
                });
              }}
            >
              <Icon type="Ionicons" name="md-radio-button-off" />
              <Text>
                {issueType.translations[lang] || issueType.description}
              </Text>
            </ListItem>
          );
        });
      }

      onBackButtonPressAndroid = () => {
        if (this.props.rootModalOpen) {
          return true;
        } else {
          return false;
        }
      };

      render() {
        const [locale] = I18n.currentLocale().split("-");
        return (
          <AndroidBackHandler onBackPress={this.onBackButtonPressAndroid}>
            <Content>
              <List>
                <ListItem reportissue itemHeader>
                  <Text>{I18n.t("appStrings.PROMPT_ISSUE_IS")}</Text>
                </ListItem>
                {this.generateCategories(locale)}
              </List>
            </Content>
          </AndroidBackHandler>
        );
      }
    }
  )
);
