import React, { Component, Fragment } from "react";
import {
  Header,
  Body,
  Left,
  Button,
  Icon,
  Right,
  Title,
  Text
} from "native-base";
import {
  View,
  Dimensions,
  Text as RNText,
  Alert,
  BackHandler,
  Linking,
  I18nManager,
  Platform,
  Image
} from "react-native";
import { StackActions, NavigationActions } from "react-navigation";
import { TabView, TabBar } from "react-native-tab-view";
import I18n from "../i18n";
import store from "../store";
import styles from "../styles";
import * as style from "../screens/mainTabNavigator/style";
import { connect } from "react-redux";
import variables from "../../native-base-theme/variables/platform";
import { setActiveTab } from "../screens/mainTabNavigator/actions";

import Animated, { Easing } from "react-native-reanimated";

const initialLayout = {
  height: 0,
  width: Dimensions.get("window").width
};
const routes = [
  {
    key: "WhatsNewTab",
    label: "appStrings.TITLE_WHATSNEW",
    icon: style.Icons.whatsnew
  },
  {
    key: "ResourcesTab",
    label: "appStrings.TITLE_INFORMATION",
    icon: style.Icons.resources
  },
  {
    key: "ReportIssueTab",
    label: "appStrings.TITLE_REPORT_ISSUE",
    icon: style.Icons.reportissue
  },
  Platform.OS !== "web" && {
    key: "SettingsTab",
    label: "appStrings.TITLE_SETTINGS",
    icon: style.Icons.settings
  }
].filter(item => item !== false);

const routeStack = {
  WhatsNewTab: [],
  ResourcesTab: [],
  ReportIssueTab: [],
  SettingsTab: []
};

export function resetStack(key) {
  if (!key) return;
  if (routeStack[key]) routeStack[key] = [];
}

export function changeStack(key, target) {
  if (!key) return;
  const stack = routeStack[key];
  const i = stack.indexOf(stack.find(r => r.routeName == target.routeName));
  if (i < 0 && target.routeName != key) {
    stack.push(target);
  } else if (i < stack.length - 1) {
    stack.splice(i, stack.length - i);
  }
}

export default class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  shouldComponentUpdate() {
    return false;
  }

  state = {
    index: routes.indexOf(
      routes.find(route => route.key == this.props.route) || routes[0]
    ),
    routes
  };

  componentDidMount() {
    if (typeof this.props.getRef == "function") {
      this.props.getRef(this.changeTab);
    }
  }

  changeTab = routeName => {
    const index = routes.indexOf(
      routes.find(route => route.key == routeName) || routes[0]
    );
    this._handleIndexChange(index);
  };

  _renderHeader = props => {
    return (
      <TabBar
        {...props}
        {...style.TabBar}
        onTabPress={({ route }) => {
          const index = routes.indexOf(route);
          const active = index == this.state.index;
          this.setState({ index }, () => {
            const key = routes[index].key;
            const stack = routeStack[key];
            if (active) {
              this.props.navigate(key);
            } else {
              this.props.dispatch(
                StackActions.reset({
                  index: stack.length,
                  actions: [
                    NavigationActions.navigate({ routeName: key, key: null }),
                    ...(stack || []).map(r =>
                      NavigationActions.navigate({ ...r, key: null })
                    )
                  ]
                })
              );
            }
          });
        }}
        indicatorStyle={style.TabBar.indicatorStyle}
        // renderIndicator={this._renderIndicator}
        renderLabel={({ route }) => {
          const index = this.state.routes.indexOf(route);
          const active = index == this.state.index;
          const icon = route.icon(active);
          return (
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Image style={style.Label.Icon(active)} source={icon.source} />
              {/**
              <Icon
                type={icon.type}
                name={icon.name}
                style={style.Label.Icon(active)}
              />
               */}
              <Text numberOfLines={1} style={style.Label.Text(active)}>
                {I18n.t(route.label)}
              </Text>
            </View>
          );
        }}
      />
    );
  };

  _renderIndicator = props => {
    const { width, position } = props;
    const translateX = I18nManager.isRTL
      ? Animated.add(
          Animated.interpolate(position, {
            inputRange: [0, 1],
            outputRange: [0, -width]
          }),

          Platform.OS == "ios" ? 0 : 0
        )
      : position.interpolate({
          inputRange: [0, 1],
          outputRange: [0, width]
        });

    return (
      <Animated.View
        style={[
          style.TabBar.indicatorStyle,
          { width, transform: [{ translateX }] }
        ]}
      />
    );
  };

  _handleIndexChange = index => {
    if (this.elementRef.current != null) {
      this.setState({ index });
    }
    setActiveTab(routes[index]);
  };

  _renderHeaderTitle = title => {
    if (!title) return "";
    if (typeof title == "string") {
      return I18n.t(title);
    }
    if (typeof title == "function") {
      return I18n.t(title(this.props));
    }
  };

  render() {
    const { goBack, hasBack, title, getTitle } = this.props;
    return (
      <View
        style={{
          height:
            Platform.OS == "ios" ? (variables.isIphoneX ? 199 : 149) : 104,
          maxWidth: Platform.OS === "web" && 1200,
          width: "100%",
          alignSelf: "center"
        }}
      >
        <Header hasTab>
          {hasBack && (
            <Left style={{ flex: Platform.OS == "web" ? -1 : 0 }}>
              <View style={{ width: 50 }}>
                <Button onPress={() => goBack(null)} transparent>
                  <Icon
                    name={I18n.isRTL ? "arrow-forward" : "arrow-back"}
                    style={{
                      lineHeight: 28,
                      height: 28
                    }}
                  />
                </Button>
              </View>
            </Left>
          )}
          <Body style={{ flex: 1 }}>
            <Text numberOfLines={1}>
              {this._renderHeaderTitle(getTitle || title)}
            </Text>
          </Body>
          {hasBack && (
            <Right style={{ flex: 0 }}>
              <View style={{ width: 50 }} />
            </Right>
          )}
        </Header>
        <TabView
          ref={this.elementRef}
          navigationState={this.state}
          renderScene={({ route }) => <Text>{route.key}</Text>}
          renderTabBar={this._renderHeader}
          onIndexChange={this._handleIndexChange}
          initialLayout={initialLayout}
        />
      </View>
    );
  }
}
