import React, { Component, Fragment } from "react";
import { Screen } from "../../utils";
import {
  Content,
  Card,
  CardItem,
  Text,
  H1,
  H2,
  H3,
  Icon,
  View,
  ListItem,
  Button
} from "native-base";
import i18n from "../../i18n";
import { GET } from "../../axios";
import { connect } from "react-redux";
import { setEmployer, setSetupStatus } from "../../actions/settings";
import { setChildHeader } from "../mainTabNavigator/actions";
import {
  TouchableWithoutFeedback,
  FlatList,
  StyleSheet,
  Dimensions
} from "react-native";
import FormInput from "../../components/FormInput";
import SearchInput, { createFilter } from "react-native-search-filter";
import wcColors from "../../../native-base-theme/variables/wcColors";

export default Screen(
  connect(state => ({
    projectId: state.settings.projectId,
    projectName: state.settings.projectName,
    employer: state.settings.employerName
  }))(
    class SetEmployer extends Component {
      state = {
        employers: this.props.navigation.getParam("employers", null),
        searchTerm: "",
        searchInputBlurred: true,
        employerListThreshold: 10,
        displaySearchHelp: false
      };

      async componentDidMount() {
        if (!this.state.employers) {
          setChildHeader("appStrings.LBL_YOUR_EMPLOYER");
          const result = await GET(`/context/projects/${this.props.projectId}`);
          this.setState({
            employers:
              (result && result.project && result.project.employers) || []
          });
        }
      }

      setEmployerAndNavigate = async employer => {
        setEmployer(employer);
        await setSetupStatus(true);
        this.props.navigation.navigate("SettingsTab");
      };

      toggleSearchHelp = () => {
        this.setState({
          displaySearchHelp: !this.state.displaySearchHelp
        });
      };

      updateSearchTerm = searchTerm => {
        this.setState({ searchTerm });
      };

      clearSearchTerm = () => {
        this.setState({ searchTerm: "" });
      };

      handleSearchInputFocused = () => {
        this.setState({ searchInputBlurred: false });
      };

      handleSearchInputBlurred = () => {
        this.setState({ searchInputBlurred: true });
      };

      renderEmptyResults = () => {
        return (
          <View
            style={{
              height: 50,
              flex: 1,
              justifyContent: "center",
              backgroundColor: wcColors.setEmployer.emptyResult.background
            }}
          >
            <Text style={{ alignSelf: "center" }}>
              {i18n.t("appStrings.EMPLOYERSEARCH_EMPTY")}
            </Text>
          </View>
        );
      };

      renderFullList = filteredEmployers => {
        const fullList = filteredEmployers || [];
        const orderedList = fullList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        return (
          <FlatList
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="always"
            contentContainerStyle={{
              borderWidth: StyleSheet.hairlineWidth,
              borderBottomWidth: 0,
              borderColor: wcColors.setEmployer.list.border,
              backgroundColor: wcColors.setEmployer.list.background,
              marginBottom: 20
            }}
            data={orderedList}
            keyExtractor={item => item.company_id}
            ListEmptyComponent={this.renderEmptyResults}
            renderItem={({ item }) => (
              <ListItem
                key={item.company_id}
                onPress={() => {
                  this.setEmployerAndNavigate(item);
                }}
              >
                <Text employerList key={item.company_id}>
                  {item.name}
                </Text>
              </ListItem>
            )}
          />
        );
      };

      renderSearchHelp = () => {
        return (
          <Content>
            <H2 setup>{i18n.t("appStrings.LBL_EMPLOYERSEARCH")}</H2>
            <View
              style={{ flex: 1, flexDirection: "column", alignItems: "center" }}
            >
              <View
                style={{ flex: 1, flexDirection: "row", alignItems: "center" }}
              >
                <Icon
                  name="lightbulb-on"
                  type="MaterialCommunityIcons"
                  style={{
                    color: wcColors.setEmployer.searchHelp.icon,
                    paddingHorizontal: 10
                  }}
                />
                <Text
                  settingsLabel
                  left
                  style={{ width: 290, paddingLeft: 10 }}
                >
                  {i18n.t("appStrings.PROMPT_YOUR_EMPLOYER_DESCRIPTION")}
                </Text>
              </View>
            </View>
            <View>
              <FormInput
                settingsFormInput
                onSubmit={value => {
                  if (value && value.trim())
                    this.setEmployerAndNavigate({ name: value.trim() });
                }}
                placeholder={i18n.t("appStrings.LBL_EMPLOYER_NAME")}
                buttonText={i18n.t("appStrings.LBL_OK")}
              />
            </View>
            <Button
              block
              transparent
              skipProject
              onPress={() => {
                this.toggleSearchHelp();
                this.clearSearchTerm();
              }}
            >
              <Text uppercase={false}>{i18n.t("appStrings.LBL_CANCEL")}</Text>
            </Button>
          </Content>
        );
      };

      renderHelpButton = () => {
        return (
          <Button
            block
            transparent
            employerHelp
            onPress={() => {
              this.clearSearchTerm();
              this.toggleSearchHelp();
            }}
          >
            <Text uppercase={false}>
              {i18n.t("appStrings.EMPLOYERSEARCH_BUTTON")}
            </Text>
          </Button>
        );
      };

      render() {
        const {
          searchTerm,
          searchInputBlurred,
          employerListThreshold,
          displaySearchHelp
        } = this.state;
        let employers = this.state.employers || [];
        const filteredEmployers = employers.filter(
          createFilter(searchTerm, ["name"])
        );

        const windowHeight = Dimensions.get("window").height;

        return (
          <Content>
            <View padder>
              {displaySearchHelp && this.renderSearchHelp()}
              {!displaySearchHelp && (
                <Fragment>
                  {searchInputBlurred && (
                    <H3 employerSearchProject>{this.props.projectName}</H3>
                  )}
                  <H3 employerSearch>
                    {i18n.t("appStrings.PROMPT_YOUR_EMPLOYER")}
                  </H3>
                  {searchInputBlurred && (
                    <Fragment>
                      <Text employer>
                        {i18n.t("appStrings.PROMPT_YOUR_EMPLOYER_DESCRIPTION")}
                      </Text>
                    </Fragment>
                  )}
                  {employers.length > employerListThreshold && (
                    <Fragment>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          marginBottom: 10
                        }}
                      >
                        <View
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.icon.background,
                            justifyContent: "center",
                            paddingLeft: 20,
                            paddingRight: 0,
                            borderBottomLeftRadius: 150,
                            borderTopLeftRadius: 150
                          }}
                        >
                          <Icon
                            name="search"
                            fontSize={40}
                            style={{ color: wcColors.setEmployer.icon.text }}
                          />
                        </View>
                        <SearchInput
                          onChangeText={term => {
                            this.updateSearchTerm(term);
                          }}
                          placeholder={i18n.t(
                            "appStrings.EMPLOYERSEARCH_PLACEHOLDER"
                          )}
                          throttle={1}
                          fuzzy={true}
                          sortResults={true}
                          onFocus={this.handleSearchInputFocused}
                          onBlur={this.handleSearchInputBlurred}
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.searchInput.background,
                            height: 50,
                            width: 250,
                            fontSize: 16,
                            alignSelf: "center"
                          }}
                          placeholderTextColor={
                            wcColors.setEmployer.searchInput.placeholder
                          }
                        />
                        <View
                          style={{
                            backgroundColor:
                              wcColors.setEmployer.searchInput.iconBackground,
                            justifyContent: "center",
                            paddingLeft: 0,
                            paddingRight: 20,
                            borderBottomRightRadius: 150,
                            borderTopRightRadius: 150
                          }}
                        >
                          <Icon
                            name="search"
                            fontSize={40}
                            style={{
                              color: wcColors.setEmployer.searchInput.icon,
                              opacity: 0.0
                            }}
                          />
                        </View>
                      </View>
                    </Fragment>
                  )}

                  {this.renderFullList(filteredEmployers)}
                  {this.renderHelpButton()}
                  {!searchInputBlurred && searchTerm.length !== 0 && (
                    <View style={{ height: windowHeight * 0.45 }} />
                  )}
                </Fragment>
              )}
            </View>
          </Content>
        );
      }
    }
  )
);
