import rights from "../variables/rights";
import types from "../actions/types";

const initialState = filterRights(rights);

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RIGHTS:
      return {
        ...filterRights(action.rights)
      };
    default:
      return state;
  }
};

export function filterRights(rights) {
  return rights.reduce((prev, next) => {
    if (prev[next.category]) {
      return {
        ...prev,
        [next.category]: [...prev[next.category], next]
      };
    } else {
      return {
        ...prev,
        [next.category]: [next]
      };
    }
  }, {});
}
