export default {
  NAV_BACK: "Back",
  WELCOME_TITLE: "Welcome to De Gardă",
  WELCOME_DESCRIPTION_1:
    "Worker Connect was developed for you, a worker living in Dubai and working on construction projects.",
  WELCOME_DESCRIPTION_2:
    "Use this app to learn about your rights, to find resources to help you with questions or problems, and to provide feedback to people who care about improving your working conditions.",
  WELCOME_DESCRIPTION_3:
    "This app is completely anonymous. When you report an issue, no one will know who sent it, but people who care will learn what isn’t going well for you. And that’s important information to share.",
  WELCOME_GETSTARTED: "Let's cure the system",
  PROMPT_WHERE_FROM: "Where are you from?",
  PROMPT_WHERE_FROM_DESCRIPTION:
    "Please tell us where you’re from so we can give you information you care about.",
  LBL_SOMEWHERE_ELSE: "Somewhere else...",
  PROMPT_WHAT_JOB_FUNCTION: "What is your job function?",
  PROMPT_WHAT_JOB_FUNCTION_DESCRIPTION:
    "Please tell us what is your job function so we can give you information you care about.",
  LBL_JOB_FUNCTION_NAME: "Job function",
  LBL_SOMETHING_ELSE: "Something else...",
  PROMPT_PROJECT_CODE_DESCRIPTION:
    "You should have received a code at your work site to use this app.",
  LBL_SKIP_THIS: "I don't want to connect to a project right now",
  LBL_OR: "or",
  PROMPT_YOUR_EMPLOYER: "Who is your employer?",
  PROMPT_YOUR_EMPLOYER_DESCRIPTION:
    "When we know your employer, we can give you information that is specific to your work project.",
  PROMPT_OTHER_EMPLOYER: "If you don't see your employer, enter it here.",
  LBL_OK: "Ok",
  LBL_EMPLOYER_NAME: "Employer Name",
  TITLE_WHATSNEW: "What's New",
  GREETING_WELCOME: "Welcome to De Gardă",
  GREETING_MORNING: "Good Morning!",
  GREETING_AFTERNOON: "Good Afternoon!",
  GREETING_EVENING: "Good Evening!",
  MSG_DISCONNECTED: "You are not connected to the internet.",
  MSG_LAST_CHECKED: "Last checked for posts:",
  MSG_CONNECT_FOR_FEED: "Connect to the internet to view more posts.",
  MSG_OPINION_MATTERS: "Your opinion matters",
  LBL_UPDATES: "Updates",
  MSG_UPDATES_PLACEHOLDER: "Check back for new items on this page soon.",
  TITLE_INFORMATION: "Resources",
  TITLE_INFO_RESOURCES: "Information and Resources",
  COMMUNITY_RESOURCES_TITLE: "Resources for workers from {{COUNTRY_NAME}}",
  COMMUNITY_RESOURCES_DESCRIPTION:
    "Here are some resources from your community who can assist you if you have problems or questions.",
  COMMUNITY_RESOURCES_NOINFO: "Check back for new resources on this page soon.",
  COMMUNITY_RESOURCES_NOTDOWNLOADED: "Resources have not yet been downloaded.",
  REFERENCE_OTHER_LANGUAGES: "Other languages",
  HELP_ISSUEREPORT_TITLE: "Reporting Issues",
  HELP_ISSUEREPORT_DESC:
    "Everything you report is anonymous and safe. Your feedback helps identify issues that need attention.",
  //  HELP_ISSUEREPORT_DESC_2:
  //    "When you report an issue, the information you send is received by people who care about your safety and welfare.",
  //  HELP_ISSUEREPORT_DESC_3:
  //    "Your feedback helps identify issues that need attention.",
  PROMPT_REPORT_ISSUE_ABOUT: "I want to report an issue about:",
  PROMPT_ISSUE_IS: "The issue is:",
  TITLE_REPORT_ISSUE: "Report an Issue",
  CATEGORY_FOOD: "The food I eat",
  CATEGORY_DIGNITY_RESPECT: "Dignity and respect",
  CATEGORY_RECRUIT_DOCS: "Recruitment and documents",
  CATEGORY_WAGES: "Getting paid",
  CATEGORY_HEALTH_SAFETY: "My health and safety",
  CATEGORY_WORKSITE: "My work site",
  CATEGORY_TRANSPORT: "Traveling to my work site",
  CATEGORY_ACCOMMODATIONS: "Where I live",
  CATEGORY_RETURNHOME: "Returning to my home country",
  LBL_CANCEL: "Cancel",
  LBL_CLOSE: "Close",
  LBL_PROJECT: "Project",
  LBL_EMPLOYER: "Employer",
  LBL_COMMENTS: "Comments",
  LBL_COMMENTS_PLACEHOLDER: "Provide additional information (optional)",
  LBL_SUBMIT_REPORT: "Submit issue anonymously",
  LBL_SUBMIT_POLL_RESPONSE: "Submit response",
  TITLE_SETTINGS: "Settings",
  LBL_YOUR_PROJECT: "Your project",
  LBL_CHANGE_PROJECT: "Choose a different project",
  PROMPT_CHOOSE_LANGUAGE: "Choose a language",
  LBL_CHANGE_LANGUAGE: "Change language",
  LBL_LANGUAGE: "Language",
  LBL_NATIONALITY: "Nationality",
  LBL_JOB_FUNCTION: "Job Function",
  LBL_YOUR_EMPLOYER: "Your employer",

  NAV_NEXT: "Next",
  NAV_CONTINUE: "Continue",
  LBL_SUBMIT_REPORT_HELP_LINK: "What happens when I submit?",
  SUBMIT_REPORT_HELP_CONTENT:
    "The notification will reach the Funky Citizens team. No one will know you sent the complaint.",
  LBL_COMMENTS_REQUIRED_PROMPT: "Describe the issue below:",
  LBL_COMMENTS_PLACEHOLDER_REQUIRED: "My issue is... ",
  LBL_COMMENTS_REQUIRED_ERROR_TITLE:
    "We need to know a little bit more information",
  LBL_COMMENTS_REQUIRED_ERROR:
    "Please write a description of your issue before submitting.",
  LBL_EMPLOYER_REQUIRED_ERROR_TITLE:
    "We need to know a little bit more information",
  LBL_EMPLOYER_REQUIRED_ERROR: "Please select your hospital.",
  POST_SUBMIT_MESSAGE_TITLE: "You submitted an issue anonymously",
  POST_SUBMIT_MESSAGE_HOTLINE_TITLE:
    "Would you like to talk to someone about this issue?",
  POST_SUBMIT_MESSAGE:
    "Thank you for your time. You will not be contacted in regards to the issue submitted, but we will take it into account.",
  POST_SUBMIT_MESSAGE_HOTLINE:
    "You can call the Expo 2020 Worker Hotline to speak to someone anonymously.",
  SETUP_OFFLINE_ERROR_TITLE: "No internet connection",
  SETUP_OFFLINE_ERROR:
    "You are not connected to the internet. Please try again.",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_TITLE:
    "Enter your Expo Worker Connect project code",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_DESCRIPTION:
    "If you were provided an Expo Worker Connect project code, enter it here to anonymously report issues to your project's worker welfare team.",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_NO_CODE:
    "If you don't have a project code, go to Resources and learn more about your options for finding help.",
  PROMPT_READMORE: "Read more",
  LBL_ALERTS_WARNING_DIVIDER_TEXT: "Alerts",
  LBL_ALERTS_INFO_DIVIDER_TEXT: "Notices",
  LBL_POLLS_DIVIDER_TEXT: "Your Opinion Matters",
  LBL_FEED_DIVIDER_TEXT: "Updates",
  LBL_RESOURCES_EMBASSY: "Embassy",
  LBL_RESOURCES_PEOPLE: "People",
  LBL_RESOURCES_ORGANIZATIONS: "Organizations",
  LBL_RESOURCES_GUIDES: "Guides",
  PROJECT_SCAN_CODE: "Scan a code",
  PROJECT_SKIP_PROMPT: "I don't want to connect to a project right now",
  WELCOME_PANEL_1_TITLE:
    "THANK YOU FOR HELPING US TAKE AN X-RAY OF YOUR HOSPITAL, DIRECTLY FROM THE SOURCE.", // FUNKY CITIZENS
  WELCOME_PANEL_1_TEXT:
    "To change what doesn't work, we need to put pressure on decision makers. The first step is to talk about malfunctions.", // FUNKY CITIZENS
  WELCOME_PANEL_2_TITLE: "HOW DOES THIS WORK?", // FUNKY CITIZENS
  WELCOME_PANEL_2_TEXT_1:
    "You use the application: you tell us what problems there are or you are informed about your rights as an employee in the healthcare field.", // FUNKY CITIZENS
  WELCOME_PANEL_2_TEXT_2:
    "We take your complaints and send them to investigative journalists. If we notice a systemic problem, we put pressure on the authorities to solve it.", // FUNKY CITIZENS
  WELCOME_PANEL_3_TITLE: "CAN I TRUST YOU?", // FUNKY CITIZENS
  WELCOME_PANEL_3_TEXT_1:
    "Absolutely. The trust you place in us honors us and forces us to have an even more developed sense of responsibility.", // FUNKY CITIZENS
  WELCOME_PANEL_3_TEXT_2:
    "We are a responsible organization with an 8 year history in which we have often collaborated with the press on sensitive and technical topics.", // FUNKY CITIZENS

  WELCOME_PANEL_4_TITLE: "Welcome screen #4", // FUNKY CITIZENS
  WELCOME_PANEL_4_TEXT:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit nibh vel cursus interdum. Nullam hendrerit diam nec orci malesuada feugiat.", // FUNKY CITIZENS
  POLL_RESPONSE_SUBMITTED_TITLE: "Thank you",
  POLL_RESPONSE_SUBMITTED_TEXT: "Your response has been submitted.",
  POLL_RESPONSE_ERROR_TITLE: "Error",
  POLL_RESPONSE_ERROR_TEXT: "There was an error submitting your poll response.",
  POLL_TITLE: "Poll",
  POLL_DECLINE: "I don't want to answer this",
  POST_AUTHOR_TIMESTAMP: "Posted {{DATE_AND_TIME}} by {{AUTHOR_NAME}}",
  KNOW_YOUR_RIGHTS_ATTRIBUTION:
    "This guide was developed by Migrant-Rights.org",
  KNOW_YOUR_RIGHTS_DISCLAIMER:
    "The Content belongs to Migrant-Rights.org, and all due process was followed to ensure the accuracy of the content.  However, Migrant-Rights.org accepts no liability arising from any errors or omissions. Migrant-Rights.org should be made aware of any modifications to content made by Licensee. Licensee is responsible for ensuring it has the most up to date information. ",
  RIGHTS_DETAIL_WHAT_IS_YOUR_RIGHT: "What is your right?",
  RIGHTS_DETAIL_CITATION: "What does the law say?",
  RIGHTS_DETAIL_CALL_TO_ACTION: "Do you have a problem?",
  RIGHTS_DETAIL_REPORT_ISSUE: "Report an issue",
  EMPLOYER_SETUP_ERROR_TITLE: "Error",
  EMPLOYER_SETUP_ERROR_TEXT:
    "There was an error retrieving the list of hospitals.",
  PROJECT_SETUP_ERROR_TITLE: "Error",
  PROJECT_SETUP_ERROR_TEXT:
    "There was an error retrieving information about your project.",
  REPORT_ISSUE_ERROR_TITLE: "Error",
  REPORT_ISSUE_ERROR_TEXT: "There was an error submitting your issue.",
  LANGUAGE_ERROR_TITLE: "Error",
  LANGUAGE_ERROR_TEXT: "Unable to switch languages.",
  BARCODE_SCAN_PROMPT: "Point the camera to the project code to scan it",

  PROMPT_PROJECT_CODE: "Connect to a project",
  LBL_PROJECT_CODE_DESCRIPTION: "Or type your project code here:",
  LBL_PROJECT_CODE: "Or type your project code here:",
  LBL_CONNECT_TO_PROJECT: "Connect",
  CATEGORY_YOUR_COMMUNITY: "Helpful Resources",
  CATEGORY_DESC_YOUR_COMMUNITY:
    "The law is your main ally. If you want to read the relevant laws in full, you can do so here.", // FUNKY CITIZENS
  CATEGORY_YOUR_PROJECT: "About Funky Citizens", // FUNKY CITIZENS
  CATEGORY_DESC_YOUR_PROJECT:
    "Find out who we are and how we came to create this app.", // FUNKY CITIZENS
  CATEGORY_YOUR_RIGHTS: "Know Your Rights",
  CATEGORY_DESC_YOUR_RIGHTS:
    "Find out what your rights are, both as a citizen and as a healthcare employee.", // FUNKY CITIZENS
  CATEGORY_HOTLINE: "Hotline",
  CATEGORY_DESC_HOTLINE: "Learn about the confidential hotline",
  PROMISES_GUIDE_TITLE: "Whistleblowing", // FUNKY CITIZENS
  PROMISES_GUIDE_INFO:
    "All you need to know about whistleblowers, in a nutshell.", // FUNKY CITIZENS
  CATEGORY_WHISTLEBLOWING: "Whistleblowing", // FUNKY CITIZENS
  CATEGORY_DESC_WHISTLEBLOWING:
    "All you need to know about whistleblowers, in a nutshell.", // FUNKY CITIZENS
  RIGHTS_GUIDE_TITLE: "Know Your Rights",
  RIGHTS_GUIDE_INTRO:
    "NOTE: This document sets out some but not all of a worker's rights as set out in the Qatar Labour and Immigration Laws; there are additional rights. Workers should attend the Labour Department (taking all employment documents) with any questions they have in relation to these or their other legal and regulatory rights.",
  LBL_ABOUT_TITLE: "About De Gardă",
  ABOUT_POWEREDBY: "De Gardă is powered by Worker Connect",
  ABOUT_CONTENT: "Copyright © 2021 Caravan Studios, a division of TechSoup",
  ABOUT_CONTENT_KYR_ATTRIBUTION:
    "The Know Your Rights section of this app includes the following content developed by Migrant-Rights.org:",
  ABOUT_CONTENT_KYR_MR_TITLE: "Know Your Rights (UAE)",
  ABOUT_CONTENT_KYR_MR_COPYRIGHT: "Copyright © 2018 Migrant-Rights.org",
  ABOUT_PRIVACY_LINK: "Privacy Policy",
  MSG_CONNECT_TO_DIFFERENT_PROJECT: "Connect to a different project...",
  MSG_NOT_CONNECTED_TO_PROJECT: "You are not connected to a project",
  MSG_CONNECT_TO_PROJECT: "Connect to a project...",
  MOOD_RESPONSE_QUESTION: "How do you feel about your work today?", // FUNKY
  RIGHTS_GUIDE_UAE_LAW_HEADER:
    "As a worker in the UAE, you are provided these rights by law:",
  RIGHTS_GUIDE_EXPO_STANDARDS_HEADER:
    "As a worker on the Expo 2020 project, you are provided the following benefits:",
  RIGHTS_GUIDE_CATEGORY_GETTINGPAID: "Getting paid",
  RIGHTS_GUIDE_CATEGORY_OVERTIME: "Overtime",
  RIGHTS_GUIDE_CATEGORY_WORKHOURS: "Work hours and holidays",
  RIGHTS_GUIDE_CATEGORY_DOCUMENTS: "Passports and personal documents",
  RIGHTS_GUIDE_CATEGORY_CONTRACTS: "Employment contracts",
  RIGHTS_GUIDE_CATEGORY_LEAVE: "Annual leave",
  RIGHTS_GUIDE_CATEGORY_FEMALE: "Female employees",
  RIGHTS_GUIDE_CATEGORY_MEDICAL: "Medical care",
  RIGHTS_GUIDE_CATEGORY_ACCOMMODATIONS: "Where I live",
  RIGHTS_GUIDE_CATEGORY_COOKINGEATING: "Cooking and eating",
  RIGHTS_GUIDE_CATEGORY_RECRUITMENT: "Recruitment",
  RIGHTS_GUIDE_CATEGORY_ISSUES: "Reporting Issues",
  RIGHTS_GUIDE_CATEGORY_COMMUNICATION: "Worker orientation and communication",
  MSG_INACTIVE_PROJECT:
    "The De Gardă service is currently unavailable. Please try again later.",
  ACTIVATION_DEEP_LINK_CONFIRM_TITLE:
    "Are you sure you want to connect to a different project?",
  ACTIVATION_DEEP_LINK_SUCCESS: "You have successfully connected to a project",
  CATEGORY_ABOUT_EXPO: "About Expo 2020 Dubai",
  CATEGORY_WORKER_HOTLINE: "Worker Hotline",
  CATEGORY_DESC_WORKER_HOTLINE:
    "Register a complaint over the phone and speak to someone who will listen to your issues.",
  POLL_ACTION: "Answer",
  LBL_LEARN_MORE_HOTLINE: "Learn about the confidential hotline",
  HOTLINE_DESCRIPTION_1:
    "Expo 2020 has a confidential hotline that you can call, where you can safely register your complaint by speaking to someone who will listen to your issues.",
  HOTLINE_DESCRIPTION_2:
    "You will be anonymous and you do not have to leave your name, but you must tell them you are on Expo 2020 and you should tell them the name of your Employer.",
  HOTLINE_DESCRIPTION_3: "Your complaint will be reported to Expo 2020 only.",
  HOTLINE_IS_FREE_DESCRIPTION:
    "This is a free number. You will not be charged for calling the Expo hotline.",
  LBL_HOTLINE_CALL_NOW: "Call now",
  HOTLINE_HEADER: "Expo 2020 Worker Hotline",
  HOTLINE_HEADER_SHORT: "Hotline",
  EXPO_2020_DUBAI: "Expo 2020 Dubai",
  ABOUT_EXPO_1:
    "The East Bridge Reconstruction Project will improve safety and traffic conditions for over 100,000 vehicles, pedestrians, and bicyclists that use the bridge each day.",
  ABOUT_EXPO_2:
    "Your hard work will make all of this possible. You are one of the thousands of workers working on this project and we’re proud you’re a part of it.",
  NETWORK_ERROR: "Network Error",
  LBL_EMPLOYERSEARCH: "Type in your employer name",
  EMPLOYERSEARCH_DESC: "You can find your employer on your Expo ID card",
  EMPLOYERSEARCH_BUTTON: "I don't see my employer name",
  EMPLOYERSEARCH_PLACEHOLDER: "Search",
  EMPLOYERSEARCH_EMPTY: "No companies found",
  HOTLINE_NUMBER_WORD: "800-WORKER",
  HOTLINE_NUMBER_NUMERAL: "800 967537",

  // FUNKY CITIZENS
  CATEGORY_EQUIPMENT_CONSUMABLES: "Equipment and consumables",
  CATEGORY_CLEANING_PRODUCTS: "Cleaning products",
  CATEGORY_HOSPITAL_WORK: "Hospital work",
  CATEGORY_CORRUPTION: "Corruption",
  CATEGORY_COVID: "COVID-19",
  RIGHTS_GUIDE_CATEGORY_FUNDAMENTAL: "Fundamental rights",
  RIGHTS_GUIDE_CATEGORY_EMPLOYEE: "Worker rights",
  RIGHTS_GUIDE_CATEGORY_WHISTLEBLOWER: "Whistleblower rights",
  RIGHTS_GUIDE_CATEGORY_ADMIN_PROFESSIONAL:
    "Professional and administrative rights",
  RIGHTS_GUIDE_ROMANIA_LAW_HEADER:
    "As a health worker in Romania, you are provided these rights by law:",
  ABOUT_FUNKY_1:
    "Hi! We’re Funky Citizens, an NGO that works in the field of good governance. Since 2012, we have been campaigning for transparency, efficiency and, if possible, competence in public institutions.",
  ABOUT_FUNKY_2:
    "We are experts in public administration, anti-corruption and public money. We develop civic education programs to prepare tomorrow's citizens, today. We know how to look at public budgets like no one else.",
  ABOUT_FUNKY_3:
    "If you want to know more about us, you can drop a line at weare@funkycitizens.org - we promise to respond promptly.",
  ABOUT_PROJECT_TITLE: "About this project",
  ABOUT_PROJECT_DESC_1:
    'The "De Gardă" app is an adaptation of a similar app - Worker Connect - developed by Caravan Studios, a division of Techsoup Global, an association that makes social change through technology.',
  ABOUT_PROJECT_DESC_2:
    "The idea for the application came to us in the spring of 2020, in the chaos of the beginning of the pandemic. We want to solve as many problems in the system together. But the first step is to find out about them.",
  ABOUT_FEEDBACK: "Send us feedback",
  HOSPITAL: "Hospital",
  ENTER_HOSPITAL_NAME: "Enter a hospital name",
  ENTER_HOSPITAL_NAME_OPTIONAL: "Enter a hospital name (optional)",

  PDF_LOADING_ERROR: "Failed to load PDF file!",
  UNEXPECTED_ERROR: "Unexpected Error",
  NEED_TO_RESTART: "Need to restart",
  RESTART: "Restart"
};
