import React, { Component, Fragment } from "react";
import { StyleSheet, Image, Linking, TouchableOpacity } from "react-native";
import { Screen } from "../../utils";
import {
  Content,
  Card,
  CardItem,
  Body,
  Text,
  Button,
  Icon,
  H2
} from "native-base";
import Hyperlink from "react-native-hyperlink";
import wcColors from "../../../native-base-theme/variables/wcColors";
import FastImage from "react-native-fast-image";
import I18n from "../../i18n";
import moment from "moment";
import { getCurrentIssueById } from "../reportIssueTab/actions";
import { getPostById, getFeed } from "./actions";
import { AndroidBackHandler } from "react-navigation-backhandler";
import { connect } from "react-redux";

export default Screen(
  connect(state => ({
    rootModalOpen: state.tabsReducer.rootModalOpen,
    activeTab: state.tabsReducer.activeTab,
    posts: state.whatsnews.posts,
    offline: state.offline
  }))(
    class PostDetails extends Component {
      constructor(props) {
        super(props);
        let post = this.props.navigation.getParam("post");
        const postId = this.props.navigation.getParam("postId");
        if (!post && postId) {
          getFeed();
          post = this.props.posts.find(it => it.id == postId);
        }
        if (post) {
          this.state = this._stateFromPost(post);
        } else {
          this.state = {};
        }

        if (!this.state.post) {
          if (!this.props.offline) {
            getPostById(this.props.navigation.getParam("postId")).then(post => {
              const state = this._stateFromPost(post);
              if (state) {
                return this.setState(state);
              }
            });
          }
          this.props.navigation.goBack();
        }
      }

      _stateFromPost(post) {
        if (!post) return null;
        return {
          content: post.data && post.data.content,
          properties: post.data && post.data.properties,
          callAction:
            (post.data &&
              post.data.properties &&
              post.data.properties.call_to_action) ||
            null,
          post
        };
      }

      async componentDidUpdate() {
        const postId = this.props.navigation.getParam("postId");
        if (postId && this.state.post && this.state.post.id != postId) {
          const state = this._stateFromPost(
            this.props.posts.find(it => it.id == postId) ||
              (await getPostById(postId))
          );
          if (state) {
            this.setState(state);
          }
        }
      }

      formatPostDate(date) {
        if (date) {
          return moment(date).fromNow();
        } else {
          return "";
        }
      }

      renderActionButtons(callActionOptions, methods) {
        return callActionOptions.map((option, index) => (
          <Button
            key={index}
            testID="report"
            block
            action
            onPress={() => {
              this.attemptAction(methods[index]);
            }}
          >
            <Text>{option}</Text>
          </Button>
        ));
      }

      onBackButtonPressAndroid = () => {
        if (this.props.rootModalOpen) {
          return true;
        } else {
          return false;
        }
      };

      //This method is called when the user have an extra options for the post.

      attemptAction(option) {
        //It's decide which method need to be called

        switch (option.type) {
          case "report":
            //open the detailed modal to report an issue
            const issue = getCurrentIssueById(option.issuetype_id);
            this.props.navigation.navigate("ReportIssue", {
              issue
            });
            break;
          case "link":
            Linking.openURL(option.url);
            break;
          default:
            break;
        }
      }

      render() {
        if (!this.state.post) return null;
        const { content, properties } = this.state;
        const { ext_resource } = this.state.post.data.properties || {};
        const { ext_resource: content_ext_resource } = this.state.content;
        return (
          <AndroidBackHandler onBackPress={this.onBackButtonPressAndroid}>
            <Content padder>
              <Card whatsnew post>
                <CardItem header>
                  {this.state.content.author_image ? (
                    <FastImage
                      style={style.image}
                      source={{
                        uri: this.state.content.author_image,
                        priority: FastImage.priority.normal
                      }}
                      resizeMode={FastImage.resizeMode.cover}
                    />
                  ) : (
                    <Image
                      style={style.image}
                      source={require("../../assets/img/no_author_image.png")}
                    />
                  )}
                  <Body>
                    <Text title>{this.state.content.title}</Text>
                    <Text timestamp>
                      {I18n.t("appStrings.POST_AUTHOR_TIMESTAMP", {
                        DATE_AND_TIME: this.formatPostDate(
                          this.state.post.created_at
                        ),
                        AUTHOR_NAME: this.state.content.author
                          ? this.state.content.author
                          : ""
                      })}
                    </Text>
                  </Body>
                </CardItem>
                {(content.post_image || properties?.post_image) && (
                  <FastImage
                    style={style.postImage}
                    source={{
                      uri: content.post_image || properties?.post_image,
                      priority: FastImage.priority.normal
                    }}
                    resizeMode={FastImage.resizeMode.cover}
                  />
                )}
                <CardItem body>
                  <Body>
                    <Hyperlink
                      linkDefault={true}
                      linkStyle={{ color: wcColors.postDetails.link }}
                    >
                      <Text>{this.state.content.post_details}</Text>
                    </Hyperlink>
                  </Body>
                </CardItem>
                {ext_resource &&
                  ext_resource.type == "video" &&
                  ext_resource.url && (
                    <TouchableOpacity
                      onPress={() =>
                        this.props.navigation.navigate("VideoModal", {
                          url: ext_resource.url
                        })
                      }
                    >
                      <CardItem link>
                        <Icon type="FontAwesome" name="play-circle-o" />
                        <Body>
                          <H2>{content_ext_resource.title}</H2>
                          <Hyperlink
                            linkDefault={true}
                            linkStyle={{ color: wcColors.postDetails.link }}
                          >
                            <Text>{content_ext_resource.description}</Text>
                          </Hyperlink>
                        </Body>
                      </CardItem>
                    </TouchableOpacity>
                  )}
                {ext_resource &&
                  ext_resource.type == "link" &&
                  ext_resource.url &&
                  Linking.canOpenURL(ext_resource.url) && (
                    <TouchableOpacity
                      onPress={() => Linking.openURL(ext_resource.url)}
                    >
                      <CardItem bordered link>
                        <Icon name="open" />
                        <Body>
                          <H2>{content_ext_resource.title}</H2>
                          <Hyperlink
                            linkDefault={true}
                            linkStyle={{ color: wcColors.postDetails.link }}
                          >
                            <Text>{content_ext_resource.description}</Text>
                          </Hyperlink>
                        </Body>
                      </CardItem>
                    </TouchableOpacity>
                  )}
              </Card>
              {this.state.callAction &&
                this.state.content.call_to_action_options && (
                  <Fragment>
                    <Text>{this.state.content.call_to_action_title}</Text>
                    {this.renderActionButtons(
                      this.state.content.call_to_action_options,
                      this.state.callAction.options
                    )}
                  </Fragment>
                )}
            </Content>
          </AndroidBackHandler>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  image: {
    width: 50,
    height: 50
  },
  postImage: {
    height: 400,
    width: "95%",
    alignSelf: "center",
    aspectRatio: 16 / 9
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: wcColors.postDetails.background
  },
  backgroundVideo: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  }
});
