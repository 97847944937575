import React, { Component, Fragment } from "react";
import { TouchableWithoutFeedback, Image } from "react-native";
import { Header, Icon, Text, View, Button, H1, H3 } from "native-base";
import I18n from "../../i18n";
import LinearGradient from "react-native-linear-gradient";
import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";

export default class Welcome1 extends Component {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <Fragment>
        <LinearGradient
            colors={wcColors.welcome.backgroundGradient}
            locations={wcColors.welcome.gradientLocations}
            start={wcColors.welcome.gradientStart}
            end={wcColors.welcome.gradientEnd}
            style={styles.gradient}
        />

        <TouchableWithoutFeedback onPress={() => this.props.navigation.goBack()} >
          <Header hasTabs welcome>
            <Icon name={I18n.isRTL ? "arrow-forward" : "arrow-back"} /><Text numberOfLines={1}>{I18n.t("appStrings.NAV_BACK")}</Text>
          </Header>
        </TouchableWithoutFeedback>

        <View style={{flex:1}}>
          <View style={{flex:1, justifyContent: 'center', alignItems: 'center', paddingHorizontal:20}}>
            <H3 style={{color:'white', textAlign: 'center', fontWeight: 'bold'}}>{I18n.t("appStrings.WELCOME_PANEL_3_TITLE")}</H3>
          </View>
          <View style={{flex:4}}>
            <View style={{flex:1}}>
              <Text style={{padding:20, color:'white', textAlign: 'center'}}>{I18n.t("appStrings.WELCOME_PANEL_3_TEXT_1")}</Text>
            </View>
            <View style={{flex:1}}>
              <Image
                  style={{flex:1, height: undefined, width: undefined, margin:15}}
                  source={require('../../assets/img/welcome/welcome3.png')}
                  resizeMode="contain"
              />
            </View>
            <View style={{flex:1}}>
              <Text style={{padding:20, color:'white', textAlign: 'center'}}>{I18n.t("appStrings.WELCOME_PANEL_3_TEXT_2")}</Text>
            </View>
          </View>
          <View style={{flex:1, justifyContent: 'flex-end', marginBottom: 36}}>
            <Button
              testID={"nextButton"}
              rounded
              welcome
              onPress={() => this.props.navigation.navigate("JobFunction")}
            >
              <Text numberOfLines={1} uppercase={true}>
                {I18n.t("appStrings.WELCOME_GETSTARTED")}
              </Text>
              
            </Button>
          </View>


          
        </View>


      </Fragment>
    );
  }

}
