import React from "react";
import { Platform, TouchableOpacity } from "react-native";
import { Body, Card, CardItem, Left, Text, Thumbnail } from "native-base";
import PDFViewer from "./PDFViewer";
import { setChildHeader } from "../../screens/mainTabNavigator/actions";

export default {
  resources: {
    mainSelector: ({
      routingKey,
      title,
      description,
      thumbnail,
      source
    }) => props => {
      const _title = typeof title == "function" ? title() : title;
      const _description =
        typeof description == "function" ? description() : description;
      return (
        <TouchableOpacity
          key="pdfViewer"
          onPress={() => {
            props.navigation.navigate(routingKey, { source });
            setChildHeader(_title);
          }}
        >
          <Card resourcecard>
            <CardItem>
              <Left>
                <Thumbnail source={thumbnail} />
                <Body>
                  <Text title>{_title}</Text>
                  <Text description>{_description}</Text>
                </Body>
              </Left>
            </CardItem>
          </Card>
        </TouchableOpacity>
      );
    },
    routes: ({ routingKey, pathKey, title, route }) => ({
      [routingKey]: {
        screen: PDFViewer,
        path: pathKey,
        navigationOptions: {
          title,
          route,
          hasBack: true
        }
      }
    })
  }
};
