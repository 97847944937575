import React, { Component } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Linking,
  I18nManager,
  Platform,
  Image
} from "react-native";
import { Screen } from "../../utils";
import { Content, Card, CardItem, Body, H3, Icon, Text } from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";
import HTML from "react-native-render-html";
import { email, phonecall, text } from "react-native-communications";
import wcColors from "../../../native-base-theme/variables/wcColors";

export default Screen(
  connect(state => ({
    settings: state.settings,
    communityProfiles: state.communityProfiles.profiles
  }))(
    class CommunityProfileSummary extends Component {
      constructor(props) {
        super(props);

        this.state = {
          communityProfile:
            props.navigation.getParam("communityProfile") ||
            (
              props.communityProfiles[props.navigation.getParam("type")] || []
            ).find(
              p =>
                p.name ==
                  decodeURIComponent(props.navigation.getParam("name")) ||
                p.id == props.navigation.getParam("name")
            )
        };

        if (!this.state.communityProfile) {
          props.navigation.goBack();
        }
      }

      generateProfile = () => {
        const communityProfile = this.state.communityProfile;
        switch (communityProfile.type) {
          case "embassy":
          case "organization":
          case "person":
            return this.generateViewWithContacts(communityProfile);
            break;
          case "guide":
            return this.generateGuide(communityProfile);
            break;
        }
      };

      generateGuide(profile) {
        const lang = I18n.currentLocale();
        const prof = profile.translations[lang]
          ? profile.translations[lang]
          : profile;
        return (
          <Card communityDetails guide>
            <CardItem title bordered>
              <H3>{prof.name}</H3>
              <Text>{prof.publisher}</Text>
            </CardItem>
            <CardItem description bordered>
              <Text>{prof.description}</Text>
            </CardItem>
            {profile.links.map((link, key) => {
              const currentLang = link.translations[lang]
                ? link.translations[lang]
                : link;
              return (
                <TouchableOpacity
                  key={key}
                  onPress={() => {
                    Linking.canOpenURL(link.url) && Linking.openURL(link.url);
                  }}
                >
                  <CardItem bordered link>
                    <Icon name="open-outline" />
                    <Text style={{ flex: 1 }}>{currentLang.description}</Text>
                  </CardItem>
                </TouchableOpacity>
              );
            })}
          </Card>
        );
      }

      generatePhoneContactItem = (touchFunction, label, value, icon) => {
        return (
          <TouchableOpacity onPress={touchFunction}>
            <CardItem bordered contact>
              {icon && <Icon type="Ionicons" name={icon} />}
              <Body>
                <Text label>{label}</Text>
                <Text value>{value}</Text>
              </Body>
            </CardItem>
          </TouchableOpacity>
        );
      };

      openMap = async (query, zoomlevel) => {
        const defaultProvider = Platform.OS === "ios" ? "apple" : "google";

        const link = {
          google: `https://www.google.com/maps/search/?api=1&zoom=${zoomlevel}&query=${query}`,
          apple: `http://maps.apple.com/?z=${zoomlevel}&q=${query}`
        };

        await Linking.openURL(encodeURI(link[defaultProvider]));
      };

      generateContacts(contacts) {
        return contacts.map((contact, key) => {
          switch (contact.type) {
            case "address":
              return (
                <TouchableOpacity
                  key={key}
                  onPress={() =>
                    this.openMap(
                      contact.value.replace(/<\/?[^>]+(>|$)/g, " "),
                      30
                    )
                  }
                >
                  <CardItem bordered contact>
                    <Icon name="pin" />
                    <Body>
                      <Text>{contact.label}</Text>
                      <HTML
                        containerStyle={{
                          width: "100%"
                        }}
                        baseFontStyle={{
                          color: wcColors.text.communityProfileDetailsAddress,
                          fontWeight: "normal",
                          writingDirection: I18nManager.isRTL ? "rtl" : "ltr"
                        }}
                        html={contact.value}
                      />
                    </Body>
                  </CardItem>
                </TouchableOpacity>
              );

            case "phone":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    () => phonecall(contact.value, true),
                    contact.label,
                    contact.value,
                    "call"
                  )}
                </View>
              );

            case "sms":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    () => text(contact.value),
                    contact.label,
                    contact.value,
                    "chatboxes"
                  )}
                </View>
              );

            case "email":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    () => email([contact.value], null, null, null, null),
                    contact.label,
                    contact.value,
                    "mail"
                  )}
                </View>
              );

            case "web":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    () => Linking.openURL(contact.value),
                    contact.label,
                    contact.value,
                    "globe"
                  )}
                </View>
              );
            case "facebook":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    () => Linking.openURL(contact.value),
                    contact.label,
                    contact.value,
                    "logo-facebook"
                  )}
                </View>
              );
            case "whatsapp":
              return (
                <View key={key}>
                  {this.generatePhoneContactItem(
                    _ => _,
                    contact.label,
                    contact.value,
                    "logo-whatsapp"
                  )}
                </View>
              );
          }
        });
      }

      generateViewWithContacts = profile => {
        return (
          <Card communityDetails>
            <CardItem title bordered>
              {/* {profile.image && (
                <Image
                  style={{ width: 35, height: 35, marginHorizontal: 10 }}
                  source={{
                    uri: profile.image
                  }}
                />
              )} */}
              <H3>{profile.name}</H3>
              <Text subtitle>{profile.title}</Text>
            </CardItem>

            {profile.contacts.length
              ? this.generateContacts(profile.contacts)
              : null}
          </Card>
        );
      };

      render() {
        const lang = I18n.currentLocale();

        if (!this.state.communityProfile) return null;
        return <Content padder>{this.generateProfile()}</Content>;
      }
    }
  )
);
