import React, { Component } from "react";
import { Image } from "react-native";
import { View, Button, Text, Icon, H1, H3 } from "native-base";
import i18n from "../i18n";

export default class Welcome extends Component {
  render() {
    return (
      <View style={{flex:1}}>
        <View style={{flex:1, justifyContent: 'center', alignItems: 'center'}}><H1 style={{color:'white'}}>HOW DOES THIS APP WORK?</H1></View>
        <View style={{flex:2, flexDirection: 'row'}}>
          <View style={{flex:1}}>
            <Image
              style={{flex:1, height: undefined, width: undefined, margin:15}}
              source={require('../assets/img/welcome/welcome1.png')}
              resizeMode="contain"
            />
          </View>
          <View style={{flex:2}}>
            <H3 style={{textAlign:'right', padding:20, color:'white'}}>This is a bunch of text. Here is a description of how things work. We want it to wrap to the next line.</H3>
          </View>
        </View>
        <View style={{flex:2, flexDirection: 'row'}}>
          <View style={{flex:2}}>
            <H3 style={{padding:20, color:'white'}}>This is a bunch of text. Here is a description of how things work. We want it to wrap to the next line.</H3>
          </View>
          <View style={{flex:1}}>
            <Image
              style={{flex:1, height: undefined, width: undefined, margin:15}}
              source={require('../assets/img/welcome/welcome1.png')}
              resizeMode="contain"
            />
          </View>
        </View>
        <View style={{flex:1}}>
          <Button
            testID={"nextButton"}
            block
            large
            welcome
            onPress={() => this.props.navigation.navigate(this.props.navigateTo)}
          >
            <Text numberOfLines={1} uppercase={false}>
              {i18n.t(`appStrings.${this.props.buttonText}`)}
            </Text>
            <Icon
              name={i18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
            />
          </Button>
        </View>
      </View>
      /*
      <View padder welcome>
        {this.props.title ? (
          <H1 welcome>{i18n.t(`appStrings.${this.props.title}`)}</H1>
        ) : null}

        {this.props.image ? (
          <Image
            resizeMode="contain"
            source={this.props.image}
            style={{
              width: "100%",
              height: 175,
              marginTop: 15,
              marginBottom: 15
            }}
          />
        ) : null}

        {this.props.description ? (
          <Text numberOfLines={999} welcome>
            {i18n.t(`appStrings.${this.props.description}`)}
          </Text>
        ) : null}

        <Button
          testID={"nextButton"}
          block
          large
          welcome
          onPress={() => this.props.navigation.navigate(this.props.navigateTo)}
        >
          <Text numberOfLines={1} uppercase={false}>
            {i18n.t(`appStrings.${this.props.buttonText}`)}
          </Text>
          <Icon
            name={i18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
          />
        </Button>
      </View>
      */
    );
  }
}
