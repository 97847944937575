import types from "../actions/types";

const initialState = {
  activeTab: {
    key: "WhatsNewsTab",
    label: "appStrings.TITLE_WHATSNEW",
    headerTitle: "appStrings.TITLE_WHATSNEW"
  },
  navigators: [],
  activeChildNavigator: null,
  childNavigatorIndex: null,
  childHeaderTitle: null,
  rootNavigator: null,
  rootModalOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_RESET:
      return initialState;
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
        activeChildNavigator: (action.tab && action.tab.navigator) || null
      };
    case types.SET_ACTIVE_NAVIGATOR:
      if (state.activeTab) {
        if (!action.activeChildNavigator && state.activeTab.navigator) {
          state.activeTab.childHeaderTitle = null;
        }
        state.activeTab.navigator = action.activeChildNavigator;
      }
      return {
        ...state,
        activeChildNavigator: action.activeChildNavigator,
        childHeaderTitle: action.activeChildNavigator
          ? state.childHeaderTitle
          : null
      };
    case types.SET_CHILD_HEADER:
      if (state.activeTab) {
        state.activeTab.childHeaderTitle = action.childHeaderTitle;
      }
      return {
        ...state,
        childHeaderTitle: action.childHeaderTitle
      };
    case types.SET_ROOT_NAVIGATOR:
      return {
        ...state,
        rootNavigator: action.rootNavigator
      };
    case types.SET_ROOT_MODAL_STATUS:
      return {
        ...state,
        rootModalOpen: action.status
      };
    default:
      return state;
  }
};
