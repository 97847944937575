import { I18nManager } from "react-native";
import I18n, { getLanguages } from "react-native-i18n";
import RNRestart from "react-native-restart";
import moment from "moment";
import en from "./locale/en";
import ro from "./locale/ro";
import moment_ro from "moment/locale/ro";
import store, { persistor } from "./store";
import { setForceRtl } from "./actions/settings";

console.log("I18n", I18n);

I18n.fallbacks = true;

I18n.translations = {
  en,
  ro
};

const originalCurrentLocaleFn = I18n.currentLocale;
I18n.currentLocale = () => {
  return originalCurrentLocaleFn.call(I18n).toLowerCase().split("-")[0] || "en";
};

export const currentLocale = I18n.currentLocale;

export default I18n;

export async function setLocale(locale, setRTL) {
  let [major, minor] = locale.split("-");
  if (!Object.keys(I18n.translations).includes(major)) {
    oldMajor = major;
    locale = major = "en";
  }
  I18n.locale = locale;
  moment.locale(major);

  if (setRTL) {
    await persistor.flush();
    const rtl = major == "ar" || major == "ur";
    I18nManager.forceRTL(rtl);
    if (rtl != I18nManager.isRTL) {
      setForceRtl(true);
      await persistor.flush();
      RNRestart.Restart();
    }
  }
}

I18nManager.allowRTL(true);
export async function initLocale() {
  const languages = await getLanguages();
  const [major, minor] = languages[0].split("-");
  if (Object.keys(I18n.translations).includes(major)) {
    await setLocale(languages[0], true);
  } else {
    await setLocale("en", true);
  }
}

Object.defineProperty(I18n, "isRTL", {
  get: function () {
    return I18nManager.isRTL;
  }
});
