import { POST } from "../../../axios";
import { getLocalToken } from "../../../actions/localToken";
import store from "../../../store";

export async function reportIssue(
  comments,
  issueTypeId,
  projectId,
  employerId,
  employerName,
  jobFunction
) {
  const issueReport = {
    issue_type_id: issueTypeId,
    context: {
      project_id: projectId,
      employer_id: employerId ? employerId : undefined,
      employer_name: employerName ? employerName : undefined,
      jobFunction: jobFunction ? jobFunction : undefined
    },
    comments: comments || undefined,
    local_token: getLocalToken()
  };

  return await POST("/issue_reports", issueReport);
}

//This funcion search the issue based on provided id
//it will be iterate trough the categories and look the current category contain the issue

export function getCurrentIssueById(id) {
  const issues = store.getState().issueTypes;
  const result = Object.keys(issues).reduce((prev, next) => {
    const issue = issues[next].find(issue => issue.id == id);
    if (issue) {
      return issue;
    } else {
      return prev;
    }
  }, {});
  return result;
}
