import React, { Component } from "react";
import { TouchableOpacity, Image, StyleSheet } from "react-native";
import { Screen } from "../../utils";
import {
  Content,
  Card,
  CardItem,
  Icon,
  H1,
  Text,
  Button,
  View,
  H2
} from "native-base";
import { connect } from "react-redux";
import I18n from "../../i18n";
import { getCurrentIssueById } from "../../screens/reportIssueTab/actions";
import { AndroidBackHandler } from "react-navigation-backhandler";
import FooterImage from "../../assets/img/rights/rights_footer_image.png";

export default Screen(
  connect(state => ({
    settings: state.settings,
    rootModalOpen: state.tabsReducer.rootModalOpen,
    rights: state.rights
  }))(
    class RightDetails extends Component {
      state = {};

      constructor(props) {
        super(props);
        this.state = {
          right:
            props.navigation.getParam("right") ||
            (props.rights[props.navigation.getParam("category")] || []).find(
              r =>
                !!r.issuetypes.find(
                  it =>
                    it.issuetype_id == props.navigation.getParam("issueType")
                ) ||
                r.description ==
                  decodeURIComponent(props.navigation.getParam("issueType"))
            )
        };

        if (!this.state.right) {
          props.navigation.goBack();
        }
      }

      generateActions = () => {
        const lang = I18n.currentLocale();
        const issueTypes =
          this.state.right &&
          this.state.right.issuetypes &&
          this.state.right.issuetypes.length > 0
            ? this.state.right.issuetypes
            : [];

        return issueTypes
          ? issueTypes.map((id, key) => {
              const issue = getCurrentIssueById(id.issuetype_id);
              return (
                <TouchableOpacity
                  testID="action"
                  key={key}
                  onPress={() => {
                    this.props.navigation.navigate("ReportIssue", {
                      issue,
                      issueType: "issueType",
                      category: this.state.right.category
                    });
                  }}
                >
                  <CardItem bordered>
                    {issue.translations &&
                      Object.keys(issue.translations).length &&
                      issue.description && (
                        <Text>
                          {issue.translations[lang]
                            ? issue.translations[lang]
                            : issue.description}
                        </Text>
                      )}
                    <Icon
                      name={
                        I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"
                      }
                    />
                  </CardItem>
                </TouchableOpacity>
              );
            })
          : null;
      };

      generateCitations = () => {
        const lang = I18n.currentLocale();

        return this.state.right.citations.map((citation, key) => {
          const text =
            citation.translations &&
            Object.keys(citation.translations).length &&
            citation.translations[lang]
              ? citation.translations[lang].source
              : citation.source;
          return (
            <Button
              transparent
              citation
              testID="citation"
              key={key}
              onPress={() => {
                this.props.navigation.navigate("CitationDetails", { citation });
              }}
            >
              {!!text && !!text.length > 0 && <Text>{text}</Text>}
            </Button>
          );
        });
      };

      onBackButtonPressAndroid = () => {
        if (this.props.rootModalOpen) {
          return true;
        } else {
          return false;
        }
      };

      render() {
        const lang = I18n.currentLocale();

        if (!this.state.right) return null;
        const description =
          this.state.right &&
          Object.keys(this.state.right.translations).length &&
          this.state.right.translations[lang]
            ? this.state.right.translations[lang]
            : this.state.right.description;

        return (
          <AndroidBackHandler onBackPress={this.onBackButtonPressAndroid}>
            <Content padder>
              <H2 rightsTitle>
                {I18n.t("appStrings.RIGHTS_DETAIL_WHAT_IS_YOUR_RIGHT")}
              </H2>
              {description && description.length && (
                <Text rightsDescription>{description}</Text>
              )}
              {this.state.right.citations != null &&
                this.state.right.citations.length > 0 &&
                this.generateCitations()}
              {
                //this.props.settings.projectId &&
                //this.props.settings.projectName &&
                //(this.props.settings.employerId ||
                //  this.props.settings.employerName) &&
                this.state.right &&
                  this.state.right.issuetypes &&
                  this.state.right.issuetypes.length > 0 && (
                    <Card rightsDetails>
                      <div>
                        <CardItem header>
                          <Icon type="Ionicons" name="ios-chatbox" />
                          <Text>
                            {I18n.t("appStrings.RIGHTS_DETAIL_CALL_TO_ACTION")}
                          </Text>
                        </CardItem>
                      </div>
                      {this.generateActions()}
                    </Card>
                  )
              }

              <View style={style.footerContainer}>
                <Image source={FooterImage} style={style.footerImage} />
              </View>
            </Content>
          </AndroidBackHandler>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  footerContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    padding: 20
  },
  footerImage: {
    resizeMode: "contain"
  }
});
