import React from "react";
import { View, Image, StyleSheet } from "react-native";

function FastImage({ style, source, resizeMode }) {
  const flatStyle = StyleSheet.flatten(style);

  if (flatStyle.aspectRatio) {
    return (
      <View
        style={[
          {
            position: "relative",
            paddingTop: `${(1 / flatStyle.aspectRatio) * 100}%`
          },
          style
        ]}
      >
        <Image
          style={[
            style,
            { resizeMode },
            {
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }
          ]}
          source={source}
        />
      </View>
    );
  }

  return <Image style={[style, { resizeMode }]} source={source} />;
}

FastImage.priority = {
  normal: "normal"
};

FastImage.resizeMode = {
  cover: "cover",
  contain: "contain"
};

export default FastImage;
