import features from "../features";

export default Object.freeze({
  PROGRESS_START: "PROGRESS_START",
  PROGRESS_END: "PROGRESS_END",
  PROGRESS_MODAL: "PROGRESS_MODAL",
  ERROR: "ERROR",
  OFFLINE: "OFFLINE",
  CURRENT_USER: "CURRENT_USER",
  JWT_TOKEN: "JWT_TOKEN",
  ROOT_NAVIGATION: "ROOT_NAVIGATION",
  GET_LANGUAGES: "GET_LANGUAGES",
  GET_ISSUE_TYPES: "GET_ISSUE_TYPES",
  GET_COMMUNITY_PROFILES: "GET_COMMUNITY_PROFILES",
  GET_PROJECT: "GET_PROJECT",
  GET_RIGHTS: "GET_RIGHTS",
  SET_PROJECT_STATUS: "SET_PROJECT_STATUS",
  ...Object.keys(features)
    .filter(f => typeof features[f].actionTypes !== "undefined")
    .reduce((p, c) => ({ ...p, [c]: features[c].actionTypes }), {})
});
