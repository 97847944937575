import React, { Component } from "react";
import {
  Header,
  Body,
  Left,
  Button,
  Icon,
  Right,
  Title,
  Text
} from "native-base";
import {
  View,
  Dimensions,
  Text as RNText,
  Alert,
  BackHandler,
  Linking,
  Animated,
  I18nManager,
  Platform
} from "react-native";
import { TabView, TabBar } from "react-native-tab-view";
import { Screen, lazy } from "../../utils";
import ReportIssueTab from "../reportIssueTab";
import ResourcesTab from "../resourcesTab";
import SettingsTab from "../settingsTab";
import WhatsNewsTab from "../whatsnewsTab";
import { uriPrefix } from "../../const";
import I18n from "../../i18n";
import store from "../../store";
import styles from "../../styles";
import * as style from "./style";
import { connect } from "react-redux";
import { setActiveTab } from "./actions";
import { setReportIssueView } from "../../actions/settings";
import { NavigationActions, StackActions } from "react-navigation";
import { AndroidBackHandler } from "react-navigation-backhandler";

const initialLayout = {
  height: 0,
  width: Dimensions.get("window").width
};

const tabs = ["whatsnew", "resources", "reportissue", "settings"];

const ConnectedHeader = connect(state => ({
  activeTab: state.tabsReducer.activeTab,
  activeChildNavigator: state.tabsReducer.activeChildNavigator,
  childHeaderTitle: state.tabsReducer.childHeaderTitle
}))(props => {
  return (
    <Header hasTab>
      {props.activeTab &&
      props.activeTab.navigator &&
      props.activeTab.navigator.state.index > 0 ? (
        <Left style={{ flex: 0 }}>
          <View style={{ width: 50 }}>
            <Button
              onPress={() =>
                props.activeTab.navigator.dispatch(NavigationActions.back())
              }
              transparent
            >
              <Icon name={I18n.isRTL ? "arrow-forward" : "arrow-back"} />
            </Button>
          </View>
        </Left>
      ) : null}
      <Body style={{ flex: 1 }}>
        <Text>
          {props.activeTab
            ? props.activeTab.childHeaderTitle &&
              props.activeTab.navigator &&
              props.activeTab.navigator.state.index > 0
              ? I18n.t(props.activeTab.childHeaderTitle)
              : I18n.t(props.activeTab.headerTitle)
            : ""}
        </Text>
      </Body>
      {props.activeTab &&
      props.activeTab.navigator &&
      props.activeTab.navigator.state.index > 0 ? (
        <Right style={{ flex: 0 }}>
          <View style={{ width: 50 }} />
        </Right>
      ) : null}
    </Header>
  );
});

export default Screen(
  connect(state => ({
    settings: state.settings,
    tabsReducer: state.tabsReducer
  }))(
    class MainTabs extends Component {
      state = {
        index:
          this.props.navigation.state.params &&
          this.props.navigation.state.params.tab
            ? tabs.indexOf(this.props.navigation.state.params.tab)
            : 0,
        routes: [
          {
            key: "WhatsNewsTab",
            component: WhatsNewsTab,
            label: "appStrings.TITLE_WHATSNEW",
            headerTitle: "appStrings.TITLE_WHATSNEW",
            icon: style.Icons.whatsnew
          },
          {
            key: "ResourcesTab",
            component: ResourcesTab,
            label: "appStrings.TITLE_INFORMATION",
            headerTitle: "appStrings.TITLE_INFO_RESOURCES",
            icon: style.Icons.resources
          },
          {
            key: "ReportIssueTab",
            component: ReportIssueTab,
            label: "appStrings.TITLE_REPORT_ISSUE",
            headerTitle: "appStrings.TITLE_REPORT_ISSUE",
            icon: style.Icons.reportissue
          },
          {
            key: "SettingsTab",
            component: SettingsTab,
            label: "appStrings.TITLE_SETTINGS",
            headerTitle: "appStrings.TITLE_SETTINGS",
            icon: style.Icons.settings
          }
        ]
      };

      componentDidMount() {
        BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);
        Linking.addEventListener("url", this._handleIntentURL);
        if (this.state.index > 0)
          setActiveTab(this.state.routes[this.state.index]);
      }

      componentWillUnmount() {
        BackHandler.removeEventListener(
          "hardwareBackPress",
          this.handleBackPress
        );
        Linking.removeEventListener("url", this._handleIntentURL);
      }

      _handleIntentURL = ({ url }) => {
        if (url) {
          url = url.replace(uriPrefix, "");
          if (url.startsWith("app")) {
            const tab = url.split("/")[1];
            const index = tabs.indexOf(tab);
            if (this.state.index != index) {
              this._handleIndexChange(index);
            }
          }
        }
      };

      handleBackPress = () => {
        const { rootNavigator, activeTab } = store.getState().tabsReducer;
        if (
          activeTab &&
          activeTab.navigator &&
          activeTab.navigator.state.index > 0
        ) {
          activeTab.navigator.goBack(null);
          return true;
        } else if (rootNavigator && rootNavigator.state.index > 0) {
          rootNavigator.goBack(null);
          return true;
        } else {
          return false;
        }
      };

      _handleIndexChange = index => {
        this.setState({ index });
        setActiveTab(this.state.routes[index]);
      };

      _renderHeader = props => {
        return (
          <TabBar
            {...props}
            {...style.TabBar}
            onTabPress={({ route }) => {
              const index = this.state.routes.indexOf(route);
              const active = index == this.state.index;
              if (active) {
                const { activeChildNavigator } = store.getState().tabsReducer;
                if (activeChildNavigator) {
                  activeChildNavigator.dispatch(StackActions.popToTop());
                }
              }
            }}
            renderIndicator={this._renderIndicator}
            renderLabel={({ route }) => {
              const index = this.state.routes.indexOf(route);
              const active = index == this.state.index;
              const icon = route.icon(active);
              return (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Icon
                    type={icon.type}
                    name={icon.name}
                    style={style.Label.Icon(active)}
                  />
                  <Text numberOfLines={1} style={style.Label.Text(active)}>
                    {I18n.t(route.label)}
                  </Text>
                </View>
              );
            }}
          />
        );
      };

      _renderIndicator = props => {
        const { width, navigationState, panX, position } = props;

        const offsetX =
          (I18nManager.isRTL && Platform.OS !== "ios"
            ? navigationState.routes.length - 1 - navigationState.index
            : -navigationState.index) * width;

        const finalPosition =
          Platform.OS === "android"
            ? Animated.add(
                Animated.multiply(
                  Animated.divide(Animated.add(panX, offsetX), width),
                  -1
                ),
                I18nManager.isRTL && Platform.OS !== "ios"
                  ? navigationState.routes.length - 1
                  : 0
              )
            : position;

        const translateX = Animated.multiply(
          Animated.multiply(
            finalPosition.interpolate({
              inputRange: [0, navigationState.routes.length - 1],
              outputRange: [0, navigationState.routes.length - 1],
              extrapolate: "clamp"
            }),
            width
          ),
          I18nManager.isRTL ? -1 : 1
        );
        return (
          <Animated.View
            style={[
              style.TabBar.indicatorStyle,
              { width, transform: [{ translateX }] }
            ]}
          />
        );
      };

      _renderScene = ({ route, navigationState }) => {
        const Scene = route.component;
        const index = this.state.routes.indexOf(route);
        return (
          <View style={{ width: "100%", height: "100%", position: "absolute" }}>
            <Scene
              active={index == this.state.index}
              rootNavigator={this.props.navigation}
              {...this.props}
            />
          </View>
        );
      };

      render() {
        return (
          <TabView
            navigationState={this.state}
            renderScene={this._renderScene}
            renderTabBar={this._renderHeader}
            onIndexChange={this._handleIndexChange}
            initialLayout={initialLayout}
          />
        );
      }
    }
  ),
  props => <ConnectedHeader {...props} />
);
