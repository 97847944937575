import React, { Component } from "react";
import { Form, Item, Button, Text, Input } from "native-base";
import { connect } from "react-redux";
import I18n from "../i18n";
import showDialog from "./Dialog";
import { dialogConfig } from "../const";
import { isOffline } from "../offline";

export default connect(state => ({
  isOffline: state.offline
}))(
  class FormInput extends Component {
    state = {
      value: this.props.value || ""
    };

    componentWillReceiveProps(nextProps) {
      if (nextProps.value) {
        this.setState({ value: nextProps.value });
      }
    }

    render() {
      return (
        <Form formInput {...this.props}>
          <Item regular>
            <Input
              autoCapitalize="none"
              multiline={false}
              numberOfLines={1}
              testID="input"
              placeholder={this.props.placeholder}
              onChangeText={value => {
                this.setState({ value });
              }}
              value={this.state.value}
            />
          </Item>
          <Button
            testID="submit"
            onPress={async () => {
              !(await isOffline())
                ? this.props.onSubmit(this.state.value)
                : showDialog(I18n.t("appStrings.MSG_DISCONNECTED"));
            }}
          >
            <Text uppercase={false}>{this.props.buttonText}</Text>
          </Button>
        </Form>
      );
    }
  }
);
