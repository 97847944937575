import types from "../actions/types";

const initialState = {
  lastRefresh: null,
  profiles: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.APP_RESET:
      return initialState;
    case types.GET_COMMUNITY_PROFILES:
      return {
        lastRefresh: Date.now(),
        profiles: filterCommunityProfiles(action.communityProfiles || [])
      };
    default:
      return state;
  }
};

//this function is collect community profiles by type
function filterCommunityProfiles(communitiProfiles) {
  return communitiProfiles.reduce((prev, next) => {
    if (prev[next.type]) {
      return {
        ...prev,
        [next.type]: [...prev[next.type], next]
      };
    } else {
      return {
        ...prev,
        [next.type]: [next]
      };
    }
  }, {});
}
