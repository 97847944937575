import types from "../actions/types";

const initialState = {
  languages: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LANGUAGES:
      return { ...state, languages: action.languages || [] };
    default:
      return state;
  }
};
