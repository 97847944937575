import React, { Component } from "react";
import { Screen } from "../../utils";
import {
  Text,
  Content,
  View,
  Card,
  CardItem,
  Icon,
  Button,
  H2
} from "native-base";
import FormInput from "../../components/FormInput";
import I18n from "../../i18n";
import { connect } from "react-redux";
import { setJobFunction, setSetupStatus } from "../../actions/settings";
import wcColors from "../../../native-base-theme/variables/wcColors";

export default Screen(
  connect(state => ({
    jobFunction: state.settings.jobFunction
  }))(
    class SetJobFunction extends Component {
      state = {
        somethingElse: false
      };

      storeJobFunction = async jobFunction => {
        setJobFunction(jobFunction);
        setSetupStatus(true);
        this.props.navigation.navigate("SettingsTab");
      };

      renderShortList = () => {
        const shortList = I18n.t("jobFunctionList").shortList;
        let isInShortList = false;

        const shortJobFunctionList = shortList.map((job, key) => {
          const currentJob = this.props.jobFunction;
          const code = job.code;
          const isCurrent = currentJob.trim() === code.trim();

          if (isCurrent) {
            isInShortList = isCurrent;
          }

          return (
            <CardItem
              key={key}
              testID={"jobFunction" + key}
              button
              disabled={isCurrent}
              bordered
              onPress={() => {
                this.storeJobFunction(job.code);
                this.props.navigation.goBack();
              }}
            >
              <Text>{job.name}</Text>
              {isCurrent ? <Icon type="Ionicons" name="md-checkmark" /> : null}
            </CardItem>
          );
        });

        return shortJobFunctionList;
      };

      toggleSomethingElse = () => {
        this.setState(state => ({ somethingElse: !state.somethingElse }));
      };

      renderSomethingElse = () => {
        return (
          <Content padder>
            <H2 setup>{I18n.t("appStrings.PROMPT_WHAT_JOB_FUNCTION")}</H2>
            <View
              style={{ flex: 1, flexDirection: "column", alignItems: "center" }}
            ></View>
            <View padder>
              <FormInput
                settingsJobFunctionInput
                onSubmit={value => {
                  if (value && value.trim())
                    this.storeJobFunction(value.trim());
                }}
                placeholder={I18n.t("appStrings.LBL_JOB_FUNCTION_NAME")}
                buttonText={I18n.t("appStrings.LBL_OK")}
              />
            </View>

            <Button
              block
              transparent
              cancelEmployerSettings
              onPress={() => {
                this.toggleSomethingElse();
              }}
            >
              <Text uppercase={false}>{I18n.t("appStrings.LBL_CANCEL")}</Text>
            </Button>
          </Content>
        );
      };

      render() {
        return this.state.somethingElse ? (
          this.renderSomethingElse()
        ) : (
          <Content padder>
            <Text divider>{I18n.t("appStrings.PROMPT_WHAT_JOB_FUNCTION")}</Text>
            <Card settings radio>
              {this.renderShortList()}
            </Card>
            <Button
              block
              transparent
              nationalityOther
              onPress={this.toggleSomethingElse}
            >
              <Text uppercase={false}>
                {I18n.t("appStrings.LBL_SOMETHING_ELSE")}
              </Text>
            </Button>
          </Content>
        );
      }
    }
  )
);
