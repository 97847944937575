import { Content, Icon, List, ListItem, Text } from "native-base";
import React, { Component } from "react";
import { Image, StyleSheet } from "react-native";
import { connect } from "react-redux";
import * as promiseImages from "../../assets/img/resources/promises";
import I18n from "../../i18n";
import { Screen } from "../../utils";

export default Screen(
  connect(state => ({
    settings: state.settings,
    promises: state.promises
  }))(
    class Promises extends Component {
      generatePromises = categoryType => {
        const promise = this.props.promises[categoryType][0];
        const locale = I18n.currentLocale() ? I18n.currentLocale() : "en";
        return (
          <ListItem
            promises
            onPress={() => {
              this.props.navigation.navigate("PromiseDetails", {
                promiseType: promise.categoryType
              });
            }}
            testID="promiseType"
            style={style.promiseItem}
          >
            <Image
              source={promiseImages[promise.image]}
              style={style.promiseImage}
            />
            <Text style={style.promiseText} numberOfLines={1}>
              {promise.title[locale]
                ? promise.title[locale]
                : promise.title["en"]}
            </Text>
            <Icon
              name={I18n.isRTL ? "ios-chevron-back" : "ios-chevron-forward"}
            />
          </ListItem>
        );
      };

      render() {
        return (
          <Content>
            <List>
              {this.generatePromises("working_at_height")}
              {this.generatePromises("mobile_plant")}
              {this.generatePromises("lifting")}
              {this.generatePromises("breaking_ground")}
              {this.generatePromises("driving")}
              {this.generatePromises("hot_works")}
              {this.generatePromises("energised_systems")}
              {this.generatePromises("confined_spaces")}
              {this.generatePromises("temporary_works")}
              {this.generatePromises("working_in_the_heat")}
            </List>
          </Content>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  promiseItem: {},
  promiseImage: {
    maxWidth: 60,
    maxHeight: 60,
    resizeMode: "contain"
  },
  promiseText: {
    fontSize: 15
  }
});
