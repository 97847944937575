export default [
  {
    categoryType: 'working_at_height',
    image: 'workAtHeight',
    title: {
      en: 'Working at Height',
      hi: 'ऊंचे स्थानों पर काम करना',
      ur: 'اونچائي پر کام کرنا',
      ar: 'العمل في الأماكن المرتفعة',
      ta: 'உயரமான இடத்தில் வேலை',
      ml: 'ഉയരമുള്ള ഇടങ്ങളിൽ ജ�ോലി ചെയ്യൽ',
      bn: 'উচ্চতায় কাজ'
    },
    descriptions: {
      en: [
        {
          description: 'I will only work at height if I am trained.'
        },
        {
          description:
            'I will not work at height unless I am safeguarded by edge protection or by a harness fixed to a suitable anchor point.'
        },
        {
          description: 'I will not enter an exclusion zone established for work at height.'
        },
        {
          description: 'I will ensure that tools and equipment are tethered for work at height.'
        },
        {
          description: 'I will maintain 3 points of contact when using a ladder.'
        },
        {
          description: 'I will not modify, alter or dismantle edge protection unless I am trained and authorised.'
        }
      ],
      hi: [
        {
          description: 'मैं ऊंचे स्थानों पर केवल तभी काम करूंगा अगर मैंने प्रशिक्षण प्राप्त किया हो।'
        },
        {
          description:
            'मैं ऊंचे स्थानों पर तब तक काम नहीं करूंगा जब तक मैंने किनारे पर सुरक्षा के लिए सुरक्षा-उपाय नहीं लगाए हों, या जब तक मैंने किसी उपयुक्त एंकर स्थान पर हार्नेस नहीं लगाया हो।'
        },
        {
          description: 'मैं ऊंचे स्थान पर काम करने के लिए निर्धारित किए गए निषिद्ध स्थान में प्रवेश नहीं करूंगा।'
        },
        {
          description:
            'मैं यह सुनिश्चित करूंगा कि ऊंचे स्थान पर काम करने के लिए साधन और उपकरण को रस्सी से बांधा गया हो।'
        },
        {
          description: 'मैं सीढ़ी का उपयोग करते समय हमेशा सीढ़ी के साथ तीन जगहों पर संपर्क बनाए रखूंगा।'
        },
        {
          description:
            'मैं किनारे पर सुरक्षा के उपायों को संशोधित, परिवर्तित एवं विघटित नहीं करूंगा या तब तक नहीं निकालूंगा जब तक मैं इ सके लिए प्रशिक्षित और अधिकृत नहीं हूं।'
        }
      ],
      ur: [
        {
          description: 'ميں اونچائي پر صرف تب کام کروں گا اگر ميں تربيت يافتہ ہوں'
        },
        {
          description:
            'ميں اونچائي پر کام نہيں کروں گا جب تک کہ مجھے کنارے کے ذريعے يا مناسب لنگر سے بندھے خفاظتي پٹے کے ذريعے تحفظ فراہم کيا گيا ہو'
        },
        {
          description: 'ميں اونچائي پر کام کرنے کے ليے قائم کردہ خارجي زون ميں داخل نہيں ہوں گا'
        },
        {
          description: 'ميں يقيني بناؤں گا کہ اونچائي پر کام کرنے کے ليے اوزار اور آلات رسے سے بندھے ہيں'
        },
        {
          description: 'جب ميں سيڑھي کا استعمال کروں تو سہارے کے 3 پوائنٹس کے اصول کا اخترام کرونگا'
        },
        {
          description:
            'ميں کنارے  کے تحفظ ميں ترميم، تبديلي يا اسے ہٹاؤں گا نہيں ماسوائے اس کے کہ ميں تربيت يافتہ اور مجاز ہوں'
        }
      ],
      ar: [
        {
          description: 'لن أعمل في الأماكن المرتفعة إلا إذا كنت متدرباً على ذلك.'
        },
        {
          description:
            'لن أعمل في الأماكن المرتفعة إلا إذا كنت مؤمّناً بسياج حواف أو حزام أمان مربوط بموضع تثبيت مناسب.'
        },
        {
          description: 'لن أدخل منطقة حظر مخصصة للعمل في الأماكن المرتفعة.'
        },
        {
          description: 'سوف أتأكد من أن الأدوات والمعدات مقيدة للعمل في الأماكن المرتفعة.'
        },
        {
          description: 'سوف أحافظ على 3 مواضع تلامس عند استخدام السلم.'
        },
        {
          description: 'لن أعدّل أو أبدّل أو أفك سياج الحواف ما لم أكن متدرباً ومصرحاً لي بذلك.'
        }
      ],
      ta: [
        {
          description: 'நான் பயிற்றுவிக்கப்பட்டிருந்தால் மட்டுமே உயரமான இடங்களில் வேலை செய்வேன்.'
        },
        {
          description:
            'எட்ஜ் புரொடக்ஷன் அல்லது பொருத்தமான ஆங்கர் பாயின்ட்டுடன் பொருத்தப்பட்ட ஹார்னெஸைப் பயன்படுத்தி பாதுகாக்கப்பட்டிருந்தால் மட்டுமே உயரமான இடங்களில் வேலை செய்வேன்.'
        },
        {
          description: 'உயரத்தில் பணிபுரிவதற்காக உருவாக்கப்பட்டுள்ள தவிர்ப்பு பிராந்தியத்தில் நுழைய மாட்டேன்.'
        },
        {
          description:
            'உயரத்தில் வேலை செய்வதற்கான கருவிகள் மற்றும் உபகரணம் டெதர் செய்யப்பட்டுள்ளதா என்பதை உறுதிசெய்து கொள்வேன்.'
        },
        {
          description: 'ஏணியைப் பயன்படுத்தும்போது 3 பாயின்ட் கான்டேக்ட்டைப் பராமரிப்பேன்.'
        },
        {
          description:
            'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே எட்ஜ் புரொடக்ஷனை மாற்றுவேன், திருத்துவேன் அல்லது கழற்றுவேன்.'
        }
      ],
      ml: [
        {
          description: 'പരിശീലനം ലഭിച്ചിട്ടുണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ഉയരങ്ങളിൽ ജ�ോലി ചെയ്യൂ'
        },
        {
          description:
            'അരികുവശത്തുള്ള പരിരക്ഷയോ ഒരു ഉചിതമായ ആങ്കർ പോയിന്റിലേക്ക് ബന്ധിപ്പിച്ചിട്ടുള്ള ഹാർണസ�ോ മുഖേന, ഞാൻ പരിരക്ഷിക്കപ്പെട്ടിട്ടില്ലെങ്കിൽ, ഞാൻ ഉയരങ്ങളിൽ ജ�ോലി ചെയ്യ'
        },
        {
          description: 'ഉയരത്തിലുള്ള ജ�ോലിക്കായി ഒഴിച്ചിട്ടിരിക്കുന്ന മേഖലയിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description:
            'ഉയരങ്ങളിൽ ജ�ോലി ചെയ്യുമ്പോൾ, ടൂളുകളും എക്യുപ്‌മെന്റും താഴെ വീഴാത്ത തരത്തിൽ ഭദ്രമായി ബന്ധിച്ചിട്ടുണ്ടെന്ന് ഞാൻ ഉറപ്പ'
        },
        {
          description:
            'ഒരു കോണി ഉപയോഗിക്കുമ്പോൾ എല്ലായ്പ്പോഴു ം കോണിയിൽ രണ്ട് കാലും ഒരു കൈയു ം അല്ലെങ്കിൽ രണ്ട് കൈയു ം ഒരു കാലും മുറുകെ വച്ചിട്ടുണ്ടെന്ന് ഞാൻ ഉറപ്പ'
        },
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിക്കുകയു ം എന്നെ അധികാരപ്പെടുത്തുകയു ം ചെയ്യാത്ത പക്ഷം, അരികുവശങ്ങളിലെ പരിരക്ഷകൾ ഞാൻ പരിഷ്ക്കരിക്കുകയോ അവയിൽ മാറ്റം വരുത്തുകയോ അവ അഴിച്ചെടുക്കുകയോ ചെയ്യ'
        }
      ],
      bn: [
        {
          description: 'আমি প্রশিক্ষিত হলেই উচ্চতায় কাজ করব�ো'
        },
        {
          description:
            'আমি উচ্চতায় কাজ করব�ো না যদি আমি প্রান্ত সুরক্ষা অথবা উপযুক্ত অ্যাঙ্কর পয়েন্ট-এ সংযুক্ত হার্নেস দ্বারা সুরক্ষিত না থাক'
        },
        {
          description: 'উচ্চতায় কাজ করার জন্যে কোনো বর্জিত জায়গায় আমি ঢু কব না'
        },
        {
          description: 'আমি নিশ্চিত করব�ো যে উচ্চতায় কাজ করার জন্যে উপকরণ এবং সরঞ্জাম দড়ি দ্বারা আবদ্ধ রয়েছে'
        },
        {
          description: 'সিড়ি ব্যবহার করার সময় আমি 3 পয়েন্ট যোগাযোগ রাখব'
        },
        {
          description:
            'আমি প্রশিক্ষিত অথবা অনুমোদিত না হলে প্রান্ত সুরক্ষায় কোনরকম পরিবর্তন,রদবদল করব না অথবা খুলে ফেলব�ো না'
        }
      ]
    }
  },
  {
    categoryType: 'mobile_plant',
    image: 'mobilePlant',
    title: {
      en: 'Mobile Plant & Equipment',
      hi: 'मोबाइल प्लांट और उपकरण',
      ur: 'موبائل پلانٹ اور لوازمات',
      ar: 'المعدات والآليات المتحركة',
      ta: 'மொபைல் பிளான்ட் & உபகரணம்',
      ml: 'മൊബൈൽ പ്ലാന്റും എക്യുപ്‌മെന്റു',
      bn: 'চলমান প্লান্ট এবং উপকরণ'
    },
    descriptions: {
      en: [
        {
          description: 'I will only operate mobile plant and equipment if I am trained and authorised.'
        },
        {
          description: 'I will only operate mobile plant and equipment if it is safe and in good condition.'
        },
        {
          description:
            'I will only operate mobile plant and equipment if I have walked around to check for people or hazards in close proximity.'
        },
        {
          description: 'I will keep a safe distance from mobile plant and equipment and use pedestrian walkways.'
        },
        {
          description: 'I will not sleep or rest under or near mobile plant and equipment.'
        },
        {
          description: 'I will only operate plant and equipment if I have 360-degree visibility.'
        },
        {
          description: 'I will use the correct plant and equipment as per its intended use.'
        },
        {
          description: 'I will always wear a seat belt when operating plant and equipment.'
        }
      ],
      hi: [
        {
          description: 'मैं केवल प्रशिक्षित और अधिकृत होने पर ही मोबाइल प्लांट और उपकरण को संचालित करूंगा।'
        },
        {
          description: 'मैं मोबाइल प्लांट को केवल तभी संचालित करूंगा जब वह सुरक्षित और अच्छी स्थिति में हो।'
        },
        {
          description:
            'मैं मोबाइल प्लांट और उपकरण को केवल तभी संचालित करूंगा जब मैंने इसके आस-पास करीबी स्थानों पर जाकर लोगों या खतरों के लिए जांच कर ली हो।'
        },
        {
          description: 'मैं मोबाइल प्लांट से एक सुरक्षित दूरी बनाए रखूंगा और पैदल चलने के मार्गों का उपयोग करूंगा।'
        },
        {
          description: 'मैं किसी मोबाइल प्लांट या उपकरण के नीचे नहीं सोऊंगा या आराम भी नहीं करूंगा।'
        },
        {
          description: 'मैं मोबाइल प्लांट और उपकरण सिर्फ तभी संचालित करूंगा अगर मेरे पास 360-डिग्री की दृश्यता है।'
        },
        {
          description: 'मैं उपयोग के उद्देश्य के अनुसार सही संयंत्र और उपकरण का उपयोग करूंगा।'
        },
        {
          description: 'मैं हमेशा मोबाइल प्लांट और उपकरण का उपयोग करते समय सीट बेल्ट पहनूंगा।'
        }
      ],
      ur: [
        {
          description: 'میں صرف تب موبائل پلانٹ کو آپریٹ کروں گا اگر میں تربیت یافتہ اور مجاز ہوں'
        },
        {
          description: 'میں صرف تب موبائل پلانٹ اور آلات کو آپریٹ کروں گا اگر یہ محفوظ اور اچھی حالت میں ہوں'
        },
        {
          description:
            'میں صرف تب موبائل پلانٹ اور آلات کو آپریٹ کروں گا اگر میں نے وہاں جا کر لوگوں یا قریبی خطرات کا جائزہ لیا ہو'
        },
        {
          description:
            'میں موبائل پلانٹ اورآلات سے محفوظ فاصلہ برقرار رکھوں گا اور پیدل چلنے کے راستوں کو استعمال کروں گا'
        },
        {
          description: 'میں موبائل پلانٹ اور آلات کے نیچے یا اس کے قریب سونے یا آرام کرنے سے گریز کروں گا'
        },
        {
          description: 'میں صرف اس صورت میں پلانٹ اور مشین کو چلاوں گا جب میں اس کے چاروں طرف اسانی سےدیکھ سکتا ہوں گا'
        },
        {
          description: 'میں پلانٹ اور مشین کو صرف اسی کام کے لیے ہی استعمال کروں گا جس مقصد کے لیے اس کو بنایا گیا ہے'
        },
        {
          description: 'میں ہر وقت پلانٹ اور مشین چلانے کے دران سیٹ بیلٹ پہنوں گا'
        }
      ],
      ar: [
        {
          description: 'لن أقوم بتشغيل المعدات أو الآليات المتحركة إلا إذا كنت متدرباً عليها ومصرحاً لي بذلك.'
        },
        {
          description: 'لن أقوم بتشغيل المعدات أو الآليات المتحركة إلا إذا كانت آمنة وبحالة جيدة.'
        },
        {
          description:
            'لن أقوم بتشغيل المعدات أو الآليات المتحركة إلا بعد تفقد المكان والتأكد من خلوه من الأشخاص أو المخاطر.'
        },
        {
          description: 'سوف أترك مسافة آمنة بيني وبين المعدات أو الآليات المتحركة وأستخدم ممرات المشاة.'
        },
        {
          description: 'لن أنام أو أستريح تحت أو بجوار المعدات أو الآليات المتحركة.'
        },
        {
          description: 'سأقوم بتشغيل المعدات أو الآليات المتحركة فقط إذا كان لدي رؤية بنسبة 360 درﺟﺔ.'
        },
        {
          description: 'سأقوم باستخدام المعدات أو الآليات المتحركة الصحيحة كما هو محدد.'
        },
        {
          description: 'سأقوم دائماً بارتداء حزام الأمان عند تشغيل المعدات أو الآليات المتحركة.'
        }
      ],
      ta: [
        {
          description:
            'Iநான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே மொபைல் பிளான்ட் & உபகரணத்தை இயக்குவேன்.'
        },
        {
          description:
            'மொபைல் பிளான்ட் மற்றும் உபகரணம் பாதுகாப்பாகவும், நல்ல நிலையில் இருந்தால் மட்டுமே நான் அவற்றை இயக்குவேன்.'
        },
        {
          description:
            'பிளான்ட்டிற்கு நெருக்கத்தில் யாரேனும் உள்ளனரா அல்லது அபாயங்கள் ஏதேனும் உள்ளதா என்பதை நடந்து சென்று ஆய்வு செய்தப் பிறகே, மொபைல் பிளான்ட் & உபகரணத்தை நான் இயக்குவேன்.'
        },
        {
          description:
            'மொபைல் பிளான்ட் மற்றும் உபகரணத்திலிருந்து பாதுகாப்பான இடைவெளியைப் பராமரித்து, பாதசாரிகளுக்கான நடைபாதைகளையே பயன்படுத்துவேன்.'
        },
        {
          description:
            'மொபைல் பிளான்ட் மற்றும் உபகரணத்திற்கு கீழ் பகுதியில் அல்லது அருகில், தூங்கவோ ஓய்வெடுக்கவோ மாட்டேன்.'
        },
        {
          description: '360-டிகிரி கோணமும் தெரிந்தால் மட்டுமே, நான் பிளான்ட் & உபகரணத்தை இயக்குவேன்.'
        },
        {
          description: 'நான் பொருத்தமான பிளான்ட் மற்றும் உபகரணத்தை அதன் உபயோகத்திற்கு தகுந்தார் போல் பயன்படுத்துவேன்.'
        },
        {
          description: 'பிளான்ட் மற்றும் உபகரணங்கள் இயங்கும் போது நான் எப்போதும் சீட் பெல்ட் அணிந்துகொள்வேன்.'
        }
      ],
      ml: [
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിച്ചാലും എന്നെ അധികാരപ്പെടുത്തിയാലും മാത്രമേ ഞാൻ മൊബൈൽ പ്ലാന്റും എക്യുപ്‌മെന്റും പ്രവർത്തിപ്പിക്ക'
        },
        {
          description:
            'സുരക്ഷിതമാണെങ്കിലും മൊബൈൽ പ്ലാന്റും എക്യുപ്‌മെന്റും നല്ല അവസ്ഥയിലാണെങ്കിലും മാത്രമേ ഞാനവ പ്രവർത്തിപ്പിക്ക'
        },
        {
          description:
            'അടുത്ത പരിസരങ്ങളിൽ ആളുകളോ അപായസാധ്യതകളോ ഉണ്ടോ എന്ന് നടന്നുചെന്ന് ഞാൻ പരിശോധിച്ചതിന്ശേഷം മാത്രമേ ഞാൻ മൊബൈൽ പ്ലാന്റും എക്യുപ്‌മെന്റും പ്രവർത്തിപ്പിക്ക'
        },
        {
          description:
            'ഞാൻ മൊബൈൽ പ്ലാന്റിൽ നിന്നും എക്യുപ്‌മെന്റിൽ നിന്നും സുരക്ഷിതമായ അകലം പാലിക്കും, ഞാൻ നടക്കുന്നതിനായി, കാൽനടക്കാർക്ക് അനുസരിച്ചിട്ടുള്ള പാതകൾ ഉപയോഗിക്കുകയു ം ചെയ'
        },
        {
          description: 'മൊബൈൽ പ്ലാന്റിനോ എക്യുപ്‌മെന്റിനോ അടിയിലോ അരികിലോ ഞാൻ ഉറങ്ങുകയോ വിശ്രമിക്കുകയോ ചെയ്യ'
        },
        {
          description:
            'എനിക്ക് 360 ഡിഗ്രി ദൃശ്യപരത ഉണ്ടെങ്കിൽ മാത്രമേ ഞാൻ പ്ലാൻറു ം ഉപകരണങ്ങളും പ്രവർത്തിപ്പിക്കുകയുള്ളൂ.'
        },
        {
          description: 'ശരിയായ ഉദ്ദേശ്യമനുസരിച്ച് ശരിയായ പ്ലാന്റും ഉപകരണങ്ങളും ഞാൻ ഉപയോഗിക്കും.'
        },
        {
          description: 'ഓപ്പറേറ്റിങ് പ്ലാൻറു ം ഉപകരണങ്ങളും ഉപയോഗിക്കുമ്പോൾ സീറ്റ്ബെൽറ്റ് എപ്പോഴു ം ഞാൻ ധരിക്കും.'
        }
      ],
      bn: [
        {
          description: 'যদি আমি প্রশিক্ষিত এবং অনুমোদিত হলে কেবল তবেই আমি চলমান প্লান্ট এবং উপকরণ পরিচালনা করব'
        },
        {
          description: 'আমি চলমান প্লান্ট এবং উপকরণ কেবল তখনই পরিচালনা করব�ো যখন এটি সুরক্ষিত এবং ভালো অবস্থায় থাকব'
        },
        {
          description:
            'যখন আমি ঘুরে দেখব�ো কাছাকাছি কোনো মানুষ বা বিপদ নেই তখনই আমি চলমান প্লান্ট এবং উপকরণ পরিচালনা করতে পার'
        },
        {
          description: 'আমি পদ যাত্রীদের চলার রাস্তার থেকে সুরক্ষিত দূরত্বে রেখে চলমান প্লান্ট এবং উপকরণ ব্যবহার করব�ো'
        },
        {
          description: 'আমি চলমান প্লান্ট এবং উপকরনের কাছে বা নিচে বিশ্রাম অথবা ঘুমাব�ো না'
        },
        {
          description: 'আমার চারপাশে (360 ডিগ্রী) দশ্যম ৃ ানতা থাকলে আমি কেবলমাত্র মেশিন এবং সরঞ্জাম গুলি পরিচালনা করব।'
        },
        {
          description: 'আমি যথাযথ ব্যবহার অনুযায়ী সঠিক মেশিন এবং সরঞ্জাম ব্যবহার করব।'
        },
        {
          description: 'মেশিন এবং সরঞ্জাম অপারেটিংর সময় , আমি সবসময় সীট বেল্ট পরব'
        }
      ]
    }
  },
  {
    categoryType: 'lifting',
    image: 'lifting',
    title: {
      en: 'Lifting',
      hi: 'भारी सामान ऊपर उठाना',
      ur: 'لفٹنگ',
      ar: 'أعمال الرفع',
      ta: 'லிஃப்ட்டிங்',
      ml: 'ഭാരമുയർത്തൽ',
      bn: 'তোলা'
    },
    descriptions: {
      en: [
        {
          description: 'I will only sling a load if I am trained and authorised.'
        },
        {
          description: 'I will only use lifting equipment and accessories if they are suitably tagged.'
        },
        {
          description:
            'I will only lift a load if I can communicate with those involved and affected by the lifting activity.'
        },
        {
          description: 'I will only lift a load if it is secure and a test lift has been performed.'
        },
        {
          description: 'I will not exceed the operating limits of lifting equipment or accessories.'
        },
        {
          description: 'I will avoid walking or standing under a suspended load.'
        }
      ],
      hi: [
        {
          description: 'मैं केवल प्रशिक्षित और अधिकृत होने पर ही भार को स्लिंग से जोङूंगा और उठाऊंगा।'
        },
        {
          description:
            'मैं ऊपर उठाने के उपकरण और सहायक साधनों का उपयोग केवल तभी करूंगा अगर उन्हें उचित रूप से टैग किया गया हो।'
        },
        {
          description:
            'मैं लोड केवल तभी उठाऊंगा अगर मैं उठाने की गतिविधि में शामिल और ऊससे प्रभावित होने वाले लोगों को बता सकूं।'
        },
        {
          description: 'मैं लोड केवल तभी उठाऊंगा अगर यह सुरक्षित हो और इसे उठाने का परीक्षण कर लिया गया हो।'
        },
        {
          description: 'मैं उठाने के उपकरण या सहायक साधनों की संचालन सीमाओं का उल्लंघन नहीं करूंगा।'
        },
        {
          description: 'मैं किसी लटकते हुए भार के नीचे से चलने या खड़े होने से बचूंगा।'
        }
      ],
      ur: [
        {
          description: 'میں صرف تب اٹکل پر بوجھ لٹکاؤں گا اگر میں تربیت یافتہ اور مجاز ہوں'
        },
        {
          description: 'میں لفٹنگ سہولیات اور لوازمات کا صرف تب استعمال کروں گا اگر وہ مناسب انداز سے ٹیگ کیے گئے ہوں'
        },
        {
          description:
            'میں صرف تب وزن لوڈ کروں گا اگر میں ان افراد کے ساتھ رابطے میں ہوں جو اس لفٹنگ سرگرمی میں شامل اور اس سے متاثر گا ہونگے'
        },
        {
          description: 'میں صرف تب وزن لوڈ کروں گا اگر یہ محفوظ ہو اور پہلے ایک ٹیسٹ لفٹ کیا گیا ہو'
        },
        {
          description: 'میں لفٹنگ سہولیات اور آلات کی آپریٹنگ کی حدود سے تجاوز نہیں کروں گا'
        },
        {
          description: 'میں ایک لٹکے ہوئے وزن کے نیچے سے گزرنے یا کھڑے ہونے سے گریز کروں گا'
        }
      ],
      ar: [
        {
          description: 'لن أعلّق أي حِمل إلا إذا كنت متدرباً ومصرحاً لي بذلك.'
        },
        {
          description: 'لن أستخدم معدات وملحقات الرفع ما لم تكن موضوعاً عليها الملصقات المناسبة.'
        },
        {
          description: 'لن أرفع أي حِمل إلا إذا كنت قادراً على التواصل مع المشاركين والمتأثرين بأعمال الرفع.'
        },
        {
          description: 'لن أتجاوز حدود التشغيل لمعدات أو ملحقات الرفع.'
        },
        {
          description: 'لن أتجاوز قيود تشغيل معدات وملحقات الرفع.'
        },
        {
          description: 'سوف أتجنب السير أو الوقوف تحت أي حِمل مرفوع.'
        }
      ],
      ta: [
        {
          description: 'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே லோடை ஸ்லிங் செய்வேன்.'
        },
        {
          description:
            'லிஃப்ட்டிங் உபகரணம் மற்றும் கருவிகள் பொருத்தமான முறையில் டேக் செய்யப்பட்டிருந்தால் மட்டுமே அவற்றைப் பயன்படுத்துவேன்.'
        },
        {
          description:
            'லிஃப்ட்டிங் செயல்பாட்டில் சம்பந்தப்பட்டுள்ள அனைவருடனும் என்னால் தொடர்புகொள்ள முடிந்தால் மட்டுமே, நான் லோடை லிஃப்ட் செய்வேன்.'
        },
        {
          description:
            'லோட் பாதுகாப்பானதாகவும், டெஸ்ட் லிஃப்ட் செய்யப்பட்டிருந்தால் மட்டுமே, நான் லோடை லிஃப்ட் செய்வேன்.'
        },
        {
          description: 'லிஃப்ட்டிங் உபகரணம் அல்லது கருவிகளின் ஆப்பரேட்டிங் வரம்புகளை நான் கடக்க மாட்டேன்.'
        },
        {
          description: 'தொங்கும் லோடிற்குக் கீழ் நிற்பதை அல்லது நடப்பதைத் தவிர்ப்பேன்.'
        }
      ],
      ml: [
        {
          description: 'എനിക്ക് പരിശീലനം ലഭിച്ചാലും എന്നെ അധികാരപ്പെടുത്തിയാലും മാത്രമേ ഞാൻ ലോഡ്കെട്ടിത്തൂക്കുകയുള'
        },
        {
          description:
            'ഭാരമുയർത്തുന്നതിനുള്ള എക്യുപ്‌മെന്റും അനുബന്ധോപകരണങ്ങളും ഉചിതമായി ടാഗ് ചെയ്യപ്പെട്ടിട്ടില്ലെങ്കിൽ, ഞാൻ ഉപയോഗിക്ക'
        },
        {
          description:
            'ഭാരമുയർത്തൽ പ്രവർത്തനത്തിൽ ഉൾപ്പെട്ടവരോടും ബാധിച്ചേക്കാവുന്നവരോടും ആശയവിനിമയം നടത്താൻ കഴിയുമെങ്കിൽ മാത്രമേ ഞാൻ ലോഡ് ഉയർത്തുകയുള'
        },
        {
          description:
            'ലോഡ് ഭദ്രമായി ബന്ധിപ്പിച്ചിട്ടുണ്ടെങ്കിലും ഉയർത്തൽ ടെസ്റ്റിംഗ് നിർവഹിച്ചിട്ടുണ്ടെങ്കിലും മാത്രമേ ഞാൻ ഭാരം ഉയർത്ത'
        },
        {
          description: 'ഭാരമുയർത്തൽ എക്യുപ്‌മെന്റിന്റെയോ അനുബന്ധോപകരണങ്ങളുടെയോ പ്രവർത്തന പരിധികളെ ഞാൻ മറികടക്ക'
        },
        {
          description: 'തൂങ്ങി നിൽക്കുന്ന ഒരു ലോഡിന് കീഴിൽ നടക്കുന്നതോ നിൽക്കുന്നതോ ഞാൻ ഒഴിവാക'
        }
      ],
      bn: [
        {
          description: 'আমি প্রশিক্ষিত অথবা অনুমোদিত হই তাহলে আমি দড়িতে ওজন ঝ�োলাব�ো'
        },
        {
          description: 'আমি উত্তোলনের সরঞ্জাম এবং উপকরণ যথোপযুক্তভাবে ট্যাগ করা থাকলে তবেই ব্যবহার করব�ো'
        },
        {
          description: 'ভারোত্তলনের দায়িত্ব প্রাপ্তদের সাথে যোগাযোগ করে তবেই আমি কোনো লোড উত্তোলন করব�ো'
        },
        {
          description: 'যদি সুরক্ষিত এবং পরীক্ষিত উত্তোলন হয়ে থাকে তবেই আমি কোনো লোড তুলব�ো'
        },
        {
          description: 'আমি তোলার সরঞ্জাম এবং উপকরনের অপারেটিং সীমা কখনই অতিক্রম করব�ো না'
        },
        {
          description: 'স্থগিত লোডের নিচে দিয়ে হাঁটা অথবা দাড়ানো বর্জন করব�ো'
        }
      ]
    }
  },
  {
    categoryType: 'breaking_ground',
    image: 'breakingGround',
    title: {
      en: 'Breaking Ground & Excavations',
      hi: 'जमीन को खोदना और उत्खनन',
      ur: 'زمین توڑنا اور کھدائی',
      ar: 'اختراق الأرض والحفريات',
      ta: 'தரையை உடைத்தல் & தோண்டுதல்',
      ml: 'മണ്ണ് കുഴിക്കലും ഉൽഖനനങ്ങളു',
      bn: 'মাঠ ভাঙা এবং খনন করা'
    },
    descriptions: {
      en: [
        {
          description:
            'I will only break ground if a permit is in place and I am aware of the precautions within the permit.'
        },
        {
          description: 'I will not break ground unless the services have been detected by a locating device.'
        },
        {
          description: 'I will only carry out safe digging practices close to buried services.'
        },
        {
          description: 'I will only enter an excavation if the sides have been stepped, sloped or supported.'
        },
        {
          description:
            'I will only enter an excavation if protection is in place to prevent people or vehicles falling in.'
        },
        {
          description: 'I will only access an excavation if ramps, steps or a secure ladder is in place.'
        }
      ],
      hi: [
        {
          description:
            'मैं ज़मीन को केवल तभी खोदूंगा अगर इसका परमिट मिल गया हो और मुझे परमिट में दी गई सावधानियों की जानकारी हो।'
        },
        {
          description:
            'मैं ज़मीन को तब तक नहीं खोदूंगा जब तक कि स्थान का पता लगाने के उपकरण द्वारा ज़मीन के नीचे की सेवाओं का पता नहीं लगा लिया गया हो।'
        },
        {
          description: 'मैं सेवाओं के लिए खुदाई के स्थानों पर सुरक्षित रूप से खोदने की प्रक्रियाएं ही करूंगा।'
        },
        {
          description:
            'मैं किसी उत्खनन वाले स्थान में केवल तभी प्रवेश करूंगा, अगर इसकी दीवारों पर सीढ़ियां हों, इसमें ढलान हो या इस पर सपोर्ट लगा हो।'
        },
        {
          description:
            'मैं उत्खनन वाले स्थान में केवल तभी प्रवेश करूंगा अगर इसमें गिरने से लोगों या वाहन को बचाने के लिए सुरक्षा मौजूद हो।'
        },
        {
          description: 'मैं उत्खनन वाले स्थान को केवल तभी एक्सेस करूंगां अगर रैंप, चढ़ाव या सुरक्षित सीढ़ी मौजूद हो।'
        }
      ],
      ur: [
        {
          description:
            'میں زمین کو صرف تب توڑوں گا اگر اجازت نامہ موجود ہو اور مجھے اجازت نامے کے اندر احتیاطی تدابیر سے آگاہی حاصل ہو'
        },
        {
          description:
            'میں تب تک زمین نہیں توڑوں گا جب تک ایک مقام شناسی آلہ کے ذریعہ زیر زمین موجود پائپ لائن اور تاروں کا کھوج نہ لگایا گیا ہو'
        },
        {
          description: 'میں زیر زمین موجود پائپ لائن اور تاروں کے قریب صرف کھدائی کرنے کا محفوظ عمل انجام دوں گا'
        },
        {
          description:
            'میں زمین کے کسی کھدے ہوئے حصے میں صرف تب داخل ہوں گا اگر اس کے اطراف مرحلہ وار، ڈھلان والے یا معاونت شدہ ہیں۔'
        },
        {
          description:
            'میں  صرف تب کسی کھدے ہوئے حصے میں داخل ہوں گا اگر لوگوں یا گاڑیوں کو اندر گرنے سے بچانے کے لئے خفاظتی انتظامات ہونگے'
        },
        {
          description:
            'میں صرف تب ہی زمین کے کسی کھدے ہوئے حصے میں داخل ہونگا ا اگر ڈھلان، مراحل یا ایک محفوظ سیڑھی موجود ہوگی'
        }
      ],
      ar: [
        {
          description: 'لن أخترق الأرض إلا بتصريح وبعد معرفة الاحتياطات التي يتضمنها.'
        },
        {
          description: 'لن أخترق الأرض إلا بعد الكشف عن المرافق بواسطة جهاز تحديد مواقع هذه المرافق.'
        },
        {
          description: 'سوف أقوم فقط بممارسات حفر آمنة بالقرب من المرافق التحتية.'
        },
        {
          description: 'لن أدخل الحفريات إلا بعد تدريج جوانبها أو إمالتها أو تدعيمها.'
        },
        {
          description: 'لن أدخل الحفريات إلا بوجود وسائل حماية تمنع وقوع الأشخاص أو المركبات فيها.'
        },
        {
          description: 'لن أدخل الحفريات إلا بوجود منحدرات أو مدرجات آمنة أو وجود سلم مناسب.'
        }
      ],
      ta: [
        {
          description:
            'பணி செய்ய அனுமதி இருக்க வேண்டும், அந்த அனுமதியில் உள்ள முன்னெச்சரிக்கை நடவடிக்கைகள் பற்றி எனக்குத் தெரிந்திருந்தால் மட்டுமே தரையை உடைப்பேன்.'
        },
        {
          description:
            'இருப்பிடமறியும் சாதனத்தின் மூலம் சர்வீஸ்கள் கண்டறியப்பட்டிருந்தாலொழிய, நான் தரையை உடைக்க மாட்டேன்.'
        },
        {
          description:
            'புதைக்கப்பட்ட சர்வீஸ்களுக்கு நெருக்கமான இடத்தில், பாதுகாப்பான தோண்டுதல் நடவடிக்கைகளை மட்டுமே நான் மேற்கொள்வேன்.'
        },
        {
          description:
            'பக்கவாட்டில் உயர்த்தப்பட்டிருந்தால், சாய்வாக கட்டப்பட்டிருந்தால் அல்லது முட்டுக் கொடுக்கப்பட்டிருந்தால் மட்டுமே குழியில் இறங்குவேன்.'
        },
        {
          description:
            'மக்கள் அல்லது வாகனங்கள் உள்ளே விழுவதைத் தவிர்க்க, பாதுகாப்பு அமைக்கப்பட்டிருந்தால் மட்டுமே பணியில் ஈடுபடுவேன்.'
        },
        {
          description: 'சாய்வுப்பாதைகள், படிகட்டுகள் அல்லது பாதுகாப்பான ஏணி இருந்தால் மட்டுமே குழியில் இறங்குவேன்.'
        }
      ],
      ml: [
        {
          description:
            'കൈവശം അനുമതി ഉണ്ടെങ്കിലും അനുമതിയിലെ മുൻകരുതലുകളെ കുറിച്ച് ഞാൻ മനസ്സിലാക്കിയിട്ടുണ്ടെങ്കിലും മാത്രമേ ഞാൻ മണ്ണ് കുഴിക്ക'
        },
        {
          description:
            'മണ്ണിനടിലൂടെ പോകുന്ന സേവനങ്ങളുടെ പൈപ്പുകളെ ഒരു ലൊക്കേറ്റിംഗ് ഉപകരണം ഉപയോഗിച്ച് തിരിച്ചറിയാത്ത പക്ഷം, ഞാൻ മണ്ണ് കുഴിക്ക'
        },
        {
          description:
            'കുഴിച്ചിട്ടിരിക്കുന്ന സേവന പൈപ്പുകൾക്ക് അടുത്ത് മണ്ണ് കുഴിക്കുമ്പോൾ ഞാൻ സുരക്ഷിത കുഴിക്കൽ സമ്പ്രദായങ്ങൾ മാത്രമേ നിർവഹിക്കുകയുള'
        },
        {
          description:
            'ഉൽഖനനം നടത്തിയ കുഴിയിലേക്ക് സ്റ്റെപ്പുകൾ ഉണ്ടെങ്കിലോ കുഴിയുടെ വശത്തിന്ചരിവ് ഉണ്ടെങ്കിലോ എന്തെങ്കിലും തരത്തിലുള്ള സപ്പോർട്ട് ഉണ്ടെങ്കിലോ മാത്രമാണ് ഞാൻ ആ കുഴിയിലേക്ക് പ്രവേശിക്കുകയുള'
        },
        {
          description:
            'ഉൽഖനന കുഴിയിലേക്ക് ആളുകളോ വാഹനങ്ങളോ വീഴുന്നത്തടയുന്നതിനുള്ള പരിരക്ഷയുണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ആ കുഴിയിലേക്ക് പ്രവേശിക്കുകയുള'
        },
        {
          description:
            'റാമ്പുകളോ സ്റ്റെപ്പുകളോ ഭദ്രമായി ബന്ധിപ്പിച്ചിട്ടുള്ള ഒരു കോണിയോ ഉണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ഉൽഖനന കുഴിയിലേക്ക് പ്രവേശിക്കുകയുള'
        }
      ],
      bn: [
        {
          description: 'অনুমোদন থাকলে এবং অনুমোদনের ভিতরের সতর্কতা সঠিক ভাবে জেনে তবেই আমি মাঠ খনন করব'
        },
        {
          description: 'অবস্থান নির্নয়কারী ডিভাইস দ্বারা পরিষেবাটি শনাক্ত না হওয়া পর্যন্ত আমি মাঠ ভাঙ্গব না'
        },
        {
          description: 'আমি কেবল আচ্ছাদিত পরিষেবার কাছাকাছিই খনন কার্য চালাব'
        },
        {
          description: 'ধাপ ধাপ, ঢালু অথবা সমর্থিত থাকলে তবেই আমি খননে প্রবেশ করব�ো'
        },
        {
          description: 'মানুষ অথবা যানবাহন পরে যাওয়া থেকে সুরক্ষিত থাকলে তবেই আমি খননে প্রবেশ করব�ো'
        },
        {
          description: 'যদি রাম্প, ধাপ ধাপ অথবা সুরক্ষিত সিড়ি থাকে তবেই আমি খননে প্রবেশ করব�ো'
        }
      ]
    }
  },
  {
    categoryType: 'driving',
    image: 'driving',
    title: {
      en: 'Driving',
      hi: 'ड्राइविंग',
      ur: 'ڈرائیونگ',
      ar: 'قيادة المركبات',
      ta: 'டிரைவிங்',
      ml: 'വാഹനമോ-ടിക്കൽ',
      bn: 'ড্রাইভ করা'
    },
    descriptions: {
      en: [
        {
          description: 'I will only operate a vehicle if I have the correct license.'
        },
        {
          description: 'I will not drive under the influence of medication, drugs or alcohol.'
        },
        {
          description: 'I will not drive with an unsecure load.'
        },
        {
          description: 'I will always wear a seatbelt when driving and ensure that passengers do the same.'
        },
        {
          description: 'I will not use a mobile phone or any other type of handheld device when driving.'
        },
        {
          description: 'I will obey signs and signals and not exceed speed limits.'
        }
      ],
      hi: [
        {
          description: 'मैं वाहन का संचालन केवल तभी करूंगा यदि मेरे पास सही लाइसेंस है।'
        },
        {
          description: 'मैं किसी दवा, नशीले पदार्थ या शराब के प्रभाव में ड्राइव नहीं करूंगा।'
        },
        {
          description: 'मैं किसी असुरक्षित भार के साथ ड्राइव नहीं करूंगा।'
        },
        {
          description:
            'मैं ड्राइव करते समय हमेशा सीटबेल्ट पहनूंगा और सुनिश्चित करूंगा कि गाड़ी में बैठे यात्री भी ऐसा ही करें।'
        },
        {
          description:
            'मैं ड्राइव करते समय किसी भी मोबाइल फोन या किसी अन्य प्रकार के हाथ में पकड़ने के उपकरण का उपयोग नहीं करूंगा।'
        },
        {
          description: 'मैं संकेतों और चिह्नों का पालन करूंगा और गति सीमाओं का उल्लंघन नहीं करूंगा।'
        }
      ],
      ur: [
        {
          description: 'میں گاڑی کو صرف اس صورت میں چلاوں گا جب میرے پاس اسکو چلانے کا صیح لائسنس ہو'
        },
        {
          description: 'میں دوا، منشیات یا شراب کے زیر اثر ڈرائیو نہیں کروں گا'
        },
        {
          description: 'میں غیر محفوظ لوڈ کے ساتھ ڈرائیو نہیں کروں گا'
        },
        {
          description: 'ڈرائیونگ کے دوران میں ہمیشہ سیٹ بیلٹ لگا کر رکھوں گا اور یقینی بناؤں گا کہ مسافر بھی ایسا کریں'
        },
        {
          description: 'ڈرائیونگ کے دوران میں موبائل فون یا دیگر اقسام کے دستی آلات کا استعمال نہیں کروں گا'
        },
        {
          description: 'میں نشانات اور اشاروں کی پابندی کروں گا اور حد رفتار سے تجاوز نہیں کروں گا'
        }
      ],
      ar: [
        {
          description: 'ﺳﺄقوم بتشغيل المركبة فقط إذا كان لدي الرخصة المناسبة لنوعية المركبة.'
        },
        {
          description: 'لن أقود مركبة إذا كنت تحت تأثير الأدوية أو العقاقير أو الكحول.'
        },
        {
          description: 'لن أقود أي مركبة بها حِمل غير آمن.'
        },
        {
          description: 'سوف استخدم حزام الأمان عند القيادة دائما كما أتأكد من استخدام الركاب لأحزمتهم.'
        },
        {
          description: 'لن أستخدم الهاتف الجوال أو أي نوع آخر من الأجهزة المحمولة عند القيادة.'
        },
        {
          description: 'سوف أتبع التعليمات و اللوحات الإرشادية ولن أتجاوز السرعات المقررة.'
        }
      ],
      ta: [
        {
          description: 'நான் சரியான உரிமம் பெற்றிருந்தால் மட்டுமே வாகனத்தை இயக்குவேன்.'
        },
        {
          description: 'மருந்து, போதைப்பொருள் அல்லது ஆல்கஹால் எடுத்திருக்கும்போது நான் வாகனம் ஓட்ட மாட்டேன்.'
        },
        {
          description: 'பாதுகாப்பற்ற லோடை வைத்துக் கொண்டு வாகனம் ஓட்ட மாட்டேன்.'
        },
        {
          description:
            'வாகனம் ஓட்டும் செய்யும்போது எப்போதும் சீட் பெல்ட் அணிந்துகொள்வேன், பயணிகளும் அணிந்துகொள்ளுமாறு பார்த்துக்கொள்வேன்.'
        },
        {
          description: 'வாகனம் ஓட்டும் போது மொபைல் ஃபோன் அல்லது எந்தவொரு கையடக்கக் கருவிகளையும் பயன்படுத்த மாட்டேன்.'
        },
        {
          description: 'குறியீடுகள் மற்றும் சிக்னல்களை மதிப்பேன், வேக வரம்பைத் தாண்ட மாட்டேன்.'
        }
      ],
      ml: [
        {
          description: 'ശരിയായ ലൈസൻസ് ഉണ്ടെങ്കിൽ മാത്രമേ ഞാൻ വാഹനം പ്രവർത്തിപ്പിക്കുകയുള്ളൂ.'
        },
        {
          description: 'മയക്കുമരുന്നിന്റെയോ മദ്യത്തിന്റെയോ സ്വാധീനത്തിൽ ഒരിക്കലും വാഹനമോടിക്ക'
        },
        {
          description: 'കെട്ടിയുറപ്പിച്ചിട്ടില്ലാത്ത ലോഡുള്ള വാഹനം ഞാൻ ഓടിക്ക'
        },
        {
          description:
            'വാഹനം ഓടിക്കുമ്പോൾ ഞാൻ സീറ്റ്ബെൽറ്റ് ധരിക്കും, യാത്രക്കാരു ം അങ്ങനെ ചെയ്യുന്നുണ്ടെന്ന് ഉറപ്പാക്കും'
        },
        {
          description:
            'വാഹനം ഓടിക്കുന്ന സമയത്ത് ഞാൻ മൊബൈൽ ഫ�ോണോ മറ്റേതെങ്കിലും തരത്തിലുള്ള ഹാൻഡ്‌ഹെൽഡ് ഉപകരണമോ ഉപയോഗിക്ക'
        },
        {
          description: 'സൂചനകളും അടയാളങ്ങളും ഞാൻ അനുസരിച്ചും, വേഗ പരിധി മറികടക്ക'
        }
      ],
      bn: [
        {
          description: 'আমার যদি সঠিক লাইসেন্স থাকে তবেই আমি শুধুমাত্র গাড়ি পরিচালনা করব'
        },
        {
          description: 'আপনি ওষুধ, ড্রাগ বা মদের নেশায় আচ্ছন্ন অবস্থায় ড্রাইভ করব না'
        },
        {
          description: 'আমি অসুরক্ষিত লোড সহ গাড়ি চালাব না'
        },
        {
          description: 'আমি ড্রাইভ করার সময় সর্বদা সিটবেল্ট লাগাব�ো এবং নিশ্চিত করব যে যাত্রীরাও তাই যেন কর'
        },
        {
          description: 'আমি ড্রাইভ করার সময় মোবাইল ফোন বা অন্য কোনো হাতে ধরা ডিভাইস ব্যবহার করব না'
        },
        {
          description: 'আমি চিহ্ন এবং সিগন্যালগুলি মেনে চলব এবং গতির সীমা অতিক্রম করব না'
        }
      ]
    }
  },
  {
    categoryType: 'hot_works',
    image: 'hotWorks',
    title: {
      en: 'Hot Works',
      hi: 'तप्त वस्तुओं का काम',
      ur: 'ہاٹ ورک',
      ar: 'الأعمال الحرارية كالتلحيم وغيره',
      ta: 'ஹாட் வொர்க்',
      ml: 'ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി',
      bn: 'গরম কাজ'
    },
    descriptions: {
      en: [
        {
          description:
            'I will only undertake hot work if a permit is in place and I am aware of the precautions within the permit.'
        },
        {
          description: 'I will only undertake hot work if I am trained and authorised.'
        },
        {
          description: 'I will only undertake hot work if combustible materials have been removed or protected.'
        },
        {
          description: 'I will only undertake hot work if a ‘fire watcher’ is in place.'
        },
        {
          description:
            'I will only undertake hot work if a suitable fire extinguisher is available at the hot work location.'
        },
        {
          description: 'I will only undertake hot work if I am aware of the emergency arrangements.'
        }
      ],
      hi: [
        {
          description:
            'मैं तप्त वस्तुओं का काम केवल तभी करूंगा अगर इसका परमिट मिल गया हो और मुझे परमिट में दी गई सावधानियों की जानकारी हो।'
        },
        {
          description: 'मैं केवल प्रशिक्षित और अधिकृत होने पर ही तप्त वस्तुओं का काम करूंगा।'
        },
        {
          description:
            'मैं तप्त वस्तुओं का काम केवल तभी करूंगा, अगर ज्वलनशील सामग्री को निकाल दिया गया हो या सुरक्षित कर दिया गया हो।'
        },
        {
          description: 'मैं तप्त वस्तुओं का काम केवल तभी करूंगा, अगर ‘फ़ायर वॉचर’ मौजूद हो।'
        },
        {
          description:
            'मैं तप्त वस्तुओं का काम केवल तभी करूंगा अगर तप्त वस्तुओं का काम करने के स्थान पर उपयुक्त अाग बुझाने के उपकरण उपलब्ध हो।'
        },
        {
          description: 'मैं आपातकालीन व्यवस्थाओं के बारे में जानकारी होने पर ही तप्त वस्तुओं का काम करूंगा।'
        }
      ],
      ur: [
        {
          description:
            'میں ہاٹ ورک صرف تب انجام دوں گا اگر اجازت نامہ جگہ پر موجود ہو اور مجھے اجازت نامے کے اندر احتیاطی تدابیر سے آگاہی حاصل ہو'
        },
        {
          description: 'میں صرف تب ہاٹ ورک انجام دوں گا اگر میں تربیت یافتہ اور مجاز ہوں'
        },
        {
          description: 'میں صرف تب ہاٹ ورک انجام دوں گا اگر آتش پذیر مواد کو ہٹایا گیا ہو یا محفوظ کیا گیا ہو'
        },
        {
          description: "میں صرف تب ہاٹ ورک انجام دوں گا اگر ایک 'آتش نگہبان' مقام پر موجود ہو"
        },
        {
          description: 'میں صرف تب ہاٹ ورک انجام دوں گا اگر ایک مناسب آگ بجھانے کا آلہ ہاٹ ورک مقام پر موجود ہو'
        },
        {
          description: 'میں صرف تب ہاٹ ورک انجام دوں گا اگر میں ہنگامی ترتیبات سے آگاہ ہوں'
        }
      ],
      ar: [
        {
          description: 'لن أقوم بأي أعمال حرارية إلا بتصريح وبعد معرفة الاحتياطات التي يتضمنها.'
        },
        {
          description: 'لن أقوم بأي أعمال حرارية إلا إذا كنت متدرباً ومصرحاً لي بذلك.'
        },
        {
          description: 'لن أقوم بأعمال حرارية إلا بعد إزالة المواد القابلة للاشتعال أو حمايتها.'
        },
        {
          description: 'لن أقوم بأعمال حرارية إلا بوجود «مراقب حرائق».'
        },
        {
          description: 'لن أقوم بأعمال حرارية إلا بوجود طفاية حريق مناسبة في موقع الأعمال الحرارية.'
        },
        {
          description: 'لن أقوم بأعمال حرارية إلا إذا كنت على دراية بترتيبات الطوارئ.'
        }
      ],
      ta: [
        {
          description:
            'பணி செய்ய அனுமதி இருக்க வேண்டும், அந்த அனுமதியில் உள்ள முன்னெச்சரிக்கை நடவடிக்கைகள் பற்றி எனக்குத் தெரிந்திருந்தால் மட்டுமே ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        },
        {
          description: 'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        },
        {
          description:
            'எளிதில் தீப்பற்றக்கூடிய பொருட்கள் அகற்றப்பட்டிருந்தால் அல்லது பாதுகாக்கப்பட்டிருந்தால் மட்டுமே, நான் ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        },
        {
          description: 'பணி நடைபெற உள்ள இடத்தில் “ஃபயர் வாட்ச்சர்” இருந்தால் மட்டுமே, நான் ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        },
        {
          description:
            'ஹாட் வொர்க் நடைபெற உள்ள இடத்தில் பொருத்தமான தீ அணைப்பான் இருந்தால் மட்டுமே, நான் ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        },
        {
          description: 'அவசர ஏற்பாடுகள் குறித்து நான் அறிந்திருந்தால் மட்டுமே, ஹாட் வொர்க்கை மேற்கொள்வேன்.'
        }
      ],
      ml: [
        {
          description:
            'കൈവശം അനുമതി ഉണ്ടെങ്കിലും അനുമതിയിലെ മുൻകരുതലുകളെ കുറിച്ച് ഞാൻ മനസ്സിലാക്കിയിട്ടുണ്ടെങ്കിലും മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        },
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിച്ചാലും എന്നെ അധികാരപ്പെടുത്തിയാലും മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        },
        {
          description:
            'തീപിടിക്കാവുന്ന മെറ്റീരിയലുകൾ നീക്കം ചെയ്തിട്ടുണ്ടെങ്കിലും അല്ലെങ്കിൽ പരിരക്ഷിക്കപ്പെട്ടിട്ടുണ്ടെങ്കിലും മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        },
        {
          description: 'ഒരു ‘ഫയർ വാച്ചർ’ ഉണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        },
        {
          description:
            'ചൂടുള്ള ഇടങ്ങളിൽ ജ�ോലി ചെയ്യുന്ന ലൊക്കേഷനിൽ ഉചിതമായ അഗ്നിശമന യന്ത്രം ഉണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        },
        {
          description: 'അടിയന്തിര സജ്ജീകരണങ്ങളെ കുറിച്ച് അറിവുണ്ടെങ്കിൽ മാത്രമേ ഞാൻ ചൂടുള്ള ഇടങ്ങളിലെ ജ�ോലി ഏറ്റെടുക്ക'
        }
      ],
      bn: [
        {
          description:
            'অনুমোদন থাকলে এবং অনুমোদনের ভিতরের সতর্কতা সঠিক ভাবে জেনে তবেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        },
        {
          description: 'আমি প্রশিক্ষিত অথবা অনুমোদিত হলে তবেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        },
        {
          description: 'দাহ্য পদার্থ সুরক্ষিত অথবা সরিয়ে দেওয়া হলে তবেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        },
        {
          description: 'অগ্নি নির্বাপক’ সঠিক জায়গায় থাকলেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        },
        {
          description: 'অগ্নি নির্বাপক যন্ত্র সঠিক জায়গায় থাকলেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        },
        {
          description: 'আপদকালীন ব্যবস্থা সম্পর্কে জানলেই তবেই আমি গরম কাজের দায়িত্ব গ্রহণ করব�ো'
        }
      ]
    }
  },
  {
    categoryType: 'energised_systems',
    image: 'energisedSystems',
    title: {
      en: 'Energised Systems',
      hi: 'ऊर्जायुक्त प्रणालियां',
      ur: 'توانائی کے نظام',
      ar: 'النظم المحملة بالطاقة و الضغط العالي',
      ta: 'ஆற்றல் அமைப்புகள்',
      ml: 'വൈദ്യുതി കൊണ്ട് പ്രവർത്തിക്കുന്ന സംവിധാനങ്ങൾ',
      bn: 'সক্রিয় সিস্টেম'
    },
    descriptions: {
      en: [
        {
          description:
            'I will only work on an energised system if a permit is in place and I am aware of the precautions within the permit.'
        },
        {
          description: 'I will only work on an energised system if I am trained and authorised.'
        },
        {
          description: 'I will only work on an energised system if all appropriate isolations are in place.'
        },
        {
          description:
            'I will only work on an energised system if a test has been carried out to verify the effectiveness of the isolation.'
        },
        {
          description: 'I will only apply and remove my own personal lock and tag.'
        },
        {
          description:
            'I will ensure additional precautions are followed when working on an energised system which cannot be isolated.'
        }
      ],
      hi: [
        {
          description:
            'मैं ऊर्जायुक्त प्रणालियों पर केवल तभी काम करूंगा अगर इसका परमिट मिल गया हो और मुझे परमिट में दी गई सावधानियों की जानकारी हो।'
        },
        {
          description: 'मैं केवल प्रशिक्षित और अधिकृत होने पर ही ऊर्जायुक्त प्रणालियों पर काम करूंगा।'
        },
        {
          description: 'मैं अलगाव करने के सभी उपयुक्त संसाधन मौजूद होने पर ही ऊर्जायुक्त प्रणालियोंपर काम करूंगा।'
        },
        {
          description:
            'मैं ऊर्जायुक्त प्रणालियों पर केवल तभी काम करूंगा अगर अलगाव करने के संसाधनों की प्रभावशीलता का सत्यापन करने के लिए जांच कर ली गई हो।'
        },
        {
          description: 'मैं अपना व्यक्तिगत लॉक और टैग ही लगाऊंगा और निकालूंगा।'
        },
        {
          description:
            'मैं ऐसी ऊर्जायुक्त प्रणालियों पर काम करते समय, जिन्हें अलग नहीं किया जा सकता, यह सुनिश्चित करूंगा कि अतिरिक्त सावधानियों का पालन किया जाएं।'
        }
      ],
      ur: [
        {
          description:
            'میں کسی توانائی کے نظام پر صرف تب کام کروں گا اگر اجازت نامہ جگہ پر موجود ہو اور مجھے اجازت نامے کے اندر احتیاطی تدابیر سے آگاہی حاصل ہو'
        },
        {
          description: 'میں کسی توانائی کے نظام پر صرف تب کام کروں گا اگر میں تربیت یافتہ اور مجاز ہوں'
        },
        {
          description: 'میں کسی توانائی کے نظام پر صرف تب کام کروں گا اگر تمام مناسب حاجز جگہ پر موجود ہوں'
        },
        {
          description:
            'میں کسی توانائی کے نظام پر صرف تب کام کروں گا اگر حاجز کے مؤثر ہونے کی تصدیق کے لیے ایک تجزیہ کیا گیا ہو'
        },
        {
          description: 'میں صرف اپنے ذاتی لاک اور ٹیگ کا اطلاق کروں گا اور اسے ہٹاؤں گا'
        },
        {
          description:
            'کسی توانائی کے نظام پر کام کرتے وقت جسے حاجز نہیں کیا جا سکتا، میں اضافی احتیاطی تدابیر کی پیروی کو یقینی بناؤں گا'
        }
      ],
      ar: [
        {
          description: 'لن أعمل على نظم محملة بالطاقة إلا بتصريح وبعد معرفة الاحتياطات التي يتضمنها.'
        },
        {
          description: 'لن أعمل على نظم محملة بالطاقة إلا إذا كنت متدرباً ومصرحاً لي بذلك.'
        },
        {
          description: 'لن أعمل على نظم محملة بالطاقة إلا بوجود كل عوامل العزل المناسبة.'
        },
        {
          description: 'لن أعمل على نظم محملة بالطاقة إلا بعد إجراء اختبار للتحقق من فعالية العزل.'
        },
        {
          description: 'سوف أقوم شخصيا بوضع وإزالة القفل والبطاقة الخاصين بي.'
        },
        {
          description: 'سوف أتأكد من اتباع احتياطات إضافية عند العمل على نظم محملة بالطاقة يتعذر عزلها.'
        }
      ],
      ta: [
        {
          description:
            'பணி செய்ய அனுமதி இருக்க வேண்டும், அந்த அனுமதியில் உள்ள முன்னெச்சரிக்கை நடவடிக்கைகள் பற்றி எனக்குத் தெரிந்திருந்தால் மட்டுமே சக்திவாய்ந்த அமைப்புகளில் வேலை செய்வேன்.'
        },
        {
          description: 'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே ஆற்றல் அமைப்புகளில் வேலை செய்வேன்.'
        },
        {
          description:
            'தனிமைப்படுத்துதலுக்கான வசதிகள் அனைத்தும் இருக்கும்பட்சத்தில் மட்டுமே ஆற்றல் அமைப்புகளில் வேலை செய்வேன்.'
        },
        {
          description:
            'தனிமைப்படுத்தலின் செயல்திறனை சரிபார்க்க சோதனை நடத்தப்பட்டிருந்தால் மட்டுமே ஆற்றல் அமைப்புகளில் வேலை செய்வேன்.'
        },
        {
          description: 'என்னுடைய தனிப்பட்ட பூட்டையும், டேகையும் நானாக மட்டுமே பொருத்துவேன், அகற்றுவேன்.'
        },
        {
          description:
            'தனிமைப்படுத்த முடியாத சக்திவாய்ந்த அமைப்புகளில் வேலை செய்யும்போது கூடுதல் முன்னெச்சரிக்கைநடவடிக்கைகளைப் பின்பற்றுவேன்.'
        }
      ],
      ml: [
        {
          description:
            'കൈവശം അനുമതി ഉണ്ടെങ്കിലും അനുമതിയിലെ മുൻകരുതലുകളെ കുറിച്ച് ഞാൻ മനസ്സിലാക്കിയിട്ടുണ്ടെങ്കിലും മാത്രമേ വൈദ്യുതി കൊണ്ട്പ്രവർത്തിക്കുന്ന സംവിധാനങ്ങളിൽ ഞാൻ ജ�ോലി ചെയ്യ'
        },
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിച്ചാലും എന്നെ അധികാരപ്പെടുത്തിയാലും മാത്രമേ വൈദ്യുതി കൊണ്ട് പ്രവർത്തിക്കുന്ന സംവിധാനങ്ങളിൽ ഞാൻ ജ�ോലി ചെയ്യ'
        },
        {
          description:
            'എല്ലാ വൈദ്യുത കണക്ഷനുകളും ഉചിതമായി വിച്ഛേദിച്ചിട്ടുണ്ടെങ്കിൽ മാത്രമേ വൈദ്യുതി കൊണ്ട് പ്രവർത്തിക്കുന്ന സംവിധാനങ്ങളിൽ ഞാൻ ജ�ോലി ചെയ്യ'
        },
        {
          description:
            'വൈദ്യുതി വിച്ഛേദനത്തിന്റെ കാര്യക്ഷമത പരിശോധിച്ചുറപ്പിക്കുന്നതിനുള്ള ടെസ്റ്റ് നിർവഹിച്ചിട്ടുണ്ടെങ്കിൽ മാത്രമേ വൈദ്യുതി കൊണ്ട്പ്രവർത്തിക്കുന്ന സംവിധാനങ്ങളിൽ ഞാൻ ജ�ോലി ചെയ്യ'
        },
        {
          description: 'ഞാനെന്റെ വ്യക്തിഗത ലോക്കും ടാഗു ം ഘടിപ്പിക്കും, നീക്കം ചെയ'
        },
        {
          description:
            'വൈദ്യുതി ബന്ധം വിച്ഛേദിക്കാൻ കഴിയാത്ത ഒരു സംവിധാനത്തിൽ പ്രവർത്തിക്കുമ്പോൾ, അധിക മുൻകരുതലുകളെല്ലാം പിന്തുടരുന്നുണ്ടെന്ന് ഞാൻ ഉറപ്പാക്കും'
        }
      ],
      bn: [
        {
          description:
            'যদি কোনো অনুমতি পত্র থাকে এবং আমি অনুমতি পত্র সংশ্লিষ্ট সতর্কতার বিষয়ে সচেতন কেবল তবেই আমি সক্রিয় সিস্টেমে কাজ করব'
        },
        {
          description: 'আমি যদি প্রশিক্ষিত এবং অনুমোদিত হই কেবল তবেই আমি সক্রিয় সিস্টেমে কাজ করব'
        },
        {
          description: 'যদি সকল যথাযথ বিচ্ছন্নতাগুলি যথাযথভাবে থাকে কেবল তবেই আমি কোনো সক্রিয় সিস্টেমে কাজ করব'
        },
        {
          description:
            'যদি বিচ্ছিন্নতার সক্ষমতার ওপর কোনো পরীক্ষা করা হয়ে গিয়ে থাকে কেবল তবেই আমি কোনো সক্রিয় সিস্টেমে কাজ করব'
        },
        {
          description: 'কেবল আমিই আমার নিজের ব্যক্তিগত লক এবং ট্যাগ খুলব�ো'
        },
        {
          description:
            'বিচ্ছিন্ন করা যাবেনা এমন সক্রিয় সিস্টেমে কাজ করার সময় আমি অতিরিক্ত সুরক্ষার জন্য সচেতনতা অবলম্বন করব'
        }
      ]
    }
  },
  {
    categoryType: 'confined_spaces',
    image: 'confinedSpaces',
    title: {
      en: 'Confined Spaces',
      hi: 'संकरे स्थान',
      ur: 'محدود مقامات',
      ar: 'الأماكن المغلقة و المحصورة',
      ta: 'வரையறு-க்கப்பட்ட இடங்கள்',
      ml: 'ചുറ്റും മറയുള്ള പരിമിതപ്പെടു-ത്തപ്പെട്ട ഇടങ്ങൾ',
      bn: 'আবদ্ধ স্থান'
    },
    descriptions: {
      en: [
        {
          description:
            'I will only enter a confined space if a permit is in place and I am aware of the precautions within the permit.'
        },
        {
          description: 'I will only enter a confined space if I am trained and authorised.'
        },
        {
          description:
            'I will only enter a confined space if the atmosphere has been tested and is continuously monitored.'
        },
        {
          description: 'I will only enter a confined space if a ‘top man’ is in place.'
        },
        {
          description: 'I will only enter a confined space if I can communicate with those involved in the work.'
        },
        {
          description:
            'I will only enter a confined space if I am aware of the rescue plan and rescue equipment is available.'
        }
      ],
      hi: [
        {
          description:
            'मैं संकरे स्थानों में केवल तभी प्रवेश करूंगा अगर इसका परमिट मिल गया हो और मुझे परमिट में दी गई सावधानियों की जानकारी हो।'
        },
        {
          description: 'मैं केवल प्रशिक्षित और अधिकृत होने पर ही संकरे स्थानों में प्रवेश करूंगा।'
        },
        {
          description:
            'मैं संकरे स्थानों में केवल तभी प्रवेश करूंगा अगर परिवेश की जांच कर ली गई हो और उसकी लगातार निगरानी की जाती है।'
        },
        {
          description: 'मैं संकरे स्थानों में केवल तभी प्रवेश करूंगा अगर ‘टॉप मैन’ मौजूद हो।'
        },
        {
          description:
            'मैं संकरे स्थानों में केवल तभी प्रवेश करूंगा अगर मैं इस काम में शामिल लोगों के साथ बातचीत कर सकूं।'
        },
        {
          description:
            'मैं संकरे स्थानों में केवल तभी प्रवेश करूंगा अगर मुझे बच निकलनेकी योजना की जानकारी हो और बच निकलने के उपकरण उपलब्ध हों।'
        }
      ],
      ur: [
        {
          description:
            'میں محدود مقامات پر صرف تب داخل ہوں گا اگر اجازت نامہ جگہ پر موجود ہو اور مجھے اجازت نامے کے اندر احتیاطی تدابیر سے آگاہی حاصل ہو'
        },
        {
          description: 'میں محدود مقامات پر صرف تب داخل ہوں گا اگر میں تربیت یافتہ اور مجاز ہوں'
        },
        {
          description:
            'میں محدود مقامات پر صرف تب داخل ہوں گا اماحولیاتی حالات کا تجزیہ کیا گیا ہو اور اس کی مسلسل نگرانی کی جا رہی ہو'
        },
        {
          description: 'میں محدود مقامات پر صرف تب داخل ہوں گا اگر «ٹاپ مین» موجود ہوگا'
        },
        {
          description:
            'میں محدود مقامات پر صرف تب داخل ہوں گا اگر میں ان سے لوگوں رابطے میں رہ سکوں گا جو کام میں ملوث ہیں'
        },
        {
          description:
            'میں محدود مقامات پر صرف تب داخل ہوں گا اگر میں بچاو کی منصوبہ بندی سے آگاہ ہوں گا اور بچاو کے لیے آلات موجود ہوں'
        }
      ],
      ar: [
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا بتصريح وبعد معرفة الاحتياطات التي يتضمنها.'
        },
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا إذا كنت متدرباً ومصرحاً لي بذلك.'
        },
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا بعد اختبار نسبة الأكسجين فيها ومراقبته بشكل مستمر.'
        },
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا بوجود «مراقب».'
        },
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا إذا كنت قادراً على التواصل (المستمر) مع المشاركين في العمل.'
        },
        {
          description: 'لن أدخل أماكن مغلقة أو محصورة إلا إذا كنت على دراية بخطة الإنقاذ وبوجود معدات إنقاذ.'
        }
      ],
      ta: [
        {
          description:
            'பணி செய்ய அனுமதி இருக்க வேண்டும், அந்த அனுமதியில் உள்ள முன்னெச்சரிக்கை நடவடிக்கைகள் பற்றி எனக்குத் தெரிந்திருந்தால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        },
        {
          description:
            'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        },
        {
          description:
            'பணிச்சூழல் சோதிக்கப்பட்டு தொடர்ச்சியாக. கண்காணிக்கப்பட்டால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        },
        {
          description: 'பணி நடைபெற உள்ள இடத்தில் ‘டாப் மேன்’ இருந்தால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        },
        {
          description:
            'பணியில் ஈடுபட்டுள்ள அனைவருடனும் என்னால் தொடர்புகொள்ள முடிந்தால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        },
        {
          description:
            'மீட்புத் திட்டம் மற்றும் மீட்பு உபகரணம் குறித்து நான் அறிந்திருந்தால் மட்டுமே வரையறுக்கப்பட்ட இடத்திற்குள் நுழைவேன்.'
        }
      ],
      ml: [
        {
          description:
            'കൈവശം അനുമതി ഉണ്ടെങ്കിലും അനുമതിയിലെ മുൻകരുതലുകളെ കുറിച്ച് ഞാൻ മനസ്സിലാക്കിയിട്ടുണ്ടെങ്കിലും മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിച്ചാലും എന്നെ അധികാരപ്പെടുത്തിയാലും മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description:
            'ഉള്ളിലെ വായു ടെസ്റ്റ്ചെയ്തിട്ടുണ്ടെങ്കിലും തുടർച്ചയായി നിരീക്ഷണം ഉണ്ടെങ്കിലും മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description: '‘മുകളിൽ ഒരാൾ’ ഉണ്ടെങ്കിൽ മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description:
            'ജ�ോലിയിൽ ഉൾപ്പെട്ടിരിക്കുന്ന ആളുകളുമായി ആശയവിനിമയം ചെയ്യാൻ കഴിയുമെങ്കിൽ മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        },
        {
          description:
            'രക്ഷപ്പെടാനുള്ള വഴിയെ കുറിച്ച് എനിക്ക് അറിവുണ്ടെങ്കിലും രക്ഷപ്പെടാൻ ഉപയോഗിക്കുന്ന യന്ത്രം ലഭ്യമാണെങ്കിലും മാത്രമേ ചുറ്റും മറയുള്ള പരിമിതമായ ഇടത്തിലേക്ക് ഞാൻ പ്രവേശിക്ക'
        }
      ],
      bn: [
        {
          description: 'অনুমোদন থাকলে এবং অনুমোদনের ভিতরের সতর্কতা সঠিক ভাবে জেনে তবেই আমি আবদ্ধ স্থান প্রবেশ করব�ো'
        },
        {
          description: 'যদি আমি প্রশিক্ষিত এবং অনুমোদিত হই তবেই আমি আবদ্ধ স্থান প্রবেশ করব�ো'
        },
        {
          description: 'যদি বাতাবরণ পরীক্ষা করা হয়ে থাকে এবং নিয়মিত ভাবে দেখা হয় তবেই আমি আবদ্ধ স্থান প্রবেশ করব�ো'
        },
        {
          description: 'কোনো ‘টপ ম্যান’ তার জায়গায় থাকলে আমি কোনো আবদ্ধ স্থানে প্রবেশ করব না'
        },
        {
          description:
            'যদি আমি কর্মক্ষেত্রে জড়িত থাকা ব্যক্তিদের সাথে যোগাযোগ করতে পারি তাহলেই শুধুমাত্র একটি আবদ্ধ স্থান প্রবেশ করব'
        },
        {
          description:
            'যদি উদ্ধারের প্ল্যান আমার জানা থাকে এবং উদ্ধারের সরঞ্জাম উপলব্ধ থাকে তাহলেই আমি কোনো আবদ্ধ স্থানে প্রবেশ করব'
        }
      ]
    }
  },
  {
    categoryType: 'temporary_works',
    image: 'temporaryWorks',
    title: {
      en: 'Temporary Works',
      hi: 'अस्थायी कार्य',
      ur: 'عبوری کام',
      ar: 'الأشغال المؤقتة',
      ta: 'தற்காலிக பணிகள்',
      ml: 'താൽക്കാലിക ജ�ോലികൾ',
      bn: 'অস্থায়ী কাজগুল'
    },
    descriptions: {
      en: [
        {
          description:
            'I will only erect or dismantle temporary works in accordance with the design, drawing, specification and sequence.'
        },
        {
          description: 'I will ensure that an approved temporary works design is available.'
        },
        {
          description:
            'I will only load or strike temporary works if a permit is in place and I am aware of the precautions within the permit.'
        },
        {
          description: 'I will not overload any temporary works.'
        },
        {
          description: 'I will not access temporary works unless it is safe.'
        },
        {
          description: 'I will not modify, alter or dismantle temporary works unless I am trained and authorised.'
        }
      ],
      hi: [
        {
          description:
            'मैं अस्थायी कार्यों को डिज़ाइन, ड्राइंग, विनिर्देश और क्रम से ही स्थापित करूंगा और विघटित करूंगा।'
        },
        {
          description: 'मैं यह सुनिश्चित करूंगा कि एक अनुमोदित/ स्वीकृत, अस्थायी कार्य डिजाइन उपलब्ध हो।'
        },
        {
          description:
            'मैं अस्थायी कार्य संबंधी वस्तुओं को केवल तभी लोड करूंगा या निकालूंगा अगर इसका परमिट मिल गया हो और मुझे परमिट में दी गई सावधानियोंकी जानकारी हो।'
        },
        {
          description: 'मैं अस्थायी कार्यों को ओवरलोड नहीं करूंगा।'
        },
        {
          description: 'मैं अस्थायी कार्यों का उपयोग तब तक नहीं करूंगा जब तक यह सुरक्षित नहीं हो।'
        },
        {
          description:
            'मैं अस्थायी कार्यों को संशोधित, परिवर्तित या विघटित तब तक नहीं करूंगा जब तक मैं इसके लिए प्रशिक्षित और अधिकृत नहीं हूं।'
        }
      ],
      ur: [
        {
          description: 'میں ڈیزائن۔ ڈرائنگ،ہدایات و تفصیلات اور ترتیب کے مطابق عارضی کام کو تعمیر یا منہدم کروں گا'
        },
        {
          description:
            'میں اس بات کو یقینی بناوں گا کہ کام کرنے سے پہلے میرے پاس عارضی کام کا منطور شدہ نقشہ یا ڈیزائن ہو'
        },
        {
          description:
            'میں صرف تب عارضی کام لوڈ یا منہدم کروں گا اگر اجازت نامہ جگہ پر موجود ہو اور مجھے اجازت نامے کے اندر احتیاطی تدابیر سے آگاہی حاصل ہو'
        },
        {
          description: 'میں کسی بھی عبوری کام کو اورلوڈ نہیں کروں گا'
        },
        {
          description: 'میں عبوری کاموں میں رسائی نہیں کروں گا ماسوائے اس کے کہ ایسا کرنا محفوظ ہو'
        },
        {
          description:
            'میں عبوری کاموں میں ترمیم، تبدیلی یا انہیں منہدم نہیں کرونگا ماسوائے اس کے کہ میں تربیت یافتہ اور مجاز ہوں'
        }
      ],
      ar: [
        {
          description: 'لن أنصب أو أفكك الهياكل المؤقتة إلا وفقاً للتصميم والرسم والمواصفات والتسلسل السليم.'
        },
        {
          description: 'سأقوم بتأكيد وجود ﺗﺼﻤﻴﻢ معتمد للأشغال المؤقتة.'
        },
        {
          description: 'لن أقوم بتحميل الأشغال المؤقتة إلا بتصريح وبعد معرفة الاحتياطات التي يتضمنها.'
        },
        {
          description: 'لن أقوم بتجاوز حدود تحميل الأشغال المؤقتة.'
        },
        {
          description: 'لن أدخل أي مواقع أشغال مؤقتة إلا إذا كانت آمنة.'
        },
        {
          description: 'لن أقوم بتعديل أو تفكيك الهياكل المؤقتة ما لم أكن متدرباً ومصرحاً لي بذلك.'
        }
      ],
      ta: [
        {
          description:
            'வடிவமைப்பு, வரைபடம், விவரக்குறிப்பு மற்றும் வரிசை ஆகியவற்றின் அடிப்படையில் மட்டுமே தற்காலிக பணிகளை நிறுவுவேன் அல்லது கழற்றுவேன்.'
        },
        {
          description: 'அங்கிகரிக்கப்பட்ட தற்காலிக வேலை வடிவமைப்பு இருப்பதை உறுதி செய்வென்.'
        },
        {
          description:
            'பணி செய்ய அனுமதி இருக்க வேண்டும், அந்த அனுமதியில் உள்ள முன்னெச்சரிக்கை நடவடிக்கைகள் பற்றி எனக்குத் தெரிந்திருந்தால் மட்டுமே, தற்காலிக பணிகளை லோட் அல்லது ஸ்ட்ரைக் செய்வேன்.'
        },
        {
          description: 'எந்தவொரு தற்காலிக பணிகளையும் ஓவர்லோடாக்க மாட்டேன்.'
        },
        {
          description: 'தற்காலிக பணிகள் பாதுகாப்பானதாக இருந்தால் மட்டுமே அவற்றை அணுகுவேன்.'
        },
        {
          description:
            'நான் பயிற்றுவிக்கப்பட்டு, அங்கீகரிக்கப்பட்டிருந்தால் மட்டுமே தற்காலிக பணிகளை மாற்றுவேன், திருத்துவேன் அல்லது கழற்றுவேன்.'
        }
      ],
      ml: [
        {
          description:
            'ഡിസൈൻ, ഡ്രോയിംഗ്, സ്പെസിഫിക്കേഷൻ, ക്രമം എന്നിവ അനുസരിച്ച് മാത്രമേ ഞാൻ താൽക്കാലിക വർക്കുകൾ ഉയർത്തുകയോ അഴിച്ചെടുക്കുകയോ ചെയ്യുക'
        },
        {
          description: 'അംഗീകൃത താൽക്കാലിക വർക്കുകൾ ഡിസൈൻ ലഭ്യമാണെന്ന് ഞാൻ ഉറപ്പാക്കും.'
        },
        {
          description:
            'കൈവശം അനുമതി ഉണ്ടെങ്കിലും അനുമതിയിലെ മുൻകരുതലുകളെ കുറിച്ച് ഞാൻ മനസ്സിലാക്കിയിട്ടുണ്ടെങ്കിലും മാത്രമേ ഞാൻ താൽക്കാലിക വർക്കുകൾ ലോഡ് ചെയ്യുകയോ അഴിച്ച് നീക്കുകയോ ചെയ്യ'
        },
        {
          description: 'ഞാൻ എന്തെങ്കിലും താൽക്കാലിക വർക്കുകൾക്ക് മേൽ ഓവർലോഡ്ചെലുത്ത'
        },
        {
          description: 'സുരക്ഷിതമല്ലെങ്കിൽ ഞാൻ താൽക്കാലിക വർക്കുകൾക്ക് അരികെ പോകില'
        },
        {
          description:
            'എനിക്ക് പരിശീലനം ലഭിക്കുകയു ം എന്നെ അധികാരപ്പെടുത്തുകയു ം ചെയ്യാത്ത പക്ഷം, താൽക്കാലിക വർക്കുകൾ ഞാൻ പരിഷ്ക്കരിക്കുകയോ അവയിൽ മാറ്റം വരുത്തുകയോ അവ അഴിച്ചെടുക്കുകയോ ചെയ്യ'
        }
      ],
      bn: [
        {
          description: 'আমি কেবল ডিজাইন, ড্রয়িং, স্পেসিফিকেশন এবং ক্রম অনুযায়ীই অস্থায়ী কাজ ইরেক্ট বা উচ্ছেদ করব'
        },
        {
          description: 'আমি নিশ্চিত করব যে একটি অনুমোদিত অস্থায়ী কাজ করার নকশা সবসময় পাওয়া যায'
        },
        {
          description:
            'যদি কোনো অনুমতি পত্র না থাকে তাহলে আমি কোনো অস্থায়ী কাজ লোড বা স্ট্রাইক করব না এবং আমি অনুমতিটি সংক্রান্ত সতর্কতার বিষয়ে সচেতন'
        },
        {
          description: 'আমি কোনো অস্থায়ী কাজে ওভারলোড করব না'
        },
        {
          description: 'আমি নিরাপদ না হলে আমি অস্থায়ী কাজে অ্যাক্সেস করব ন'
        },
        {
          description: 'আমি প্রশিক্ষিত বা অনুমোদিত না থাকলে অস্থায়ী কাজ পরিবর্তন, রদবদল বা বন্ধ করব না'
        }
      ]
    }
  },
  {
    categoryType: 'working_in_the_heat',
    image: 'workingInTheHeat',
    title: {
      en: 'Working in the Heat',
      hi: 'गर्म परिवेश में काम करना',
      ur: 'گرمی میں کام کرنا',
      ar: 'العمل في الطقس الحار',
      ta: 'சூடான இடங்களில் வேலை',
      ml: 'ചൂടുള്ള സാഹചര്യങ്ങളിൽ ജ�ോലി ചെയ്യൽ',
      bn: 'গরমে কাজ করা'
    },
    descriptions: {
      en: [
        {
          description: 'I will drink plenty of water before I start work and regularly throughout the day.'
        },
        {
          description: 'I will take regular breaks in designated areas.'
        },
        {
          description: 'I will avoid working alone without close supervision.'
        },
        {
          description: 'I will look out for symptoms of heat stress in my co-workers.'
        },
        {
          description: 'I will inform my supervisor or co-workers if I am feeling unwell.'
        },
        {
          description:
            'I will familiarise myself with the emergency arrangements for dealing with heat related illnesses.'
        }
      ],
      hi: [
        {
          description: 'मैं काम शुरू करने के पहले और पूरे दिन नियमित रूप से बहुत सारा पानी पियुंगा।'
        },
        {
          description: 'मैं निर्धारित क्षेत्रों में नियमित रूप से विराम लूंगा।'
        },
        {
          description: 'मैं करीबी पर्यवेक्षण के बिना अकेले काम करने से बचूंगा।'
        },
        {
          description: 'मैं अपने सहकर्मियों में गर्मी से तनाव की अधिकता के लक्षणों पर नज़र रखूंगा।'
        },
        {
          description: 'अगर मुझे अस्वस्थता महसूस होती हो तो मैं अपने सुपरवाइज़र या सहकर्मियों को सूचित करूंगा।'
        },
        {
          description:
            'मैं ऊष्मागत तनाव से संबंधित बीमारियों से निपटने के लिए आपातकालीन व्यवस्थाओं के बारे में जानकारी प्राप्त करूंगा।'
        }
      ],
      ur: [
        {
          description: 'میں کام کرنے سے قبل اور باقاعدہ طور پر دن کے دوران بہت پانی پیوں گا'
        },
        {
          description: 'میں متعین جگہوں پر باقاعدہ وقفے لوں گا'
        },
        {
          description: 'میں قریبی نگرانی کے بغیر اکیلے کام کرنے سے اجتناب کروں گا'
        },
        {
          description: 'میں گرمی کے دباؤ کی علامات کی نشاندہی کے لیے اپنے سا تھیوں پر نظر رکھوں گا'
        },
        {
          description: 'میں اپنے نگران یا رفیق کار کو آگاہ کروں گا اگر میں ٹھیک محسوس نہ کروں'
        },
        {
          description: 'میں گرمی سے متعلقہ بیماریوں سے نپٹنے کے لیے ہنگامی ترتیبات سے واقفیت حاصل کروں گا`'
        }
      ],
      ar: [
        {
          description: 'سوف أشرب كمية وفيرة من الماء قبل بدء العمل وطوال اليوم بصفة منتظمة.'
        },
        {
          description: 'سوف آخذ أوقات راحة منتظمة في الأماكن المخصصة لذلك.'
        },
        {
          description: 'سوف أتجنب العمل منفرداً من دون إشراف مباشر.'
        },
        {
          description: 'سوف أراقب زملائي من أعراض الإجهاد الحراري.'
        },
        {
          description: 'سوف أبلغ مشرفي أو زملائي في العمل إذا شعرت بأي إرهاق.'
        },
        {
          description: 'سوف أتعرف على ترتيبات الطوارئ الخاصة بالتعامل مع الإصابات الناتجة عن العمل في الطقس الحار.'
        }
      ],
      ta: [
        {
          description: 'வேலையைத் தொடங்குவதற்கு முன்பும், நாள் முழுதும் அதிகளவு நீர் அருந்துவேன்.'
        },
        {
          description: 'நியமிக்கப்பட்ட பகுதிகளில் வழக்கமான இடைவெளிகளை எடுத்துக்கொள்வேன்.'
        },
        {
          description: 'நெருங்கிய மேற்பார்வை இல்லாதபோது, தனியாக வேலை செய்வதைத் தவிர்ப்பேன்.'
        },
        {
          description: 'என்னுடைய சக ஊழியர்களுக்கு, வெப்ப அழுத்த அறிகுறிகள் ஏதேனும் இருக்கிறதா என்று பார்ப்பேன்.'
        },
        {
          description: 'எனக்கு உடல்நிலை சரியில்லையென்றால் என்னுடைய மேற்பார்வையாளர் அல்லது சக ஊழியர்களிடம் தெரிவிப்பேன்.'
        },
        {
          description: 'வெப்பம் தொடர்புடைய நோய்களைக் கையாள்வதற்கான அவசர ஏற்பாடுகள் குறித்து நான் அறிவேன்.'
        }
      ],
      ml: [
        {
          description: 'ജ�ോലി തുടങ്ങുന്നതിന് മുമ്പും ദിവസത്തിലുടനീളം നിശ്ചിത ഇടവേളകളിലും ഞാൻ ധാരാളം വെള്ളം കുടിക'
        },
        {
          description: 'നിയോഗിച്ചിട്ടുള്ള പ്രദേശങ്ങളിൽ ഞാൻ പതിവ് വിശ്രമ ഇടവേളകൾ എടുക'
        },
        {
          description: 'സൂക്ഷ്മമായ മേൽനോട്ടമിലാതെ, ഒറ്റയ്ക്ക് ജ�ോലി ചെയ്യുന്നത് ഞാൻ ഒഴിവാക'
        },
        {
          description: 'സഹപ്രവർത്തകർക്ക് ചൂടിൽ നിന്നുള്ള സമ്മർദ്ദത്തിന്റെ ലക്ഷണങ്ങൾ കാണുന്നുണ്ടോ എന്ന് ഞാൻ നിരീക'
        },
        {
          description: 'എനിക്ക് സുഖമില്ലായ്മ അനുഭവപ്പെടുകയാണെങ്കിൽ ഞാനെന്റെ സൂപ്പർവൈസറെയോ സഹപ്രവർത്തകരെയോ അറിയിക'
        },
        {
          description:
            'ചൂടുമായി ബന്ധപ്പെട്ട അസുഖങ്ങൾ കൈകാര്യം ചെയ്യുന്നതിനുള്ള അടിയന്തിര സജ്ജീകരണങ്ങളെ കുറിച്ച് ഞാൻ മനസ്സിലാക'
        }
      ],
      bn: [
        {
          description: 'কাজ শুরু করার আগে এবং সারাদিন ধরে আমি অনেক পানি খাব�ো'
        },
        {
          description: 'মনোনীত এলাকায় আমি নিয়মিত বিরতি নেব�ো'
        },
        {
          description: 'কাছাকাছি রক্ষণাবেক্ষণ না থাকলে আমি একা কাজ করব�ো না'
        },
        {
          description: 'আমি নিজের সহকর্মীদের মধ্যে উত্তাপ সংক্রান্ত চাপের বিষয়ে খেয়াল রাখব'
        },
        {
          description: 'আমি যদি অসুস্থ অনুভব করি তাহলে আমার সহকর্মীদের সে বিষয়ে জানাব�ো'
        },
        {
          description: 'উত্তাপ সংক্রান্ত অসুস্থতার আপদকালীন পরিস্থিতি সামাল দেওয়ার জন্য আমি নিজেকে প্রস্তুত রাখব'
        }
      ]
    }
  }
];
