import { I18nManager } from "react-native";
import variable from "./../variables/platform";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const h2Theme = {
    color: variables.textColor,
    fontSize: variables.fontSizeH2,
    lineHeight: variables.lineHeightH2,
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr",

    ".welcome": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h2.welcome,
      marginVertical: 5,
      fontSize: variables.fontSizeH3
    },
    ".setup": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h2.setup,
      marginVertical: 5,
      fontSize: variables.fontSizeH3
    },
    ".rightsHeader": {
      color: colors.header.h2.rights,
      fontWeight: "bold",
      paddingVertical: 15,
      paddingHorizontal: 10
    },
    ".rightsTitle": {
      color: colors.header.h2.rightsTitle,
      marginVertical: 10
    }
  };

  return h2Theme;
};
