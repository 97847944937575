import axios, { CancelToken } from "axios";
import { api, APP_TOKEN, isDev, namespace } from "./const";
import store from "./store";
import types from "./actions/types";
import { isOffline } from "./offline";
import config from "react-native-config";
import I18n from "./i18n";

axios.defaults.baseURL = api;
axios.defaults.timeout = Number(config.REQUEST_TIMEOUT);
axios.defaults.headers.common["X-App-Token"] = APP_TOKEN;
if (isDev) axios.defaults.headers.common["X-App-Mode"] = "development";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = namespace;
axios.defaults.headers.common["X-WC-Client"] = namespace;
axios.defaults.responseType = "json";

let cancelQueue = [];
export function cancelRequests() {
  while (cancelQueue.length > 0) {
    const c = cancelQueue.shift();
    if (typeof c == "function") {
      c();
    }
  }
}

function axiosFactory(method, modal, hidden) {
  const axiosMethod = async function (...args) {
    const offline = await isOffline();
    if (offline) return null;
    store.dispatch({
      type: types.ERROR,
      err: null
    });
    let timer = setTimeout(() => {
      store.dispatch({ type: types.PROGRESS_START, modal: true });
    }, 2000);

    if (!hidden) store.dispatch({ type: types.PROGRESS_START, modal });
    try {
      // cancelRequests();

      const response =
        hidden || modal
          ? await axios[method](...args)
          : await axios[method](...args, {
              cancelToken: new CancelToken(c => {
                cancelQueue.push(c);
              })
            });

      if (!hidden) store.dispatch({ type: types.PROGRESS_END });
      if (timer) store.dispatch({ type: types.PROGRESS_END });
      clearTimeout(timer);

      return response.data || (method == "get" ? null : {});
    } catch (err) {
      if (!hidden || timer) store.dispatch({ type: types.PROGRESS_END });
      if (timer) store.dispatch({ type: types.PROGRESS_END });
      clearTimeout(timer);
      if (axios.isCancel(err)) {
        return null;
      }
      if (err.code === "ECONNABORTED") {
        store.dispatch({
          type: types.ERROR,
          err: I18n.t("appStrings.NETWORK_ERROR")
        });
      } else {
        store.dispatch({
          type: types.ERROR,
          err
        });
      }
      return null;
    }
  };
  return axiosMethod;
}

export const GET = axiosFactory("get", true);
export const POST = axiosFactory("post", true);
export const hiddenPOST = axiosFactory("post", false, true);
export const PUT = axiosFactory("put", true);
export const PATCH = axiosFactory("patch", true);
export const DELETE = axiosFactory("delete", true);
export const inlineGET = axiosFactory("get", false);

const requestCache = {};
export const cachedGET = async function (url, throttle) {
  if (
    requestCache[url] &&
    requestCache[url].timestamp >= Date.now() - throttle
  ) {
    return requestCache[url].result;
  }
  const offline = await isOffline();
  if (offline) return null;
  const result = await GET(url);
  requestCache[url] = { result, timestamp: Date.now() };
  return result;
};

export function setToken(token) {
  if (token) axios.defaults.headers.common["Authorization"] = token;
  else delete axios.defaults.headers.common["Authorization"];
}
export function getToken() {
  return axios.defaults.headers.common["Authorization"];
}
export function useApi(url) {
  axios.defaults.baseURL = url || axios.defaults.baseURL;
  return url || axios.defaults.baseURL;
}
