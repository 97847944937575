import variable from "./../variables/platform";
import { I18nManager } from "react-native";
import colors from "../variables/wcColors";

export default (variables = variable) => {
  const h1Theme = {
    color: variables.textColor,
    fontSize: variables.fontSizeH1,
    lineHeight: variables.lineHeightH1,
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr",
    ".pollTitle": {
      color: colors.header.h1.poll,
      fontSize: variables.fontSizeH1 * 1.2
    },
    ".rightsTitle": {
      color: colors.header.h1.rights,
      marginVertical: 10,
      fontSize: variables.fontSizeH1 * 1.2
    },
    ".connectProject": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.connectProject,
      marginVertical: 15
    },
    ".employer": {
      color: colors.header.h1.employer,
      marginVertical: 10
    },
    ".projectName": {
      flex: 1,
      margin: 0,
      textAlign: "center",
      color: colors.header.h1.projectName,
      fontWeight: "bold"
    },
    ".welcome": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.welcome,
      marginTop: 10
    },
    ".setup": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.setup,
      marginVertical: 15
    },
    ".nationality": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.nationality,
      marginVertical: 15
    },
    ".selectProject": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.selectProject,
      marginVertical: 15
    },
    ".employerSearchHelp": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.header.h1.employerSearchHelp,
      marginTop: 50,
      marginHorizontal: 10
    },
    ".employerSearchHelpSetup": {
      fontWeight: "bold",
      textAlign: "center",
      color: colors.funkyWhite,
      marginTop: 50,
      marginHorizontal: 10
    },
    ".aboutFunky": {
      color: colors.funkyBlue1
    },
    ".kyrHeader": {
      color: colors.funkyBlue1,
      textAlign: 'center',
      marginVertical: 30
    }
  };

  return h1Theme;
};
