import React, { Component } from "react";
import AutoSuggest from "./AutoSuggest";
import I18n from "../i18n";

const localeContains = (a, b) =>
  !!a
    .split("")
    .filter(
      (v, i) =>
        a
          .slice(i, i + b.length)
          .localeCompare(b, "en", { sensitivity: "base" }) == 0
    ).length;

export default class HospitalSuggest extends Component {
  state = {
    filteredEmployers: []
  };

  get filteredEmployersNames() {
    return this.state.filteredEmployers.map(({ name, company_id }) => ({
      label: name,
      value: company_id
    }));
  }

  handleAutoSuggestChange = text => {
    const { employers } = this.props;
    const filteredEmployers =
      employers && text.trim() !== ""
        ? employers.filter(employer =>
            localeContains(
              employer.name.toLowerCase().replace(/\W+/, " "),
              text.trim().toLowerCase().replace(/\W+/, " ")
            )
          )
        : [];
    this.setState({ filteredEmployers });

    const value = (
      filteredEmployers.find(
        it =>
          it.name
            .toLowerCase()
            .replace(/\W+/, " ")
            .localeCompare(text.trim().toLowerCase().replace(/\W+/, " ")) == 0
      ) || {
        company_id: null
      }
    ).company_id;
    this.props.onSelect(text, value);
  };

  handleEmployerSelect = ({ label, value }) => {
    this.props.onSelect(label, value);
    this.setState({
      filteredEmployers: []
    });
  };

  render() {
    return (
      <AutoSuggest
        data={this.filteredEmployersNames}
        label={I18n.t("appStrings.HOSPITAL")}
        placeholder={this.props.placeholder}
        onChangeText={this.handleAutoSuggestChange}
        onSelect={this.handleEmployerSelect}
        value={this.props.value}
      />
    );
  }
}
