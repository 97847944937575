import React from "react";
import { TouchableOpacity } from "react-native";
import { Body, Card, CardItem, Left, Text, Thumbnail } from "native-base";
import actionTypes from "./actionTypes";
import reducer from "./reducer";
import RightsImage from "../../assets/img/resources/Resources_rights.png";
import KnowYourRights from "./KnowYourRights";
import RightsCategoryList from "./RightsCategoryList";
import RightDetails from "./RightDetails";
import I18n from "../../i18n";
import { setChildHeader } from "../../screens/mainTabNavigator/actions";

export default {
  resources: {
    mainSelector: function KnowYourRights(props) {
      return (
        <TouchableOpacity
          testID="knowYourRights"
          key="knowYourRights"
          onPress={() => {
            props.navigation.navigate("KnowYourRights");
            setChildHeader("appStrings.RIGHTS_GUIDE_TITLE");
          }}
        >
          <Card resourcecard>
            <CardItem>
              <Left>
                <Thumbnail source={RightsImage} />
                <Body>
                  <Text title>{I18n.t("appStrings.CATEGORY_YOUR_RIGHTS")}</Text>
                  <Text description>
                    {I18n.t("appStrings.CATEGORY_DESC_YOUR_RIGHTS")}
                  </Text>
                </Body>
              </Left>
            </CardItem>
          </Card>
        </TouchableOpacity>
      );
    },
    routes: {
      KnowYourRights: {
        screen: KnowYourRights,
        path: "resources/rights",
        navigationOptions: {
          title: "appStrings.RIGHTS_GUIDE_TITLE",
          route: "ResourcesTab",
          hasBack: true
        }
      },
      RightsCategoryList: {
        screen: RightsCategoryList,
        path: "resources/rights/:category",
        navigationOptions: {
          title: "appStrings.RIGHTS_GUIDE_TITLE",
          route: "ResourcesTab",
          hasBack: true
        }
      },
      RightDetails: {
        screen: RightDetails,
        path: "resources/rights/:category/:issueType",
        navigationOptions: {
          title: "appStrings.RIGHTS_GUIDE_TITLE",
          route: "ResourcesTab",
          hasBack: true
        }
      }
    }
  },
  actionTypes,
  reducer
};
