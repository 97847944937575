import wcColors from "../../../native-base-theme/variables/wcColors";
import WhatsNewIconImage from "../../assets/img/tabs/whatsnew_inactive.png";
import WhatsNewIconImageActive from "../../assets/img/tabs/whatsnew_active.png";
import ResourcesIconImage from "../../assets/img/tabs/resources_inactive.png";
import ResourcesIconImageActive from "../../assets/img/tabs/resources_active.png";
import ReportIconImage from "../../assets/img/tabs/report_inactive.png";
import ReportIconImageActive from "../../assets/img/tabs/report_active.png";
import SettingsIconImage from "../../assets/img/tabs/settings_inactive.png";
import SettingsIconImageActive from "../../assets/img/tabs/settings_active.png";

export const TabBar = {
  indicatorStyle: {
    borderBottomColor: wcColors.tabBar.indicator.border,
    borderBottomWidth: 2,
    position: "absolute",
    left: 0,
    bottom: 0,
    right: 0,
    height: 2,
    backgroundColor: wcColors.tabBar.indicator.background
  },
  tabStyle: {
    backgroundColor: "transparent",
    paddingHorizontal: 0, // TODO: Core change
    opacity: 1,
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: wcColors.tabBar.tab.border
  },
  style: {
    backgroundColor: wcColors.tabBar.background,
    elevation: 0
  }
};

export const Label = {
  Icon(active) {
    /*
    return {
      fontSize: variables.iconFontSize, // TODO: Core change
      color: active ? colors.brown : colors.lightYellow
    };
    */
    return {
      width: 24,
      height: 24,
      margin: 4
    };
  },
  Text(active) {
    return {
      fontSize: 12, // TODO: Core change
      textAlign: "center",
      // top: -2, // TODO: Core change
      //fontWeight: active ? "bold" : null,
      color: active
        ? wcColors.tabBar.label.active
        : wcColors.tabBar.label.inactive
    };
  }
};

/*
export const Icons = {
  whatsnew(active) {
    return {
      type: "Ionicons",
      name: active ? "ios-pulse" : "ios-pulse-outline"
    };
  },
  resources(active) {
    return {
      type: "Ionicons",
      name: active ? "ios-book" : "ios-book-outline"
    };
  },
  reportissue(active) {
    return {
      type: "Ionicons",
      name: active ? "ios-chatboxes" : "ios-chatboxes-outline"
    };
  },
  settings(active) {
    return {
      type: "Ionicons",
      name: active ? "ios-settings" : "ios-settings-outline"
    };
  }
};
*/

export const Icons = {
  whatsnew(active) {
    return {
      source: active ? WhatsNewIconImageActive : WhatsNewIconImage
    };
  },
  resources(active) {
    return {
      source: active ? ResourcesIconImageActive : ResourcesIconImage
    };
  },
  reportissue(active) {
    return {
      source: active ? ReportIconImageActive : ReportIconImage
    };
  },
  settings(active) {
    return {
      source: active ? SettingsIconImageActive : SettingsIconImage
    };
  }
};
