import React, { Component } from "react";
import { View } from "react-native";
import { Screen } from "../../utils";
import { connect } from "react-redux";

import SelectCategory from "./selectCategory";
// import SetProject from "./setProject";
// import SetEmployer from "./setEmployer";

export default Screen(
  connect(state => ({
    settings: state.settings,
    isActiveProject: state.projectStatus
  }))(
    class InitialRoute extends Component {
      switchComponent = () => {
        const {
          projectId,
          projectName
          // employerId,
          // employerName
        } = this.props.settings;

        if (
          projectId &&
          projectName //&&
          // ((employerId && employerName) || employerName) &&
          // this.props.isActiveProject
        ) {
          return <SelectCategory {...this.props} />;
        }

        // if (projectId && projectName && !employerName) {
        //   return <SetEmployer {...this.props} projectId={projectId} />;
        // }

        // if (!this.props.isActiveProject) {
        //   return <SetProject {...this.props} showNoProject={true} />;
        // }
        // return <SetProject {...this.props} />;
      };

      render() {
        const settings = this.props.settings;
        return (
          <View style={{ height: "100%", width: "100%" }}>
            {this.switchComponent()}
          </View>
        );
      }
    }
  )
);
