import React, { Component, PureComponent } from "react";
import {
  View,
  WebView,
  Linking,
  Easing,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  TouchableOpacity,
  I18nManager
} from "react-native";
import {
  Container,
  Header,
  Left,
  Body,
  Right,
  Title,
  Subtitle,
  Footer,
  Button,
  Icon,
  Toast,
  Text
} from "native-base";
import { NavigationActions, StackActions } from "react-navigation";
import { connect } from "react-redux";
import hoistStatics from "hoist-non-react-statics";
import styles from "./styles";
import store from "./store";
import types from "./actions/types";
import { ProgressComponent } from "./components/Progress";
// import variable from "../native-base-theme/variables/platform";
import getSceneIndicesForInterpolationInputRange from "react-navigation-stack/lib/module/utils/getSceneIndicesForInterpolationInputRange";

import CardStackStyleInterpolator from "react-navigation-stack/lib/module/views/StackView/StackViewStyleInterpolator";

export function Screen(
  WrappedComponent,
  header = null,
  footer = null,
  staticProps = {}
) {
  let screen = Object.assign(
    class extends Component {
      constructor(props) {
        super(props);

        if (typeof header == "function") {
          this.header = header(this.props);
          if (typeof this.header == "string") {
            this.header = HeaderWithBack(header, this.props);
          }
        } else if (typeof header == "string") {
          this.header = HeaderWithBack(header, this.props);
        }

        if (typeof footer == "function") {
          this.footer = footer(this.props);
        }
      }

      render() {
        return (
          <Container>
            <View style={{ flex: 1 }}>
              {this.header}
              <View style={styles.container}>
                {/* <Image
                style={styles.backgroundImage}
                source={require("./images/bg_vertical.jpg")}
              /> */}
                <WrappedComponent {...this.props} />
              </View>
              {this.footer}
            </View>
          </Container>
        );
      }
    },
    {
      navigationOptions: {
        // header: null
      }
    },
    staticProps
  );

  hoistStatics(screen, Component);
  return screen;
}

export function HeaderWithLogo(title, props, hasTabs) {
  return (
    <Header {...props} hasTabs>
      <Left>
        {/* <Image
          source={require("./images/logo_matted.png")}
          style={{ width: 52, resizeMode: "contain" }}
        /> */}
        <Button
          transparent
          onPress={() => props.navigation.dispatch(NavigationActions.back())}
        >
          <Icon name="menu" />
        </Button>
      </Left>
      <Body>
        <Title>
          {title} {"" + this.props.progress}
        </Title>
      </Body>
      <Right />
    </Header>
  );
}

export function HeaderWithDrawerMenu(title, props) {
  return (
    <Header {...props}>
      <Left>
        <Button
          transparent
          onPress={() => props.navigation.navigate("DrawerOpen")}
        >
          <Icon name="menu" />
        </Button>
      </Left>
      <Body>
        <Title>{title}</Title>
      </Body>
      <Right />
    </Header>
  );
}

export function HeaderWithBack(title, props) {
  return (
    <Header {...props}>
      <Left style={{ flex: 0 }}>
        <View style={{ width: 50 }}>
          <TouchableOpacity
            transparent
            onPress={() => props.navigation.dispatch(NavigationActions.back())}
          >
            <Icon name="arrow-back" />
          </TouchableOpacity>
        </View>
      </Left>
      <Body style={{ flex: 1 }}>
        <Title>{typeof title == "string" ? title : title(props)}</Title>
      </Body>
      <Right style={{ flex: 0 }}>
        <View style={{ width: 50 }} />
      </Right>
    </Header>
  );
}

export function NavigationChange(Navigator) {
  const nav = class extends Component {
    state = { currentScreen: null };

    _getCurrentRouteName(navState) {
      if (navState.hasOwnProperty("index")) {
        return this._getCurrentRouteName(navState.routes[navState.index]);
      } else {
        return navState.routeName;
      }
    }

    _navigationStateChange = (prevState, newState, action) => {
      this.setState({ currentScreen: this._getCurrentRouteName(newState) });
    };

    render() {
      return (
        <Navigator
          onNavigationStateChange={this._navigationStateChange}
          screenProps={{ ...this.props.screenProps, ...this.state }}
          {...this.props}
        />
      );
    }
  };

  hoistStatics(nav, Navigator);
  return nav;
}

function forHorizontal(props) {
  const { layout, position, scene } = props;

  const interpolate = getSceneIndicesForInterpolationInputRange(props);

  if (!interpolate) return { opacity: 0 };

  const { first, last } = interpolate;
  const index = scene.index;
  const opacity = position.interpolate({
    inputRange: [first, first + 0.01, index, last - 0.01, last],
    outputRange: [0, 1, 1, 0.85, 0]
  });

  const width = layout.initWidth;
  const translateX = position.interpolate({
    inputRange: [first, index, last],
    outputRange: I18nManager.isRTL ? [-width, 0, 0] : [width, 0, 0]
  });
  const translateY = 0;

  return {
    opacity,
    transform: [{ translateX }, { translateY }]
  };
}

export const transitionConfig = () => {
  return {
    transitionSpec: {
      duration: 200,
      easing: Easing.bezier(0.2833, 0.99, 0.31833, 0.99)
    },
    screenInterpolator: sceneProps => {
      const { scene } = sceneProps;
      const { route, descriptor } = scene;
      const params = route.params || {};
      const transition =
        params.transition || (descriptor.options.modal ? "modal" : "default");
      return {
        default: forHorizontal(sceneProps),
        modal: CardStackStyleInterpolator.forVertical(sceneProps)
      }[transition];
    }
  };
};

export function DismissKeyboard(WrappedComponent) {
  return ({ children, ...props }) => (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <WrappedComponent {...props}>{children}</WrappedComponent>
    </TouchableWithoutFeedback>
  );
}

export function showToast(text, type, duration, options) {
  Toast.show({
    text,
    type,
    duration: duration || 3000,
    position: "top",
    ...options
  });
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function sortString(arr, prop) {
  return arr.sort((a, b) =>
    a[prop].toLowerCase().localeCompare(b[prop].toLowerCase())
  );
}

export function isCloseToBottom({
  layoutMeasurement,
  contentOffset,
  contentSize
}) {
  const paddingToBottom = 20;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
}

export function isCloseToTop({ contentOffset }) {
  return contentOffset.y == 0;
}

export function buildWP(startPos, endPos, currentPos) {
  if (currentPos) {
    return (
      "wp.0" +
      currentPos.lat +
      "," +
      currentPos.lng +
      "wp.1=" +
      startPos.lat +
      "," +
      startPos.lng +
      "&wp.2=" +
      endPos.lat +
      "," +
      endPos.lng
    );
  } else {
    return (
      "wp.0=" +
      startPos.lat +
      "," +
      startPos.lng +
      "&wp.1=" +
      endPos.lat +
      "," +
      endPos.lng
    );
  }
}

export const generateInitialRegion = latLong => {
  //@param distance later it will be enable to modify the range of map
  const distance = 10 / 2;
  const circumference = 40075;
  const oneDegreeOfLatitudeInMeters = 111.32 * 1000;
  const angularDistance = distance / circumference;

  const latitudeDelta = distance / oneDegreeOfLatitudeInMeters;
  const longitudeDelta = Math.abs(
    Math.atan2(
      Math.sin(angularDistance) * Math.cos(latLong.latitude),
      Math.cos(angularDistance) -
        Math.sin(latLong.latitude) * Math.sin(latLong.latitude)
    )
  );
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export function sortAlpha(arr, prop) {
  return sortString(arr, prop);
}

export function sortCondition(arr, cond) {
  return arr.sort(function (x, y) {
    return x.status == cond ? -1 : y.status == cond ? 1 : 0;
  });
}

export function lazy(LazyComponent) {
  const lazyWrapper = class extends Component {
    state = { loaded: this.props.active };

    static getDerivedStateFromProps(props, state) {
      if (props.active && !state.loaded) {
        return { loaded: true };
      }
      return null;
    }

    render() {
      const { active } = this.props;
      return active || this.state.loaded ? (
        <LazyComponent {...this.props} />
      ) : null;
    }
  };

  hoistStatics(lazyWrapper, LazyComponent);
  return lazyWrapper;
}

export function withSetupGuard(Component) {
  const wrapper = class extends Component {
    componentDidMount() {
      if (!store.getState().settings.hasBeenSetup) {
        this.props.navigation.navigate("SetupScreens");
        // this.props.navigation.dispatch(
        //   StackActions.reset({
        //     index: 0,
        //     key: null,
        //     actions: [NavigationActions.navigate({ routeName: "SetupScreens" })]
        //   })
        // );
      }
    }

    render() {
      return store.getState().settings.hasBeenSetup ? (
        <Component {...this.props} />
      ) : (
        <ProgressComponent modalProgress={true} />
      );
    }
  };

  hoistStatics(wrapper, Component);
  return wrapper;
}
