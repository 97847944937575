import React, { Component } from "react";
import { Platform } from "react-native";
import { withBrowserHistory } from "react-navigation-browser-history-helpers";
import { createAppContainer, createSwitchNavigator } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";
import SetupScreens from "./screens/setup";
import { transitionConfig, withSetupGuard } from "./utils";
import { uriPrefix } from "./const";
import initPushNotifications from "./notifications";
// import { checkProjectStatus } from "./actions/projectStatus";
import store from "./store";
import { setLocale, initLocale } from "./i18n";
import MainTab from "./screens/mainTabNavigator";
import ReportIssue from "./screens/reportIssueTab/reportIssue";
import PollModal from "./screens/whatsnewsTab/pollModal";
import VideoModal from "./screens/whatsnewsTab/videoModal";
import AboutProject from "./screens/settingsTab/about";
import MainHeader, { changeStack } from "./components/MainHeader";
import WhatsNewTab from "./screens/whatsnewsTab";
import ResourcesTab from "./screens/resourcesTab";
import ReportIssueTab from "./screens/reportIssueTab";
import SettingsTab from "./screens/settingsTab";
import features from "./features";

export const routes = {
  ...WhatsNewTab,
  ...ResourcesTab,
  ...ReportIssueTab,
  ...SettingsTab
};

let changeTab = null;
export function getChangeTab() {
  return changeTab;
}

const Root = createStackNavigator(
  {
    ...Object.keys(features)
      .filter(f => typeof features[f].modals == "object")
      .reduce((p, c) => ({ ...p, ...features[c].modals }), {}),
    VideoModal: {
      screen: VideoModal,
      mode: "modal",
      path: "video/:url"
    },
    ReportIssue: {
      screen: withSetupGuard(ReportIssue),
      mode: "modal",
      path: "reportissue/:issueType"
    },
    PollModal: {
      screen: withSetupGuard(PollModal),
      mode: "modal",
      path: "poll/:pollId",
      navigationOptions: {
        header: null
      }
    },
    AboutProject: {
      screen: withSetupGuard(AboutProject),
      mode: "modal",
      path: "aboutProject"
    },
    ...routes
  },
  {
    initialRouteName: "WhatsNewTab",
    transitionConfig: Platform.OS === "web" ? undefined : transitionConfig,
    headerMode: Platform.OS === "web" ? "screen" : "float",
    cardStyle: {
      width: "100%",
      height: "100%",
      maxWidth: 1200,
      alignSelf: "center",
      position: "absolute",
      left: "50%",
      transform: [{ translateX: "-50%" }]
    },
    defaultNavigationOptions: props => {
      const { navigation } = props;
      const { navigationOptions } = routes[navigation.state.routeName] || {};
      return {
        header: props => {
          return (
            <MainHeader
              {...navigationOptions}
              {...navigation}
              getRef={ref => {
                changeTab = ref;
              }}
            />
          );
        }
      };
    }
  }
);

class Loading extends Component {
  componentDidMount() {
    this._bootstrapAsync();
  }

  _bootstrapAsync = async () => {
    const isSetupedUp = store.getState().settings.hasBeenSetup;
    const forceRtl = store.getState().settings.forceRtl;

    if (!isSetupedUp && !forceRtl) store.dispatch({ type: "APP_RESET" });
    const lang = store.getState().settings.language;
    if (lang) {
      await setLocale(lang, true);
    } else {
      await initLocale();
    }

    // await checkProjectStatus();
    await initPushNotifications();

    this.props.navigation.navigate(isSetupedUp ? "App" : "SetupScreens");
  };

  render() {
    return null;
  }
}

export default withBrowserHistory(
  createAppContainer(
    createSwitchNavigator(
      {
        Loading,
        App: Root,
        SetupScreens: {
          screen: SetupScreens,
          path: "app/setup"
        }
      },
      {
        initialRouteName: "Loading"
      }
    )
  )
);
