export default {
  NAV_BACK: "Înapoi",
  WELCOME_TITLE: "Bun venit",
  WELCOME_DESCRIPTION_1:
    "[RO] Worker Connect was developed for you, a worker living in Dubai and working on construction projects.",
  WELCOME_DESCRIPTION_2:
    "[RO] Use this app to learn about your rights, to find resources to help you with questions or problems, and to provide feedback to people who care about improving your working conditions.",
  WELCOME_DESCRIPTION_3:
    "[RO] This app is completely anonymous. When you report an issue, no one will know who sent it, but people who care will learn what isn’t going well for you. And that’s important information to share.",
  WELCOME_GETSTARTED: "Hai să vindecăm sistemul",
  PROMPT_WHERE_FROM: "[RO] Where are you from?",
  PROMPT_WHERE_FROM_DESCRIPTION:
    "[RO] Please tell us where you’re from so we can give you information you care about.",
  LBL_SOMEWHERE_ELSE: "[RO] Somewhere else...",
  PROMPT_WHAT_JOB_FUNCTION: "Care este postul pe care îl ocupi?",
  PROMPT_WHAT_JOB_FUNCTION_DESCRIPTION:
    "[RO] Please tell us what is your job function so we can give you information you care about.",
  LBL_JOB_FUNCTION_NAME: "Profesie",
  LBL_SOMETHING_ELSE: "Altceva...",
  PROMPT_PROJECT_CODE_DESCRIPTION:
    "[RO] You should have received a code at your work site to use this app.",
  LBL_SKIP_THIS: "[RO] I don't want to connect to a project right now",
  LBL_OR: "[RO] or",
  PROMPT_YOUR_EMPLOYER: "[RO] Who is your employer?",
  PROMPT_YOUR_EMPLOYER_DESCRIPTION:
    "[RO] When we know your employer, we can give you information that is specific to your work project.",
  PROMPT_OTHER_EMPLOYER: "[RO] If you don't see your employer, enter it here.",
  LBL_OK: "Ok",
  LBL_EMPLOYER_NAME: "[RO] Employer Name",
  TITLE_WHATSNEW: "Noutăți",
  GREETING_WELCOME: "Bun venit",
  GREETING_MORNING: "Bună dimineața!",
  GREETING_AFTERNOON: "Bună ziua!",
  GREETING_EVENING: "Bună seara!",
  MSG_DISCONNECTED: "Nu ești conectat la internet!",
  MSG_LAST_CHECKED: "Ultima verificare:",
  MSG_CONNECT_FOR_FEED: "Conectează-te la internet pentru mai multe noutăți.",
  MSG_OPINION_MATTERS: "Opinia ta contează",
  LBL_UPDATES: "[RO] Updates",
  MSG_UPDATES_PLACEHOLDER: "Revino mai târziu pentru noutăți.",
  TITLE_INFORMATION: "Resurse",
  TITLE_INFO_RESOURCES: "Informații și resurse",
  COMMUNITY_RESOURCES_TITLE: "[RO] Resources for workers from {{COUNTRY_NAME}}",
  COMMUNITY_RESOURCES_DESCRIPTION:
    "[RO] Here are some resources from your community who can assist you if you have problems or questions.",
  COMMUNITY_RESOURCES_NOINFO: "Revino mai târziu pentru resurse noi.",
  COMMUNITY_RESOURCES_NOTDOWNLOADED: "Resursele nu au fost încă descărcate.",
  REFERENCE_OTHER_LANGUAGES: "[RO] Other languages",
  HELP_ISSUEREPORT_TITLE: "Raportează probleme",
  HELP_ISSUEREPORT_DESC:
    "Orice raportare făcută este anonimă și securizată. Feedback-ul tău ne ajută să identificăm problemele ce au nevoie de atenție.",
  //  HELP_ISSUEREPORT_DESC_2:
  //    "[RO] When you report an issue, the information you send is received by people who care about your safety and welfare.",
  //  HELP_ISSUEREPORT_DESC_3:
  //    "[RO] Your feedback helps identify issues that need attention.",
  PROMPT_REPORT_ISSUE_ABOUT: "Vreau să raportez o problemă despre:",
  PROMPT_ISSUE_IS: "Problema este despre:",
  TITLE_REPORT_ISSUE: "Sesizează",
  CATEGORY_FOOD: "[RO] The food I eat",
  CATEGORY_DIGNITY_RESPECT: "[RO] Dignity and respect",
  CATEGORY_RECRUIT_DOCS: "[RO] Recruitment and documents",
  CATEGORY_WAGES: "[RO] Getting paid",
  CATEGORY_HEALTH_SAFETY: "[RO] My health and safety",
  CATEGORY_WORKSITE: "[RO] My work site",
  CATEGORY_TRANSPORT: "[RO] Traveling to my work site",
  CATEGORY_ACCOMMODATIONS: "[RO] Where I live",
  CATEGORY_RETURNHOME: "[RO] Returning to my home country",
  LBL_CANCEL: "Anulează",
  LBL_CLOSE: "Închide",
  LBL_PROJECT: "[RO] Project",
  LBL_EMPLOYER: "[RO] Employer",
  LBL_COMMENTS: "Comentarii",
  LBL_COMMENTS_PLACEHOLDER: "Adaugă informații suplimentare (opțional)",
  LBL_SUBMIT_REPORT: "Raportează problema anonim",
  LBL_SUBMIT_POLL_RESPONSE: "Trimiteți răspunsul",
  TITLE_SETTINGS: "Setări",
  LBL_YOUR_PROJECT: "[RO] Your project",
  LBL_CHANGE_PROJECT: "[RO] Choose a different project",
  PROMPT_CHOOSE_LANGUAGE: "Alege o limbă",
  LBL_CHANGE_LANGUAGE: "Schimbă limba",
  LBL_LANGUAGE: "Limbă",
  LBL_NATIONALITY: "[RO] Nationality",
  LBL_JOB_FUNCTION: "Profesia",
  LBL_YOUR_EMPLOYER: "[RO] Your employer",

  NAV_NEXT: "[RO] Next",
  NAV_CONTINUE: "Continuă",
  LBL_SUBMIT_REPORT_HELP_LINK: "Ce se întâmplă când trimit sesizarea?",
  SUBMIT_REPORT_HELP_CONTENT:
    "Sesizarea va ajunge la echipa Funky Citizens. Nimeni nu va ști că tu ai trimis sesizarea.",
  LBL_COMMENTS_REQUIRED_PROMPT: "Descrie problema mai jos:",
  LBL_COMMENTS_PLACEHOLDER_REQUIRED: "Problema mea este că... ",
  LBL_COMMENTS_REQUIRED_ERROR_TITLE: "Avem nevoie de mai multe informații aici",
  LBL_COMMENTS_REQUIRED_ERROR:
    "Te rugăm să introduci o descriere a problemei înainte să trimiți",
  LBL_EMPLOYER_REQUIRED_ERROR_TITLE: "Avem nevoie de mai multe informații aici",
  LBL_EMPLOYER_REQUIRED_ERROR: "Selectează spitalul tău",
  POST_SUBMIT_MESSAGE_TITLE: "Ai trimis o sesizare sub anonimat",
  POST_SUBMIT_MESSAGE_HOTLINE_TITLE:
    "[RO] Would you like to talk to someone about this issue?",
  POST_SUBMIT_MESSAGE:
    "Îți mulțumim pentru sesizare. Nu vei fi contactat în legătură cu ea, dar o vom lua în calcul în încercările noastre de advocacy.",
  POST_SUBMIT_MESSAGE_HOTLINE:
    "[RO] You can call the Expo 2020 Worker Hotline to speak to someone anonymously.",
  SETUP_OFFLINE_ERROR_TITLE: "Lipsă conexiune internet",
  SETUP_OFFLINE_ERROR:
    "Nu ești conectat la internet. Te rugăm să încerci din nou.",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_TITLE:
    "[RO] Enter your Expo Worker Connect project code",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_DESCRIPTION:
    "[RO] If you were provided an Expo Worker Connect project code, enter it here to anonymously report issues to your project's worker welfare team.",
  PROMPT_REPORT_ISSUE_PROJECT_SETUP_NO_CODE:
    "[RO] If you don't have a project code, go to Resources and learn more about your options for finding help.",
  PROMPT_READMORE: "Citește mai mult",
  LBL_ALERTS_WARNING_DIVIDER_TEXT: "[RO] Alerts",
  LBL_ALERTS_INFO_DIVIDER_TEXT: "[RO] Notices",
  LBL_POLLS_DIVIDER_TEXT: "[RO] Your Opinion Matters",
  LBL_FEED_DIVIDER_TEXT: "[RO] Updates",
  LBL_RESOURCES_EMBASSY: "[RO] Embassy",
  LBL_RESOURCES_PEOPLE: "Persoane",
  LBL_RESOURCES_ORGANIZATIONS: "Organizații",
  LBL_RESOURCES_GUIDES: "Ghiduri",
  PROJECT_SCAN_CODE: "[RO] Scan a code",
  PROJECT_SKIP_PROMPT: "[RO] I don't want to connect to a project right now",
  WELCOME_PANEL_1_TITLE:
    "ÎȚI MULȚUMIM CĂ NE AJUȚI SĂ FACEM RADIOGRAFIA SPITALULUI TĂU, DIRECT DE LA SURSĂ.", // FUNKY CITIZENS
  WELCOME_PANEL_1_TEXT:
    "Ca să schimbăm ce nu funcționează, trebuie să punem presiune pe decidenți. Primul pas e să vorbim despre disfuncționalități.", // FUNKY CITIZENS
  WELCOME_PANEL_2_TITLE: "CUM FUNCȚIONEAZĂ?", // FUNKY CITIZENS
  WELCOME_PANEL_2_TEXT_1:
    "Tu folosești aplicația: ne spui ce probleme există sau te informezi despre drepturile tale de angajat în domeniul sanitar.", // FUNKY CITIZENS
  WELCOME_PANEL_2_TEXT_2:
    "Noi preluăm sesizările tale și le trimitem către jurnaliști de investigație. Dacă observăm o problemă sistemică, punem presiune pe autorități să rezolve.", // FUNKY CITIZENS
  WELCOME_PANEL_3_TITLE: "POT AVEA ÎNCREDERE ÎN VOI?", // FUNKY CITIZENS
  WELCOME_PANEL_3_TEXT_1:
    "Absolut. Încrederea pe care ne-o acorzi ne onorează și ne obligă să avem un simț al răspunderii și mai dezvoltat.", // FUNKY CITIZENS
  WELCOME_PANEL_3_TEXT_2:
    "Suntem o organizație responsabilă, cu un istoric de 8 ani în care am colaborat adesea cu presa pe subiecte sensibile și tehnice.", // FUNKY CITIZENS
  WELCOME_PANEL_4_TITLE: "[RO] Welcome screen #4", // FUNKY CITIZENS
  WELCOME_PANEL_4_TEXT:
    "[RO] Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit nibh vel cursus interdum. Nullam hendrerit diam nec orci malesuada feugiat.", // FUNKY CITIZENS
  POLL_RESPONSE_SUBMITTED_TITLE: "Mulțumim",
  POLL_RESPONSE_SUBMITTED_TEXT: "Răspunsul tău a fost trimis.",
  POLL_RESPONSE_ERROR_TITLE: "Eroare",
  POLL_RESPONSE_ERROR_TEXT:
    "A apărut o eroare în trimiterea răspunsului tău la sondaj.",
  POLL_TITLE: "Sondaj",
  POLL_DECLINE: "Nu vreau să răspund.",
  POST_AUTHOR_TIMESTAMP: "Postat {{DATE_AND_TIME}} de {{AUTHOR_NAME}}",
  KNOW_YOUR_RIGHTS_ATTRIBUTION:
    "[RO] This guide was developed by Migrant-Rights.org",
  KNOW_YOUR_RIGHTS_DISCLAIMER:
    "[RO] The Content belongs to Migrant-Rights.org, and all due process was followed to ensure the accuracy of the content.  However, Migrant-Rights.org accepts no liability arising from any errors or omissions. Migrant-Rights.org should be made aware of any modifications to content made by Licensee. Licensee is responsible for ensuring it has the most up to date information. ",
  RIGHTS_DETAIL_WHAT_IS_YOUR_RIGHT: "Care este dreptul tău?",
  RIGHTS_DETAIL_CITATION: "[RO] What does the law say?",
  RIGHTS_DETAIL_CALL_TO_ACTION: "Ai o problemă?",
  RIGHTS_DETAIL_REPORT_ISSUE: "Raportează o problemă",
  EMPLOYER_SETUP_ERROR_TITLE: "Eroare",
  EMPLOYER_SETUP_ERROR_TEXT: "A apărut o eroare în afișarea listei de spitale.",
  PROJECT_SETUP_ERROR_TITLE: "Eroare",
  PROJECT_SETUP_ERROR_TEXT:
    "[RO] There was an error retrieving information about your project.",
  REPORT_ISSUE_ERROR_TITLE: "Eroare",
  REPORT_ISSUE_ERROR_TEXT: "A apărut o eroare în trimiterea problemei tale.",
  LANGUAGE_ERROR_TITLE: "Eroare",
  LANGUAGE_ERROR_TEXT: "Nu am reușit să schimbăm limba.",
  BARCODE_SCAN_PROMPT: "[RO] Point the camera to the project code to scan it",

  PROMPT_PROJECT_CODE: "[RO] Connect to a project",
  LBL_PROJECT_CODE_DESCRIPTION: "[RO] Or type your project code here:",
  LBL_PROJECT_CODE: "[RO] Or type your project code here:",
  LBL_CONNECT_TO_PROJECT: "[RO] Connect",
  CATEGORY_YOUR_COMMUNITY: "Resurse utile", // FUNKY CITIZENS
  CATEGORY_DESC_YOUR_COMMUNITY:
    "Legea e principalul tău aliat. Dacă vrei să citești în întregime legile relevante, o poți face de aici.", // FUNKY CITIZENS
  CATEGORY_YOUR_PROJECT: "Despre Funky Citizens", // FUNKY CITIZENS
  CATEGORY_DESC_YOUR_PROJECT:
    "Află cine suntem și cum de am ajuns să creăm această aplicație.", // FUNKY CITIZENS
  CATEGORY_YOUR_RIGHTS: "Cunoaște-ți Drepturile",
  CATEGORY_DESC_YOUR_RIGHTS:
    "Află ce drepturi ai, atât ca cetățean, cât și ca angajat în domeniul sanitar.", // FUNKY CITIZENS
  CATEGORY_HOTLINE: "[RO] Hotline",
  CATEGORY_DESC_HOTLINE: "[RO] Learn about the confidential hotline",
  PROMISES_GUIDE_TITLE: "[RO] Whistleblowing",
  PROMISES_GUIDE_INFO:
    "Scurt/2 despre avertizorii de integritate (whistleblower).", // FUNKY CITIZENS
  CATEGORY_WHISTLEBLOWING: "Whistleblowing", // FUNKY CITIZENS
  CATEGORY_DESC_WHISTLEBLOWING:
    "Scurt/2 despre avertizorii de integritate (whistleblower).", // FUNKY CITIZENS
  RIGHTS_GUIDE_TITLE: "Cunoaște-ți drepturile",
  RIGHTS_GUIDE_INTRO:
    "[RO] NOTE: This document sets out some but not all of a worker's rights as set out in the Qatar Labour and Immigration Laws; there are additional rights. Workers should attend the Labour Department (taking all employment documents) with any questions they have in relation to these or their other legal and regulatory rights.",
  LBL_ABOUT_TITLE: "Despre \u201EDe Gardă\u201D",
  ABOUT_POWEREDBY: "\u201EDe Gardă\u201D este susținut de Worker Connect",
  ABOUT_CONTENT: "Copyright © 2021 Caravan Studios, o divizie TechSoup",
  ABOUT_CONTENT_KYR_ATTRIBUTION:
    "[RO] The Know Your Rights section of this app includes the following content developed by Migrant-Rights.org:",
  ABOUT_CONTENT_KYR_MR_TITLE: "[RO] Know Your Rights (UAE)",
  ABOUT_CONTENT_KYR_MR_COPYRIGHT: "[RO] Copyright © 2018 Migrant-Rights.org",
  ABOUT_PRIVACY_LINK: "Politica de Confidențialitate",
  MSG_CONNECT_TO_DIFFERENT_PROJECT: "[RO] Connect to a different project...",
  MSG_NOT_CONNECTED_TO_PROJECT: "[RO] You are not connected to a project",
  MSG_CONNECT_TO_PROJECT: "[RO] Connect to a project...",
  MOOD_RESPONSE_QUESTION: "Care e starea ta de spirit azi, la muncă?", // FUNKY
  RIGHTS_GUIDE_UAE_LAW_HEADER:
    "[RO] As a worker in the UAE, you are provided these rights by law:",
  RIGHTS_GUIDE_EXPO_STANDARDS_HEADER:
    "[RO] As a worker on the Expo 2020 project, you are provided the following benefits:",
  RIGHTS_GUIDE_CATEGORY_GETTINGPAID: "[RO] Getting paid",
  RIGHTS_GUIDE_CATEGORY_OVERTIME: "[RO] Overtime",
  RIGHTS_GUIDE_CATEGORY_WORKHOURS: "[RO] Work hours and holidays",
  RIGHTS_GUIDE_CATEGORY_DOCUMENTS: "[RO] Passports and personal documents",
  RIGHTS_GUIDE_CATEGORY_CONTRACTS: "[RO] Employment contracts",
  RIGHTS_GUIDE_CATEGORY_LEAVE: "[RO] Annual leave",
  RIGHTS_GUIDE_CATEGORY_FEMALE: "[RO] Female employees",
  RIGHTS_GUIDE_CATEGORY_MEDICAL: "[RO] Medical care",
  RIGHTS_GUIDE_CATEGORY_ACCOMMODATIONS: "[RO] Where I live",
  RIGHTS_GUIDE_CATEGORY_COOKINGEATING: "[RO] Cooking and eating",
  RIGHTS_GUIDE_CATEGORY_RECRUITMENT: "[RO] Recruitment",
  RIGHTS_GUIDE_CATEGORY_ISSUES: "[RO] Reporting Issues",
  RIGHTS_GUIDE_CATEGORY_COMMUNICATION:
    "[RO] Worker orientation and communication",
  MSG_INACTIVE_PROJECT:
    "Serviciul \u201EDe Gardă\u201D este momentan indisponibil. Te rugăm să încerci mai târziu.",
  ACTIVATION_DEEP_LINK_CONFIRM_TITLE:
    "[RO] Are you sure you want to connect to a different project?",
  ACTIVATION_DEEP_LINK_SUCCESS:
    "[RO] You have successfully connected to a project",
  CATEGORY_ABOUT_EXPO: "[RO] About Expo 2020 Dubai",
  CATEGORY_WORKER_HOTLINE: "[RO] Worker Hotline",
  CATEGORY_DESC_WORKER_HOTLINE:
    "[RO] Register a complaint over the phone and speak to someone who will listen to your issues.",
  POLL_ACTION: "Răspunde",
  LBL_LEARN_MORE_HOTLINE: "[RO] Learn about the confidential hotline",
  HOTLINE_DESCRIPTION_1:
    "[RO] Expo 2020 has a confidential hotline that you can call, where you can safely register your complaint by speaking to someone who will listen to your issues.",
  HOTLINE_DESCRIPTION_2:
    "[RO] You will be anonymous and you do not have to leave your name, but you must tell them you are on Expo 2020 and you should tell them the name of your Employer.",
  HOTLINE_DESCRIPTION_3:
    "[RO] Your complaint will be reported to Expo 2020 only.",
  HOTLINE_IS_FREE_DESCRIPTION:
    "[RO] This is a free number. You will not be charged for calling the Expo hotline.",
  LBL_HOTLINE_CALL_NOW: "[RO] Call now",
  HOTLINE_HEADER: "[RO] Expo 2020 Worker Hotline",
  HOTLINE_HEADER_SHORT: "[RO] Hotline",
  EXPO_2020_DUBAI: "[RO] Expo 2020 Dubai",
  ABOUT_EXPO_1:
    "[RO] The East Bridge Reconstruction Project will improve safety and traffic conditions for over 100,000 vehicles, pedestrians, and bicyclists that use the bridge each day.",
  ABOUT_EXPO_2:
    "[RO] Your hard work will make all of this possible. You are one of the thousands of workers working on this project and we’re proud you’re a part of it.",
  NETWORK_ERROR: "Eroare de rețea",
  LBL_EMPLOYERSEARCH: "[RO] Type in your employer name",
  EMPLOYERSEARCH_DESC: "[RO] You can find your employer on your Expo ID card",
  EMPLOYERSEARCH_BUTTON: "[RO] I don't see my employer name",
  EMPLOYERSEARCH_PLACEHOLDER: "[RO] Search",
  EMPLOYERSEARCH_EMPTY: "[RO] No companies found",
  HOTLINE_NUMBER_WORD: "800-WORKER",
  HOTLINE_NUMBER_NUMERAL: "800 967537",

  // FUNKY CITIZENS
  CATEGORY_EQUIPMENT_CONSUMABLES: "Echipament și materiale consumabile",
  CATEGORY_CLEANING_PRODUCTS: "Produse de curățenie",
  CATEGORY_HOSPITAL_WORK: "Munca la spital",
  CATEGORY_CORRUPTION: "Corupție",
  CATEGORY_COVID: "COVID-19",
  RIGHTS_GUIDE_CATEGORY_FUNDAMENTAL: "Drepturi fundamentale",
  RIGHTS_GUIDE_CATEGORY_EMPLOYEE: "Drepturile angajatului",
  RIGHTS_GUIDE_CATEGORY_WHISTLEBLOWER: "Drepturile avertizorului",
  RIGHTS_GUIDE_CATEGORY_ADMIN_PROFESSIONAL:
    "Drepturi administrative și profesionale",
  RIGHTS_GUIDE_ROMANIA_LAW_HEADER:
    "Ca un lucrător în sănătate, în România, îți sunt asigurate prin lege aceste drepturi:",
  ABOUT_FUNKY_1:
    "Salut, noi suntem Funky Citizens, un ONG care activează în domeniul bunei guvernări. Din 2012, milităm pentru transparență, eficiență și, dacă se poate, și competență în instituțiile publice. ",
  ABOUT_FUNKY_2:
    "Ne pricepem la administrație publică, anticorupție și bani publici.  Facem educație civică pentru a pregăti astăzi cetățenii de mâine. Știm să ne uităm în bugetele publice ca nimeni altcineva.",
  ABOUT_FUNKY_3:
    "Dacă vrei să afli mai multe despre noi, ne poți scrie oricând pe weare@funkycitizens.org - promitem să-ți răspundem prompt.",
  ABOUT_PROJECT_TITLE: "Despre acest proiect",
  ABOUT_PROJECT_DESC_1:
    "Aplicația \u201EDe Gardă\u201D este o adaptare a unei aplicații similare - Worker Connect - dezvoltată de Caravan Studios, o divizie a Techsoup Global, o asociație care face schimbare socială prin tehnologie.",
  ABOUT_PROJECT_DESC_2:
    "Ideea aplicației ne-a venit în primăvara lui 2020, în haosul de la începutul pandemiei. Vrem să rezolvăm, împreună, cât mai multe probleme din sistem. Dar primul pas este să aflăm de ele.",
  ABOUT_FEEDBACK: "Trimite-ne feedback",
  HOSPITAL: "Spital",
  ENTER_HOSPITAL_NAME: "Introdu numele unui spital",
  ENTER_HOSPITAL_NAME_OPTIONAL: "Introdu numele unui spital (opțional)",

  PDF_LOADING_ERROR: "[RO] Failed to load PDF file!",
  UNEXPECTED_ERROR: "[RO] Unexpected Error",
  NEED_TO_RESTART: "[RO] Need to restart",
  RESTART: "[RO] Restart"
};
