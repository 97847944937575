import expoStandards from "../../variables/expoStandards";
import types from "./actionTypes";

const initialState = filterExpoStandards(expoStandards);

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EXPO_STANDARDS:
      return {
        ...filterExpoStandards(action.expoStandards)
      };
    default:
      return state;
  }
};

export function filterExpoStandards(expoStandards) {
  return expoStandards.reduce((prev, next) => {
    if (prev[next.category]) {
      return {
        ...prev,
        [next.category]: [...prev[next.category], next]
      };
    } else {
      return {
        ...prev,
        [next.category]: [next]
      };
    }
  }, {});
}
