import React, { Component } from "react";
import { View, StyleSheet, Linking, I18nManager, Image } from "react-native";
import { Screen } from "../../utils";
import {
  Content,
  Card,
  CardItem,
  Body,
  Text,
  Left,
  Button,
  Icon,
  Right,
  H1
} from "native-base";
import HTML from "react-native-render-html";
import { connect } from "react-redux";
import FastImage from "react-native-fast-image";
import { getProjectProfile } from "./actions";
import I18n from "../../i18n";
import { isOffline } from "../../offline";
import DeviceInfo from "react-native-device-info";

export default Screen(
  connect(state => ({
    settings: state.settings,
    projectProfile: state.projectProfile.projectProf
  }))(
    class ProjectDetails extends Component {
      render() {
        return (
          <Content padder>
            <Card aboutExpo>
              <CardItem cardBody>
                <Image
                  source={require("../../assets/img/about_project.png")}
                  style={{
                    height: 250,
                    width: null,
                    flex: 1,
                    marginHorizontal: 20
                  }}
                  resizeMode="contain"
                />
              </CardItem>
              <CardItem>
                <Body>
                  <Text aboutDescription>
                    {I18n.t("appStrings.ABOUT_FUNKY_1")}
                  </Text>
                  <Text aboutDescription>
                    {I18n.t("appStrings.ABOUT_FUNKY_2")}
                  </Text>
                  <Text aboutDescription>
                    {I18n.t("appStrings.ABOUT_FUNKY_3")}
                  </Text>
                </Body>
              </CardItem>
              <CardItem>
                <Body>
                  <H1 aboutFunky>{I18n.t("appStrings.ABOUT_PROJECT_TITLE")}</H1>
                  <Text aboutDescription>
                    {I18n.t("appStrings.ABOUT_PROJECT_DESC_1")}
                  </Text>
                  <Text aboutDescription>
                    {I18n.t("appStrings.ABOUT_PROJECT_DESC_2")}
                  </Text>
                </Body>
              </CardItem>
            </Card>
            <View about style={{ marginTop: 20 }}>
              <Text aboutAppName>{DeviceInfo.getApplicationName()}</Text>
              <Text
                selectable={false}
                version
                delayLongPress={10000}
                onLongPress={() =>
                  ActionSheet.show(
                    {
                      options: ["Erase all data and reset app", "Cancel"],
                      cancelButtonIndex: 1,
                      destructiveButtonIndex: 0,
                      title: "Advanced options"
                    },
                    buttonIndex => {
                      if (buttonIndex == 0) {
                        store.dispatch({ type: "APP_RESET" });
                        this.props.navigation.navigate("SetupScreens");
                        // store
                        //   .getState()
                        //   .tabsReducer.rootNavigator.navigate("SetupScreens");
                        // store.getState().tabsReducer.rootNavigator.dispatch(
                        //   StackActions.reset({
                        //     index: 0,
                        //     key: null,
                        //     actions: [
                        //       NavigationActions.navigate({
                        //         routeName: "SetupScreens"
                        //       })
                        //     ]
                        //   })
                        // );
                      }
                    }
                  )
                }
              >
                v{DeviceInfo.getReadableVersion()}
              </Text>
            </View>
            <View style={{ marginVertical: 30 }}>
              <Button
                rounded
                bordered
                small
                aboutContact
                style={{ alignSelf: "center" }}
                onPress={() =>
                  Linking.openURL(
                    "mailto:degarda@funkycitizens.org?subject=Feedback%20despre%20%E2%80%9EDe%20Gard%C4%83%E2%80%9D"
                  )
                }
              >
                <Text>{I18n.t("appStrings.ABOUT_FEEDBACK")}</Text>
              </Button>
            </View>
            <View about>
              <Text center aboutCopyright>
                {I18n.t("appStrings.ABOUT_POWEREDBY")}
              </Text>
              <Text center aboutCopyright>
                {I18n.t("appStrings.ABOUT_CONTENT")}
              </Text>

              <Text
                center
                aboutPrivacy
                onPress={() =>
                  Linking.openURL("https://www.workerapp.org/degarda-privacy")
                }
              >
                {I18n.t("appStrings.ABOUT_PRIVACY_LINK")}
              </Text>
            </View>
          </Content>
        );
      }
    }
  )
);

const style = StyleSheet.create({
  image: {
    width: "100%",
    height: 200
  }
});

const htmlStyle = StyleSheet.create({
  span: {
    fontWeight: "bold"
  }
});
