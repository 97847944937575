import { Linking, Platform, Alert } from "react-native";
import PushNotificationIOS from "@react-native-community/push-notification-ios";
import PushNotification from "react-native-push-notification";
import { GCM_SENDER_ID, FCM_PROJECT_ID } from "./const";
import { setNotificationToken } from "./actions/settings";
import { uriPrefix } from "./const";

let notificationUrl = null;
export function getNotificationUrl() {
  const url = notificationUrl;
  notificationUrl = null;
  return url;
}

export default () =>
  PushNotification.configure({
    // (optional) Called when Token is generated (iOS and Android)
    onRegister: async function (token) {
      await setNotificationToken(token.token);
    },

    // (required) Called when a remote or local notification is opened or received
    onNotification: function (notification) {
      // process the notification
      if (notification.badge) {
        PushNotification.setApplicationIconBadgeNumber(+notification.badge);
        // setOpenTasksBadge(+notification.badge);
      }
      if (
        notification.userInteraction &&
        (Platform.OS === "android" ? notification.url : notification.data.url)
      ) {
        const url = notification.url || notification.data.url;
        const protomatch = /^(workerconnect):\/\//;
        if (url) {
          const replacedUrl = url
            .replace(protomatch, uriPrefix)
            .replace(uriPrefix + "poll/", uriPrefix + "app/poll/");
          notificationUrl = replacedUrl;
          if (Linking.canOpenURL(replacedUrl)) {
            Linking.openURL(replacedUrl);
          }
        }
      }

      // required on iOS only (see fetchCompletionHandler docs: https://facebook.github.io/react-native/docs/pushnotificationios.html)
      notification.finish(PushNotificationIOS.FetchResult.NoData);
    },

    // ANDROID ONLY: GCM Sender ID (optional - not required for local notifications, but is need to receive remote push notifications)
    senderID: FCM_PROJECT_ID, // GCM_SENDER_ID,

    // IOS ONLY (optional): default: all - Permissions to register.
    permissions: {
      alert: true,
      badge: true,
      sound: true
    },

    // Should the initial notification be popped automatically
    // default: true
    popInitialNotification: true,

    /**
     * (optional) default: true
     * - Specified if permissions (ios) and token (android and ios) will requested or not,
     * - if not, you must call PushNotificationsHandler.requestPermissions() later
     */
    requestPermissions: true
  });
