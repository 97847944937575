import store from "../../store";
import React, { Component, Fragment } from "react";
import { TouchableWithoutFeedback, Image } from "react-native";
import { Header, Icon, Text, View, Button, H1, H3 } from "native-base";
import I18n from "../../i18n";
import LinearGradient from "react-native-linear-gradient";
import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";

export default class Welcome1 extends Component {
  static navigationOptions = {
    header: null
  };

  render() {
    return (
      <Fragment>
        <LinearGradient
            colors={wcColors.welcome.backgroundGradient}
            locations={wcColors.welcome.gradientLocations}
            start={wcColors.welcome.gradientStart}
            end={wcColors.welcome.gradientEnd}
            style={styles.gradient}
        />

        <Header hasTabs welcome>
          <Button bordered small onPress={() => this.props.navigation.goBack()} style={{borderColor:'white'}}>
            <Text numberOfLines={1} style={{color:"white"}}>{I18n.t("appStrings.LBL_CHANGE_LANGUAGE")}</Text>
          </Button>
        </Header>

        <View style={{flex:1}}>
          <View style={{flex:2, justifyContent: 'center', alignItems: 'center', paddingHorizontal:20}}>
            <H3 style={{color:'white', textAlign: 'center', fontWeight: 'bold'}}>{I18n.t("appStrings.WELCOME_PANEL_1_TITLE")}</H3>
          </View>
          <View style={{flex:3}}>
            <Image
                style={{flex:1, height: undefined, width: undefined, margin:15}}
                source={require('../../assets/img/welcome/welcome1.png')}
                resizeMode="contain"
            />
          </View>
          <View style={{flex:2}}>
            <Text style={{padding:20, color:'white', textAlign: 'center'}}>{I18n.t("appStrings.WELCOME_PANEL_1_TEXT")}</Text>
          </View>
          <View style={{flex:1, justifyContent: 'flex-end', marginBottom: 36}}>
            <Button
              testID={"nextButton"}
              rounded
              welcome
              onPress={() => this.props.navigation.navigate("Welcome2")}
            >
              <Text numberOfLines={1} uppercase={true}>
                {I18n.t("appStrings.NAV_CONTINUE")}
              </Text>
              
            </Button>
          </View>
        </View>


      </Fragment>
    );
  }

}