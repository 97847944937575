import React, { Component, Fragment } from "react";
import { TouchableWithoutFeedback, Image } from "react-native";
import { Header, Icon, Text } from "native-base";
import I18n from "../../i18n";
import LinearGradient from "react-native-linear-gradient";

import Welcome from "../../components/Welcome";
import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";

export default function WelcomeFactory(
  title,
  description,
  navigateTo,
  buttonText,
  image,
  background,
  hasBack = true
) {
  return class WelcomeFactory extends Component {
    static navigationOptions = {
      header: null
    };

    render() {
      return (
        <Fragment>
          {background ? (
            <Image style={styles.backgroundImage} source={background} />
          ) : (
            <LinearGradient
              colors={wcColors.welcome.backgroundGradient}
              locations={wcColors.welcome.gradientLocations}
              start={wcColors.welcome.gradientStart}
              end={wcColors.welcome.gradientEnd}
              style={styles.gradient}
            />
          )}
          <TouchableWithoutFeedback
            onPress={() => this.props.navigation.goBack()}
          >
            <Header hasTabs welcome>
              {hasBack && (
                <>
                  {typeof hasBack != "function" && (
                    <Icon name={I18n.isRTL ? "arrow-forward" : "arrow-back"} />
                  )}
                  <Text numberOfLines={1}>
                    {typeof hasBack == "function"
                      ? hasBack()
                      : I18n.t("appStrings.NAV_BACK")}
                  </Text>
                </>
              )}
            </Header>
          </TouchableWithoutFeedback>
          <Welcome
            title={title}
            description={description}
            navigateTo={navigateTo}
            buttonText={buttonText}
            image={image}
            {...this.props}
          />
        </Fragment>
      );
    }
  };
}
