import variable from "./../variables/platform";
import colors from "../variables/wcColors";
import { I18nManager, Platform } from "react-native";
import { bold } from "ansi-colors";

export default (variables = variable) => {
  const textTheme = {
    writingDirection: I18nManager.isRTL ? "rtl" : "ltr",
    fontSize: variables.DefaultFontSize,
    fontFamily: variables.fontFamily,
    color: variables.textColor,
    alignSelf: "flex-start",
    ".note": {
      color: colors.text.note,
      fontSize: variables.noteFontSize
    },
    ".divider": {
      fontFamily: "Nunito",
      color: colors.text.divider,
      marginVertical: 10
    },
    ".settingsLabel": {
      color: colors.text.settingsLabel,
      fontWeight: "bold",
      marginVertical: 10
    },
    ".welcomeMessage": {
      flex: 1,
      alignSelf: "center",
      color: colors.text.welcomeMessage,
      fontWeight: "bold",
      padding: 15
    },
    ".dialog": {
      fontFamily: "Nunito",
      color: colors.text.dialog,
      paddingVertical: 15
    },
    ".pollTitle": {
      color: colors.text.pollTitle,
      marginTop: 15
    },
    ".pollDescription": {
      color: colors.text.pollDescription,
      marginVertical: 15
    },
    ".pollResponseText": {
      color: colors.text.pollResponseText
    },
    ".pollDecline": {
      color: colors.text.pollDecline
    },
    ".communityDescription": {
      marginVertical: 10,
      color: colors.text.communityDescription,
      fontSize: 14
    },
    ".aboutDescription": {
      color: colors.demoGrey1,
      fontSize: 16,
      marginVertical: 10
    },
    ".aboutContact": {
      color: colors.funkyBlue1
    },
    ".center": {
      textAlign: "center",
      width: "100%",
      marginVertical: 5,
      ".reportissue": {
        marginVertical: 15,
        color: colors.text.center.reportIssue,
        fontSize: variables.noteFontSize * 0.9
      }
    },
    ".attribution": {
      color: colors.text.attribution
    },
    ".disclaimer": {
      fontSize: variables.noteFontSize * 0.8
    },
    ".title": {
      marginTop: 10,
      color: colors.text.title
    },
    ".copyright": {
      color: colors.text.copyright,
      fontSize: variables.noteFontSize * 0.9
    },
    ".reportissue": {
      color: colors.text.reportIssue.text,
      "NativeBase.Text": {
        ".label": {
          fontWeight: "bold",
          color: colors.text.reportIssue.label
        }
      }
    },
    ".aboutAppName": {
      color: colors.text.aboutAppName,
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      width: "100%"
    },
    ".aboutCopyright": {
      color: colors.demoGrey2,
      fontSize: 14,
      margin: 2
    },
    ".aboutKYR": {
      marginTop: 5,
      fontSize: 12,
      paddingHorizontal: 10,
      width: "100%",
      textAlign: "center"
    },
    ".aboutPrivacy": {
      fontSize: 14,
      textAlign: "center",
      paddingHorizontal: 5,
      color: colors.funkyBlue1,
      margin:10,
      width: "100%"
    },
    ".version": {
      color: colors.text.version,
      fontSize: 14,
      textAlign: "center",
      width: "100%"
    },
    ".skipProject": {
      color: colors.text.skipProject,
      fontSize: variables.noteFontSize
    },
    ".connectProject": {
      color: colors.text.connectProject,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      marginVertical: 15,
      paddingHorizontal: 15,
      width: "100%",
      flex: 1
    },
    ".yourProject": {
      color: colors.text.yourProject,
      margin: 0
    },
    ".welcome": {
      color: colors.text.welcome,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      paddingHorizontal: 5,
      width: "100%",
      flex: 1
    },
    ".setup": {
      color: colors.text.setup,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      marginVertical: 15,
      paddingHorizontal: 15,
      width: "100%",
      flex: 1
    },
    ".nationality": {
      color: colors.text.nationality,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      paddingHorizontal: 15,
      width: "100%",
      flex: 1
    },
    ".selectProject": {
      color: colors.text.selectProject,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      paddingHorizontal: 15,
      width: "100%",
      flex: 1
    },
    ".employerSetup": {
      color: colors.text.employerSetup,
      fontSize: 16,
      fontWeight: "600",
      textAlign: "center",
      marginTop: 23,
      marginBottom: 20,
      paddingHorizontal: 15,
      width: "100%",
      flex: 1
    },
    ".about": {
      color: colors.text.about,
      padding: 15,
      marginTop: 5
    },
    ".rightsDescription": {
      color: colors.text.rightsDescription
    },
    ".employer": {
      color: colors.text.employer,
      padding: 10
    },
    ".hotlineSmall": {
      fontSize: 15
    },
    ".hotlineMedium": {
      fontSize: 16
    },
    ".autosuggest": {
      marginBottom: 10
    },
    ".disconnected": {
      color: colors.card.disconnected.text
    }
  };

  return textTheme;
};
