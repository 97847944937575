import React, { Component, Fragment } from "react";
import I18n from "../../i18n";
import { Content, Button, Text, View, H1 } from "native-base";
import LinearGradient from "react-native-linear-gradient";
import languages from "../../locale/languages";
import { setLanguage } from "../../actions/settings";
import { connect } from "react-redux";
import SplashScreen from "react-native-splash-screen";

import wcColors from "../../../native-base-theme/variables/wcColors";
import styles from "../../styles";
import { Platform } from "react-native";

export default connect(state => ({
  language: state.settings.language,
  detectedLanguage: state.settings.detectedLanguage
}))(
  class ChooseLanguage extends Component {
    static navigationOptions = {
      header: null
    };

    constructor(props) {
      super(props);

      if (props.language == "ro" && props.detectedLanguage == "ro") {
        if (Platform.OS === "web") {
          props.navigation.navigate("JobFunction");
          return;
        }
        props.navigation.navigate("Welcome1");
      }

      SplashScreen.hide();
    }

    renderButtons = () => {
      return languages.map((lang, key) => (
        <Button
          key={key}
          rounded
          testID={"languageButton" + key}
          bordered
          languages
          onPress={() => this.changeLanguage(lang.languageCode)}
        >
          <Text>{lang.languageName}</Text>
        </Button>
      ));
    };

    changeLanguage = langCode => {
      setLanguage(langCode);
      if (Platform.OS === "web") {
        this.props.navigation.navigate("JobFunction");
        return;
      }
      this.props.navigation.navigate("Welcome1");
    };

    render() {
      return (
        <View style={{ flex: 1 }}>
          <LinearGradient
            colors={wcColors.chooseLang.backgroundGradient}
            locations={wcColors.chooseLang.gradientLocations}
            start={wcColors.chooseLang.gradientStart}
            end={wcColors.chooseLang.gradientEnd}
            style={styles.gradient}
          />
          <View
            style={{
              padding: 10,
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              width: "100%",
              maxWidth: 500
            }}
          >
            {this.renderButtons()}
          </View>
        </View>
      );
    }
  }
);
